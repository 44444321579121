/* eslint-disable no-case-declarations */
import {
  INCOMMING_RESMED_RISK_NOTIFICATION,
  INITIALIZE_RESMED_ARRAY_FOR_NOTIFICATION,
  IS_REAL_DATA,
  RESET_REAL_DATA_FLAG,
  ADD_NEW_USER,
  PENDING_PATIENT_IS_AVAILABLE,
  ADD_NEW_PRACTICE,
  MANUALLY_NEW_PATIENT_ADDED,
  IMPORT_NEW_PATIENT_SUCCESSFULLY,
  ADD_NEW_LOG,
  ALERT_PATIENT_RISK_CHANGE,
  RISK_ALERT_PATIENT_REMOVE,
} from './resmedTypes';

const initialState = {
  isRealTimeDataAvailable: null,
  pendingPatientIsAvailable: false,
  newPatientAddedManully: false,
  importNewPatientSuccessfully: false,
  addNewLog: false,
  addNewPractice: false,
  addNewUser: false,
  alertPatientRiskChange: [],
};

export const resmedReducer = (state = initialState, action) => {
  switch (action.type) {
    case IS_REAL_DATA:
      return {
        ...state,
        isRealTimeDataAvailable: action.payload,
      };
    case INITIALIZE_RESMED_ARRAY_FOR_NOTIFICATION:
      return {
        ...state,
        alertPatientRiskChange: action.payload,
      };
    case PENDING_PATIENT_IS_AVAILABLE:
      return {
        ...state,
        pendingPatientIsAvailable: !state.pendingPatientIsAvailable,
      };
    case MANUALLY_NEW_PATIENT_ADDED:
      return {
        ...state,
        newPatientAddedManully: !state.newPatientAddedManully,
      };
    case IMPORT_NEW_PATIENT_SUCCESSFULLY:
      return {
        ...state,
        importNewPatientSuccessfully: !state.importNewPatientSuccessfully,
      };
    case ADD_NEW_LOG:
      return {
        ...state,
        addNewLog: !state.addNewLog,
      };
    case ADD_NEW_PRACTICE:
      return {
        ...state,
        addNewPractice: !state.addNewPractice,
      };
    case ADD_NEW_USER:
      return {
        ...state,
        addNewUser: !state.addNewUser,
      };
    case INCOMMING_RESMED_RISK_NOTIFICATION:
      let tempArray = [];

      let updatedAlertPatientRiskChange =
        state.alertPatientRiskChange?.length > 0
          ? state.alertPatientRiskChange.filter((msg) => {
              return action?.payload?.patient?._id !== msg?.patient?._id;
            })
          : action.payload;
      if (state.alertPatientRiskChange?.length > 0) {
        tempArray = [...updatedAlertPatientRiskChange, action.payload];
      } else {
        tempArray = [action.payload];
      }
      return {
        ...state,
        alertPatientRiskChange: [...tempArray],
      };
    case ALERT_PATIENT_RISK_CHANGE:
      let temp = [];
      if (state.alertPatientRiskChange && state.alertPatientRiskChange.length > 0) {
        temp = state?.alertPatientRiskChange.filter((obj) => obj._id !== action.payload._id);

        temp.push(action.payload);
      } else {
        temp.push(action.payload);
      }
      return {
        ...state,
        alertPatientRiskChange: [...temp],
      };
    case RISK_ALERT_PATIENT_REMOVE:
      let _temp = [];
      if (state.alertPatientRiskChange && state.alertPatientRiskChange.length > 0) {
        _temp = state?.alertPatientRiskChange.filter((obj) => obj._id !== action.payload);
      } else {
        _temp = [];
      }
      return {
        ...state,
        alertPatientRiskChange: [..._temp],
      };
    case RESET_REAL_DATA_FLAG:
      return initialState;
    default:
      return state;
  }
};
