import React, { useCallback, useEffect, useRef, useState } from 'react';
import { PrimaryProv } from './PrimaryProv';
import { PrimirayClinical } from './PrimirayClinical';
import { Box, Paper, Grid, Stack, Checkbox, FormControlLabel } from '@mui/material';
import { SecProv } from './SecProv';
import { SecClinical } from './SecClinical';
import { MuiBtn } from 'src/pages/common/components/Button/CustomBtn';
import { defFontColor, dynamicObjValidation, makeFullName, printInBrowser } from 'src/utils';
import { successToast } from 'src/pages/common/components/snackBar/toast';
import { BULK_IMPORT_PATIENT_URL, GET_ALL_MD_BY_PRACTICE_ID, GET_ALL_QHCP_BY_PRACTICE_ID, getRequest, postRequest } from 'src/crud/crud';
import { useDispatch, useSelector } from 'react-redux';
import { ZoomWrapper } from 'src/pages/common/HOC/ZoomWrapper';
import { saveBulkImportCsv } from 'src/redux/patient/patientActions';
import { useHistory } from 'react-router-dom';
import { HistoryBlockWrraper } from 'src/pages/common/HOC/HIstoryBlockWrraper';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { ToolTipProvider } from 'src/pages/common/components/Tooltip/ToolTipProvider';
import { RouteProtectionWrapper } from 'src/pages/common/HOC/RouteProtectionWrapper';
import { PatientList } from './PatientList';

const WrapWithGridItem = ({ children, points = 6 }) => {
  return (
    <Grid item xs={points} sm={points} md={points} lg={points} xl={points}>
      {children}
    </Grid>
  );
};

const WrapWithCont = ({ children, dontmakeStyles, sx = {} }) => {
  return <Box {...(!dontmakeStyles && { component: Paper, sx: { p: 2, ...sx } })}>{children}</Box>;
};
const WrappWithGridCont = ({ children }) => {
  return (
    <Grid container spacing={2}>
      {children}
    </Grid>
  );
};

export const PatientBulkImport = () => {
  let pSeliniTialVal = {
    _providerId: ``,
    _clinicalUserId: ``,
    secProv: [],
    secClinical: [],
    assignToNewlyAddedUsers: false,
  };

  const [isBulkingImporting, setIsBulkingImporting] = useState({ loading: false, error: false });
  const [phaseSelection, setPhaseSelection] = useState({
    ...pSeliniTialVal,
  });
  const [patientList, setPatientList] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const setSuspense = (key, value, isWhole) => {
    if (isWhole) {
      setIsBulkingImporting(isWhole);
    } else setIsBulkingImporting((p) => ({ ...p, [key]: value }));
  };

  const [errors, setErrors] = useState({
    patients: ``,
    _providerId: ``,
    _clinicalUserId: ``,
  });

  const {
    patient: { bulkImportCsv: patientListRed },
    user: { user = { _practiceId: ``, _id: `` } },
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const history = useHistory();
  const { _practiceId = `` } = user;
  const [users, setUsers] = useState({ providers: [], clinicalStaffs: [] });
  const { providers, clinicalStaffs } = users;

  const renderCount = useRef(0);

  const isClickedPatient = () => {
    return !!(patientList?.length && patientList?.find((patient) => patient?.isChecked && !patient?.isAssigned));
  };

  useEffect(() => {
    const doIfArray = (arr, key) => {
      if (Array.isArray(arr)) setUsers((p) => ({ ...p, [key]: [...makeFullName(arr, [...keysToSave])] }));
    };
    let keysToSave = [`_id`, `firstName`, `lastName`];
    const getAllUSers = () => {
      getRequest(`${GET_ALL_MD_BY_PRACTICE_ID}${_practiceId}`)
        .then(({ data = { MD: [] } }) => {
          const { MD = [] } = data;
          doIfArray(MD, `providers`);
        })
        .catch();
      getRequest(`${GET_ALL_QHCP_BY_PRACTICE_ID}${_practiceId}`)
        .then(({ data = { QHCPUsers: [] } }) => {
          const { QHCPUsers = [] } = data;
          doIfArray(QHCPUsers, `clinicalStaffs`);
        })
        .catch();
    };
    if (renderCount.current === 0) getAllUSers();
    renderCount.current = renderCount.current + 1;
    setPatientList([
      ...patientListRed.map((patient) => {
        return {
          ...patient,
          isChecked: false,
          isAssigned: false,
          assignToNewlyAddedUsers: false,
          users: {
            secondaryProviders: [],
            secondaryClinicalUsers: [],
            _clinicalUserId: ``,
            _providerId: ``,
          },
        };
      }),
    ]);

    return () => resetValues(true);
  }, [renderCount, patientListRed]);

  const { _providerId, _clinicalUserId, secProv, secClinical, assignToNewlyAddedUsers } = phaseSelection;

  const doIfAllSelected = (sucessCb, failureCb) => {
    const isAllChecked = patientList?.filter((item) => item?.isChecked && item?.isAssigned)?.length === patientList?.length;
    if (isAllChecked) return sucessCb();
    return failureCb();
  };

  const validationProtection = (failureCb, succesCb) => {
    return doIfAllSelected(() => {
      if (succesCb) return succesCb();
      return successToast(`You have selected all the patients`);
    }, failureCb);
  };
  const resetValues = (isUnmount) => {
    setPhaseSelection({ ...pSeliniTialVal });
    setIsSubmitted(false);
    if (isUnmount) {
      dispatch(saveBulkImportCsv([]));
      setIsBulkingImporting({ loading: false, error: false });
    }
  };

  const onChangeHandler = useCallback((key, value) => {
    setPhaseSelection((p) => ({ ...p, [key]: value }));
  }, []);

  const onBlurValidation = (field, value) => {
    let isValid = false;
    const runDefValidation = () => {
      const setRequired = () => setErrors((p) => ({ ...p, [field]: `*Required` }));
      if (!value?.length && Array.isArray(value)) {
        setRequired();
      } else if (!value && typeof value === `string`) setRequired();
      else setErrors((p) => ({ ...p, [field]: `` }));
    };

    const rundDefValidForPat = () => {
      if (!isClickedPatient()) {
        setErrors((p) => ({ ...p, patients: `*Required` }));
      } else setErrors((p) => ({ ...p, patients: `` }));
    };
    switch (field) {
      case `_providerId`:
        runDefValidation();
        break;
      case `_clinicalUserId`:
        runDefValidation();
        break;
      case `patients`:
        rundDefValidForPat();
        break;
      default:
        rundDefValidForPat();
        isValid = !dynamicObjValidation({ obj: { _clinicalUserId, _providerId }, setErrors }) && isClickedPatient();
    }
    return isValid;
  };

  printInBrowser({ key: `asadxzcsfd`, value: { errors, patientList, phaseSelection } });

  const validateBeforeAssign = () => {
    validationProtection(() => {
      let isValid = onBlurValidation();
      if (isValid) {
        listSetter((prev) => {
          return prev?.map((patient) => {
            if (patient.isChecked && !patient.isAssigned) {
              return {
                ...patient,
                isChecked: true,
                isAssigned: true,
                assignToNewlyAddedUsers: !!assignToNewlyAddedUsers,
                users: {
                  _providerId,
                  _clinicalUserId,
                  secondaryClinicalUsers: secClinical?.length ? [...secClinical] : [],
                  secondaryProviders: secProv?.length ? [...secProv] : [],
                  ...(secClinical?.length ? { secondaryClinicalUsers: [...secClinical] } : []),
                  ...(secProv?.length ? { secondaryProviders: [...secProv] } : []),
                },
              };
            }

            return { ...patient };
          });
        });
        resetValues();
      }
    });
  };

  const submitHandler = (e) => {
    e.preventDefault();
    validationProtection(
      () => alert(`no`),
      () => {
        setSuspense(false, false, { loading: true, error: false });
        postRequest(BULK_IMPORT_PATIENT_URL, { patients: [...patientList] })
          .then(() => {
            setSuspense(`loading`, false);
            dispatch(saveBulkImportCsv([]));
            setIsSubmitted(true);
            successToast(`Patients have been imported via bulk import.`);
            history.push(`/`);
          })
          .catch(() => setSuspense(false, false, { loading: false, error: true }));
      }
    );
  };

  const filterUsers = (selectedUser, users) => {
    return users.filter((users) => users?._id !== selectedUser);
  };

  const listSetter = useCallback((cb) => {
    setPatientList((p) => cb(p));
  }, []);

  return (
    <RouteProtectionWrapper cond={!patientListRed?.length}>
      <HistoryBlockWrraper
        blockNavigation={() => {
          let pass = doIfAllSelected(
            () => false,
            () => true
          );
          console.log(!pass && isSubmitted, `asafdsfsdwqa`, patientList?.length)
          return !((!pass && isSubmitted) || !patientList?.length);
        }}
      >
        <form onSubmit={submitHandler}>
          <Stack direction={`column`} spacing={2} sx={{ p: 4 }}>
            <Box>
              <PatientList
                fixedUsers={users}
                list={patientList}
                setList={listSetter}
                hasError={errors.patients}
                onBlur={() => onBlurValidation(`patients`)}
              />
            </Box>
            <RenderPara isStaric={true} phrase={`Care Team`} />
            <WrapWithCont>
              <WrappWithGridCont>
                <WrapWithGridItem>
                  <PrimaryProv
                    value={phaseSelection._providerId}
                    error={errors?._providerId}
                    users={providers}
                    onChange={({ target: { value } }) => onChangeHandler(`_providerId`, value)}
                    onBlur={({ target: { value } }) => onBlurValidation(`_providerId`, value)}
                  />
                </WrapWithGridItem>
                <WrapWithGridItem>
                  <PrimirayClinical
                    value={phaseSelection._clinicalUserId}
                    error={errors?._clinicalUserId}
                    users={clinicalStaffs}
                    onChange={({ target: { value } }) => onChangeHandler(`_clinicalUserId`, value)}
                    onBlur={({ target: { value } }) => onBlurValidation(`_clinicalUserId`, value)}
                  />
                </WrapWithGridItem>
              </WrappWithGridCont>
            </WrapWithCont>
            <WrapWithCont dontmakeStyles={true}>
              <RenderPara phrase={`Additional Care Team`} />
              <WrappWithGridCont>
                <WrapWithGridItem>
                  <ZoomWrapper zoomProps={{ in: _providerId }}>
                    <WrapWithCont>
                      <SecProv
                        users={() => filterUsers(_providerId, providers)}
                        value={secProv}
                        onChange={(value) => onChangeHandler(`secProv`, value)}
                      />
                    </WrapWithCont>
                  </ZoomWrapper>
                </WrapWithGridItem>
                <WrapWithGridItem>
                  <ZoomWrapper zoomProps={{ in: _clinicalUserId }}>
                    <WrapWithCont>
                      <SecClinical
                        value={secClinical}
                        users={() => filterUsers(_clinicalUserId, clinicalStaffs)}
                        onChange={(value) => onChangeHandler(`secClinical`, value)}
                      />
                    </WrapWithCont>
                  </ZoomWrapper>
                </WrapWithGridItem>
                <WrapWithGridItem points={12}>
                  <ZoomWrapper zoomProps={{ in: true }}>
                    <WrapWithCont>
                      <Stack direction={`row`} justifyContent={`space-between`}>
                        <Box>
                          <FormControlLabel
                            sx={{
                              margin: `0px !important`,
                            }}
                            control={
                              <Checkbox
                                value={phaseSelection.assignToNewlyAddedUsers}
                                sx={{
                                  p: `0 5px 0 0 !important`,
                                  '&.Mui-checked': {
                                    color: `#1699c5`,
                                  },
                                }}
                                onChange={() => setPhaseSelection((p) => ({ ...p, assignToNewlyAddedUsers: !p.assignToNewlyAddedUsers }))}
                                checked={phaseSelection.assignToNewlyAddedUsers}
                              />
                            }
                            label="Automatically assign new users added into MonitAir as secondary users for this patient."
                          />
                        </Box>
                        <Box>
                          <ToolTipProvider
                            toolTipProps={{
                              title:
                                `Selecting this option will automatically assign all users added into this practice to this patient as a secondary user.  This is only recommended for practices with a workflow that requires every user to have visibility to all patients.  Once you select his feature it cannot be disabled for those patients which have this option`,
                            }}
                            element={<InfoOutlinedIcon sx={{ color: defFontColor }} />}
                          />
                        </Box>
                      </Stack>
                    </WrapWithCont>
                  </ZoomWrapper>
                </WrapWithGridItem>
              </WrappWithGridCont>
            </WrapWithCont>
            <Box>
              <Stack spacing={1} direction="row" justifyContent={`end`}>
                <Box>
                  <MuiBtn
                    exctProps={{
                      size: `large`,
                    }}
                    btnProps={{
                      onClick: validateBeforeAssign,
                    }}
                  >
                    Assign
                  </MuiBtn>
                </Box>
                <Box>
                  <MuiBtn
                    loading={isBulkingImporting}
                    exctProps={{
                      size: `large`,
                    }}
                    btnProps={{
                      sx: {
                        '&:disabled': {
                          color: `white`,
                          backgroundColor: `#99999999`,
                        },
                      },
                      type: `submit`,
                      disabled: doIfAllSelected(
                        () => false,
                        () => true
                      ),
                    }}
                  >
                    Submit
                  </MuiBtn>
                </Box>
              </Stack>
            </Box>
          </Stack>
        </form>
      </HistoryBlockWrraper>
    </RouteProtectionWrapper>
  );
};

const RenderPara = ({ phrase, isStaric }) => <Box sx={{ fontWeight: `bold`, fontSize: `large` }}>{`${isStaric ? '*' : ''}Assign ${phrase}`}</Box>;
