import React, { useEffect, useState } from 'react';
import { Box, IconButton } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { MuiSpinner } from 'src/pages/common/components/MuiSpinner/MuiSpinner';
import GppMaybeIcon from '@mui/icons-material/GppMaybe';
import TaskAltRoundedIcon from '@mui/icons-material/TaskAltRounded';
import { removeFromClickedRep } from 'src/redux/PatientOverview/Reports.actions';

export const RenderReportState = ({ children, reportStates, report, spinnerProps = {}, contProps = {} }) => {
  let blockPrps = {
    ...contProps,
    sx: { display: 'flex', justifyContent: 'center', padding: '0px !important', ...(contProps?.sx ? { ...contProps.sx } : {}) },
  };
  const { _id: reportId = '' } = report;
  const dispatch = useDispatch();
  const [reportState, setReportState] = useState('def');

  const {
    readyToSign: { clickedReports = [] },
  } = useSelector((state) => state);

  useEffect(() => {
    if (clickedReports.includes(reportId)) setReportState('inProgress');
  }, [clickedReports, reportId]);

  useEffect(() => {
    const reportStatusHandler = () => {
      let foundRep = reportStates?.find((rep) => rep?.reportId === reportId);
      if (foundRep) {
        setReportState(foundRep?.reportStatus);
        dispatch(removeFromClickedRep(reportId));
      }
    };
    reportStatusHandler();
  }, [reportStates, reportId]);

  return (
    <Box {...blockPrps}>
      {reportState === 'inProgress' && <MuiSpinner {...spinnerProps} sx={{ color: '#1699c5' }} />}
      {reportState === 'FAILED' && (
        <IconButton sx={{ color: 'red' }}>
          <GppMaybeIcon />
        </IconButton>
      )}
      {reportState === 'SUCCESS' && <TaskAltRoundedIcon color="success" />}
      {reportState === 'def' && children}
    </Box>
  );
};
