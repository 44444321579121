import React from 'react';
import { TemplateCompMultiSelect } from './TemplateCompMultiSelect';

export const Comp = ({ users, onChange, onBlur, value = [], error }) => {
  return (
    <TemplateCompMultiSelect
      users={users}
      compProps={{
        controlProps: {
          error: !!error,
          size: `small`,
          fullWidth: true,
          id: `bulkImportSecClinical`,
        },
        label: 'Secondary Clinical Staff',
        optionKey: `fullName`,
        optionValue: `_id`,
        selectProps: {
          value,
          helperText: error,
          label: `Secondary Clinical Staff`,
          name: `bulkImportSecClinical`,
          ...(onChange && { onChange }),
          ...(onBlur && { onBlur }),
        },
      }}
    />
  );
};
export const SecClinical = React.memo(Comp);
