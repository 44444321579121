import { Box, Collapse, Fade, Stack } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import GlowingCircle from 'src/pages/common/components/GlowingCircleAnimation/GlowingCircle';
import { newSocket } from 'src/pages/common/HOC/SocketWrapper';
import { capitalizeFirstLetter, emitEventOnLessThan5s, returnIfArr } from 'src/utils';
import { SingleUserLog } from './SingleUserLog';

let alertStyles = {
  fontWeight: 600,
  fontSize: `x-small`,
  color: `#0095c8`,
  alignSelf: `end`,
  display: `flex`,
  pb: 1,
};

export const MultipleUsersLogs = () => {
  const { patientData, isSwitchingPatient } = useSelector(({ patient }) => patient) || {};
  const { data } = patientData;
  const { _id: patientId, firstName, lastName } = data || false;
  let patientName = capitalizeFirstLetter(`${firstName} ${lastName}`);
  return patientId && !isSwitchingPatient ? <RenderLogs patientName={patientName} patientId={patientId} /> : <></>;
};

const RenderLogs = ({ patientName, patientId }) => {
  const {
    user: {
      user: { _id: loggedInUserId },
    },
    patient: {
      timer: { toggleButton },
    },
  } = useSelector((state) => state);
  const [users, setUsers] = useState([]);
  const [openUsers, setOpenUsers] = useState(false);

  const setUsersCached = useCallback((data, isLeft) => {
    const { userId } = data || false;
    if (isLeft) {
      setUsers((p) => {
        return [
          ...p.filter((user) => {
            const { userId: userIdInState } = user;
            return userIdInState !== userId;
          }),
        ];
      });
    } else setUsers(data);
  }, []);

  useEffect(() => {
    const wrappWithFilter = (data, cb) => {
      let removeLoggedUser = [...returnIfArr(data).filter((user) => user?.userId !== loggedInUserId)];
      cb(removeLoggedUser);
    };
    newSocket.on(`userAddedInWatchList`, (data) => wrappWithFilter(data, (users) => setUsersCached(users, false)));
    newSocket.on(`userRemovedFromWatchList`, (data) => setUsersCached(data, true));
    if (toggleButton) {
      let eventPayload = { patientId };
      newSocket.emit(`addUserToWatchList`, eventPayload);
    } else {
      emitEventOnLessThan5s();
    }
  }, [newSocket, patientId, toggleButton, loggedInUserId]);

  useEffect(() => {
    return () => setUsers([]);
  }, []);

  return (
    <Box>
      {toggleButton ? (
        <Box sx={{ pl: 0, p: 2 }}>
          {' '}
          <Stack direction="row" spacing={1}>
            <Fade in={!!users?.length} mountOnEnter unmountOnExit>
              <Box sx={{ pr: 0.5, pb: 1, pt: 1 }}>
                {' '}
                <GlowingCircle onClick={() => setOpenUsers((p) => !p)} />{' '}
              </Box>
            </Fade>
            <Collapse orientation="horizontal" in={!openUsers && users?.length} mountOnEnter unmountOnExit>
              <Box sx={{ ...alertStyles }}>{`Some other users are also monitoring ${patientName} click to view !`}</Box>
            </Collapse>
            <Collapse orientation="horizontal" in={!!(openUsers && users?.length)} mountOnEnter unmountOnExit>
              <Box sx={{ mt: 1 }}>
                {users.map((user, ind) => (
                  <SingleUserLog ind={ind} key={user.firstName} user={user} />
                ))}
              </Box>
            </Collapse>
          </Stack>
        </Box>
      ) : null}
    </Box>
  );
};
