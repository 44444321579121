// Import the necessary dependencies
import React, { useState } from 'react';
import { CustomizedDialogs } from 'src/pages/common/components/modal/CustomizeModal';
import { InputField } from 'src/pages/common/components/InputField/InputField';
import { ToastContainer } from 'react-toastify';
import { EXTERNAL_DEVICE_CREDENTIALS_UPDATE, patchRequest } from 'src/crud/crud';
import { errorToast, successToast } from 'src/pages/common/components/snackBar/toast';
import { PulseSpinner } from 'src/pages/common/components/spinner/spinner';
import { capitalizeFirstLetter, formatDeviceManufacturer } from 'src/utils';

export const SystemAdminViewAndUpdateCredentials = (props) => {
  // Define state variables using the useState hook
  const [clientId, setUserName] = useState('');
  const [clientSecret, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();

    // Get form data
    const formData = new FormData(event.target);

    // Validate form fields
    for (let [key, value] of formData.entries()) {
      // Check if the 'Client ID' field is empty
      if (key === 'clientId') {
        if (!value) {
          errorToast('Client ID is required');
          return;
        }
      }

      // Check if the 'Client Secret' field is empty
      if (key === 'clientSecret') {
        if (!value) {
          errorToast('Client Secret is required');
          return;
        }
      }
    }

    setLoading(true);

    // Prepare the route for the PATCH request
    let route = `${EXTERNAL_DEVICE_CREDENTIALS_UPDATE}/${props?.viewCredentials?._id}`;

    // Prepare the data for the PATCH request
    const data = {
      userName: clientId,
      password: clientSecret,
    };

    // Send the PATCH request to update the credentials
    patchRequest(route, data)
      .then((response) => {
        successToast('Edit successfully');
        props.setOpenViewDialog(false);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  // Capitalize the first letter of the practiceName obtained from props, viewCredentials, and _practiceId.
  // Store the result in the practiceNameCapitalized variable.
  let practiceNameCapitalized = capitalizeFirstLetter(props?.viewCredentials?._practiceId?.practiceName);

  let manufacturerNameFormatted = formatDeviceManufacturer(props?.viewCredentials?.deviceManufacturer);

  return (
    <CustomizedDialogs
      title="View Provider Credentials"
      open={props.openViewDialog}
      setOpen={() => {
        props.setOpenViewDialog(false);
      }}
      size="md"
      fullWidth={true}
    >
      <ToastContainer />
      <form onSubmit={handleSubmit}>
        <div>
          <div className="d-flex w-100">
            <div className="mt-2" style={{ width: `100%` }}>
              <div className="w-100">
                <div className="d-flex flex-wrap">
                  <div style={{ marginLeft: 0, width: `32%` }}>
                    <div className="form-group">
                      <InputField
                        disabled
                        size="small"
                        fullWidth={true}
                        label="Friendly Name"
                        value={props?.viewCredentials?.friendlyName}
                        name="friendlyName"
                        type="text"
                        id="friendlyName"
                      />
                    </div>
                  </div>
                  <div style={{ marginLeft: `1%`, width: `32%` }}>
                    <div className="form-group">
                      <InputField
                        size="small"
                        fullWidth={true}
                        label="*Client Id / Username"
                        onChange={(e) => setUserName(e.target.value)}
                        value={clientId}
                        type="text"
                        name="clientId"
                        id="clientId"
                      />
                    </div>
                  </div>
                  <div style={{ marginLeft: `1%`, width: `34%` }}>
                    <div className="form-group">
                      <InputField
                        size="small"
                        fullWidth={true}
                        label="*Client Secret / Password"
                        onChange={(e) => setPassword(e.target.value)}
                        value={clientSecret}
                        type="text"
                        name="clientSecret"
                        id="clientSecret"
                      />
                    </div>
                  </div>
                  <div style={{ marginLeft: 0, width: `32%` }}>
                    <div className="form-group">
                      <InputField
                        disabled
                        size="small"
                        fullWidth={true}
                        label="Practice Name"
                        value={practiceNameCapitalized}
                        name="practiceName"
                        type="text"
                        id="practiceName"
                      />
                    </div>
                  </div>
                  <div style={{ marginLeft: `1%`, width: `32%` }}>
                    <div className="form-group">
                      <InputField
                        disabled
                        size="small"
                        fullWidth={true}
                        label="Manufacturer Name"
                        value={manufacturerNameFormatted}
                        name="manufacturerName"
                        type="text"
                        id="manufacturerName"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ display: `flex`, justifyContent: `flex-end` }}>
          <button className="btn btn-outline-info" type="submit" variant="outline-info">
            Update Credentials
          </button>
        </div>
      </form>
      {loading && <PulseSpinner />}
    </CustomizedDialogs>
  );
};
