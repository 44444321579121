import React, { useState, useEffect, useRef } from 'react';
import css from './Compose.module.css';
import { momentWrapper } from 'src/momentWrapper';
import { SmartPhraseModal } from 'src/pages/common/components/smartphrasemodal/SmartPhraseModal';
import { newSocket } from 'src/pages/common/HOC/SocketWrapper';
import { useSelector } from 'react-redux';
import { MentionsInput, Mention } from 'react-mentions';
import AttachmentIcon from '@mui/icons-material/Attachment';
import Tooltip from '@mui/material/Tooltip';
import UploadIcon from '@mui/icons-material/Upload';
import { IconButton, Grid } from '@mui/material';
import defaultStyles from './defaultStyles';
import defaultMentionStyle from './defaultMentionStyle';
import { AutoWriteCredentials } from 'src/pages/common/components/AutoWriteCredentials/AutoWriteCredentials';
import { errorToast } from 'src/pages/common/components/snackBar/toast';
import {
  capitalizeFirstLetter, convertToBase64, getFileExtension, onKeyUpCredentialsHandler,
  printInBrowser, returnIfArr, shallowValueSetter, userInfoNotify, validMimeTypesForMessenger,
} from 'src/utils';
import { postRequest, UPLOAD_CHAT_MEDIA } from 'src/crud/crud';

const style = { width: `76%`, right: `18%`, bottom: `54px` };

const ComposeComp = (props) => {
  const { setMessages, messages } = props;
  const {
    messenger: { individualUser: individualUsers, selectedUser: selectedUserRedux },
  } = useSelector((state) => state);
  const patientReport = useSelector((state) => state?.patient?.patientReport);
  const patientReportId = patientReport?.data?.[0]?._id;
  const inputRefs = useRef(null);
  const [newMsg, setNewMsg] = useState(``);
  const [patients, setPatient] = useState([]);
  const [phraseId, setPhraseId] = useState(null);
  const [phraseScope, setPhraseScope] = useState(null);
  const [shallowValue, setShallowValue] = useState(``);
  const [openTemplatesGate, setOpenTemplatesGate] = useState(false);
  const targetChatRoomIdObj = individualUsers[selectedUserRedux];

  useEffect(() => {
    if (props?.listOfPatients) {
      setPatient([
        ...props.listOfPatients.map((patient) => {
          const firstName = capitalizeFirstLetter(patient?.firstName);
          const lastName = capitalizeFirstLetter(patient?.lastName);

          const display = `${firstName} ${lastName}`;
          return {
            ...patient,
            id: patient._id,
            display: display,
          };
        }),
      ]);
    }
  }, [props?.listOfPatients]);

  const onFocusHandler = () => {
    newSocket.emit(`changeChatReadStatusOnFocus`, {
      senderId: props.senderId,
      receiverId: props.selectedUser,
      practiceId: props.practiceId,
      _chatRoomId: individualUsers[props.selectedUser]?._chatRoomId,
    });
  };

  // ...(isFile ? { isFile: true, } : {
  //   message: props?.patientName ? `Patient  : ${props?.patientName},
  //     ${newMsg}` : newMsg,
  // }),

  const commonObj = (isFile) => {
    const commonData = {
      _receiverId: props.selectedUser,
      _senderId: props.senderId,
      _practiceId: props.practiceId,
      _socketId: props.socketId,
      _chatRoomId: props.chatId,
      phraseId: phraseId,
      phraseScope: phraseScope,
      date: momentWrapper().format(),
    };

    if (isFile) {
      commonData.isFile = true;
      commonData.type = `mms`;
    } else if (props?.patientName) {
      commonData.message = `Patient : @[${props.patientName}](${patientReportId}), ${newMsg}`;
    } else {
      commonData.message = newMsg;
    }

    return [commonData];
  };

  //   some check for phrase selected using up and down key and send on enter button
  const sendMessageHandler = (key, isSendIcon) => {
    if (
      (key?.keyCode === 13 && !key.shiftKey && newMsg.trim().length > 0 && newMsg !== `#`) ||
      (key === 1300 && newMsg.trim().length > 0 && newMsg !== `#`) ||
      isSendIcon
    ) {
      key.preventDefault();
      let temp = commonObj(false);
      props.newMsgHandler(temp, () => setNewMsg(``));

      setPhraseScope(null);
      setPhraseId(null);
      inputRefs.current.value = ``;
    }
  };

  const messageStateUpdate = (e) => {
    let { value } = e.target;
    shallowValueSetter({
      flag: openTemplatesGate,
      setValue: () => setShallowValue(value),
    });
    if (!e.keyCode != 13) {
      setNewMsg(value);
    }
  };

  useEffect(() => {
    const receiveFile = (info) => {
      let data = info || [{}]
      props.dispatchMostRecentMsg({
        message: `file`,
        id: selectedUserRedux,
        _chatRoomId: targetChatRoomIdObj?._chatRoomId,
        type: `mms`,
      });
      const { _uniqueId = ``, message = ``, _receiverId: selecedUserId = `` } = data[0] || false;

      if (selecedUserId === selectedUserRedux && messages?.find((e) => e?._uniqueId === _uniqueId)) {
        if (message) {
          delete data.message;
        }
        setMessages((p) => {
          return [
            ...returnIfArr(p).map((mediaMessage) => {
              const { _uniqueId: mappedUniqueId } = mediaMessage || {};
              if (_uniqueId === mappedUniqueId) {
                return { ...mediaMessage, status: `realTimeSent`, ...data[0] };
              } else {
                return { ...mediaMessage };
              }
            }),
          ];
        });
      }
      // else if (selecedUserId === selectedUserRedux && messages?.find((e) => e?._uniqueId === _uniqueId)) {
      //   setMessages((p) => {
      //     return [{ ...data, status: `realTimeSent` }, ...p];
      //   });
      // }
    };
    newSocket.on(`chatMedia`, receiveFile);
    return () => newSocket.off(`chatMedia`, receiveFile);
  }, [messages]);

  const uploadDocument = async (uploadFileEvent) => {
    uploadFileEvent.persist();
    const file = uploadFileEvent.target.files[0];
    let type = getFileExtension(file?.name);
    let hasValidMime = validMimeTypesForMessenger?.find((e) => e?.includes(type));
    printInBrowser({ key: `validMIme`, value: { type: type, hasValidMime: hasValidMime, file: getFileExtension(file?.name) } });
    let size = file?.size / 1000;
    if (!hasValidMime) {
      errorToast(`image,docs, pdf,xlsx,ppt,csv,txt, mp3 and mp4files are supported!`);
      uploadFileEvent.target.value = ``;
    } else if (size && size > 10000) {
      errorToast(`File Must be less than 10MB`);
      uploadFileEvent.target.value = ``;
    } else {
      let fileSrc = await convertToBase64(file);
      let formData = new FormData();
      let temp = commonObj(true);
      let _uniqueId = Date.now().toString(36).substring(2, 15) + Date.now().toString(36).substring(2, 15);
      let payLoad = {
        ...temp[0],
        _uniqueId: _uniqueId,
        _chatRoomId: targetChatRoomIdObj?._chatRoomId,
        ...(userInfoNotify() ? { ...userInfoNotify() } : {}),
      };
      for (const key in payLoad) {
        formData.append(key, payLoad[key]);
      }
      formData.append(`chatMedia`, file, file?.name);
      uploadFileEvent.target.value = ``;
      let sentDate = new Date();
      setMessages((p) => {
        return [{ date: sentDate, isMine: true, message: fileSrc, type: `mms`, mimeType: type, status: `pending`, _uniqueId: _uniqueId }, ...p];
      });
      // Request for uploadMedia.
      postRequest(UPLOAD_CHAT_MEDIA, formData)
        .then((response) => {
          printInBrowser({ key: `i am here`, value: response });
        })
        .catch(() =>
          setMessages((p) => {
            return [...returnIfArr(p).map((mediaMessage) => {
              if (mediaMessage && mediaMessage._uniqueId === _uniqueId) {
                return { ...mediaMessage, status: `realTimeSentError` };
              } else {
                return mediaMessage;
              }
            })];
          })
        );
    }
  };

  const doSendMessage = newMsg.trim().length > 0 && newMsg !== `#`;

  const iconId = newMsg.trim().length > 0 && newMsg !== `#` ? css.toolbarButton : css.disabledIcon;

  return (
    <SmartPhraseModal
      slectedPhraseId="practiceMessengerPhrases"
      setNewNote={setNewMsg}
      newNote={newMsg}
      setPhraseId={setPhraseId}
      setPhraseScope={setPhraseScope}
      style={style}
    >
      <div className={props?.isFromView ? css.composeView : css.compose}>
        <div className={css.composeInputs}>
          <AutoWriteCredentials setShallowValue={setShallowValue} value={shallowValue} open={openTemplatesGate} setValue={setNewMsg} />
          <MentionsInput
            value={newMsg}
            onChange={messageStateUpdate}
            onKeyDown={(e) => sendMessageHandler(e, false)}
            onKeyUp={(e) => {
              onKeyUpCredentialsHandler({
                event: e,
                openTemplatesHandler: (value) => setOpenTemplatesGate(value),
              });
            }}
            onFocus={() => onFocusHandler()}
            placeholder="Type a Message..."
            allowSuggestionsAboveCursor={true}
            inputRef={inputRefs}
            disabled={!props.selectedUser}
            style={defaultStyles}
            className={css.composeInput}
          >
            <Mention type="user" trigger="@" data={patients} style={defaultMentionStyle} />
          </MentionsInput>

        </div>
        <div id={css.composeInputBtn}>
          <i
            className={`ion-md-send`}
            id={iconId}
            onClick={(e) => sendMessageHandler(e, doSendMessage)}
          />
        </div>

        <Tooltip
          arrow
          sx={{ fontSize: `small` }}
          title={
            <Grid container>
              <Grid item>
                <UploadIcon sx={{ fontSize: `small` }} />
                {` `}
              </Grid>
              <Grid item>Upload file</Grid>
            </Grid>
          }
          placement="top-start"
        >
          <IconButton aria-label="upload" size="large" component="label">
            <AttachmentIcon sx={{ fontSize: `28px`, color: `#99999C` }} />
            <input hidden type="file" onChange={(uploadFileEvent) => uploadDocument(uploadFileEvent)} />
          </IconButton>
        </Tooltip>
      </div>
    </SmartPhraseModal>
  );
};

export const Compose = React.memo(ComposeComp);
