/* eslint-disable require-atomic-updates */
/* eslint-disable camelcase */
import React, { useEffect, useState, useRef } from 'react';
import './telemedicineVideoWindow.css';
import { useDispatch } from 'react-redux';
import { isSupported } from 'twilio-video';
import { isMobile } from './components/browser';
import joinRoom from './components/joinroom';
import micLevel from './components/miclevel';
import selectMedia from './components/selectmedia';
import selectRoom from './components/selectroom';
import showError from './components/showerror';
import ScreenShare from './components/screenshare';
import HeightIcon from '@mui/icons-material/Height';
import $ from 'jquery';
import LocalMediaControls from './components/localmediacontrols';
import {
  getRequest,
  UPDATE_TELEMEDICINE_VIDEO_CALL_TIME_REFERENCE,
  patchRequest,
  PATIENT_SEND_TEXT_MESSAGE_TELEMEDICINE_URL,
  postRequest,
  TELEMEDICINE_TWILIO_VIDEO_CALL_TOKEN_VERIFY_URL,
  ADD_TELEMEDICINE_VIDEO_CALL_TIME
} from '../../../../../../crud/crud';

import Tooltip from '@mui/material/Tooltip';
import Fab from '@mui/material/Fab';
import VideocamOffIcon from '@mui/icons-material/VideocamOff';
import PhoneDisabledIcon from '@mui/icons-material/PhoneDisabled';
import ScreenShareIcon from '@mui/icons-material/ScreenShare';
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import StopScreenShareIcon from '@mui/icons-material/StopScreenShare';
import { startVideoCall, endVideoCall } from '../../../../../../redux/timer/timerActions';
import {
  _createTelemedicineRoom,
  _individualRoomTelemedicineVideoCallTime,
  _deleteSpecificTelemedicineRoom,
} from '../../../../../../redux/telemedicine/telemedicineActions';
import { printInBrowser } from 'src/utils';
let token = ``;
let roomName = ``;

// ConnectOptions settings for a video web application.
const connectOptions = {
  bandwidthProfile: {
    video: {
      dominantSpeakerPriority: `high`,
      mode: `collaboration`,
      renderDimensions: {
        high: { height: 720, width: 1280 },
        standard: { height: 90, width: 160 },
      },
    },
  },

  dominantSpeaker: true,

  // Comment this line to disable verbose logging.
  logLevel: `debug`,

  // Comment this line if you are playing music.
  maxAudioBitrate: 16000,

  preferredVideoCodecs: [{ codec: `VP8`, simulcast: true }],

  video: { height: 720, frameRate: 24, width: 1280 },
};

// For mobile browsers, limit the maximum incoming video bitrate to 2.5 Mbps.
if (isMobile) {
  connectOptions.bandwidthProfile.video.maxSubscriptionBitrate = 2500000;
}

const deviceIds = {
  audio: isMobile ? null : localStorage.getItem(`audioDeviceId`),
  video: isMobile ? null : localStorage.getItem(`videoDeviceId`),
};

export const TelemedicineVideoWindow = ({
  dialer,
  videoCallFlag,
  setVideoCallFlag,
  propsFromVideoWindowReceiver,
  fetchTimeLogs,
}) => {
  const dispatch = useDispatch();
  const [roomLink, setRoomLink] = useState(``);
  const [videoCallTime, setVideoCallTime] = useState(0);
  const [audioVolume, setAudioVolume] = useState(false);
  const [isScreenShare, setIsScreenShare] = useState(false);

  const [isVideoCallStart, setIsVideoCallStart] = useState(false);
  const [videoTimeReferenceId, setVideoTimeReferenceId] = useState(``);
  const [resizeScreen, setResizeScreen] = useState(false);
  const [videoCallStartTime, setVideoCallStartTime] = useState(null);
  const $main = useRef(null);
  const $modals = useRef(null);
  const $selectMicModal = useRef(null);
  const $selectCameraModal = useRef(null);
  const $showErrorModal = useRef(null);
  const $joinRoomModal = useRef(null);

  useEffect(() => {
    if (videoCallFlag) {
      if ($showErrorModal.current && isSupported) {
        selectMicrophone();
      } else {
        showError($showErrorModal.current, new Error(`This browser is not supported.`));
      }
    }
  }, [videoCallFlag]);

  const toHHMMSS = (v) => {
    let sec_num = parseInt(v, 10); // don't forget the second param
    let hours = Math.floor(sec_num / 3600);
    let minutes = Math.floor((sec_num - hours * 3600) / 60);
    let seconds = sec_num - hours * 3600 - minutes * 60;

    if (hours < 10) {
      hours = `0` + hours;
    }
    if (minutes < 10) {
      minutes = `0` + minutes;
    }
    if (seconds < 10) {
      seconds = `0` + seconds;
    }
    return hours + `:` + minutes + `:` + seconds;
  };

  useEffect(() => {
    if (isVideoCallStart) {
      patchRequest(UPDATE_TELEMEDICINE_VIDEO_CALL_TIME_REFERENCE, { videoTimeReferenceId: videoTimeReferenceId })
        .then((response) => {
          setVideoCallStartTime(response.data?.dateCreated);
          dispatch(_individualRoomTelemedicineVideoCallTime(roomName, response.data?.dateCreated));
        })
        .catch(() => { });

      let intervalId = setInterval(() => {
        setVideoCallTime((preState) => preState + 1);
      }, 1000);
      return () => {
        clearInterval(intervalId);
      };
    } else {
      setVideoCallTime(0);
    }
  }, [isVideoCallStart]);

  async function selectMicrophone() {
    if (deviceIds.audio === null) {
      try {
        setVideoCallFlag(false);
        deviceIds.audio = await selectMedia(`audio`, $selectMicModal.current, (stream) => {
          const $levelIndicator = $(`svg rect`, $selectMicModal.current);
          const maxLevel = Number($levelIndicator.attr(`height`));
          micLevel(stream, maxLevel, (level) => $levelIndicator.attr(`y`, maxLevel - level));
        });
      } catch (error) {
        showError($showErrorModal.current, error);
        return;
      }
    }
    return selectCamera();
  }

  async function selectCamera() {
    if (deviceIds.video === null) {
      try {
        deviceIds.video = await selectMedia(`video`, $selectCameraModal.current, (stream) => {
          const $video = $(`video`, $selectCameraModal.current);
          $video.get(0).srcObject = stream;
        });
      } catch (error) {
        showError($showErrorModal.current, error);
        return;
      }
    }
    return sendRequestForToken();
  }

  async function sendRequestForToken() {
    try {
      // Fetch an AccessToken to join the Room.
      const response = await getRequest(TELEMEDICINE_TWILIO_VIDEO_CALL_TOKEN_VERIFY_URL + `123` + `?number=${dialer}`);
      // Extract the AccessToken from the Response.
      token = await response.data.token;
      // Extract the RoomName from the Response.
      roomName = await response.data.roomName;

      // NOTE -> CREATE TELEMEDICINE VIDEO CHATROOM AT REDUX
      dispatch(_createTelemedicineRoom((await response.data.roomName, await response.data.token)));

      // Extract the RoomLink from the Response.
      setRoomLink(await response.data.roomLink);

      // EXTRACT TIME REFERENCE ID
      setVideoTimeReferenceId(await response.data.videoTimeReferenceId);
      return selectAndJoinRoom();
    } catch (error) {
      return selectAndJoinRoom(error);
    }
  }

  const stopTimerHandler = async () => {
    sendSMSHandler();
    setIsVideoCallStart(true);
    setVideoCallFlag(false);
    dispatch(startVideoCall());

    $(`#join-room`).modal(`hide`);
  };

  const stopTelemedicineVideoCall = async () => {
    await postRequest(`url/deleteToken`, {
      encodedToken: roomLink,
    });
    try {
      const payload = {
        startDate: videoCallStartTime,
        endDate: new Date(),
        source: `Telemedicine video call`,
        number: dialer,
      };
      postRequest(ADD_TELEMEDICINE_VIDEO_CALL_TIME, payload).then().catch()
      propsFromVideoWindowReceiver();
      dispatch(endVideoCall());
    } catch (e) {
      printInBrowser({ key: `error`, value: e })
    }
  };

  // Send Video Call using SMS
  const sendSMSHandler = () => {
    postRequest(PATIENT_SEND_TEXT_MESSAGE_TELEMEDICINE_URL, {
      telemedicine: true,
      patientPhoneNumber: `${dialer}`,
      MDPhoneNumber: `+12402976385`,
      doctorMessage: `Hi, Please click on the following link to join the video conference with the doctor ${roomLink}
      Note: Link will expire in 1 hour`,
    })
      .then(() => { })
      .catch(() => { });
  };

  async function selectAndJoinRoom(error = null) {
    setVideoCallFlag(false);

    $(`#join-room`).modal(`hide`);

    const formData = await selectRoom($joinRoomModal.current, error);
    if (!formData) {
      // User wants to change the camera and microphone.
      // So, show them the microphone selection modal.
      deviceIds.audio = null;
      deviceIds.video = null;
      return selectMicrophone();
    }
  }

  const handleScreenShare = () => {
    ScreenShare($main.current, `start`);
    setIsScreenShare(true);
  };
  const handleStopScreenShare = () => {
    ScreenShare($main.current, `stop`);
    setIsScreenShare(false);
  };
  const hideMainModal = () => {
    setResizeScreen(!resizeScreen);
    $(`#main-modal`).modal(`hide`);
  };
  const showMinimizedScreen = () => {
    setResizeScreen(!resizeScreen);
    $(`#main-modal`).modal(`show`);
  };
  const openVideoWindowModal = async () => {
    stopTimerHandler();
    try {
      // Add the specified audio device ID to ConnectOptions.
      connectOptions.audio = { deviceId: { exact: deviceIds.audio } };

      // Add the specified Room name to ConnectOptions.
      connectOptions.name = roomName;

      // Add the specified video device ID to ConnectOptions.
      connectOptions.video.deviceId = { exact: deviceIds.video };

      // Join the Room.
      await joinRoom(token, connectOptions, $main.current);

      // After the video session.
      return;
    } catch (error) {
      return selectAndJoinRoom(error);
    }
  };
  const closeVideoCallModalAndStatus = () => {
    propsFromVideoWindowReceiver();
    dispatch(endVideoCall());
  };

  return (
    <div ref={$main}>
      <div>
        <div
          style={{
            userSelect: `none`,
            margin: `0 auto`,
            zIndex: `10000`,
          }}
          className="modal fade"
          id="main-modal"
          data-backdrop="static"
          data-keyboard="false"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="main-modal-label"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-xl" role="document">
            <div
              className="modal-content"
              style={{
                position: `relative`,
                display: `flex`,
                flexDirection: `column`,
                width: `100%`,
                pointerEvents: `auto`,
                backgroundColor: `#072e3c`,
                backgroundClip: `padding-box`,
                border: `2px solid #eee`,
                borderRadius: `2.3rem`,
                outline: `0`,
              }}
            >
              <div className="modal-body m-1" style={{ textAlign: `center` }}></div>
              <div className="row" id="room">
                <div id="participants" className="col-xs-12 col-sm-3 col-md-2" style={{ textAlign: `center`, paddingLeft: `5%` }}></div>
                <div
                  id="active-participant"
                  className="col-xs-12 col-sm-9 col-md-10"
                  style={{ textAlign: `center`, paddingRight: `5%`, paddingBottom: `2%` }}
                >
                  <div id="mainPreviewWindow" className="participant main">
                    <video id="videopreview" autoPlay playsInline muted style={{ objectFit: `contain` }}></video>
                    <video id="screenpreview" autoPlay></video>
                    <div id="controls">
                      <h6 style={{ color: `#82cded`, marginTop: `20px` }}>{toHHMMSS(videoCallTime)}</h6>
                      <Tooltip title="Stop video camera">
                        <Fab color="primary" size="small" id="muteVideoBtn" onClick={() => LocalMediaControls($main.current, `video`)}>
                          <VideocamOffIcon />
                        </Fab>
                      </Tooltip>
                      {!audioVolume ? (
                        <Tooltip title="Mute Audio">
                          <Fab
                            size="small"
                            color="primary"
                            id="muteAudioBtn"
                            onClick={() => {
                              LocalMediaControls($main.current, `audio`);
                              setAudioVolume((p) => !p);
                            }}
                          >
                            <MicIcon />
                          </Fab>
                        </Tooltip>
                      ) : (
                        <Tooltip title="Unmute Audio">
                          <Fab
                            size="small"
                            color="primary"
                            id="muteAudioBtn"
                            onClick={() => {
                              LocalMediaControls($main.current, `audio`);
                              setAudioVolume((p) => !p);
                            }}
                          >
                            <MicOffIcon />
                          </Fab>
                        </Tooltip>
                      )}

                      <Tooltip title="Leave room">
                        <Fab
                          size="small"
                          color="secondary"
                          id="leave-room"
                          disabled={!isVideoCallStart}
                          onClick={async () => {
                            setIsVideoCallStart(false);
                            await stopTelemedicineVideoCall();
                            dispatch(_deleteSpecificTelemedicineRoom(roomName));
                            fetchTimeLogs();
                          }}
                        >
                          <PhoneDisabledIcon />
                        </Fab>
                      </Tooltip>

                      <Tooltip title="Screen share">
                        <Fab size="small" color="primary" id="capturescreen" onClick={() => handleScreenShare()}>
                          <ScreenShareIcon />
                        </Fab>
                      </Tooltip>
                      <Tooltip title="Stop screen share">
                        <Fab
                          size="small"
                          color="primary"
                          id="stopscreencapture"
                          style={{ pointerEvents: !isScreenShare ? `none` : null }}
                          onClick={() => handleStopScreenShare()}
                        >
                          <StopScreenShareIcon />
                        </Fab>
                      </Tooltip>
                      <Tooltip title="Resize the screen">
                        <Fab
                          size="small"
                          color="primary"
                          onClick={() => {
                            hideMainModal();
                          }}
                        >
                          <HeightIcon />
                        </Fab>
                      </Tooltip>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {resizeScreen && (
          <div style={{ position: `absolute`, zIndex: 1 }} onClick={() => showMinimizedScreen()}>
            <Tooltip title="Move in video call again">
              <Fab size="small" color="primary" id="leave-room">
                <HeightIcon />
              </Fab>
            </Tooltip>
          </div>
        )}
        <div ref={$modals} id="modals">
          <div
            className="modal fade"
            ref={$selectMicModal}
            id="select-mic"
            data-backdrop="static"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="select-mic-label"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="select-mic-label">
                    Microphone
                  </h5>
                </div>
                <div className="modal-body" style={{ textAlign: `center` }}>
                  <select style={{ width: `100%` }}></select>
                  <svg focusable="false" viewBox="0 0 100 100" aria-hidden="true" height="100" width="100" style={{ margin: `10px 0` }}>
                    <defs>
                      <clipPath id="level-indicator">
                        <rect x="0" y="100" width="100" height="100" />
                      </clipPath>
                    </defs>
                    <path
                      fill="rgb(220, 220, 220)"
                      d="m52 38v14c0 9.757-8.242 18-18 18h-8c-9.757 0-18-8.243-18-18v-14h-8v14c0 14.094 11.906 26 26 26v14h-17v8h42v-8h-17v-14c14.094 0 26-11.906 26-26v-14h-8z"
                    ></path>
                    <path
                      fill="rgb(220, 220, 220)"
                      d="m26 64h8c5.714 0 10.788-4.483 11.804-10h-11.887v-4h12.083v-4h-12.083v-4h12.083v-4h-12.083v-4h12.083v-4h-12.083v-4h12.083v-4h-12.083v-4h12.083v-4h-12.083v-4h11.887c-1.016-5.517-6.09-10-11.804-10h-8c-6.393 0-12 5.607-12 12v40c0 6.393 5.607 12 12 12z"
                    ></path>
                    <path
                      fill="#080"
                      clipPath="url(#level-indicator)"
                      d="m52 38v14c0 9.757-8.242 18-18 18h-8c-9.757 0-18-8.243-18-18v-14h-8v14c0 14.094 11.906 26 26 26v14h-17v8h42v-8h-17v-14c14.094 0 26-11.906 26-26v-14h-8z"
                    ></path>
                    <path
                      fill="#080"
                      clipPath="url(#level-indicator)"
                      d="m26 64h8c5.714 0 10.788-4.483 11.804-10h-11.887v-4h12.083v-4h-12.083v-4h12.083v-4h-12.083v-4h12.083v-4h-12.083v-4h12.083v-4h-12.083v-4h12.083v-4h-12.083v-4h11.887c-1.016-5.517-6.09-10-11.804-10h-8c-6.393 0-12 5.607-12 12v40c0 6.393 5.607 12 12 12z"
                    ></path>
                  </svg>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-primary">
                    Apply
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal fade"
            id="select-camera"
            ref={$selectCameraModal}
            data-backdrop="static"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="select-camera-label"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="select-camera-label">
                    Camera
                  </h5>
                </div>
                <div className="modal-body" style={{ textAlign: `center` }}>
                  <select style={{ width: `100%` }}></select>
                  <video autoPlay muted playsInline style={{ margin: `10px 0`, width: `60%` }}></video>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-primary">
                    Apply
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal fade"
            id="join-room"
            ref={$joinRoomModal}
            tabIndex="-1"
            data-backdrop="static"
            role="dialog"
            aria-labelledby="join-room-label"
            aria-hidden="true"
            isOpen={false}
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="join-room-label">
                    Video Chat
                  </h5>
                  <button type="button" onClick={() => closeVideoCallModalAndStatus()} className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="form-group">
                    <label htmlFor="joinLinkTextarea">Room Join Link:</label>
                    <textarea className="form-control" id="joinLinkTextarea" rows="3" value={roomLink} readOnly />
                  </div>
                  <div className="alert alert-warning" role="alert"></div>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary">
                    Change Settings
                  </button>
                  <button type="button" className="btn btn-primary" onClick={() => openVideoWindowModal()}>
                    Join
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal fade"
            id="show-error"
            ref={$showErrorModal}
            data-backdrop="static"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="show-error-label"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="show-error-label">
                    Error
                  </h5>
                  <button type="button" onClick={() => closeVideoCallModalAndStatus()} className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="alert alert-warning" role="alert"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}



