import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styling from 'src/pages/common/components/wrapper/components/avator/editUser/editUser.module.css';
import { OTP_REQUEST, patchRequest, postRequest, REMOVE_OTP, UPDATE_USER, VERIFY_OTP_SEND } from 'src/crud/crud';
import { errorToast, successToast } from 'src/pages/common/components/snackBar/toast';
import { setUserHandler } from 'src/redux/action/setUserHandler';
import { PulseSpinner } from 'src/pages/common/components/spinner/spinner';
import Swal from 'sweetalert2';
import { CustomizedDialogs } from 'src/pages/common/components/modal/CustomizeModal';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { Box } from '@mui/system';
import { Grid, Typography } from '@mui/material';
import { ResetPassword } from 'src/pages/common/components/wrapper/components/avator/resetPassword/resetPassword';
import { ToastContainer } from 'react-toastify';
import { InputField } from 'src/pages/common/components/InputField/InputField';

// Export The Setting Component And Render In avatar.js File.
export const Setting = ({ open, setOpen, setHasEdited, hasEdited, userData, setSettingModalFlag }) => {
    // Get User from redux.
    const user = useSelector((state) => state?.user?.user);
    const [loading, setLoading] = useState(false);
    const dispatchRedux = useDispatch();
    // State For Two Factor Authentication.
    const [twoFactorAuthenticationLoading, setTwoFactorAuthenticationLoading] = useState(false);
    const [twoFactorAuthenticationSendingError, setTwoFactorAuthenticationSendingError] = useState(false);
    const [otpModalOpenFlag, setOtpModalOpenFlag] = useState(false);
    const [verifyOtpLoading, setVerifyOtpLoading] = useState(false);
    const [otpVerifySendMsgError, setOtpVerifySendMsgError] = useState(``);
    const [otpNumber, setOtpNumber] = useState(``);

    // Handle Function For TWO_FACTORAUTHENTICATION.
    const twoFactorAuthentication = async () => {
        setTwoFactorAuthenticationSendingError(false);
        if (!user?.tfaStatus) {
            setTwoFactorAuthenticationLoading(true);
            try {
                await postRequest(OTP_REQUEST, { username: userData?.userName });
                setOtpModalOpenFlag(true);
                setTwoFactorAuthenticationLoading(false);
            } catch (error) {
                setTwoFactorAuthenticationLoading(false);
                setTwoFactorAuthenticationSendingError(true);
                console.log(error)
            }
        } else {
            Swal.fire({
                title: `Are you sure?`,
                text: `You won't be able to revert this!`,
                icon: `warning`,
                showCancelButton: true,
                confirmButtonColor: `#3085d6`,
                cancelButtonColor: `#d33`,
                confirmButtonText: `Yes, delete it!`,
            }).then(async (result) => {
                if (result?.isConfirmed) {
                    setTwoFactorAuthenticationLoading(true);
                    postRequest(REMOVE_OTP, { userName: userData?.userName })
                        .then(() => {
                            Swal.fire(`Done!`, `Two Factor Authentication has been Disabled.`, `success`);
                            dispatchRedux(setUserHandler({ ...user, tfaStatus: false }));
                            setTwoFactorAuthenticationLoading(false);
                        })
                        .catch((error) => {
                            errorToast(`Something Bad Has Happened Please Try Again Later`);
                            setTwoFactorAuthenticationLoading(false);
                            if (error?.response?.status === 400) {
                                setOtpVerifySendMsgError(error?.response?.data?.error);
                            }
                        });
                }
            });
        }
    };

    // Handle Function For VERIFY_OTP_SEND.
    const verifyOTP = () => {
        setVerifyOtpLoading(true);
        postRequest(VERIFY_OTP_SEND, { userName: userData?.userName, otp: otpNumber })
            .then((res) => {
                successToast(`OTP Verified`);
                setOtpModalOpenFlag(false);
                setVerifyOtpLoading(false);
                dispatchRedux(setUserHandler({ ...user, tfaStatus: res?.data?.otp }));
            })
            .catch(() => {
                setVerifyOtpLoading(false);
            });
    }

    // Handle Function For Mobile Notification's.
    const handleMobileNotification = (clickedStatus) => {
        setLoading(true);
        patchRequest(UPDATE_USER + `/` + user?._id + `/` + user?._practiceId, { messageNotificationStatus: clickedStatus })
            .then((res) => {
                const messageNotificationStatus = res?.data?.user?.messageNotificationStatus ? `on` : `off`;
                const message = `Successfully Turned ${messageNotificationStatus} The Notifications`;
                successToast(message);
                setLoading(false);
                dispatchRedux(setUserHandler({ ...user, messageNotificationStatus: res?.data?.user?.messageNotificationStatus }));
            })
            .catch(() => {
                errorToast(`Something Bad Has Happened Please Try Again Later`);
                setLoading(false);

            });
    }

    // Handle Function For Email Notification's.
    const handleEmailNotification = (clickedStatus) => {
        setLoading(true);
        patchRequest(UPDATE_USER + `/` + user?._id + `/` + user?._practiceId, { emailNotificationStatus: clickedStatus })
            .then((res) => {
                const notificationStatus = res?.data?.user?.emailNotificationStatus ? `on` : `off`;
                const message = `Successfully Turned ${notificationStatus} The Notifications`;
                successToast(message);
                setLoading(false);
                dispatchRedux(setUserHandler({ ...user, emailNotificationStatus: res?.data?.user?.emailNotificationStatus }));
            })
            .catch(() => {
                errorToast(`Something Bad Has Happened Please Try Again Later`);
                setLoading(false);
            });
    }

    //Returning The JSX.
    return (
        // Otp Dialog.
        <>
            <CustomizedDialogs
                title="Add OTP Number"
                open={otpModalOpenFlag}
                setOpen={() => {
                    setOtpModalOpenFlag(false);
                    if (hasEdited) {
                        setHasEdited(`edited`);
                    }
                }}
                size="sm"
                fullWidth={true}
                notShowDividers={true}
            >
                <ToastContainer />
                {verifyOtpLoading && <PulseSpinner />}
                <DialogTitle id="customized-dialog-title" style={{ textAlign: `center` }}>
                    Add OTP Number
                </DialogTitle>
                <DialogContent style={{ textAlign: `center` }}>
                    <InputField
                        size='small'
                        fullWidth={true}
                        type="text"
                        value={otpNumber}
                        name="otpNumber"
                        onChange={(e) => {
                            setOtpNumber(e.target.value);
                        }}
                    />
                    <button
                        // className="btn btn-outline-info"
                        disabled={!(otpNumber.length >= 4 && !verifyOtpLoading)}
                        style={{ color: `white`, backgroundColor: `green`, outline: `none`, marginLeft: 10, borderRadius: 5, marginTop: 24 }}
                        onClick={verifyOTP}
                    >
                        Verify
                    </button>
                    {otpVerifySendMsgError?.length > 4 && <span>{otpVerifySendMsgError}</span>}
                </DialogContent>
                <DialogActions>
                    <button
                        autoFocus
                        onClick={() => {
                            setOtpModalOpenFlag(false);
                        }}
                    >
                        Close
                    </button>
                </DialogActions>
            </CustomizedDialogs>

            {/* Two Factor Authentication Button.*/}
            <CustomizedDialogs title="Settings" open={open} setOpen={setOpen} size="md" fullWidth={true}>
                {twoFactorAuthenticationLoading && <PulseSpinner />}
                <Box sx={{ borderBottom: `1px solid rgb(0 0 0 / 13%)`, marginBottom: `20px` }}>
                    <Typography variant="h6" component="div">
                        Authentication
                    </Typography>
                </Box>

                <Grid container>
                    <Grid item xs={3} sm={3} lg={3} md={3}>
                        <label style={{ fontSize: `14px` }}>Two Factor Authentication</label>
                    </Grid>
                    <Grid item xs={9} sm={9} lg={9} md={9} marginTop={`8px`}>
                        <label id={styling.switch}>
                            <input
                                type="checkbox"
                                id={styling.switchInput}
                                checked={user?.tfaStatus}
                                onChange={twoFactorAuthentication}
                            />
                            <span id={styling.switchLabel} data-on="On" data-off="Off"></span>
                            <span id={styling.switchHandle}></span>
                        </label>
                    </Grid>
                </Grid>
                {twoFactorAuthenticationSendingError && <span style={{ fontSize: `11px`, color: `red` }}>*Otp not sended! Try Again</span>}

                <Box sx={{ borderBottom: `1px solid rgb(0 0 0 / 13%)`, marginBottom: `20px` }}>
                    <Typography variant="h6" component="div">
                        Reset Password
                    </Typography>
                </Box>
                <Grid container>
                    <Grid item xs={9} sm={9} lg={9} md={9}>
                        <ResetPassword setSettingModalFlag={setSettingModalFlag} />
                    </Grid>
                </Grid>
                {/* Check If User Type Is Provider. */}
                {user?.isProvider && (
                    <>
                        <Box sx={{ borderBottom: `1px solid rgb(0 0 0 / 13%)`, marginBottom: `20px` }}>
                            <Typography variant="h6" component="div">
                                Personal Notifications
                            </Typography>
                        </Box>

                        {/* Personal Notifications Button.*/}
                        <Grid container>
                            <Grid item xs={3} sm={3} lg={3} md={3}>
                                <label style={{ fontSize: `14px` }}>Mobile Notifications</label>
                            </Grid>
                            <Grid item xs={9} sm={9} lg={9} md={9} marginTop={`4px`}>
                                <label id={styling.switch}>
                                    <input
                                        type="checkbox"
                                        id={styling.switchInput}
                                        defaultChecked={!!user?.messageNotificationStatus}
                                        onChange={() => {
                                            handleMobileNotification(!user?.messageNotificationStatus)
                                        }}
                                    />
                                    <span id={styling.switchLabel} data-on="On" data-off="Off"></span>
                                    <span id={styling.switchHandle}></span>
                                </label>
                            </Grid>
                        </Grid>

                        {/* Email Notifications Button.*/}
                        <Grid container>
                            <Grid item xs={3} sm={3} lg={3} md={3}>
                                <label style={{ fontSize: `14px` }}>Email Notifications</label>
                            </Grid>
                            <Grid item xs={9} sm={9} lg={9} md={9} marginTop={`4px`}>
                                <label id={styling.switch}>
                                    <input
                                        type="checkbox"
                                        id={styling.switchInput}
                                        defaultChecked={!!user?.emailNotificationStatus}
                                        onChange={() => {
                                            handleEmailNotification(!user?.emailNotificationStatus)
                                        }}
                                    />
                                    <span id={styling.switchLabel} data-on="On" data-off="Off"></span>
                                    <span id={styling.switchHandle}></span>
                                </label>
                            </Grid>
                        </Grid>
                    </>
                )}
                {loading && <PulseSpinner />}
            </CustomizedDialogs >
        </>
    );
};