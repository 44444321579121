import React, { useState, useEffect } from 'react';
import { postRequest, REMOTE_RISK_CATEGORY } from '../../../../../../../crud/crud';
import { Bar } from 'react-chartjs-2';
import 'chartjs-plugin-annotation';
import { useSelector } from 'react-redux';
import { Box, Grid, Zoom, createMuiTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { fromDateChangeHandler, pickerPropsHandler, toDateChangeHandler } from 'src/pages/home/modules/settings/Analytics/utilities';
import { DatePickerCustomize } from 'src/pages/common/components/datePicker/DatePickerCustomize';
import { analyticsUrlHandler, doIfArr, startDateAnalytics } from 'src/utils';
import { Filterbutton } from 'src/pages/home/modules/settings/Analytics/Components/Filterbutton';
import { momentWrapper } from 'src/momentWrapper';
import moment from 'moment';

export const RiskCategory = ({ clicked }) => {
  const [loading, setLoading] = useState({ loading: false, error: false });
  const [fromDate, setFromDate] = useState(startDateAnalytics());
  const [toDate, setToDate] = useState(moment(new Date()).endOf(`month`));

  const [filterBtn, setFilterBtn] = useState(false);
  const [graphLabels, setGraphLabels] = useState([]);

  const [showMsg, setErrorMsg] = useState(false);

  const [veryHighRiskPatients, setVeryHighRishPatients] = useState([]);
  const [highRiskPatients, setHighRiskPatients] = useState([]);
  const [mediumRiskPatients, setMediumRiskPatients] = useState([]);
  const [lowRiskPatients, setLowRiskPatients] = useState([]);
  const [noDataRiskPatients, setNoDataRiskPatients] = useState([]);
  const breakpointValues = {
    xxs: 0,
    xs: 400,
    sm: 600,
    smd: 730,
    md: 900,
    lg: 1080,
    blg: 1127,
    mdl: 1254,
    xl: 1400,
  };
  const theme = createMuiTheme({ breakpoints: { values: breakpointValues } });

  const matchesBlg = useMediaQuery(theme.breakpoints.down(`blg`));
  const view = useSelector((state) => state?.analytics?.view);
  useEffect(() => {
    apiCall(view);
  }, [view]);
  const apiCall = (view) => {
    let labelsTempArray = [];
    let vHighRiskPatients = [];
    let highRiskPatients = [];
    let mediumRiskPatients = [];
    let lowRiskPatients = [];
    let noDataRiskPatients = [];

    setLoading({ loading: true, error: false });
    setFilterBtn(true);
    setGraphLabels([]);
    setVeryHighRishPatients([]);
    setHighRiskPatients([]);
    setMediumRiskPatients([]);
    setLowRiskPatients([]);
    setNoDataRiskPatients([]);
    let filters = analyticsUrlHandler(view);
    postRequest(REMOTE_RISK_CATEGORY + `?filters=${filters}`, {
      startDate: momentWrapper(fromDate).startOf(`month`).toISOString(),
      endDate: momentWrapper(toDate).endOf(`month`).toISOString(),
    })
      .then((res) => {
        setLoading({ loading: false, error: false });
        setFilterBtn(false);

        doIfArr(res?.data?.numOfMinutesPerMonthByRiskCategoryAnalytics, () => {
          if (res.data.numOfMinutesPerMonthByRiskCategoryAnalytics) {
            for (const data of res.data.numOfMinutesPerMonthByRiskCategoryAnalytics) {
              labelsTempArray.push(data.monthName);
              let {
                veryHighRiskMinutes,
                veryHighRiskPercentage,
                highRiskMinutes,
                highRiskPercentage,
                moderateRiskMinutes,
                moderateRiskPercentage,
                lowRiskMinutes,
                lowRiskPercentage,
                noDataRiskMinutes,
                noDataRiskPercentage,
              } = data;
              let getVerHighRisktMinutes = veryHighRiskMinutes.split(` `)[0];
              let getHighRiskMinutes = highRiskMinutes.split(` `)[0];
              let getMediumRiskMinutes = moderateRiskMinutes.split(` `)[0];
              let getLowRiskMinutes = lowRiskMinutes.split(` `)[0];
              let geNoDataRiskMinutes = noDataRiskMinutes.split(` `)[0];
              let verHighRiskSeconds = veryHighRiskMinutes.split(` `)[2];
              let highRiskSeconds = highRiskMinutes.split(` `)[2];
              let mediumRiskSeconds = moderateRiskMinutes.split(` `)[2];
              let lowRiskSeconds = lowRiskMinutes.split(` `)[2];
              let noDataRiskSeconds = noDataRiskMinutes.split(` `)[2];
              let mergeVeryHighRisk = getVerHighRisktMinutes + `.` + verHighRiskSeconds;
              let mergeHighRisk = getHighRiskMinutes + `.` + highRiskSeconds;
              let mergeMediumRisk = getMediumRiskMinutes + `.` + mediumRiskSeconds;
              let mergeLowRisk = getLowRiskMinutes + `.` + lowRiskSeconds;
              let mergeNoRisk = geNoDataRiskMinutes + `.` + noDataRiskSeconds;

              vHighRiskPatients.push({
                id: mergeVeryHighRisk,
                percentage: veryHighRiskPercentage,
              });
              highRiskPatients.push({
                id: mergeHighRisk,
                percentage: highRiskPercentage,
              });
              mediumRiskPatients.push({
                id: mergeMediumRisk,
                percentage: moderateRiskPercentage,
              });
              lowRiskPatients.push({
                id: mergeLowRisk,
                percentage: lowRiskPercentage,
              });
              noDataRiskPatients.push({
                id: mergeNoRisk,
                percentage: noDataRiskPercentage,
              });
            }
          }
        });

        setGraphLabels([...labelsTempArray]);
        setVeryHighRishPatients([...vHighRiskPatients]);
        setHighRiskPatients([...highRiskPatients]);
        setMediumRiskPatients([...mediumRiskPatients]);
        setLowRiskPatients([...lowRiskPatients]);
        setNoDataRiskPatients([...noDataRiskPatients]);
      })
      .catch(() => {
        setLoading({ loading: false, error: true });
        setFilterBtn(false);
      });
  };

  return (
    <Zoom in={clicked} timeout={500} unmountOnExit>
      <Box
        sx={{
          backgroundColor: `white`,
          boxShadow: `0 0 10px 0 rgba(0, 0, 0, 0.1), 0 0 16px 0 rgba(0, 0, 0, 0.05) `,
          borderRadius: `8px`,
        }}
      >
        <h4 className="px-3 pt-3 pb-2 mb-0 mb-0" style={{ fontWeight: 400 }}>
          Monitoring by risk category:
        </h4>
        {showMsg ? (
          <span className="ml-3" style={{ color: `red`, fontSize: `12px` }}>
            * Invalid date selection
          </span>
        ) : null}
        <Grid container sx={{ p: 2 }} rowSpacing={1} columnSpacing={1}>
          <Grid item xs={5.5} sm={4} md={5.5} lg={4} xl={4}>
            {` `}
            <DatePickerCustomize
              pickerProps={{
                ...pickerPropsHandler(`From`),
              }}
              dateInputValue={fromDate}
              onChange={(e) => fromDateChangeHandler(e, setFilterBtn, setErrorMsg, setFromDate, toDate)}
            />
          </Grid>
          <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
            {` `}
            <Box sx={{ p: 0.7 }}>-</Box>
          </Grid>
          <Grid item xs={5.5} sm={4} md={5.5} lg={4} xl={4}>
            {` `}
            <DatePickerCustomize
              pickerProps={{
                ...pickerPropsHandler(`To`),
              }}
              dateInputValue={toDate}
              maxDate={true}
              onChange={(e) => toDateChangeHandler(e, setFilterBtn, setErrorMsg, fromDate, setToDate)}
            />
          </Grid>
          <Grid item xs={12} sm={3} md={12} lg={3} xl={3}>
            <Filterbutton
              arg={{ payLoad: {}, setData: null, setLoading: null }}
              apiCall={() => apiCall(view)}
              filterBtn={filterBtn}
              loading={loading}
              ownApi={true}
            />
          </Grid>
        </Grid>
        <Box sx={{ mt: -2, marginLeft: 3, marginRight: 3, height: matchesBlg ? 330 : 300 }}>
          <Bar
            data={{
              labels: graphLabels,
              datasets: [
                {
                  label: `Very High Risk`,
                  backgroundColor: `#eb2f06`,
                  data: veryHighRiskPatients.map((item) => item.id),
                  percentage: veryHighRiskPatients.map((item) => item.percentage),
                },
                {
                  label: `High Risk`,
                  backgroundColor: `#a70909`,
                  data: highRiskPatients.map((item) => item.id),
                  percentage: highRiskPatients.map((item) => item.percentage),
                },
                {
                  label: `Medium Risk`,
                  backgroundColor: `#227093`,
                  data: mediumRiskPatients.map((item) => item.id),
                  percentage: mediumRiskPatients.map((item) => item.percentage),
                },
                {
                  label: `Low Risk`,
                  backgroundColor: `#17a2b8`,
                  data: lowRiskPatients.map((item) => item.id),
                  percentage: lowRiskPatients.map((item) => item.percentage),
                },
                {
                  label: `No Data`,
                  backgroundColor: `#95afc0`,
                  data: noDataRiskPatients.map((item) => item.id),
                  percentage: noDataRiskPatients.map((item) => item.percentage),
                },
              ],
            }}
            width={100}
            height={50}
            options={{
              tooltips: {
                callbacks: {
                  label: function (tooltipItem, data) {
                    let label = ``;
                    let percentage = data.datasets[tooltipItem.datasetIndex].percentage;
                    label += `${percentage[tooltipItem.index]}%, ${tooltipItem.value.split('.')[0] ? tooltipItem.value.split('.')[0] : 0} min ${
                      tooltipItem.value.split(`.`)[1] ? tooltipItem.value.split(`.`)[1] : 0
                    } sec`;
                    return label;
                  },
                },
              },
              scales: {
                xAxes: [
                  {
                    stacked: true,
                    gridLines: {
                      display: false,
                    },
                    scaleLabel: {
                      display: true,
                    },
                  },
                ],
                yAxes: [
                  {
                    stacked: true,
                    ticks: {
                      beginAtZero: true,
                    },
                    scaleLabel: {
                      display: true,
                      labelString: `Time spent on RPM(minutes)`,
                    },
                    type: `linear`,
                  },
                ],
              },
              responsive: true,
              maintainAspectRatio: false,
              legend: {
                position: `top`,
                labels: {
                  usePointStyle: true,
                  boxWidth: 6,
                },
              },
            }}
          />
        </Box>
      </Box>
    </Zoom>
  );
};
