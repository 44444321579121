import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import css from '../viewData.module.css';
import { PdfViewer } from '../pdfViewer/pdfViewer';
import momentOriginal from 'moment';
import { Box, Stack } from '@mui/material';
import { fixNum, condForDetaiandSummaryGraphs } from 'src/utils';
import { ConditionallyRenderWrapper } from 'src/pages/common/HOC/ConditionallyRenderWrapper';

let dayData = null;

const WrappWithBox = ({ children }) => {
  return <Box sx={{ height: 25 }}><h5>{children}</h5> </Box>

}
const WrappWithHeading = ({ children, isRenderingValues }) => {
  return <>
    {isRenderingValues ? <Box sx={{ height: 25 }}><h5 style={{ visibility: `hidden` }}>Hidden</h5></Box> : <Box sx={{ height: 25 }}></Box>}
    {children}
  </>
}
const RenderSpontaneousBreaths = ({ breaths, isHeading }) => {
  const { spontCycledBreaths, spontTriggBreaths } = breaths || null
  const triggerHeading = `Triggered Breaths :`
  const cycledHeading = `Cycled Breaths :`
  if (isHeading) {
    return <WrappWithHeading isRenderingValues={false}> {spontCycledBreaths &&
      spontTriggBreaths ? <><WrappWithBox>
        {cycledHeading}
      </WrappWithBox>
      <WrappWithBox >
        {triggerHeading}
      </WrappWithBox>

    </> : spontTriggBreaths ? <WrappWithBox>{triggerHeading}</WrappWithBox> : spontCycledBreaths ? <WrappWithBox >{cycledHeading}</WrappWithBox> : null}</WrappWithHeading>
  } else {
    return <WrappWithHeading isRenderingValues={true}> {spontCycledBreaths &&
      spontTriggBreaths ? <><WrappWithBox>
        {`${spontCycledBreaths} %`}
      </WrappWithBox>
      <WrappWithBox>
        {`${spontTriggBreaths} %`}
      </WrappWithBox>
    </> : spontTriggBreaths ? <WrappWithBox>{`${spontTriggBreaths} %`}</WrappWithBox> : spontCycledBreaths ? <WrappWithBox>{`${spontCycledBreaths} %`}</WrappWithBox> : null}</WrappWithHeading>
  }
}

export const ViewDataDetail = ({ data, reportId, start, end, userId }) => {
  const { resmed: { realTimeDataAvailable } } = useSelector((state) => state)
  const [isLiveDataAvailable, setIsLiveDataAvailable] = useState(null);
  const [showModalList, setShowModalList] = useState(false);
  const patientManufacturer = useSelector((state) => state?.patient?.patientReport?.patient?.device?.manufacturer);
  const [isResmedPdfSummaryReportAvailable, setIsPdfSummaryReportAvailable] = useState(null);

  // EFFECT IS USE, TO ENABLE/DISABLED -> PENDING PATIENT LIST + IMPORT PATIENT BUTTON
  useEffect(() => {
    if (realTimeDataAvailable) {
      if (realTimeDataAvailable.apiStatus === `available`) {
        setIsLiveDataAvailable(false);
      }
      if (realTimeDataAvailable.apiStatus === `unavailable`) {
        setIsLiveDataAvailable(true);
      }
      if (realTimeDataAvailable.apiStatus === `disabled`) {
        setIsLiveDataAvailable(true);
      }
    }
  }, [realTimeDataAvailable]);

  const handleConvertAverageHighLeakInToHoursAndMinutes = (mappedData) => {
    const averageHighLeakInToHours = Math.floor(mappedData?.leak_time / 60);
    const averageHighLeakInToMinutes = mappedData?.leak_time % 60;
    return `${averageHighLeakInToHours?.toString().padStart(2, '0')}:${averageHighLeakInToMinutes?.toString().padStart(2, '0')}`;
  }
  const formatValue = (value) => (value !== null && value !== undefined ? value.toFixed(2) : "N/A");


  if (data) {
    dayData = data?.usage?.DataPerDay.map((mappedData, i) => {
      return (
        <React.Fragment key={i.date}>
          <tr>
            <td colSpan="3" className="pt-0 pb-0">
              <h5
                style={{
                  color: `black`,
                  textDecoration: `underline`,
                  fontSize: 14,
                }}
              >
                {/* <span style={{ fontWeight: "bold" }}>{`DAY ${i + 1}:`}</span> */}
                {` ${momentOriginal.utc(mappedData?.date).format('MMMM DD, YYYY')}`}
              </h5>
            </td>
          </tr>
          <tr>
            <td colSpan="3" className="pt-0 pb-0">
              <h5 style={{ color: `black`, fontSize: 14 }}>
                <span style={{ fontWeight: `bold` }}>Total Usage:</span>
                {mappedData?.usage ? ` ${Math.floor(mappedData.usage / 60)} hours ${mappedData.usage - Math.floor(mappedData.usage / 60) * 60} minutes` : ` N/A`}
              </h5>
            </td>
          </tr>

          <tr>
            <td colSpan="3" className="pt-0 pb-0">
              <h5 className="mb-0" style={{ color: `black` }}>
                Pressure:
              </h5>
            </td>
          </tr>
          {patientManufacturer === `resmed` && (
            <tr>
              <td style={{ width: `35%` }}>
                <h5 className="mb-3">&nbsp;</h5>
                <h5>95th percentile:</h5>
                <h5>Maximum:</h5>
                <h5>Median:</h5>
              </td>
              <td style={{ width: `60%` }}>
                <tr className="w-100">
                  {mappedData?.ninetyFiveTgtIPAP && mappedData?.maxTgtIPAP && mappedData?.medTgtIPAP && (
                    <td className="col-50">
                      <div>
                        <h5 className="text-dark">IPAP</h5>
                      </div>
                      <h5>
                        {mappedData?.ninetyFiveTgtIPAP.toFixed(1)} cmH<sub>2</sub>O
                      </h5>
                      <h5>
                        {mappedData?.maxTgtIPAP.toFixed(1)} cmH<sub>2</sub>O
                      </h5>
                      <h5>
                        {mappedData?.medTgtIPAP.toFixed(1)} cmH<sub>2</sub>O
                      </h5>
                    </td>
                  )}

                  {mappedData?.ninetyFiveTgtEPAP && mappedData?.maxTgtEPAP && mappedData?.medTgtEPAP && (
                    <td className="col-50">

                      <div>
                        <h5 className="text-dark">EPAP</h5>
                      </div>
                      <h5>
                        {mappedData?.ninetyFiveTgtEPAP.toFixed(1)} cmH<sub>2</sub>O
                      </h5>
                      <h5>
                        {mappedData?.maxTgtEPAP.toFixed(1)} cmH<sub>2</sub>O
                      </h5>
                      <h5>
                        {mappedData?.medTgtEPAP.toFixed(1)} cmH<sub>2</sub>O
                      </h5>

                    </td>
                  )}
                  {!mappedData?.ninetyFiveTgtEPAP && !mappedData?.maxTgtEPAP && !mappedData?.medTgtEPAP && !mappedData?.ninetyFiveTgtIPAP && !mappedData?.maxTgtIPAP && !mappedData?.medTgtIPAP && (
                    <td className="col-50">

                      <h5>&nbsp;</h5>
                      <h5>N/A</h5>
                      <h5>N/A</h5>
                      <h5>N/A</h5>

                    </td>
                  )}
                </tr>
              </td>
            </tr>
          )}

          {patientManufacturer === `reactHealth` && (
            <tr>
              <td style={{ width: `35%` }}>
                <h5>95th percentile:</h5>
              </td>

              {mappedData?.eip > 0 ? <td style={{ width: `60%` }}>
                <Stack sx={{ marginTop: `-25px` }} direction='row' spacing={2}>
                  <div>
                    <div>
                      <h5 className="text-dark">IPAP</h5>
                    </div>
                    <h5>
                      {fixNum(mappedData?.ipapPressure95, 1)} cmH<sub>2</sub>O
                    </h5>
                    <h5>
                      {fixNum(mappedData?.epapPressure95, 1)} cmH<sub>2</sub>O
                    </h5>
                  </div>
                  <div>
                    <div>
                      <h5 className="text-dark">EPAP</h5>
                    </div>
                    <h5>
                      {fixNum(mappedData?.ipapMaxPressure, 1)} cmH<sub>2</sub>O
                    </h5>
                    <h5>
                      {fixNum(mappedData?.epapMaxPressure, 1)} cmH<sub>2</sub>O
                    </h5>
                  </div>
                </Stack>


              </td> : mappedData?.p95 && (
                <>
                  <td style={{ width: `60%` }}>
                    <h5>
                      {fixNum(mappedData?.p95, 2)} cmH<sub>2</sub>O
                    </h5>
                  </td>
                  {!mappedData?.p95 && !mappedData?.max_p && (
                    <h5>N/A</h5>
                  )}
                </>

              )}



            </tr>
          )}

          {patientManufacturer &&
            patientManufacturer !== `resmed` &&
            patientManufacturer !== `reactHealth` && (
              <tr>
                <td style={{ width: `35%` }}>
                  <h5 className="mb-3">&nbsp;</h5>
                  <h5>90 percentile:</h5>
                  <h5>Average:</h5>
                </td>
                <td style={{ width: `60%` }}>
                  <tr className="w-100">
                    {(mappedData?.ninetyTgtIPAP || mappedData?.avgTgtIPAP) && (
                      (mappedData?.ninetyTgtIPAP !== 0 || mappedData?.avgTgtIPAP !== 0) && (
                        <td className="col-50">
                          <div>
                            <h5 className="text-dark">IPAP</h5>
                          </div>
                          <h5>
                            {mappedData?.ninetyTgtIPAP?.toFixed(1)} cmH<sub>2</sub>O
                          </h5>
                          <h5>
                            {mappedData?.avgTgtIPAP?.toFixed(1)} cmH<sub>2</sub>O
                          </h5>
                        </td>
                      )
                    )}

                    {(mappedData?.ninetyTgtEPAP || mappedData?.avgTgtEPAP) && (
                      (mappedData?.ninetyTgtEPAP !== 0 || mappedData?.avgTgtEPAP !== 0) && (
                        <td className="col-50">
                          <div>
                            <h5 className="text-dark">EPAP</h5>
                          </div>
                          <h5>
                            {mappedData?.ninetyTgtEPAP?.toFixed(1)} cmH<sub>2</sub>O
                          </h5>
                          <h5>
                            {mappedData?.avgTgtEPAP?.toFixed(1)} cmH<sub>2</sub>O
                          </h5>
                        </td>
                      )
                    )}

                    {(mappedData?.ninetyTgtCPAP || mappedData?.avgTgtCPAP) && (
                      (mappedData?.ninetyTgtCPAP !== 0 || mappedData?.avgTgtCPAP !== 0) && (
                        <td className="col-50">
                          <div>
                            <h5 className="text-dark">CPAP</h5>
                          </div>
                          <h5>
                            {mappedData?.ninetyTgtCPAP?.toFixed(1)} cmH<sub>2</sub>O
                          </h5>
                          <h5>
                            {mappedData?.avgTgtCPAP?.toFixed(1)} cmH<sub>2</sub>O
                          </h5>
                        </td>
                      )
                    )}

                    {!mappedData?.ninetyTgtIPAP && !mappedData?.avgTgtIPAP && mappedData?.ninetyTgtEPAP && !mappedData?.avgTgtEPAP && !mappedData?.ninetyTgtCPAP && !mappedData?.avgTgtCPAP ? (
                      <td className="col-50">
                        <h5>&nbsp;</h5>
                        <h5>N/A</h5>
                        <h5>N/A</h5>
                      </td>
                    ) : null}
                  </tr>
                </td>
              </tr>
            )}

          <tr>
            <td colSpan="3" className="pt-0 pb-0">
              <h5 className="mb-0" style={{ color: `black` }}>
                Events per hour:
              </h5>
            </td>
          </tr>
          {patientManufacturer === `resmed` && (
            <tr>
              <td style={{ width: `35%` }}>
                <h5>AHI:</h5>
                <h5>AI:</h5>
                <h5>HI:</h5>
                <h5>CAI:</h5>
                <h5>CSR:</h5>
                <h5>RERA:</h5>
              </td>
              <td style={{ width: `60%` }}>
                <h5>{formatValue(mappedData?.AHI)}</h5>
                <h5>{formatValue(mappedData?.AI)}</h5>
                <h5>{formatValue(mappedData?.HI)}</h5>
                <h5>{formatValue(mappedData?.CAI)}</h5>
                <h5>{formatValue(mappedData?.CSR)}</h5>
                <h5>{formatValue(mappedData?.RERA)}</h5>
              </td>
            </tr>
          )}

          {patientManufacturer === `reactHealth` && (
            <tr>
              <td style={{ width: `35%` }}>
                <h5>AHI:</h5>
                <h5>CAI:</h5>
              </td>
              <td style={{ width: `60%` }}>
                <h5>{formatValue(mappedData?.ahi)}</h5>
                <h5>{formatValue(mappedData?.cai)}</h5>
              </td>
            </tr>
          )}

          {patientManufacturer &&
            patientManufacturer !== `resmed` &&
            patientManufacturer !== `reactHealth` && (
              <tr>
                <td style={{ width: `35%` }}>
                  <h5>AHI:</h5>
                  <h5>CAI:</h5>
                  <h5>PB:</h5>
                </td>
                <td style={{ width: `60%` }}>
                  <h5>{formatValue(mappedData?.AHI)}</h5>
                  <h5>{formatValue(mappedData?.CAI)}</h5>
                  <h5>
                    <h5>
                      {mappedData?.periodicBreathingTime === 0 || (mappedData?.periodicBreathingTime !== null && mappedData?.periodicBreathingTime !== undefined)
                        ? `${mappedData?.periodicBreathingTime.toFixed(2)}%`
                        : "N/A"}
                    </h5>

                  </h5>
                </td>
              </tr>
            )}

          <tr>
            <td colSpan="3" className="pt-0 pb-0">
              <h5 className="mb-0" style={{ color: `black` }}>
                Leak:
              </h5>
            </td>
          </tr>
          {patientManufacturer === `resmed` && (
            <tr>
              <td style={{ width: `35%` }}>
                <h5>95th percentile leak:</h5>
                <h5>Maximum leak:</h5>
                <h5>Median leak:</h5>
              </td>
              <td style={{ width: `60%` }}>
                <h5>{mappedData?.ninetyFiveLeak === 0 || mappedData?.ninetyFiveLeak !== null ? `${mappedData?.ninetyFiveLeak ?? 'N/A'} L/min` : 'N/A'}</h5>
                <h5>{mappedData?.maxLeak === 0 || mappedData?.maxLeak !== null ? `${mappedData?.maxLeak ?? 'N/A'} L/min` : 'N/A'}</h5>
                <h5>{mappedData?.medLeak === 0 || mappedData?.medLeak !== null ? `${mappedData?.medLeak ?? 'N/A'} L/min` : 'N/A'}</h5>

              </td>
            </tr>
          )}

          {patientManufacturer === `reactHealth` && (
            <tr>
              <td style={{ width: `35%` }}>
                <h5>Average Leak:</h5>
                <h5>{`High Leak Time (>90 LPM):`}</h5>
              </td>

              <td style={{ width: `60%` }}>
                <h5>{mappedData?.avgLeak || mappedData?.avgLeak === 0 ? `${mappedData?.avgLeak !== null ? mappedData?.avgLeak.toFixed(1) : `N/A`} L/min` : `N/A`}</h5>
                <h5>{mappedData?.leak_time || mappedData?.leak_time === 0 ? `${mappedData?.leak_time !== null ? `${handleConvertAverageHighLeakInToHoursAndMinutes(mappedData)} (HH:mm)` : `N/A`}` : `N/A`}
                </h5>
              </td>
            </tr>
          )}

          {patientManufacturer &&
            patientManufacturer !== `resmed` &&
            patientManufacturer !== `reactHealth` && (
              <tr>
                <td style={{ width: `35%` }}>
                  <h5>Average Unintentional Leak:</h5>
                </td>
                <td style={{ width: `60%` }}>
                  <h5>{mappedData?.avgLeak || mappedData?.avgLeak === 0 ? `${mappedData?.avgLeak !== null ? mappedData?.avgLeak?.toFixed(2) : `N/A`} L/min` : `N/A`}</h5>
                </td>
              </tr>
            )}

          {patientManufacturer === `resmed` && (
            <ConditionallyRenderWrapper
              con={condForDetaiandSummaryGraphs({ conArr: [mappedData?.ninetyFiveTidalVol, mappedData?.ninetyFiveRespRate, mappedData?.ninetyFiveMinuteVent, mappedData?.ninetyFiveIERatio], breaths: !!(mappedData?.spontCycledBreaths || mappedData?.spontTriggBreaths) })}
            >
              <>
                <tr>
                  <td colSpan="3" className="pt-0 pb-0">
                    <h5 className="mb-0" style={{ color: `black` }}>
                      NIV Parameters (95th percentile metrics):
                    </h5>
                  </td>
                </tr>
                <tr>
                  <td style={{ width: `60%` }}>
                    <Stack direction='column'>
                      <ConditionallyRenderWrapper con={condForDetaiandSummaryGraphs({ conArr: [mappedData?.ninetyFiveTidalVol] })} >
                        <Box>
                          <h5>Tidal Volume (TV):</h5>
                        </Box>
                      </ConditionallyRenderWrapper>
                      <ConditionallyRenderWrapper con={condForDetaiandSummaryGraphs({ conArr: [mappedData?.ninetyFiveRespRate] })}>
                        <Box>
                          <h5>Respiratory rate (RR):</h5>
                        </Box>
                      </ConditionallyRenderWrapper>
                      <RenderSpontaneousBreaths isHeading={true} breaths={{ ...mappedData }} />
                      <ConditionallyRenderWrapper con={condForDetaiandSummaryGraphs({ conArr: [mappedData?.ninetyFiveMinuteVent] })}>
                        <Box>
                          <h5>Minute ventilation (MV):</h5>
                        </Box>
                      </ConditionallyRenderWrapper>
                      <ConditionallyRenderWrapper con={condForDetaiandSummaryGraphs({ conArr: [mappedData?.mappedData?.ninetyFiveIERatio] })}>
                        <Box>
                          <h5>I:E Ratio:</h5>
                        </Box>
                      </ConditionallyRenderWrapper>
                    </Stack>
                  </td>
                  <td style={{ width: `40%` }}>
                    <Stack direction='column'>
                      <Box> <h5>
                        {mappedData?.ninetyFiveTidalVol || mappedData?.ninetyFiveTidalVol === 0
                          ? `${mappedData?.ninetyFiveTidalVol !== null ? mappedData?.ninetyFiveTidalVol : `N/A`} ml/breath`
                          : `N/A`}
                      </h5></Box>
                      <Box> <h5>
                        {mappedData?.ninetyFiveRespRate || mappedData?.ninetyFiveRespRate === 0
                          ? `${mappedData?.ninetyFiveRespRate !== null ? mappedData?.ninetyFiveRespRate : `N/A`} breaths/min`
                          : `N/A`}
                      </h5></Box>
                      <RenderSpontaneousBreaths isHeading={false} breaths={{ ...mappedData }} />
                      <Box>    <h5>

                        {mappedData?.ninetyFiveMinuteVent || mappedData?.ninetyFiveMinuteVent === 0
                          ? `${mappedData.ninetyFiveMinuteVent !== null ? mappedData?.ninetyFiveMinuteVent : `N/A`} L/min`
                          : `N/A`}
                      </h5></Box>
                      <Box><h5>
                        {mappedData?.ninetyFiveIERatio || mappedData?.ninetyFiveIERatio === 0
                          ? `${mappedData?.ninetyFiveIERatio !== null ? `1:${mappedData?.ninetyFiveIERatio}` : `N/A`}`
                          : `N/A`}
                      </h5></Box>
                    </Stack>
                  </td >
                </tr >
              </>
            </ConditionallyRenderWrapper>


          )}

          {patientManufacturer !== `resmed` && patientManufacturer !== `reactHealth` && (
            <ConditionallyRenderWrapper
              con={condForDetaiandSummaryGraphs({ conArr: [mappedData?.avgTidalVol, mappedData?.avgBreathRate, mappedData?.avgMinuteVent] })}>
              <>
                <tr>
                  <td colSpan="3" className="pt-0 pb-0">
                    <h5 className="mb-0" style={{ color: `black` }}>
                      NIV Parameters (Average metrics):
                    </h5>
                  </td>
                </tr>
                <tr>
                  <td style={{ width: `35%` }}>
                    <ConditionallyRenderWrapper con={condForDetaiandSummaryGraphs({ conArr: [mappedData?.avgTidalVol] })}>
                      <h5>Tidal Volume (TV):</h5>
                    </ConditionallyRenderWrapper>
                    <ConditionallyRenderWrapper con={condForDetaiandSummaryGraphs({ conArr: [mappedData?.avgBreathRate] })}>
                      <h5>Respiratory rate (RR):</h5>
                    </ConditionallyRenderWrapper>
                    <ConditionallyRenderWrapper con={condForDetaiandSummaryGraphs({ conArr: [mappedData?.avgMinuteVent] })}>
                      <h5>Minute ventilation (MV):</h5>
                    </ConditionallyRenderWrapper>
                  </td>
                  <td style={{ width: `60%` }}>
                    <h5>{mappedData?.avgTidalVol || mappedData?.avgTidalVol === 0 ? `${mappedData?.avgTidalVol !== null ? mappedData?.avgTidalVol : 'N/A'} ml/breath` : `N/A`}</h5>
                    <h5>
                      {mappedData?.avgBreathRate || mappedData?.avgBreathRate === 0 ? `${mappedData?.avgBreathRate !== null ? mappedData?.avgBreathRate : 'N/A'} breaths/min` : `N/A`}
                    </h5>
                    <h5>{mappedData?.avgMinuteVent || mappedData?.avgMinuteVent === 0 ? `${mappedData.avgMinuteVent !== null ? mappedData?.avgMinuteVent : 'N/A'} L/min` : `N/A`}</h5>
                  </td>
                </tr>
              </>
            </ConditionallyRenderWrapper>
          )}
        </React.Fragment >
      );
    });
  } else {
    dayData = null;
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ minHeight: `298px`, color: `rgb(193, 193, 193)` }}>
        Patient record not found.
      </div>
    );
  }




  return (
    <>
      <div id={css.viewSummaryData} className="pt-3">
        <table className="table table-borderless ml-1">
          <tbody>{dayData}</tbody>
        </table>
        <h5
          className="ml-3"
          data-toggle={!isLiveDataAvailable ? `modal` : null}
          data-target="#PdfViewer"
          onClick={() => (!isLiveDataAvailable && isResmedPdfSummaryReportAvailable ? setShowModalList(true) : null)}
          style={{
            color: !isLiveDataAvailable && isResmedPdfSummaryReportAvailable ? `black` : `grey`,
            cursor: !isLiveDataAvailable && isResmedPdfSummaryReportAvailable ? `pointer` : `unset`,
            textDecoration: !isLiveDataAvailable && isResmedPdfSummaryReportAvailable ? `underline` : `none`,
          }}
        >
          {patientManufacturer === `reactHealth` ? null : `Click Here to View Detailed Report`}
        </h5>
      </div>
      <PdfViewer
        userId={userId}
        start={start}
        end={end}
        reportId={reportId}
        show={showModalList}
        onHide={() => setShowModalList(false)}
        setIsPdfSummaryReportAvailable={setIsPdfSummaryReportAvailable}
        detail={true}
      />
    </>
  );

}


