/* eslint-disable camelcase */
import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import css from './pastData.module.css';
import { GET_PAST_DATA_REPORTS, getRequest, GET_PATIENT_PAST_DATA } from '../../../../../../../../crud/crud';
import { PulseSpinner } from '../../../../../../../common/components/spinner/spinner';
import { PastDataPDFViewer } from './pastDataPDFviewer/PastDataPDFViewer';
import { Box, Button, Grid } from '@mui/material';
import { PastDataNavigation } from './pastDataPDFviewer/pastDataNavigation';
import { TableSkelton } from 'src/pages/common/components/Skelton/tableSkelton';
import moment from 'moment';
import LoadingButton from '@mui/lab/LoadingButton';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { DatePickerCustomize } from 'src/pages/common/components/datePicker/DatePickerCustomize';
import { momentWrapper } from 'src/momentWrapper';

export const PatientPastData = ({ telemedicinePatientId, patient }) => {
  const userId = useSelector((state) => state?.patient?.patientData?.data?._id);
  const auth = useSelector((state) => state?.user?.auth);
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [fileURL, setFileURL] = useState(``);
  const [visibleFlag, setVisibleFlag] = useState(true);
  const [loading, setLoading] = useState(false);
  const [pdfFileLoading, setpdfFileLoading] = useState({ loading: false, error: false });
  const [isCurrent, setisCurrent] = useState(`checking`);
  const selectedPatient = useSelector((state) => state.patient?.patientReport?.patient);
  const [errorMsg, seterrorMsg] = useState({ fromDate: ``, toDate: `` });
  const [toDate, setToDate] = useState(selectedPatient || patient ? momentWrapper().endOf(`month`).format(`YYYY-MM`) : null);
  const [fromDate, setFromDate] = useState(selectedPatient || patient ? momentWrapper().subtract(5, `months`).startOf(`month`).format(`YYYY-MM`) : null);
  const theme = useTheme();
  const isGreater = useMediaQuery(theme.breakpoints.up(`xl`));
  const handleFromDateChange = (date) => {
    // converting date object to year & month
    date = moment(date).format(`YYYY-MM`);

    // preparing moment objects for comparison
    let startDate_StartOfMonth = momentWrapper(date, `YYYY-MM`).startOf(`month`);
    let endDate_EndOfMonth = momentWrapper(toDate, `YYYY-MM`).endOf(`month`);

    if (startDate_StartOfMonth.isSameOrBefore(endDate_EndOfMonth)) {
      seterrorMsg({ ...errorMsg, fromDate: `` });
      setFromDate(date);
    } else {
      seterrorMsg({ ...errorMsg, fromDate: `Invalid Date` });
      setFromDate(date);
    }
  };

  const handleToDateChange = (date) => {
    // To Date
    // converting date object to year & month
    date = moment(date).format(`YYYY-MM`);

    // preparing moment objects for comparison
    let startDate_StartOfMonth = momentWrapper(fromDate, `YYYY-MM`).startOf(`month`);
    let endDate_EndOfMonth = momentWrapper(date, `YYYY-MM`).endOf(`month`);

    if (endDate_EndOfMonth.isSameOrAfter(startDate_StartOfMonth)) {
      seterrorMsg({ ...errorMsg, toDate: `` });
      setToDate(date);
    } else {
      seterrorMsg({ ...errorMsg, toDate: `Invalid Date` });
      setToDate(date);
    }
  };

  const dates = {
    endDate: selectedPatient || patient ? momentWrapper().toISOString() : null,
    startDate: selectedPatient || patient ? momentWrapper().subtract(5, `months`).startOf(`month`).toISOString() : null,
  };
  const renderCount = useRef(0)
  const cachedUrl = useMemo(() => {

    let dateRange = `/?startDate=${renderCount.current ? fromDate : dates?.startDate}&endDate=${renderCount.current ? toDate : dates?.endDate}`

    let conditionalUr = selectedPatient || patient ? dateRange : ``
    let id = userId || telemedicinePatientId
    let url = GET_PATIENT_PAST_DATA + id + conditionalUr
    return { url, id }
  }, [selectedPatient, patient, dates])
  useEffect(() => {
    if (userId || telemedicinePatientId) {
      renderCount.current = renderCount.current + 1
      setLoading(true);

      getRequest(cachedUrl?.url)
        .then((response) => {
          setLoading(false);

          setData(response.data.reports);
          setisCurrent(`yes`);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
    return () => {
      setData(null);
      setFromDate(momentWrapper().subtract(5, `months`).startOf(`month`).format(`YYYY-MM`));
      setToDate(momentWrapper().endOf(`month`).format(`YYYY-MM`));
      seterrorMsg({ fromDate: ``, toDate: `` });
    };
  }, [userId || telemedicinePatientId, selectedPatient, patient]);

  const getPdfReportPastData = (blodId) => {
    if (blodId === `N/A`) {
      return;
    }
    let temp = null;
    setpdfFileLoading({ loading: true, error: false });
    getRequest(GET_PAST_DATA_REPORTS + cachedUrl?.id
      + `/` + blodId,
      { responseType: `arraybuffer` }
    )
      .then((res) => {
        setpdfFileLoading({ loading: false, error: false });
        temp = new Blob([res.data], { type: `application/pdf` });
        setFileURL(URL.createObjectURL(temp));
        setShow(true);
      })
      .catch((err) => {
        setpdfFileLoading({ loading: false, error: true });
        setShow(false);
      });
  };

  const fetchReports = async () => {
    setLoading(true);
    getRequest(cachedUrl?.url)
      .then((response) => {
        setLoading(false);

        setData(response.data.reports);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  let number = data?.length + 1;


  const getStatus = (val) => {
    return val === false ? `NO` : `YES`
  }

  return (
    <>
      {pdfFileLoading?.loading && <PulseSpinner />}
      {selectedPatient || patient ? (
        <div id={css.pastDataMain} className="mb-4">
          <Box sx={{ p: isGreater ? 3.5 : 2, pb: 1 }}>
            <Grid container sx={{ width: `100%` }} rowSpacing={1.5} columnSpacing={1}>
              <Grid item xs={5.5} sm={5.5} md={5.5} lg={5.5} xl={3}>
                {/* <LocalizationProvider dateAdapter={AdapterDateFns}> */}
                <DatePickerCustomize
                  dateInputValue={momentWrapper(fromDate).toDate() || null}
                  pickerProps={{
                    size: `small`,
                    PopperProps: {
                      style: {
                        zIndex: 5000,
                      },
                    },
                    views: [`year`, `month`],
                    label: `From`,
                    inputFormat: `MMM/YY`,

                    InputProps: {
                      error: errorMsg?.fromDate?.length > 0,
                    },
                    OpenPickerButtonProps: {
                      style: {
                        color: errorMsg?.fromDate ? `red` : `#7c7c7c`,
                      },
                    },
                    maxDate: momentWrapper().endOf(`month`).toDate()
                  }}
                  onChange={(date) => handleFromDateChange(date)}

                />
              </Grid>
              <Grid sx={{ textAlign: `center`, mt: 1 }} item xs={1} sm={1} md={1} lg={1} xl={1}>
                <div>-</div>
              </Grid>
              <Grid item xs={5.5} sm={5.5} md={5.5} lg={5.5} xl={3}>
                <DatePickerCustomize
                  dateInputValue={momentWrapper(toDate).toDate() || null}
                  pickerProps={{
                    size: `small`,
                    views: [`year`, `month`],
                    label: `To`,
                    inputFormat: `MMM/YY`,
                    maxDate: momentWrapper().endOf(`month`).toDate(),
                    OpenPickerButtonProps: {
                      style: {
                        color: errorMsg?.toDate ? `red` : `#7c7c7c`,
                      },
                    },
                    InputProps: {
                      error: errorMsg?.toDate?.length > 0,
                    }
                  }}
                  onChange={(date) => handleToDateChange(date)}

                />
              </Grid>
              <Grid sx={{ textAlign: isGreater ? `center` : `start` }} item xs={12} sm={12} md={12} lg={12} xl={5}>
                {loading ? (
                  <LoadingButton sx={{ width: isGreater ? `70%` : `100%` }} loading variant="outlined">
                    Submit
                  </LoadingButton>
                ) : (
                  <Button
                    onClick={() => fetchReports()}
                    disableElevation
                    disabled={!!(errorMsg.fromDate?.length > 0 || errorMsg.toDate?.length > 0)}
                    sx={{ textTransform: `none`, width: isGreater ? `70%` : `100%` }}
                    variant="outlined"
                  >
                    Get Reports
                  </Button>
                )}
              </Grid>
            </Grid>
          </Box>
          <div className="pl-3">
            <div className="d-flex justify-content-end">
              <button
                className="btn"
                id={css.collapseButton}
                onClick={() => setVisibleFlag(!visibleFlag)}
                type="button"
                data-toggle="collapse"
                data-target="#patientPastDataComponent"
                aria-expanded="true"
                aria-controls="patientBasicInfo"
              >
                {visibleFlag ? <i className="fa fa-caret-down" aria-hidden="true"></i> : <i className="fa fa-caret-right" aria-hidden="true"></i>}
              </button>
            </div>
            <div className="collapse show" id="patientPastDataComponent">
              <div id={css.patientPastData} className="w-100 pb-2 pr-3 table-responsive">
                {loading ? (
                  <TableSkelton />
                ) : (
                  <table className="table table-bordered">
                    <thead style={{ backgroundColor: `transparent`, color: `black` }}>
                      <tr>
                        <th scope="col">Period</th>

                        {data?.map((d, index) => (
                          <th
                            scope="col"
                            key={d._id || index}
                            style={d.blobId !== `N/A` ? { color: `blue`, cursor: `pointer` } : null}
                            onClick={() => getPdfReportPastData(d.blobId)}
                          >
                            {d.monthName}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">RPM Time</th>
                        {data?.map((d, index) => (
                          <th scope="col" key={d._id || index}>
                            {d.totalTime}
                          </th>
                        ))}
                      </tr>
                      <tr>
                        <th scope="row">Adherent</th>
                        {data?.map((d, index) => (
                          <th scope="col" key={d._id || index}>
                            {d.isCompliant === `N/A` ? `N/A` : getStatus(d.isCompliant)}
                          </th>
                        ))}
                      </tr>
                      <tr>
                        <th scope="row">Avg. Use</th>
                        {data?.map((d, index) => (
                          <th scope="col" key={d._id || index}>
                            {d.averageUsage === `N/A` ? `N/A` : Math.trunc(d.averageUsage / 60) + `h ` + (d.averageUsage % 60) + `m`}
                          </th>
                        ))}
                      </tr>
                      <tr>
                        <th scope="row">Avg. Leak</th>
                        {data?.map((d, index) => (
                          <th scope="col" key={d._id || index}>
                            {d.averageLeak}
                          </th>
                        ))}
                      </tr>
                      <tr>
                        <th scope="row">Avg. AHI</th>
                        {data?.map((d, index) => (
                          <th scope="col" key={d._id || index}>
                            {d.averageAHI}
                          </th>
                        ))}
                      </tr>
                      <tr>
                        <th scope="row">Signed</th>
                        {data?.map((d, index) => (
                          <th scope="col" key={d._id || index}>
                            {d.isSigned === `N/A` ? `N/A` : getStatus(d.isSigned)}
                          </th>
                        ))}
                      </tr>
                      <tr>
                        <th scope="row">Billed</th>
                        {data?.map((d, index) => (
                          <th scope="col" key={d._id || index}>
                            {d.isBilled === `N/A` ? `N/A` : getStatus(d.isBilled)}
                          </th>
                        ))}
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colSpan={`${number}`}>
                          {` `}
                          <PastDataNavigation
                            auth={auth}
                            setData={setData}
                            userId={userId}
                            telemedicinePatientId={telemedicinePatientId}
                            loading={loading}
                            setLoading={setLoading}
                            isCurrent={isCurrent}
                            setisCurrent={setisCurrent}
                            selectedPatient={selectedPatient || patient}
                            setFromDate={setFromDate}
                            setToDate={setToDate}
                            fromDate={fromDate}
                            toDate={toDate}
                            url={cachedUrl?.url}
                          />
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : null}

      {show && <PastDataPDFViewer show={show} setShow={setShow} fileURL={fileURL} />}
    </>
  );
}

