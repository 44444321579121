export const HANDLE_TIMER = `HANDLE_TIMER`; 
export const LIST_ALL_PATIENTS = `LIST_ALL_PATIENTS`; 
export const LIST_ALL_PATIENTS_LOADING = `LIST_ALL_PATIENTS_LOADING`;
export const LIST_ALL_PATIENTS_ERROR = `LIST_ALL_PATIENTS_ERROR`;
export const SELECTED_PATIENT = `SELECTED_PATIENT`;
export const SELECTED_PATIENT_LOADING = `SELECTED_PATIENT_LOADING`;
export const SELECTED_PATIENT_ERROR = `SELECTED_PATIENT_ERROR`;
export const PATIENTS_FILTERS = `PATIENTS_FILTERS`;
export const LIST_ALL_PATIENTS_EMPTY = `LIST_ALL_PATIENTS_EMPTY`;
export const PATIENT_DATA_EMPTY = `PATIENT_DATA_EMPTY`;
export const INITIALIZE_START_END_TIME = `INITIALIZE_START_END_TIME`;
export const SET_VIDEO_CALL_STATUS = `SET_VIDEO_CALL_STATUS`;
export const HANDLE_SELECTED_TAB = `HANDLE_SELECTED_TAB`;
export const REPORT_LOADING = `REPORT_LOADING`;
export const HIDE_TIMER = `HIDE_TIMER`;
export const SET_MESSAGES = `SET_MESSAGES`;
export const SET_MESSAGES_CURRENT_PAGE = `SET_MESSAGES_CURRENT_PAGE`;
export const SET_MESSAGES_HASMORE = `SET_MESSAGES_HASMORE`;
export const TXT_ERROR = `TXT_ERROR`;
export const PATIENT_SWITCHING = `PATIENT_SWITCHING`;
export const SAVE_PAGE_REFRESH_PATIENT_INFO = `SAVE_PAGE_REFRESH_PATIENT_INFO`;
export const PATIENT_DATA_FLAG = `PATIENT_DATA_FLAG`;
export const SAVE_CSV_BULK_IMPORT_PATIENT = `SAVE_CSV_BULK_IMPORT_PATIENT`;
export const EMPTY_CSV_BULK_IMPORT_PATIENT = `EMPTY_CSV_BULK_IMPORT_PATIENT`;
export const ACTIVE_CONNECTION = `ACTIVE_CONNECTION`;
export const PATIENT_API_CAN_TOK_LIST = `PATIENT_API_CAN_TOK_LIST`;
export const PATIENT_API_CAN_TOK_DETAIL = `PATIENT_API_CAN_TOK_DETAIL`;
export const CHANGE_TIME_MONITORED = `CHANGE_TIME_MONITORED`

export const SMSRESPONSE = `SMSRESPONSE`;
export const ALL_TIME_LOGS = `ALL_TIME_LOGS`;
export const ALL_TIME_LOGS_LOADING = `ALL_TIME_LOGS_LOADING`;

export const FIRST_RESPOSNE = `FIRST_RESPOSNE`;
export const SOCKET_RESPONSE = `SOCKET_RESPONSE`;
export const COMMUNICATION_PATIENT = `COMMUNICATIONPATIENT`;
export const SET_NOTES = `SET_NOTES`;
export const CHANGE_LEFT_VOICE_MAIL_STATUS = `CHANGE_LEFT_VOICE_STATUS`;
export const SELECTED_PATIENT_UNUSE = `SELECTED_PATIENT_UNUSE`;


export const SELECTED_PATIENT_REPORT = `SELECTED_PATIENT_REPORT`;
export const GET_SMART_PHRASE = `GET_SMART_PHRASE`;
export const CURRENT_TAB = `CURRENT_TAB`;
