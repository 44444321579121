import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import css from './tasks.module.css';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { PuffSpinner } from '../../../../../../common/components/spinner/puff/puff';
import { Box, Grid, Stack } from '@mui/material';
import { CreateTask } from 'src/pages/home/modules/TaskManagement/CreateTask/CreateTask';
import { capitalizeFirstLetter, priorityColorTaskMang, taskStatusPillCol } from 'src/utils';
import { ViewTask } from 'src/pages/home/modules/TaskManagement/ViewTask/ViewTask';
import { getRequest, GET_ALL_TASKS } from 'src/crud/crud';
import { MuiPill } from 'src/pages/common/components/BadgePill/BadgePill';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import moment from 'moment';
import NoLength from 'src/pages/common/components/NoLength/NoLength';
import { ToggleTasks } from 'src/pages/home/modules/TaskManagement/ToggleTasks/ToggleTasks';
import { CustomBtn } from 'src/pages/common/components/Button/CustomBtn';

export const PatientTasks = ({ toggle, handleChartingToggle }) => {
    return (
        <div id={css.log}>

            <div
                style={{
                    display: `flex`,
                    borderTop: `1px solid #c1c1c1`,
                    borderBottom: `1px solid #c1c1c1`,
                    backgroundColor: `#1699c5`,
                    height: `30px`,
                    color: `white`,
                    width: `100%`,
                }}
            >
                {toggle === true ? <ExpandLessIcon onClick={handleChartingToggle} /> : <ExpandMoreIcon onClick={handleChartingToggle} />}
                Task Manager
            </div>
            {toggle && <TaskList toggle={toggle} />}
        </div>
    );
}

const TaskList = ({ toggle }) => {
    const [taskListFlag, setTaskListFlag] = useState(0)
    const blockRef = useRef(null)
    const [loadingState, setLoading] = useState({ error: false, loading: false })
    const [activeTasks, setActiveTasks] = useState(false)
    const { loading, error } = loadingState
    const [patientTasksState, setPatientTasks] = useState({
        patientTasks: [],
        selectedTask: {}
    })
    const [pagination, setPagination] = useState({
        rowsPerPage: 100,
        currentPage: 1,
        totalRows: 0,
        totalPage: 1
    })
    const {
        rowsPerPage,
        currentPage,
        totalPage } = pagination
    const { patientTasks, selectedTask } = patientTasksState
    const [viewTask, setViewTask] = useState(false)
    const [createTaskModelHandler, setCreateTaskModalHandler] = useState(false);
    const logBox = useRef(null);
    const renderCount = useRef(0)
    const fetchTaskList = useRef(`no`)
    const loadingSetter = (key, value) => {
        setLoading((p) => ({ ...p, [key]: value }))
    }
    const { patient: { patientData, isSwitchingPatient }, user: { user: { _id: userId } } } = useSelector((state) => state) || {}
    const { data } = patientData || {}
    const { _id: patientId, firstName, lastName } = data || false
    const resetState = () => {
        setPatientTasks({
            patientTasks: [],
            selectedTask: {}
        })
        fetchTaskList.current = `get`
        setLoading({ error: false, loading: false })
        setActiveTasks(false)
    }
    useEffect(() => {
        if (fetchTaskList.current === `get` || toggle && isSwitchingPatient === false && patientId) {
            const getPatientTasks = () => {
                loadingSetter(`error`, false)
                loadingSetter(`loading`, true)
                fetchTaskList.current = `no`
                let query =
                    GET_ALL_TASKS +
                    `?page=${currentPage}&recordsPerPage=${rowsPerPage}&sort=${JSON.stringify({
                        createdAt: -1,
                    })}&filters=${JSON.stringify({
                        _patientId: patientId,
                        ...(!activeTasks ? {} : { taskStatus: `closed` })
                    })}`;
                // getTasksHere
                getRequest(query).then((response) => {
                    const { data = { tasks: [] } } = response.data
                    const { tasks } = data
                    setPatientTasks((p) => ({ ...p, patientTasks: [...tasks] }))
                    setPagination((p) => {
                        return { ...p, totalRows: response?.data?.data?.pager?.totalRecords, totalPage: Math.ceil(response?.data?.data?.pager.totalRecords / response?.data?.data?.pager.recordsPerPage) }
                    })
                    loadingSetter(`loading`, false)
                }).catch(() => {
                    loadingSetter(`error`, true)
                    loadingSetter(`loading`, false)
                })
                // setPatientTasks()
            }
            if (taskListFlag && fetchTaskList.current !== `get`) {
                fetchTaskList.current = `get`
            }

            if (renderCount.current === 0 || fetchTaskList.current === `get`) {
                renderCount.current = renderCount.current + 1
                getPatientTasks()
            }
        } else if (toggle && isSwitchingPatient === true) {
            resetState()
        }

    }, [userId, activeTasks, currentPage, rowsPerPage, toggle, patientId, renderCount, taskListFlag, fetchTaskList, blockRef, isSwitchingPatient]);

    return (<>{toggle && (
        <div id={css.msgActualBody} >
            <div
                id="customBody"
                className="p-2"
                style={{
                    borderBottom: `1px solid #c1c1c1`,
                    backgroundColor: `white`,
                    overflow: `auto`,
                    height: `71vh`
                }}
                ref={logBox}
                onScroll={(e) => {
                    let hasRechedBottom = e.currentTarget.scrollHeight - e.currentTarget.scrollTop === e.currentTarget.clientHeight
                    let hasMore = currentPage !== totalPage
                    if (hasRechedBottom && hasMore) {
                        setPagination((p) => {
                            return { ...p, currentPage: p.currentPage + 1 }
                        })
                        setTaskListFlag((p) => p + 1)
                    }
                }}
            >
                {patientId ? <Box>
                    <Box sx={{
                        marginTop: `8px`,
                        marginBottom: `16px`,
                        display: `flex`,
                        justifyContent: `center`
                    }}>
                        <ToggleTasks customTexts={{ active: `Active`, closed: `Closed` }} customContStyles={{ width: { xs: `100%`, sm: `100%`, md: `100%`, lg: `100%`, xl: `100%` }, display: `flex`, alignItems: `center`, justifyContent: `center` }} assignedByMe={activeTasks} setAssignedByMe={() => setActiveTasks((p) => !p)} setTaskListFlag={() => setTaskListFlag((p) => p + 1)} />
                    </Box>

                    {!error && !loading ? !patientTasks?.length && <NoLength phrase={`No records found!`} /> : error && <Box sx={{ mt: 3, width: `100%`, display: `flex`, justifyContent: `center` }}>
                        <ErrorOutlineIcon sx={{ color: `red` }} />
                    </Box>}
                    {patientTasks?.map((task) => {
                        const { taskName, taskPriority, taskType, taskStatus, recurring = { recurrStatus: `off` } } = task || false
                        const { recurrStatus } = recurring
                        return (
                            <div key={taskName} ref={blockRef} style={{ cursor: `pointer` }} onClick={() => {
                                setViewTask((p) => !p)
                                setPatientTasks((p) => ({ ...p, selectedTask: task }))
                            }}>

                                <Box
                                    key={taskName}
                                    sx={{
                                        backgroundColor: task?._created_by?.isProvider ? `rgba(223, 212, 119, 0.5)` : `rgba(119, 223, 218, 0.5)`,
                                        width: `100%`,
                                        padding: `5px 10px`,
                                        mt: 1
                                    }}
                                >
                                    <Box sx={{ fontWeight: 500, fontSize: `large`, pb: 1 }}>{taskName}</Box>
                                    <Grid container spacing={.5} >
                                        {recurrStatus === `on` ? (
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <MuiPill
                                                    pillProps={{
                                                        sx: {
                                                            backgroundColor: `#56c516`,
                                                            color: `white`,
                                                            borderRadius: `none`
                                                        },
                                                        size: `small`,
                                                    }}
                                                >
                                                    Recurring
                                                </MuiPill>
                                            </Grid>
                                        ) : null}
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <MuiPill
                                                pillProps={{
                                                    sx: {
                                                        backgroundColor: taskStatusPillCol[taskStatus],
                                                        color: `white`,
                                                        borderRadius: `none`,
                                                    },
                                                    size: `small`,
                                                }}
                                            >
                                                {capitalizeFirstLetter(taskStatus)}
                                            </MuiPill>
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                            <MuiPill pillProps={{
                                                sx: {
                                                    backgroundColor: `#1699c5`,
                                                    color: `white`,
                                                    borderRadius: `none`,
                                                },
                                                size: `small`,
                                            }} >
                                                {taskType}
                                            </MuiPill>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <Box>{priorityColorTaskMang[taskPriority]}</Box></Grid>
                                    </Grid>
                                    <Stack direction='row' justifyContent={`end`}>
                                        <Box>
                                            <Stack direction='row' >
                                                <Box sx={{ mt: .6 }}>
                                                    <p className={css.cardTimeStamp}>
                                                        Due Date : {moment(task?.dueDate).format(`MM/DD hh:mm A`)}
                                                    </p>
                                                </Box>
                                            </Stack>
                                        </Box>
                                    </Stack>
                                </Box>
                            </div>
                        );
                    })}
                </Box> : null}
            </div>
            <div style={{ position: `relative` }}>
                <Box sx={{ display: `flex`, justifyContent: `flex-end`, marginTop: `10px`, paddingRight: `12px` }}>
                    <CustomBtn
                        btnProps={{
                            disabled: !patientId,
                            sx: { backgroundColor: `#238F3B`, width: 141 },
                            variant: `contained`,
                            color: `success`,
                            onClick: () => setCreateTaskModalHandler(true)
                        }}

                        element={`Add Task`}

                    />
                </Box>
            </div>
        </div>
    )
    }
        {loading && <PuffSpinner />}
        {
            createTaskModelHandler && (
                <CreateTask
                    setTaskListFlag={() => setTaskListFlag((p) => p + 1)}
                    hasPatientId={{ _id: patientId, firstName, lastName }}
                    createTaskModelHandler={createTaskModelHandler}
                    setCreateTaskModalHandler={setCreateTaskModalHandler}
                />
            )
        }
        {
            viewTask && <ViewTask selectedTaskModalHandler={viewTask}
                setSelectedTaskModalHandler={setViewTask}
                selectedTask={selectedTask}
                setTaskListFlag={setTaskListFlag} />
        }
    </>

    )
}