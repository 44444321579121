import { Box, Grid, IconButton, Fade } from '@mui/material';
import React, { useCallback, useEffect, useState, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { CustomizedDialogs } from 'src/pages/common/components/modal/CustomizeModal';
import { errorToast, successToast } from 'src/pages/common/components/snackBar/toast';
import { WordsFormatter } from 'src/pages/common/components/WordsFormatter/WordsFormatter';
import { CREATE_TASK, postRequest, UPDATE_TASK } from 'src/crud/crud';
import { PulseSpinner } from 'src/pages/common/components/spinner/spinner';
import { MuiSelect } from 'src/pages/common/components/MuiSelect/MuiSelect';
import { DateTimePickerMui } from 'src/pages/common/components/datePicker/DatePickerCustomize';
import { InputField } from 'src/pages/common/components/InputField/InputField';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import {
  assignUniqueId,
  convertToBase64,
  dynamicObjCreator,
  dynamicObjValidation,
  validateFilesAndInputs,
  _taskPriorityOptions,
  _recurringOptions,
  alertTimeOptions,
  customRecurringOptions,
  customRecurringNumberOptions,
  _taskTypeOptions,
  invalidDateTimePhraseTaskMang,
  dueDateValidation,
  cleanWordsFormatterVal,
} from 'src/utils';
import { AskForAssignee } from '../AskforAssignee/AskForAssignee';
import { momentWrapper } from 'src/momentWrapper';
import { ToastContainer } from 'react-toastify';
import { FilePreview } from './FilePreview';
import moment from 'moment';

import { ConfirmationWrapper as AttentionWrapper } from 'src/pages/common/HOC/ConfirmationWrapper';
export const CreateTask = ({ hasPatientId, createTaskModelHandler, setCreateTaskModalHandler, setTaskListFlag }) => {
  return (
    <CustomizedDialogs
      title="Create New Task"
      open={createTaskModelHandler}
      setOpen={() => setCreateTaskModalHandler((createTaskModelHandler) => !createTaskModelHandler)}
      size="xl"
      fullWidth={true}
      notShowDividers={true}
      showCloseButton={false}
    >
      <Box sx={{ mt: 2 }}>
        <TaskCreationAndEditing
          {...(hasPatientId ? { _patientId: hasPatientId } : {})}
          hasValues={false}
          mount={createTaskModelHandler}
          setTaskListFlag={setTaskListFlag}
          setCreateTaskModalHandler={() => setCreateTaskModalHandler((createTaskModelHandler) => !createTaskModelHandler)}
        />
      </Box>
    </CustomizedDialogs>
  );
};

export const TaskCreationAndEditing = ({ _patientId, hasValues, mount, isFromEditing, setCreateTaskModalHandler, setTaskListFlag }) => {
  const { user } = useSelector(({ user }) => user);
  const [loading, setLoading] = useState(false);
  const [inputsToEdit, setInputsToEdit] = useState({});
  const [changedAttachMents, setChangedAttachMents] = useState([]);
  const [newTask, setNewTask] = useState({
    patient: ``,
    taskName: ``,
    taskDescription: ``,
    taskPriority: `low`,
    taskType: `Reminder/Followup`,
    assignedBy: ``,
    assignedTo: ``,
    assignedDate: momentWrapper(new Date()).toISOString(),
    dueDate: null,
    recurring: ``,
    customRecurring: ``,
    customRecurringNumber: ``,
    remindBefore: ``,
    customRecurringDay: [],
  });
  const [openDueDateConf, setOpenDueDateConf] = useState({ open: false, confirmed: false });
  const [openDateSelection, setOpenDateSelection] = useState(false);
  const [onCloseHappenedDueDate, setOnCloseHappenedDueDate] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const renderCount = useRef(0);
  const { taskAttachments } = hasValues || false;
  const [errors, setErrors] = useState({
    assignedTo: ``,
    taskDescription: ``,
    taskName: ``,
    dueDate: ``,
    frequency: ``,
    frequencyNumeration: ``,
  });

  const { assignedTo: assignedToError, taskDescription: taskDescriptionError, taskName: taskNameError, dueDate: dueDateError } = errors;

  useEffect(() => {
    if (onCloseHappenedDueDate) dueDateValidation(newTask?.dueDate, setOpenDueDateConf);
  }, [onCloseHappenedDueDate]);

  useEffect(() => {
    if (_patientId) {
      setNewTask((p) => ({ ...p, patient: _patientId?._id }));
    } else if (!hasValues && attachments) {
      return;
    } else if (hasValues && attachments) {
      renderCount.current === 0 &&
        taskAttachments?.length &&
        setAttachments(() => {
          return taskAttachments.map((file) => {
            return { ...file, uniqueId: assignUniqueId() };
          });
        });
      renderCount.current = renderCount.current + 1;
      const { assignedTo, recurring } = hasValues ? { ...hasValues } : {};
      const { recurrAfter, recurrType } = recurring || false;
      const { _id: assignedToId } = assignedTo || false;
      const setRecurring = () => {
        if (hasValues?.recurring && recurrAfter === 1) return recurrType;
        return recurrAfter > 1 ? `custom` : ``;
      };
      setNewTask((p) => ({
        ...p,
        taskName: hasValues?.taskName ? hasValues?.taskName : ``,
        taskDescription: hasValues?.taskDescription ? hasValues?.taskDescription : ``,
        taskPriority: hasValues?.taskPriority ? hasValues?.taskPriority : ``,
        taskType: hasValues?.taskType ? hasValues?.taskType : ``,
        assignedTo: hasValues?.assignedTo ? assignedToId : ``,
        assignedDate: hasValues?.assignedDate ? hasValues?.assignedDate : ``,
        dueDate: hasValues?.dueDate ? moment(hasValues?.dueDate).toDate() : ``,
        remindBefore: hasValues?.remindBefore ? hasValues?.remindBefore : ``,
        patient: hasValues?._patientId ? hasValues?._patientId?._id : ``,
        recurring: setRecurring(),
        ...(recurrAfter > 1 ? { customRecurring: hasValues?.recurring ? recurrType : `` } : {}),
        ...(recurrAfter > 1 ? { customRecurringNumber: hasValues?.recurring ? recurrAfter?.toString() : `` } : {}),
      }));
    }
  }, [attachments, hasValues, taskAttachments, _patientId]);

  const defaultValidator = (passCb) => {
    let requiredFields = dynamicObjValidation({
      obj: {
        assignedTo: newTask?.assignedTo,
        taskName: newTask?.taskName,
        dueDate: newTask?.dueDate ? moment(newTask?.dueDate).toISOString() : ``,
        ...(newTask?.recurring === `custom` ? { frequency: newTask?.customRecurring, frequencyNumeration: newTask?.customRecurringNumber } : {}),
      },
      setErrors: setErrors,
      ...(passCb ? { cb: () => dueDateValidation(newTask?.dueDate, setOpenDueDateConf, true) } : {}),
    });
    return requiredFields;
  };

  const handleSubmit = (validateDueDate) => {
    const hasError = defaultValidator(validateDueDate);
    if (typeof hasError === `boolean` && hasError) {
      errorToast(`Fill all required fields.`);
    } else if (hasError === `false` || !hasError) {
      const appendAttachment = (formData) => {
        const appendUploadedAttachMents = (formData) => {
          let hasUploaded = attachments?.filter((attach) => !!attach?.isConverted);
          if (hasUploaded) {
            hasUploaded.forEach((el) => formData.append(`taskAttachments`, el.resource, `${el?.resource?.name}`));
          }
        };
        if (isFromEditing) {
          if (changedAttachMents?.length) {
            formData.append(`updatedAttachments`, JSON.stringify({ changedFiles: [...changedAttachMents] }));
          } else {
            formData.append(`updatedAttachments`, JSON.stringify({ changedFiles: [] }));
          }
          appendUploadedAttachMents(formData);
        } else if (!isFromEditing) {
          attachments.forEach((el) => formData.append(`taskAttachments`, el.resource, `${el?.resource?.name}`));
        }
      };

      const spreadTaskDesc = () => {
        const returnVal = (obj) => {
          return obj?.taskDescription ? { taskDescription: obj?.taskDescription } : {};
        };
        if (hasValues) {
          return returnVal(inputsToEdit);
        }
        return !hasValues && cleanWordsFormatterVal(newTask?.taskDescription)?.length ? returnVal(newTask) : {};
      };

      let dueDate;
      if (hasValues) {
        dueDate = momentWrapper(inputsToEdit?.dueDate ? inputsToEdit?.dueDate : newTask?.dueDate).toISOString();
      } else if (!isFromEditing) {
        dueDate = momentWrapper(newTask?.dueDate).toISOString();
      }

      let dynamicKeys = dynamicObjCreator({
        _practiceId: user?._practiceId,
        _patientId: hasValues ? inputsToEdit?.patient : newTask?.patient,
        assignedTo: hasValues ? inputsToEdit?.assignedTo : newTask?.assignedTo,
        taskName: hasValues ? inputsToEdit?.taskName : newTask?.taskName,
        ...spreadTaskDesc(),
        taskType: hasValues ? inputsToEdit?.taskType : newTask?.taskType,
        taskPriority: hasValues ? inputsToEdit?.taskPriority : newTask?.taskPriority,
        ...(dueDate ? { dueDate } : {}),
      });

      let updateKeyHandler = validateFilesAndInputs({
        inputs: inputsToEdit,
        filesKey: `attachments`,
        changedAttachMents: changedAttachMents,
      });

      let recurring;
      if ((hasValues && inputsToEdit?.customRecurring) || (hasValues && inputsToEdit?.recurring) || (hasValues && inputsToEdit?.recurring === ``)) {
        recurring = {
          recurrType: newTask?.recurring === `custom` ? newTask?.customRecurring : newTask?.recurring || `one-time`,
          recurrAfter: newTask?.recurring !== `custom` ? 1 : newTask?.customRecurringNumber,
          ...(inputsToEdit?.recurring === `` ? { recurrStatus: `off` } : {}),
        };
      } else if (!hasValues) {
        recurring = {
          recurrType: newTask?.recurring === `custom` ? newTask?.customRecurring : newTask?.recurring || `one-time`,
          recurrAfter: newTask?.recurring !== `custom` ? 1 : newTask?.customRecurringNumber,
        };
      }

      let payLoad = {
        ...dynamicKeys,
        ...(hasValues ? { updatedModules: updateKeyHandler } : {}),
        ...(recurring ? { recurring } : {}),
      };

      try {
        let formData = new FormData();
        appendAttachment(formData);
        for (const key in payLoad) {
          if (key === `recurring`) {
            for (let recurringKey in payLoad[key]) {
              formData.append(`${key}[${recurringKey}]`, payLoad[key][recurringKey]);
            }
          } else {
            formData.append(key, payLoad[key]);
          }
        }

        const apiCallHandler = (formData) => {
          const paramHandler = (isEditing) => {
            let commonParam = `?remindBefore=`;
            if (hasValues && inputsToEdit?.remindBefore && isEditing) {
              return `${commonParam}${inputsToEdit?.remindBefore}`;
            } else if (hasValues && !inputsToEdit?.remindBefore && isEditing) return inputsToEdit?.remindBefore === `` ? `${commonParam}` : ``;
            return !isEditing && newTask?.remindBefore ? `${commonParam}${newTask?.remindBefore}` : `${commonParam}false`;
          };
          const commonFunctions = (res) => {
            setTaskListFlag();
            setLoading(false);
            successToast(res.data.message);
            setCreateTaskModalHandler();
          };
          if (isFromEditing) {
            if (Object.keys(inputsToEdit)?.length) {
              setLoading(true);
              let route = `${UPDATE_TASK}${hasValues?._id}${paramHandler(true)}`;
              postRequest(route, formData)
                .then((res) => {
                  commonFunctions(res);
                })
                .catch(() => setLoading(false));
            } else errorToast(`Please change any input if you want to update the task!`);
          } else {
            setLoading(true);
            postRequest(CREATE_TASK + `${paramHandler(false)}`, formData)
              .then((res) => {
                commonFunctions(res);
              })
              .catch(() => setLoading(false));
          }
        };
        apiCallHandler(formData);
      } catch (e) {
        console.log(e, `why there`);
      }
    }
  };

  const setDueDate = (date) => {
    hasValues && setInputsToEdit((p) => ({ ...p, dueDate: date ? momentWrapper(date, `YYYY-MM-DD hh:mm`).toDate() : `` }));
    setNewTask((prev) => ({ ...prev, dueDate: date ? momentWrapper(date, `YYYY-MM-DD hh:mm`).toDate() : `` }));
  };
  const handleTaskChange = (name, event) => {
    if (name === `dueDate`) {
      setDueDate(event);
    } else {
      hasValues && setInputsToEdit((p) => ({ ...p, [name]: event?.target?.value }));
      setNewTask((prev) => ({ ...prev, [name]: event?.target?.value }));
    }
  };

  const handleAddFiles = async (files) => {
    const convertFiles = async () => {
      let filesToReturn = [];
      for (let i = 0; i < files?.length; i++) {
        let base64File = await convertToBase64(files[i]);
        filesToReturn.push({ uniqueId: assignUniqueId(), src: base64File, isConverted: true, type: files[i]?.type, resource: files[i] });
      }
      return filesToReturn;
    };
    let totalFiles = attachments?.length + files?.length;
    if (totalFiles > 3) {
      errorToast(`Only 3 Files are allowed`);
      return;
    }
    let convertedFiles = await convertFiles();
    setAttachments((pre) => [...pre, ...convertedFiles]);
    if (inputsToEdit?.attachments) {
      setInputsToEdit((p) => ({ ...p, attachments: [...p.attachments, ...convertedFiles] }));
    } else {
      setInputsToEdit((p) => ({ ...p, attachments: [...convertedFiles] }));
    }
  };

  const handleRemoveAttachMents = (file) => {
    const newAttachments = attachments.filter((attach) => attach.uniqueId !== file.uniqueId);
    setAttachments(newAttachments);
    isFromEditing &&
      !file?.isConverted &&
      setChangedAttachMents((p) => {
        return [...p, file?.blobId];
      });
    if (inputsToEdit.attachments) {
      let hasInFiles = inputsToEdit.attachments.find((fileToFilter) => fileToFilter?.uniqueId === file?.uniqueId);
      if (hasInFiles && inputsToEdit.attachments?.length > 1) {
        setInputsToEdit((p) => ({ ...p, attachments: [...p.attachments.filter((fileToFilter) => fileToFilter?.uniqueId !== file?.uniqueId)] }));
      } else if (hasInFiles && inputsToEdit.attachments?.length === 1) {
        let inputs = { ...inputsToEdit };
        if (inputs?.attachments) {
          inputs[`attachments`] = [];
        }
        setInputsToEdit(inputs);
      }
    }
  };

  let showUploadBtn = useMemo(() => {
    if (!isFromEditing) {
      return true;
    } else if (isFromEditing && attachments?.length < 3) {
      return true;
    } else {
      return false;
    }
  }, [isFromEditing, attachments]);

  const validateOnBlur = (value, field) => {
    const emptyValidation = () => {
      setErrors((p) => ({ ...p, [field]: value ? `` : `*Required` }));
    };

    switch (field) {
      case `assignedTo`:
        emptyValidation();
        break;
      case `taskName`:
        emptyValidation();
        break;
      case `dueDate`:
        emptyValidation();
        break;
      case `frequency`:
        emptyValidation();
        break;
      case `frequencyNumeration`:
        emptyValidation();
        break;
      default:
        defaultValidator();
    }
  };

  let decidePatientObj = hasValues ? { ...hasValues?._patientId } : _patientId;
  const giveDefaultValue = useCallback((obj) => {
    return [{ _id: obj?._id, firstName: obj?.firstName, lastName: obj?.lastName }];
  }, []);

  return (
    <Fade in={mount} mountOnEnter unmountOnExit>
      <Box>
        <ToastContainer />
        <Grid container rowSpacing={2} columnSpacing={1}>
          <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
            <AskForAssignee
              onBlur={(value) => validateOnBlur(value, `assignedTo`)}
              {...(hasValues
                ? {
                    isValueComing: giveDefaultValue(hasValues?.assignedTo),
                  }
                : { isValueComing: false })}
              setValue={(value) => handleTaskChange(`assignedTo`, value)}
              error={assignedToError}
            />
          </Grid>
          <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
            <AskForAssignee
              {...(hasValues || _patientId ? { isValueComing: giveDefaultValue(decidePatientObj) } : { isValueComing: false })}
              providerAssignedPatients={newTask.assignedTo}
              setValue={(value) => handleTaskChange(`patient`, value)}
              isPatient="yes"
            />
          </Grid>
          <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
            <MuiSelect
              controlProps={{
                size: `small`,
                fullWidth: true,
                id: `taskType`,
              }}
              label="Task Type*"
              options={[..._taskTypeOptions]}
              optionKey={`name`}
              optionValue={`value`}
              selectProps={{
                label: `Task Type*`,
                value: newTask?.taskType,
                name: `taskType`,
                onChange: (event) => {
                  handleTaskChange(`taskType`, event);
                },
              }}
            />
          </Grid>
          <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
            <MuiSelect
              showNone={false}
              controlProps={{
                size: `small`,
                fullWidth: true,
                id: `taskPriority`,
              }}
              label="Task Priority*"
              options={[..._taskPriorityOptions]}
              optionKey={`name`}
              optionValue={`value`}
              selectProps={{
                label: `Task Priority*`,
                value: newTask?.taskPriority,
                name: `taskPriority`,

                onChange: (event) => {
                  handleTaskChange(`taskPriority`, event);
                },
              }}
            />
          </Grid>
          <AttentionWrapper
            sx={{ zIndex: 10000000 }}
            open={openDueDateConf.open}
            title="Attention Due Date Settings!"
            description={invalidDateTimePhraseTaskMang(newTask?.dueDate)}
            successText="Ok"
            successFunc={() => {
              setOpenDueDateConf({ confirmed: true, open: false, ...(openDueDateConf?.isSubmit ? { isValidatedOnSub: true } : {}) });
              setOnCloseHappenedDueDate(false);

              if (openDueDateConf?.isBeforSelection) setOpenDateSelection(true);
            }}
          >
            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
              <DateTimePickerMui
                {...(openDateSelection ? { disablePast: true } : { disablePast: false })}
                disableOpenPicker={true}
                onClose={() => {
                  setOpenDateSelection(false);
                  setOnCloseHappenedDueDate(true);
                }}
                open={openDateSelection}
                onOpen={() => setOpenDateSelection(true)}
                onClick={() => setOpenDateSelection(true)}
                error={!!dueDateError}
                heplerText={dueDateError || ``}
                label="Due Date*"
                value={newTask?.dueDate ? moment(newTask.dueDate).toDate() : null}
                onChange={(date) => handleTaskChange(`dueDate`, date?.$d)}
              />
              {dueDateError && <Box sx={{ color: `red` }}>{dueDateError}</Box>}
            </Grid>
          </AttentionWrapper>
          <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
            <MuiSelect
              controlProps={{
                size: `small`,
                fullWidth: true,
                id: `remindBefore`,
              }}
              label="Alert Time"
              showNone={true}
              options={[...alertTimeOptions]}
              optionKey={`name`}
              optionValue={`value`}
              selectProps={{
                label: `Alert Time`,
                value: newTask?.remindBefore,
                name: `remindBefore`,
                onChange: (event) => {
                  handleTaskChange(`remindBefore`, event);
                },
              }}
            />
          </Grid>
          <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
            <MuiSelect
              showNone={true}
              controlProps={{
                size: `small`,
                fullWidth: true,
                id: `recurring`,
              }}
              label="Recurring"
              options={[..._recurringOptions]}
              optionKey={`name`}
              optionValue={`value`}
              selectProps={{
                label: `Recurring`,
                value: newTask?.recurring,
                name: `recurring`,
                onChange: (event) => {
                  handleTaskChange(`recurring`, event);
                },
              }}
            />
          </Grid>
          {newTask?.recurring === `custom` && (
            <>
              <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                <MuiSelect
                  controlProps={{
                    error: !!errors.frequencyNumeration,
                    size: `small`,
                    fullWidth: true,
                    id: `customRecurringNumber`,
                  }}
                  label={`*Every`}
                  options={[...customRecurringNumberOptions]}
                  optionKey={`name`}
                  optionValue={`value`}
                  selectProps={{
                    helperText: errors.frequencyNumeration,
                    onBlur: ({ target: { value } }) => {
                      validateOnBlur(value, `frequencyNumeration`);
                    },
                    label: `*Every`,
                    value: newTask?.customRecurringNumber,
                    name: `customRecurringNumber`,
                    onChange: (event) => {
                      handleTaskChange(`customRecurringNumber`, event);
                    },
                    MenuProps: {
                      PaperProps: { style: { maxHeight: `300px` } },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                <MuiSelect
                  controlProps={{
                    error: !!errors.frequency,
                    size: `small`,
                    fullWidth: true,
                    id: `customRecurring`,
                  }}
                  label="*Frequency"
                  options={[...customRecurringOptions]}
                  optionKey={`name`}
                  optionValue={`value`}
                  selectProps={{
                    onBlur: ({ target: { value } }) => {
                      validateOnBlur(value, `frequency`);
                    },
                    helperText: errors.frequency,
                    label: `*Frequency`,
                    value: newTask?.customRecurring,
                    name: `customRecurring`,
                    onChange: (event) => {
                      handleTaskChange(`customRecurring`, event);
                    },
                  }}
                />
                <Box></Box>
              </Grid>
            </>
          )}

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <InputField
              fullWidth={true}
              onChange={(event) => {
                const { target } = event || {};
                const { value } = target || false;
                hasValues && setInputsToEdit((p) => ({ ...p, taskName: value }));
                setNewTask((prev) => ({ ...prev, taskName: value }));
              }}
              value={newTask?.taskName}
              type="text"
              onBlur={(e) => {
                const {
                  target: { value },
                } = e;
                validateOnBlur(value, `taskName`);
              }}
              label="Title*"
              size="small"
              error={!!taskNameError}
              helperText={taskNameError || false}
            />
          </Grid>

          <Box style={{ marginLeft: `9px`, width: `100%` }}>
            <WordsFormatter
              maxLength={1800}
              readyToSet={true}
              placeHolder={`Add Description`}
              setKeyValue={(value, isOnChnage) => {
                if (isFromEditing && isOnChnage === `onChange`) setInputsToEdit((p) => ({ ...p, taskDescription: value }));
                setNewTask((p) => ({ ...p, taskDescription: value }));
              }}
              hasInitialValue={hasValues?.taskDescription}
            />
            {taskDescriptionError && <Box sx={{ color: `red` }}>{taskDescriptionError}</Box>}
          </Box>
        </Grid>
        <Grid container spacing={1} sx={{ mt: `3%` }}>
          {attachments.length > 0 &&
            attachments?.map((file) => (
              <Grid item xs={4} sm={4} md={4} lg={4} xl={4} key={file?.uniqueId} sx={{ display: `flex`, alignItems: `center` }}>
                <FilePreview file={file} delHandler={() => handleRemoveAttachMents(file)} />
              </Grid>
            ))}
        </Grid>
        <Box sx={{ display: `flex`, justifyContent: `space-between`, mt: `` }}>
          <div style={{ display: `flex`, justifyContent: `flex-end` }}>
            {showUploadBtn && (
              <IconButton color="primary" aria-label="upload picture" component="label">
                <input
                  hidden
                  accept="image/* "
                  name="taskAttachments"
                  multiple
                  type="file"
                  onChange={(e) => handleAddFiles(Array.from(e.target.files))}
                  onClick={(e) => (e.target.value = ``)}
                />
                <AttachFileIcon />
              </IconButton>
            )}
          </div>
          <div style={{ display: `flex`, justifyContent: `flex-end` }}>
            <button className="btn btn-outline-info" onClick={() => handleSubmit(!openDueDateConf?.isValidatedOnSub)} size="sm" disabled={loading}>
              {isFromEditing ? `Update` : `Create`}
            </button>
          </div>
        </Box>
        {loading && <PulseSpinner />}
      </Box>
    </Fade>
  );
};

export default CreateTask;
