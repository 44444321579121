import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getRequest,
  LOG_OUT,
  GET_USER_BY_ID,
  GET_USER_BY_PRACTICE,
  CHECK_EMAIL_USERNAME_AVAILABILITY_URL,
  postRequest,
  patchRequest,
  UPDATE_USER,
  INACTIVE_ADMIN_USER,
} from '../../../../../../../crud/crud';
import { PulseSpinner } from '../../../../../../common/components/spinner/spinner';
import { successToast, errorToast } from 'src/pages/common/components/snackBar/toast';
import { setProviderType } from '../../../../../../../redux/action/setUserHandler';
import { ToastContainer } from 'react-toastify';
import Swal from 'sweetalert2';
import css from './editUser.module.css';
import { newSocket } from 'src/pages/common/HOC/SocketWrapper';
import { addNewUserMountFlag } from '../../../../../../../redux/resmed/resmedActions';
import { CustomizedDialogs } from 'src/pages/common/components/modal/CustomizeModal';
import {
  capitalizeFirstLetter,
  faxNumberValidation,
  checkPracticeId,
  npiMask,
  phoneMask,
  providerType,
  usStateName,
  faxNumberMask,
  wrapWithAsyncSimple,
  fieldValidationPhrase,
} from 'src/utils';
import { Box, Collapse, Grid } from '@mui/material';
import { InputField } from 'src/pages/common/components/InputField/InputField';
import { MuiSelect } from 'src/pages/common/components/MuiSelect/MuiSelect';
import { emailRegex, phoneRegex } from 'src/regexes';

export const EditUser = ({ auth, userId, setSelectedId, editUserModalFlag, setEditUserModalFlag }) => {
  const [setLogout] = useState(false);
  const [userData, setUserData] = useState(null);
  const [extension, setExtension] = useState(``);
  const [userName, setUserName] = useState(``);
  const [userNameError, setUserNameError] = useState({
    status: false,
    msg: ``,
  });
  const [firstName, setFirstName] = useState(``);
  const [status, setStatus] = useState(``);

  const [firstNameError, setFirstNameError] = useState({
    status: false,
    msg: ``,
  });
  const [lastName, setLastName] = useState(``);
  const [lastNameError, setLastNameError] = useState({
    status: false,
    msg: ``,
  });
  const [phone, setPhone] = useState(``);
  const [phoneError, setPhoneError] = useState({ status: false, msg: `` });
  const [email, setEmail] = useState(``);
  const [emailError, setEmailError] = useState({ status: false, msg: `` });
  const [fax, setFax] = useState(``);
  const [faxError, setFaxError] = useState({ status: false, msg: `` });
  const [adminFlag, setAdminFlag] = useState(false);
  const [providerFlag, setProviderFlag] = useState(false);
  const [clinicalStaffFlag, setClinicalStaffFlag] = useState(false);
  const [isBiller, setIsBiller] = useState(false);
  const [roleError, setRoleError] = useState({ status: false, msg: `` });
  const containerRef = useRef(null);
  const [title, setTitle] = useState(``);
  const [titleError, setTitleError] = useState({ status: false, msg: `` });
  const [type, setType] = useState(``);
  const [typeError, setTypeError] = useState({ status: false, msg: `` });
  const [npi, setNPI] = useState(``);
  const [npiError, setNPIError] = useState({ status: false, msg: `` });
  const [license, setLicense] = useState(``);
  const [licenseError, setLicenseError] = useState({ status: false, msg: `` });
  const [licenseState, setLicenseState] = useState(``);
  const [licenseStateError, setLicenseStateError] = useState({
    status: false,
    msg: ``,
  });
  const { _practiceId } = useSelector((state) => state?.user?.user);
  const practiceId = useSelector((state) => state?.practice?.practiceInfo?._id);
  const groupId = useSelector((state) => state?.user?.user?._practiceGroupId);
  const _practiceGroupId = useSelector((state) => state?.practiceGroup?.groupId);
  const [loading, setLoading] = useState(false);
  const close = useRef(null);
  const user = useSelector((state) => state.user.user);
  const dispatch = useDispatch();
  const throwError = (errorMsg) => {
    return errorToast(errorMsg);
  };
  const logOutHandler = () => {
    if (newSocket) {
      newSocket.close(); //  CLOSING THE SOCKET, WHEN LOGOUT
    }
    setLogout(true);
    getRequest(LOG_OUT); // API CALL - IN CASE OF USER LOGOUT
  };
  const [isLoggedInUser, setIsLoggedInUser] = useState(false);

  const inActiveUser = (statusUser) => {
    Swal.fire({
      title: `Are you sure?`,
      text: statusUser === `active` ? `Do you want to activate user!` : `Do you want to Inactivate user!`,
      icon: `warning`,
      showCancelButton: true,
      confirmButtonColor: `#3085d6`,
      cancelButtonColor: `#d33`,
      confirmButtonText: statusUser === `active` ? `Yes, activate it!` : `Yes, inactivate it!`,
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        let route = `${INACTIVE_ADMIN_USER}/${userId}/${user?.isGroupAdmin || user?.isSystemAdministrator ? practiceId : _practiceId}`;
        patchRequest(route, {
          status: statusUser,
        })
          .then((res) => {
            if (res?.data?.himself) {
              logOutHandler();
            } else {
              if (statusUser === `active`) {
                setStatus(`active`);
              } else {
                setStatus(`inactive`);
              }
              dispatch(addNewUserMountFlag()); // NEW USER ADDED, RESET REFRESH FLAG
              setLoading(false);
              successToast(res?.data?.message);
            }
          })
          .catch(() => setLoading(false));
      }
    });
  };

  useEffect(() => {
    setLoading(true);
    let isSysOrGrp = user?.isGroupAdmin || user?.isSystemAdministrator;
    if (userId !== ``) {
      const spreadPracticeId = !isSysOrGrp ? `/${user?._practiceId}` : ``;
      let route = `${isSysOrGrp ? GET_USER_BY_ID : GET_USER_BY_PRACTICE}${userId}${spreadPracticeId}`;
      getRequest(route)
        .then((res) => {
          const { data = {} } = res;
          const { user = {} } = data;
          const { systemRoles = false } = user;
          res?.data?.user?._practiceId === _practiceId && setIsLoggedInUser(true);
          setUserData(res?.data);
          setExtension(res?.data?.user?.ext);
          setUserName(res?.data?.user?.userName);
          setFirstName(capitalizeFirstLetter(res?.data?.user?.firstName));
          setLastName(capitalizeFirstLetter(res?.data?.user?.lastName));
          setPhone(res?.data?.user?.phoneNumber);
          const setStatusFunc = (status) => setStatus(status);
          if (systemRoles) {
            checkPracticeId(practiceId || _practiceId, systemRoles, setStatusFunc);
          } else setStatusFunc(res?.data?.user?.status);

          setEmail(res?.data?.user?.email);
          setFax(res?.data?.user?.faxNumber);
          if (res?.data?.user?.systemRoles) {
            for (let iterator = 0; iterator < systemRoles?.length; iterator++) {
              if (systemRoles[iterator]?._practiceId === practiceId || systemRoles[iterator]?._practiceId === _practiceId) {
                setAdminFlag(!!systemRoles[iterator]?.isSuperAdmin);
                setProviderFlag(!!systemRoles[iterator]?.isProvider);
                setClinicalStaffFlag(!!systemRoles[iterator]?.isClinicalStaff);
                setIsBiller(!!systemRoles[iterator]?.isBiller);
              }
            }
          } else {
            setAdminFlag(!!res?.data?.user?.isSuperAdmin);
            setProviderFlag(!!res?.data?.user?.isProvider);
            setClinicalStaffFlag(!!res?.data?.user?.isClinicalStaff);
            setIsBiller(!!res?.data?.user?.isBiller);
          }
          setTitle(res?.data?.user?.title);
          setType(res?.data?.user?.providerType);
          setNPI(res?.data?.user?.NPI);
          setLicense(res?.data?.user?.medicalLicense);
          setLicenseState(res?.data?.user?.licenseState);
          setExtension(res?.data?.ext);
          setLoading(false);
        })
        .catch(() => setLoading(false));
    }
    return () => {
      setUserData(``);
      setExtension(``);
      setUserName(``);
      setFirstName(``);
      setLastName(``);
      setPhone(``);
      setStatus(``);
      setEmail(``);
      setFax(``);
      setAdminFlag(false);
      setProviderFlag(false);
      setClinicalStaffFlag(false);
      setIsBiller(false);
      setTitle(``);
      setType(``);
      setNPI(``);
      setLicense(``);
      setLicenseState(``);
      setExtension(``);
      setLoading(false);
    };
  }, [userId, editUserModalFlag, auth, user]);

  const validateInput = async (field) => {
    let temp = { status: false, msg: `` };
    switch (field) {
      case `firstName`:
        if (!firstName) {
          temp.status = true;
          temp.msg = `*Required`;
          setFirstNameError(temp);
        } else {
          setFirstNameError(temp);
        }
        break;
      case `lastName`:
        if (!lastName) {
          temp.status = true;
          temp.msg = `*Required`;
          setLastNameError(temp);
        } else {
          setLastNameError(temp);
        }
        break;

      case `userName`:
        if (!userName) {
          temp.status = true;
          temp.msg = `*Required`;
          setUserNameError(temp);
        } else {
          setUserNameError(temp);
        }
        break;
      case `phone`:
        if (phone?.length > 0 && !phoneRegex.test(phone)) {
          temp.status = true;
          temp.msg = `Invalid Phone No.`;
          setPhoneError(temp);
          return false;
        } else {
          setPhoneError(temp);
          return true;
        }
      case `email`:
        if (!email) {
          temp.status = true;
          temp.msg = `*Required`;
          setEmailError(temp);
        } else if (!emailRegex.test(email)) {
          temp.status = true;
          temp.msg = `Invalid Email Address`;
          setEmailError(temp);
        } else {
          setEmailError(temp);
        }
        break;
      case `role`:
        if (!adminFlag && !providerFlag && !clinicalStaffFlag && !isBiller) {
          temp.status = true;
          temp.msg = `*Required`;
          setRoleError(temp);
        } else {
          setRoleError(temp);
        }
        break;
      case `fax`:
        if (fax) {
          let hasError = !faxNumberValidation(fax);
          if (hasError) {
            temp.status = true;
            temp.msg = `Invalid Fax Number`;
          }
          setFaxError(temp);
        }
        break;
      case `type`:
        if (providerFlag && !type) {
          temp.status = true;
          temp.msg = `*Required`;
          setTypeError(temp);
        } else {
          setTypeError(temp);
        }
        break;
      case `npi`:
        if (providerFlag && !npi) {
          temp.status = true;
          temp.msg = `*Required`;
          setNPIError(temp);
        } else if (npi.length < 10) {
          temp.status = true;
          temp.msg = `Invalid Npi`;
          setNPIError(temp);
        } else {
          setNPIError(temp);
        }
        break;
      case `license`:
        if (providerFlag && !license) {
          temp.status = true;
          temp.msg = `*Required`;
          setLicenseError(temp);
        } else {
          setLicenseError(temp);
        }
        break;
      case `state`:
        if (providerFlag && !licenseState) {
          temp.status = true;
          temp.msg = `*Required`;
          setLicenseStateError(temp);
        } else {
          setLicenseStateError(temp);
        }
        break;

      default:
        if (!firstName) {
          temp.status = true;
          temp.msg = `*Required`;
          setFirstNameError(temp);
          return false;
        }
        if (fax) {
          let hasError = !faxNumberValidation(fax);
          if (hasError) {
            temp.status = true;
            temp.msg = `Invalid Fax Number`;
            setFaxError(temp);
            return false;
          }
        }
        if (!lastName) {
          temp.status = true;
          temp.msg = `*Required`;
          setLastNameError(temp);
          return false;
        }
        if (!userName) {
          temp.status = true;
          temp.msg = `*Required`;
          setUserNameError(temp);
          return false;
        }
        if (!phone) {
          temp.status = true;
          temp.msg = `*Required`;
          setPhoneError(temp);
          return false;
        }
        if (!email) {
          temp.status = true;
          temp.msg = `*Required`;
          setEmailError(temp);
          return false;
        } else if (!emailRegex.test(email)) {
          temp.status = true;
          temp.msg = `Invalid Email Address`;
          setEmailError(temp);
          return false;
        }
        if (!adminFlag && !providerFlag && !clinicalStaffFlag && !isBiller) {
          temp.status = true;
          temp.msg = `*Required`;
          setRoleError(temp);
          return false;
        }

        if (providerFlag && !type) {
          temp.status = true;
          temp.msg = `*Required`;
          setTypeError(temp);
          return false;
        }
        if (providerFlag && !npi) {
          temp.status = true;
          temp.msg = `*Required`;
          setNPIError(temp);
          return false;
        } else if (providerFlag && npi?.length < 10) {
          temp.status = true;
          temp.msg = `Invalid NPI`;
          setNPIError(temp);
          return false;
        }
        if (providerFlag && !license) {
          temp.status = true;
          temp.msg = `*Required`;
          setLicenseError(temp);
          return false;
        }
        if (providerFlag && !licenseState) {
          temp.status = true;
          temp.msg = `*Required`;
          setLicenseStateError(temp);
          return false;
        }

        return true;
    }
  };

  // VALIDATE FIRST NAME
  const validateFirstName = (val) => {
    if (val.match(`^[a-zA-Z ]*$`) != null) {
      setFirstName(val);
    }
  };

  // VALIDATE LAST NAME
  const validateLastName = (val) => {
    if (val.match(`^[a-zA-Z ]*$`) != null) {
      setLastName(val);
    }
  };
  // VALIDATE USER NAME
  const validateUserName = (val) => {
    if (val.match(`^[a-zA-Z0-9]*$`) != null) {
      setUserName(val);
    }
  };

  const isAvailable = (val, key) => {
    if (key === `email` && userData.user.email === val) {
      setEmailError({ status: false, msg: `` });
      return;
    }
    if (key === `phone` && userData.user.phoneNumber === val) {
      setPhoneError({ status: false, msg: `` });
      return;
    }
    if (key === `userName` && userData.user.userName === val) {
      setUserNameError({ status: false, msg: `` });
      return;
    }

    setLoading(true);
    postRequest(CHECK_EMAIL_USERNAME_AVAILABILITY_URL, { userName: val })
      .then(() => {
        setLoading(false);
        successToast(`Available`);
      })
      .catch((err) => {
        setLoading(false);
        if (err?.response) {
          if (err?.response?.status === 409 && key === `userName`) setUserNameError({ status: true, msg: `Username already exists.` });
          if (err?.response?.status === 409 && key === `email`) setEmailError({ status: true, msg: `Email already exists.` });
          if (err?.response?.status === 409 && key === `phone`)
            setPhoneError({
              status: true,
              msg: `Phone number already exists.`,
            });
        }
      });
  };

  // RESET FORM
  const resetForm = () => {
    setUserName(``);
    setUserNameError({ status: false, msg: `` });
    setFirstName(``);
    setFirstNameError({ status: false, msg: `` });
    setLastName(``);
    setLastNameError({ status: false, msg: `` });
    setPhone(``);
    setPhoneError({ status: false, msg: `` });
    setEmail(``);
    setEmailError({ status: false, msg: `` });
    setFax(``);
    setAdminFlag(false);
    setProviderFlag(false);
    setClinicalStaffFlag(false);
    setIsBiller(false);
    setTitle(``);
    setTitleError({ status: false, msg: `` });
    setType(``);
    setTypeError({ status: false, msg: `` });
    setNPI(``);
    setNPIError({ status: false, msg: `` });
    setLicense(``);
    setLicenseError({ status: false, msg: `` });
    setLicenseState(``);
    setLicenseStateError({ status: false, msg: `` });
  };

  const loadingHandler = (flag) => {
    setLoading(flag);
  };

  // FORM SUBMIT HANDLER
  const submitHandler = async () => {
    try {
      const temp = await validateInput();
      if (temp) {
        loadingHandler(true);
        let route = `${UPDATE_USER}/${userId}/${user?.isGroupAdmin || user?.isSystemAdministrator ? practiceId : _practiceId}`;
        patchRequest(route, {
          firstName: firstName,
          lastName: lastName,
          email: email,
          title: title,
          userName: userName,
          phoneNumber: phone,
          faxNumber: fax,
          ext: extension,
        })
          .then((res) => {
            if (isLoggedInUser) {
              dispatch(setProviderType(res?.data?.user?.providerType));
              setIsLoggedInUser(false);
            }
            setEditUserModalFlag(false);
            loadingHandler(false);
            setSelectedId(``);
            dispatch(addNewUserMountFlag()); // NEW USER ADDED, RESET REFRESH FLAG
            successToast(`User Edit successfully!`);
            resetForm();
            close.current.click();
          })
          .catch(() => loadingHandler(false));
      } else {
        throwError(fieldValidationPhrase);
      }
    } catch (error) {
      throwError(`Failed`);
      console.log(error)
    }
  };

  const checkAndAssignRoleHandler = (e) => {
    const { name } = e.target;

    //admin flag
    if (name === `admin`) {
      if (!isBiller) {
        setAdminFlag(!adminFlag);
        return;
      } else {
        throwError(`Admin role cannot assign with Clinical Staff or Biller`);
      }
    }
    //Provider flag
    if (name === `provider`) {
      if (!clinicalStaffFlag && !isBiller) {
        setProviderFlag(!providerFlag);
        return;
      } else {
        throwError(`Provider role cannot assign with Clinical Staff or Biller`);
      }
    }
    //Clinical Staff flag
    if (name === `clinicalStaff`) {
      if (!providerFlag && !isBiller) {
        setClinicalStaffFlag(!clinicalStaffFlag);
        return;
      } else {
        throwError(`Clinical Staff role cannot assign with Provider,Biller or Admin`);
      }
    }
    //Clinical Staff flag
    if (name === `isBiller`) {
      if (!providerFlag && !adminFlag && !clinicalStaffFlag) {
        setIsBiller(!isBiller);
        return;
      } else {
        throwError(`Biller role cannot assign with Provider,Clinical Staff or Admin`);
      }
    }
  };
  const requiredTag = clinicalStaffFlag ? `` : `*`;

  const editRolesHandler = async () => {
    let payLoad = {
      _practiceId: _practiceId || practiceId,
      _practiceGroupId: _practiceGroupId || groupId,
      isProvider: providerFlag,
      isClinicalStaff: clinicalStaffFlag,
      isSuperAdmin: adminFlag,
      isBiller: isBiller,
      ...(providerFlag || clinicalStaffFlag
        ? {
          providerOrCs: {
            providerType: type,
            NPI: npi,
            medicalLicense: license,
            licenseState: licenseState,
          },
        }
        : {}),
    };
    let hasValidated = await validateInput();
    if (hasValidated) {
      loadingHandler(true);
      patchRequest(`users/updateRole/${userId}`, payLoad)
        .then((res) => {
          dispatch(addNewUserMountFlag());
          successToast(`User Edit successfully!`);
          loadingHandler(false);
          if (isLoggedInUser) {
            dispatch(setProviderType(res?.data?.user?.providerType));
            setIsLoggedInUser(false);
          }
          setEditUserModalFlag(false);
          setSelectedId(``);
          resetForm();
        })
        .catch(() => loadingHandler(false));
    } else {
      throwError(`Error in field validation please check your given entries!`);
    }
  };

  const sectionHeading = clinicalStaffFlag ? `Clinical User` : `Provider`;

  return (
    <div>
      <CustomizedDialogs
        title="EDIT USER"
        open={editUserModalFlag}
        setOpen={() => setEditUserModalFlag(false)}
        size="md"
        fullWidth={true}
        notShowDividers={true}
        showCloseButton={false}
      >
        <ToastContainer />
        <div>
          {status === `active` || status === `inactive` ? (
            <>
              <p className={css.sectionHeadingText} style={{ marginTop: `16px` }}>
                Status
              </p>
              <hr style={{ margin: `0px` }} />
              <div className="d-flex flex-wrap pb-3">
                <div className="w-100 mt-2">
                  <div className="d-flex flex-wrap">
                    <div className={css.widthCustom} style={{ marginLeft: 0 }}>
                      <label htmlFor="firstName" id={css.customLabel}>
                        <span
                          {...(status === `active` && {
                            style: {
                              fontSize: `14px`,
                            },
                          })}
                        >
                          Active
                        </span>
                        <span>/</span>
                        <span
                          {...(status === `inactive` && {
                            style: {
                              fontSize: `14px`,
                            },
                          })}
                        >
                          Inactive
                        </span>
                      </label>
                      <select
                        style={{ padding: `3.7% 0%` }}
                        id={status ? css.selectedSelector : css.selector}
                        name="status"
                        onChange={(event) => inActiveUser(event.target.value)}
                        value={status}
                      >
                        <option value="active">Activate</option>
                        <option value="inactive">Inactivate</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : null}

          <p className={css.sectionHeadingText} style={{ marginTop: `16px` }}>
            Basic Information
          </p>
          <hr style={{ margin: `0px` }} />
          <Grid container spacing={2} sx={{ pt: 2, pb: 2 }}>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <InputField
                size="small"
                label="First Name*"
                fullWidth={true}
                onBlur={() => validateInput(`firstName`)}
                autoComplete="off"
                onChange={(event) => {
                  validateFirstName(event.target.value);
                  wrapWithAsyncSimple({ successFunc: () => validateInput(`firstName`) });
                }}
                value={firstName}
                type="text"
                name="firstName"
                placeholder="First Name*"
              />
              <p className={firstNameError.status && !firstName ? `d-block text-danger` : `d-none`}>{firstNameError.msg}</p>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <InputField
                label="Last Name*"
                size="small"
                fullWidth={true}
                className={lastNameError.status && !lastName ? css.errorCustom : null}
                onBlur={() => wrapWithAsyncSimple({ successFunc: () => validateInput(`lastName`) })}
                autoComplete="off"
                onChange={(event) => {
                  validateLastName(event.target.value);
                  wrapWithAsyncSimple({ successFunc: () => validateInput(`lastName`) });
                }}
                value={lastName}
                type="text"
                name="lastName"
                placeholder="Last Name*"
              />
              <p className={lastNameError.status && !lastName ? `d-block text-danger` : `d-none`}>{lastNameError.msg}</p>
            </Grid>
            <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
              <InputField
                label="User Name*"
                size="small"
                fullWidth={true}
                onBlur={() => (userName && !userNameError.status ? isAvailable(userName, `userName`) : wrapWithAsyncSimple({ successFunc: () => validateInput(`userName`) }))}
                autoComplete="off"
                onChange={(event) => {
                  validateUserName(event.target.value);
                  wrapWithAsyncSimple({ successFunc: () => validateInput(`userName`) });
                }}
                value={userName}
                type="text"
                id="userName"
                name="userName"
              />
              <p className={userNameError.status ? `d-block text-danger` : `d-none`}>{userNameError.msg}</p>
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
              <InputField
                size="small"
                fullWidth={true}
                autoComplete="off"
                onChange={(e) => setExtension(e.target.value)}
                value={extension}
                type="text"
                name="phone"
                id="phone"
                label="Extension"
              />
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <InputField
                label="Phone Number*"
                size="small"
                fullWidth={true}
                error={!!phoneError.status}
                onBlur={async () => {
                  let hasValid = await validateInput(`phone`);
                  phone && hasValid && isAvailable(phone, `phone`);
                }}
                autoComplete="off"
                onChange={(event) => phoneMask(event.target.value, setPhone)}
                value={phone}
                type="text"
                name="phone"
                id="phone"
              />
              <p className={phoneError.status ? `d-block text-danger` : `d-none`}>{phoneError.msg}</p>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <InputField
                label="Email*"
                size="small"
                fullWidth={true}
                error={!!emailError.status}
                onBlur={() =>
                  email && !emailError.status ? isAvailable(email, `email`) : wrapWithAsyncSimple({ successFunc: () => validateInput(`email`) })
                }
                autoComplete="off"
                onChange={(event) => {
                  setEmail(event.target.value);
                  wrapWithAsyncSimple({ successFunc: () => validateInput(`email`) });
                }}
                value={email}
                type="email"
                id="email"
                name="email"
              />
              <p className={emailError.status ? `d-block text-danger` : `d-none`}>{emailError.msg}</p>
            </Grid>
            <Grid item xs={7} sm={7} md={7} lg={7} xl={7}>
              <InputField
                size="small"
                fullWidth={true}
                autoComplete="off"
                error={faxError?.status}
                helperText={faxError?.msg}
                onChange={(event) =>
                  faxNumberMask({
                    value: event.target.value,
                    setValue: (val) => {
                      setFax(val);
                    },
                  })
                }
                onBlur={() => validateInput(`fax`)}
                value={fax}
                type="text"
                id="fax"
                name="fax"
                label="Fax"
              />
            </Grid>
            <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
              <InputField
                size="small"
                fullWidth={true}
                error={!!(titleError.status && !title)}
                autoComplete="off"
                onChange={(event) => {
                  setTitle(event.target.value);
                }}
                value={title}
                type="text"
                name="title"
                label="Title"
              />
              <p className={titleError.status && !title ? `d-block text-danger` : `d-none`}>{titleError.msg}</p>
            </Grid>
          </Grid>

          <div className="d-flex justify-content-end" style={{ marginTop: `20px`, marginBottom: `20px` }}>
            <button type="button" className="btn btn-outline-info" id={css.addNewUserModalButton} onClick={submitHandler}>
              Edit Basic Info
            </button>
          </div>
          <>
            <p className={css.sectionHeadingText} style={{ marginTop: `16px` }}>
              Role
            </p>
            <hr style={{ margin: `0px` }} />
            <div
              ref={containerRef}
              className="d-flex align-items-center"
              id={css.additionalRole}
              style={{
                opacity: `1`,
                marginTop: `15px`,
                marginBottom: `15px`,
                justifyContent: `center`,
                width: `100%`,
              }}
            >
              <label style={roleError.status && !adminFlag && !providerFlag && !clinicalStaffFlag && !isBiller ? { color: `red` } : null}>
                Assign Role(s)*:{` `}
              </label>
              <input
                type="checkbox"
                name="admin"
                checked={adminFlag}
                onChange={(e) => {
                  checkAndAssignRoleHandler(e);
                  wrapWithAsyncSimple({ successFunc: () => validateInput(`role`) });
                }}
              />
              <label htmlFor="admin"> Admin</label>
              <input
                type="checkbox"
                name="provider"
                checked={providerFlag}
                onChange={(e) => {
                  checkAndAssignRoleHandler(e);
                  wrapWithAsyncSimple({ successFunc: () => validateInput(`role`) });
                }}
              />
              <label htmlFor="provider"> Provider</label>
              <input
                type="checkbox"
                name="clinicalStaff"
                checked={clinicalStaffFlag}
                onChange={(e) => {
                  let resetError = { status: false, msg: `` };
                  typeError.msg && setTypeError(resetError);
                  npiError.msg && setNPIError(resetError);
                  licenseError.msg && setLicenseError(resetError);
                  licenseStateError.msg && setLicenseError(resetError);
                  checkAndAssignRoleHandler(e);
                  wrapWithAsyncSimple({ successFunc: () => validateInput(`role`) });
                }}
              />
              <label htmlFor="clinicalStaff"> Clinical Staff</label>
              <input
                type="checkbox"
                name="isBiller"
                checked={isBiller}
                onChange={(e) => {
                  checkAndAssignRoleHandler(e);
                  wrapWithAsyncSimple({ successFunc: () => validateInput(`role`) });
                }}
              />
              <label htmlFor="clinicalStaff"> Biller Role</label>
            </div>
            <Collapse
              container={containerRef.current}
              direction="left"
              in={providerFlag || clinicalStaffFlag}
              mountOnEnter
              unmountOnExit
              timeout={900}
            >
              <div>
                <p className={css.sectionHeadingText}> {`${sectionHeading} Additional Information`} </p>
                <hr style={{ margin: `0px` }} />
                <Box sx={{ mt: 2, mb: 2 }}>
                  <Grid container columnSpacing={1} rowSpacing={2}>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <MuiSelect
                        isSimple={false}
                        controlProps={{
                          size: `small`,
                          fullWidth: true,
                          id: `providerType`,
                        }}
                        label={`Provider Type${requiredTag}`}
                        options={[...providerType]}
                        selectProps={{
                          error: !!typeError?.msg,
                          label: `Provider Type${requiredTag}`,
                          onBlur: () => wrapWithAsyncSimple({ successFunc: () => validateInput(`type`) }),
                          name: `type`,
                          onChange: (event) => {
                            setType(event.target.value);
                            wrapWithAsyncSimple({ successFunc: () => validateInput(`type`) });
                          },
                          value: type,
                        }}
                        optionKey={`name`}
                        optionValue={`name`}
                      />
                      <p className={!typeError?.msg ? `d-block text-danger` : `d-none`}>{typeError.msg}</p>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <InputField
                        fullWidth={true}
                        size="small"
                        error={!!licenseError?.msg}
                        onBlur={() => wrapWithAsyncSimple({ successFunc: () => validateInput(`license`) })}
                        autoComplete="off"
                        onChange={(event) => {
                          setLicense(event.target.value);
                          wrapWithAsyncSimple({ successFunc: () => validateInput(`license`) });
                        }}
                        value={license}
                        type="text"
                        id="licenseAddUser"
                        name="license"
                        label={`License No.${requiredTag}`}
                      />
                      <p className={licenseError?.msg ? `d-block text-danger` : `d-none`}>{licenseError.msg}</p>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <MuiSelect
                        isSimple={false}
                        controlProps={{
                          size: `small`,
                          fullWidth: true,
                          id: `licenseState`,
                        }}
                        label={`License State${requiredTag}`}
                        options={[...usStateName]}
                        selectProps={{
                          error: !!licenseStateError?.msg,
                          id: `licenseState`,
                          label: `License State${requiredTag}`,
                          onBlur: () => wrapWithAsyncSimple({ successFunc: () => validateInput(`state`) }),
                          name: `type`,
                          onChange: (event) => {
                            setLicenseState(event.target.value);
                            wrapWithAsyncSimple({ successFunc: () => validateInput(`state`) });
                          },
                          value: licenseState,
                        }}
                        optionKey={`name`}
                        optionValue={`name`}
                      />
                      <p className={licenseStateError?.msg ? `d-block text-danger` : `d-none`}>{licenseStateError?.msg}</p>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <InputField
                        size="small"
                        fullWidth={true}
                        error={!!npiError?.msg}
                        onBlur={() => wrapWithAsyncSimple({ successFunc: () => validateInput(`npi`) })}
                        autoComplete="off"
                        onChange={(event) => npiMask(event.target.value, setNPI)}
                        value={npi}
                        id="npiAddUser"
                        name="npi"
                        label={`NPI${requiredTag}`}
                        placeholder={`NPI${requiredTag}`}
                      />
                      <p className={npiError?.msg ? `d-block text-danger` : `d-none`}>{npiError.msg}</p>
                    </Grid>
                  </Grid>
                </Box>
              </div>
            </Collapse>
            <div className="d-flex justify-content-end" style={{ marginTop: `20px`, marginBottom: `20px` }}>
              <button type="button" className="btn btn-outline-info" id={css.addNewUserModalButton} onClick={editRolesHandler}>
                Edit Roles
              </button>
            </div>
          </>
        </div>

        {loading && <PulseSpinner />}
      </CustomizedDialogs>
    </div>
  );
};
