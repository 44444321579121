import React, { useState, useEffect, useRef } from 'react';
import css from './notification.module.css';
import { successToast } from 'src/pages/common/components/snackBar/toast';
import { useDispatch, useSelector } from 'react-redux';
import Paper from '@mui/material/Paper';
import { useHistory } from 'react-router-dom';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Avatar from 'react-avatar';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  LIST_ALL_NOTIFICATIONS_URL, getRequest, UNREAD_NOTICATIONS_STATUS_UPDATE_URL, PENDING_PROVIDERS,
  ALL_UNREAD_MESSAGE_RED_DOT_SHOW, UPDATE_PATIENT_UNREAD_NOTIFICATION_STATUS, patchRequest, ALL_RISK_NOTIFICATION,
  ALL_UNREAD_PATIENT_NOTIFICATION, deleteRequest, ADD_PATIENT_MONITORING_TIME_LOG_URL, postRequest,
  GET_ALL_MISSED_CALL_NOTIFICATIONS, GET_ALL_VOICE_MAILS,
} from '../../../../../../crud/crud';
import { capitalizeFirstLetter, returnIfArr, spreadUniqueKeyProp, timeLogAddOnSwitchPatientAndSorting } from 'src/utils';
import Moment from 'react-moment';
import { setUnreadNotifyCountHandler } from '../../../../../../redux/action/setUnreadNotifyCountHandler';
import { removeSMSFromNotificationArray, emptySMSArrayObject, addSMSArrayObjectInitial } from '../../../../../../redux/twilio/twilioActions';
import { addResmedRiskArrayObjectInitial, _removeRiskAlertPatient } from '../../../../../../redux/resmed/resmedActions';
import { newSocket } from 'src/pages/common/HOC/SocketWrapper';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Collapse from '@mui/material/Collapse';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { momentWrapper } from 'src/momentWrapper';
import { ViewTask } from 'src/pages/home/modules/TaskManagement/ViewTask/ViewTask';
import { TasksNotifications } from './TasksNotifications';
import { useGenerateIds } from 'src/pages/common/hooks/useGenerateIds';
import { saveInTaskNotifyArray } from 'src/redux/taskManager/taskManagerActions';
import { MissedCallNotifications } from './MissedCallNotifications';
import { VoiceMailNotifications } from './VoiceMailNotifications';
import PhoneMissedIcon from '@mui/icons-material/PhoneMissed';
import { REMOVE_VM_NOTIFICATION } from 'src/redux/twilio/twilioTypes';

const customTheme = createTheme({
  overrides: {
    MuiTab: {
      root: {
        maxWidth: `120px`,
        fontSize: `0.75rem`,
        minWidth: `80px`,
        '@media(minWidth: 600px)': {
          minWidth: `80px`,
        },
        MuiButtonBaseRoot: {
          size: `small`,
        },
      },
    },
  },
});

let row = null;
let notFlag = false;
let timerVm = null
export const NotificationDropDown = () => {
  const { rtData, twilio, resmed, patient,
    tasksManager: { taskNotificationArray },
    twilio: { removeVmNotification }
  } = useSelector((state) => state);
  const user = useSelector((state) => state.user.user);
  const { notifyCount: count } = rtData;
  const { smsArrayForNotification } = twilio;
  const { timer } = patient;
  const { toggleButton, startTime } = timer;
  const { alertPatientRiskChange } = resmed;
  let selectedPatient = patient?.patientData?.data?._id;
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [notificationFlag, setNotificationFlag] = useState(false);
  const [value, setValue] = useState(0);
  const [collapseFlag, setCollapseFlag] = useState(false);
  const [listOfPendingProviders, setListOfPendingProviders] = useState([]);
  const [openSpecificTask, setOpenSpecificTask] = useState(false);
  const [selectedTask, setSelectedTask] = useState({});
  const [listOfNewPatientNotifications, setListOfNewPatientNotifications] = useState([]);
  const [missedCallNot, setMissedCallNot] = useState([]);
  const [missedVoiceMailNot, setMissedVoiceMailNot] = useState([]);
  let history = useHistory();





  useEffect(() => {
    if (removeVmNotification) {
      setMissedVoiceMailNot((p) => [...returnIfArr(p).filter((msg) => msg?._id !== removeVmNotification)]);
      timerVm = setTimeout(() => dispatch({ type: REMOVE_VM_NOTIFICATION, payLoad: `` }), 1000);
    }
    return () => {
      if (timerVm) clearTimeout(timerVm)
    }
  }, [removeVmNotification]);

  // GET LIST OF PENDING PROVIDERS

  const getListOfPendingProvidersHandler = () => {
    if (user?.isSuperAdmin) {
      getRequest(PENDING_PROVIDERS)
        .then((res) => {
          setListOfPendingProviders(res?.data?.pendingProviders);
        })
        .catch(() => { });
    }
  };

  const handleClickAway = () => {
    if (collapseFlag) {
      notFlag = true;
      setCollapseFlag((pre) => !pre);
    }
  };

  useEffect(() => {
    newSocket.on(`missedVMNotification`, (data) => setMissedVoiceMailNot((prev) => [{ ...data }, ...prev]));
    newSocket.on(`missedCallNotification`, (data) => setMissedCallNot((p) => [{ ...data }, ...p]));
    getListOfPendingProvidersHandler();
  }, []);

  const handleHide = () => {
    setCollapseFlag((prev) => {
      if (!prev) setValue(0);
      return !prev;
    });

    user?.isSystemAdministrator && fetchNoticationHandler();
  };
  const renderCount = useRef(0);

  useEffect(() => {
    if (renderCount.current === 0 && (user?.isProvider || user?.isClinicalStaff)) {
      getRequest(GET_ALL_MISSED_CALL_NOTIFICATIONS)
        .then((res) => {
          const { data = {} } = res;
          const { missedCallLogs = [] } = data;
          setMissedCallNot(missedCallLogs);
        })
        .catch();
      postRequest(GET_ALL_VOICE_MAILS, {
        notifyOnly: true,
      })
        .then((res) => setMissedVoiceMailNot(res?.data?.voicemails))
        .catch();
      if (!user?.isSystemAdministrator && !user?.isGroupAdmin) {
        dispatch(saveInTaskNotifyArray());
      }
      allUnreadMessageRedDotShow();
      unreadRiskNotification();
      getPatientNotifications();
      renderCount.current = renderCount.current + 1;
    }
  }, [user, renderCount]);

  useEffect(() => {
    if (newSocket && user) {
      // EVENT EMIT - TO GET LIST OnesSocketF USERS
      newSocket.emit(`getUnreadRequestsOfProviders`);

      newSocket.on(`newPatientNotification`, (data) => {
        setListOfNewPatientNotifications((oldListOfNewPatientNotifications) => {
          if (oldListOfNewPatientNotifications?.length !== 0) return [...oldListOfNewPatientNotifications, data];
          else return [data];
        });
      });

      newSocket.on(`deletePatientNotification`, (id) => {
        setListOfNewPatientNotifications((oldListOfNewPatientNotifications) => {
          return oldListOfNewPatientNotifications.filter((notify) => notify._id !== id);
        });
      });

      newSocket.on(`getUnreadRequestsOfProviders`, (data) => {
        dispatch(setUnreadNotifyCountHandler(data));
      });

      newSocket.on(`approvalRequestForProvider`, (provider) => {
        successToast(provider.description);
        fetchNoticationHandler();
      });

      newSocket.on(`newProviderApprovalNotification`, (provider) => {
        successToast(provider.message);
      });
    }

    return () => {
      newSocket.off(`newProviderApprovalNotification`);
      newSocket.off(`approvalRequestForProvider`);
      newSocket.off(`getUnreadRequestsOfProviders`);
      newSocket.off(`newPatientNotification`);
      newSocket.off(`deletePatientNotification`);
    };
  }, [newSocket]);

  useEffect(() => {
    return () => {
      dispatch(emptySMSArrayObject());
    };
  }, []);

  const getPatientNotifications = () => {
    if (!user?.isBiller) {
      getRequest(ALL_UNREAD_PATIENT_NOTIFICATION + user?._id)
        .then((res) => {
          setListOfNewPatientNotifications(res?.data?.notifications);
        })
        .catch(() => { });
    }
  };

  const unreadRiskNotification = () => {
    if (!user?.isBiller) {
      getRequest(ALL_RISK_NOTIFICATION)
        .then((res) => {
          dispatch(addResmedRiskArrayObjectInitial(res?.data?.notifications));
        })
        .catch(() => { });
    }
  };
  const fetchNoticationHandler = () => {
    getRequest(LIST_ALL_NOTIFICATIONS_URL)
      .then((res) => {
        const { data } = res || {};
        const { notifications } = data | [];
        setData(notifications);
      })
      .catch(() => { });
  };
  const allUnreadMessageRedDotShow = () => {
    if (!user?.isBiller) {
      getRequest(ALL_UNREAD_MESSAGE_RED_DOT_SHOW)
        .then((res) => {
          dispatch(addSMSArrayObjectInitial(res?.data?.messages));
        })
        .catch(() => { });
    }
  };

  const markAsReadHandler = (id) => {
    setNotificationFlag(true);
    getRequest(UNREAD_NOTICATIONS_STATUS_UPDATE_URL + id)
      .then(() => {
        setNotificationFlag(false);

        newSocket.emit(`getUnreadRequestsOfProviders`);
        fetchNoticationHandler();

        //
      })
      .catch(() => {
        setNotificationFlag(false);
      });
  };

  // REDIRECT PATIENT HANDLER
  const redirectPatientHandler = (patientId, setLocalState, additionalParams, remKey, vmId) => {
    timeLogAddOnSwitchPatientAndSorting();
    const msgRemovalHand = () => dispatch(removeSMSFromNotificationArray(patientId));
    if (selectedPatient === patientId) {
      msgRemovalHand();
      setCollapseFlag((prev) => !prev);
    } else {
      if (setLocalState) {
        let evaluationKey = remKey === `from` ? patientId : vmId;
        setLocalState((p) => {
          return [...returnIfArr(p).filter((not) => not?.[remKey] !== evaluationKey)];
        });
      }
      patchRequest(UPDATE_PATIENT_UNREAD_NOTIFICATION_STATUS + patientId, { ...additionalParams })
        .then(() => {
          msgRemovalHand();
          setCollapseFlag((prev) => !prev);
          history.push({ pathname: `/patient`, patientId, updateNotif: { ...additionalParams } });
        })
        .catch(() => { });
    }
  };

  // RISK ALERT REDIRECT HANDLER
  const riskChangeAlertPatientRedirectHandler = (notification) => {
    if (selectedPatient === notification?.patient?._id) {
      dispatch(_removeRiskAlertPatient(notification?.patient?._id));
      setCollapseFlag((prev) => !prev);
    } else if (!user?.isBiller) {
      deleteRequest(`notifications/rf/${notification?.patient?._id}`)
        .then(() => {
          dispatch(_removeRiskAlertPatient(notification?._id));
          setCollapseFlag((prev) => !prev);
          if (toggleButton === true) {
            //Redirect to dashboard from the risk notification
            history.push({ pathname: `/patient`, patientId: notification?.patient?._id });

            let endTimeTemp = momentWrapper().toISOString();
            const timeDiff = momentWrapper(endTimeTemp).diff(startTime, `seconds`);

            // if time toggle is running more than 5 seconds then save the time first
            if (timeDiff > 5) {
              let url = ADD_PATIENT_MONITORING_TIME_LOG_URL + `${selectedPatient}` + `?reportId=${null}`;
              postRequest(url, {
                startDate: startTime,
                endDate: endTimeTemp,
                startOfMonth: momentWrapper().startOf(`month`).toISOString(),
                now: momentWrapper().toISOString(),
                source: `View Report`,
                type: `Auto`,
                description: `View graphs and reports`,
                callSuccess: false,
              })
                .then(() => {
                  successToast(`Time Log Added.`);
                })
                .catch(() => { });
            }
          } else {
            history.push({
              pathname: `/patient`,
              patientId: notification?.patient?._id,
            });
          }
        })
        .catch(() => { });
    }
  };

  if (data?.length !== 0) {
    row = data.map((note) => {
      const cursorClassHandler = () => {
        if (notificationFlag) {
          return `unset`;
        }
        return `pointer`;
      };
      return (
        <tr
          key={note._id}
          onClick={() => (note.status !== `READ` ? markAsReadHandler(note._id) : null)}
          style={{
            borderBottom: `1px solid rgb(211, 209, 209)`,
            cursor: `${cursorClassHandler()}`,
          }}
          id={note.status === `UNREAD` ? css.unreadNote : css.readNote}
        >
          <td>
            <div className="d-flex justify-content-between">
              <div className="d-flex">
                <p style={{ margin: 0 }}>{`${capitalizeFirstLetter(note?.resourceId?.firstName)} ${capitalizeFirstLetter(
                  note?.resourceId?.lastName
                )} (`}</p>
                <p style={{ margin: 0 }}>{<Moment date={note?.resourceId?.dateOfBirth} format="MM/DD/YY" />}</p>
                <p style={{ margin: 0 }}>{` )`}</p>
              </div>
              <p style={{ margin: 0 }}>{<Moment date={note.createdAt} format="MM/DD/YY" />}</p>
            </div>
            <div className="d-flex align-items-center">
              {note.status === `UNREAD` ? (
                <i
                  className="fa fa-circle mr-1"
                  style={{
                    color: `#1699C5`,
                    fontSize: `6px`,
                    verticalAlign: `middle`,
                  }}
                  aria-hidden="true"
                ></i>
              ) : null}
              <p style={{ margin: 0 }}>{note.description}</p>
            </div>
          </td>
        </tr>
      );
    });
  }

  const deleteNewPatientNotifications = (id, patientId, providerId, clinicalId) => {
    history.push({ pathname: `/patient`, patientId });
    const data = {
      notificationId: id,
      patientId: patientId,
      // userId:user?._id
      providerId: providerId,
      clinicalId: clinicalId,
    };
    newSocket.emit(`deleteNewPatientNotification`, data);
  };
  const isOnVideo = useSelector((state) => state.patient.isOnVideo);
  const ids = useGenerateIds({
    listOfNewPatientNotifications: ``,
    listOfPendingProviders: ``,
    smsArrayForNotification: ``,
    alertPatientRiskChange: ``,
  });

  let isOnlySuperAdmin = !!(user?.isSuperAdmin && !user.isProvider && !user.isClinicalStaff);

  const { listOfNewPatientNotificationsKey, smsArrayForNotificationKey, alertPatientRiskChangeKey } = ids || false;
  return (
    <>
      {openSpecificTask && (
        <ViewTask
          isNotify={true}
          selectedTaskModalHandler={openSpecificTask}
          setSelectedTaskModalHandler={setOpenSpecificTask}
          selectedTask={selectedTask}
        />
      )}
      <ThemeProvider theme={customTheme}>
        <button disabled={isOnVideo} id={css.topBarBtnSmall} type="button" onClick={() => handleHide()}>
          {!user?.isSystemAdministrator &&
            (smsArrayForNotification?.length > 0 ||
              alertPatientRiskChange?.length > 0 ||
              listOfNewPatientNotifications?.length > 0 ||
              taskNotificationArray?.length > 0 ||
              missedVoiceMailNot?.length > 0 ||
              missedCallNot?.length > 0 ||
              listOfPendingProviders?.length) ? (
            <span id={css.badge}>
              {smsArrayForNotification?.length +
                alertPatientRiskChange?.length +
                listOfNewPatientNotifications?.length +
                taskNotificationArray?.length +
                listOfPendingProviders?.length +
                missedVoiceMailNot?.length +
                missedCallNot?.length}
            </span>
          ) : null}
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 40 43.993">
            <g id="bell_2_" data-name="bell (2)" transform="translate(2 2)">
              <path
                id="Path"
                d="M30,12A12,12,0,0,0,6,12C6,26,0,30,0,30H36s-6-4-6-18"
                fill="none"
                stroke={count > 0 ? `#1699c5` : `#6d7278`}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="4"
              />
              <path
                id="Path-2"
                data-name="Path"
                d="M6.92,0A4,4,0,0,1,3.46,2,4,4,0,0,1,0,0"
                transform="translate(14.54 37.996)"
                fill="none"
                stroke={count > 0 ? `#1699c5` : `#6d7278`}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="4"
              />
            </g>
          </svg>
        </button>
        {isOnlySuperAdmin || user.isBiller ? (
          <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={() => handleClickAway()}>
            <Collapse in={collapseFlag}>
              {isOnlySuperAdmin || user.isBiller ? (
                <div
                  style={{
                    overflow: `inherit`,
                    position: `absolute`,
                    zIndex: `22`,
                    display: `flex`,
                    marginTop: `18%`,
                    marginLeft: `-9em`,
                    maxHeight: `300px`,
                    boxShadow: `0 2px 6px 0 #0000001a, 0 6px 20px 0 rgb(0 0 0 / 19%)`,
                  }}
                >
                  <Paper square style={{ width: `249px` }}>
                    <div
                      style={{
                        height: 46,
                        justifyContent: `space-between`,
                        display: `flex`,
                        alignItems: `center`,
                      }}
                    >
                      {value != 0 && isOnlySuperAdmin ? (
                        <ChevronLeftIcon
                          style={{ marginLeft: 10 }}
                          onClick={() => {
                            if (value == 0) {
                              setValue(0);
                            } else {
                              setValue((p) => p - 1);
                            }
                          }}
                        />
                      ) : (
                        <span style={{ marginLeft: 10 }}></span>
                      )}
                      {value === 0 && isOnlySuperAdmin ? (
                        <div style={{ textTransform: `capitalize` }} onClick={getListOfPendingProvidersHandler}>
                          <span style={{ fontSize: `small` }}>{`Pending Providers (${listOfPendingProviders.length})`}</span>
                        </div>
                      ) : null}
                      {value === 1 || user?.isBiller ? (
                        <div>
                          <span style={{ fontSize: `small` }}>{`Tasks (${taskNotificationArray?.length})`}</span>
                        </div>
                      ) : null}
                      {value != 1 && isOnlySuperAdmin ? (
                        <ChevronRightIcon
                          style={{ marginRight: 10 }}
                          onClick={() => {
                            if (value === 1) {
                              setValue();
                            } else {
                              setValue((p) => p + 1);
                            }
                          }}
                        />
                      ) : (
                        <span style={{ marginLeft: 10 }}></span>
                      )}
                    </div>
                    {value === 0 && isOnlySuperAdmin ? <PendingProviders listOfPendingProviders={listOfPendingProviders} /> : null}
                    {value === 1 || user?.isBiller ? (
                      <TasksNotifications setOpenSpecificTask={setOpenSpecificTask} setSelectedTask={setSelectedTask} />
                    ) : null}
                    {` `}
                  </Paper>
                </div>
              ) : null}
            </Collapse>
          </ClickAwayListener>
        ) : (
          <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={() => handleClickAway()}>
            <Collapse in={collapseFlag}>
              {!user?.isSystemAdministrator && (
                <div
                  style={{
                    overflow: `inherit`,
                    position: `absolute`,
                    zIndex: `22`,
                    display: `flex`,
                    marginTop: `18%`,
                    marginLeft: `-9em`,
                    maxHeight: `300px`,
                    boxShadow: `0 2px 6px 0 #0000001a, 0 6px 20px 0 rgb(0 0 0 / 19%)`,
                  }}
                >
                  <Paper square style={{ width: `249px`, overflowY: `scroll` }}>
                    <div
                      style={{
                        height: 46,
                        justifyContent: `space-between`,
                        display: `flex`,
                        alignItems: `center`,
                      }}
                    >
                      {value != 0 ? (
                        <ChevronLeftIcon
                          style={{ marginLeft: 10 }}
                          onClick={() => {
                            if (value == 0) {
                              setValue(0);
                            } else {
                              setValue((p) => p - 1);
                            }
                          }}
                        />
                      ) : (
                        <span style={{ marginLeft: 10 }}></span>
                      )}
                      {value == 0 ? (
                        <div style={{ textTransform: `capitalize` }}>
                          <span style={{ fontSize: `small` }}>{`Missed calls (${missedCallNot?.length})`}</span>
                        </div>
                      ) : null}
                      {value == 1 ? (
                        <div style={{ textTransform: `capitalize` }}>
                          <span style={{ fontSize: `small` }}>{`Messages (${smsArrayForNotification?.length})`}</span>
                        </div>
                      ) : null}
                      {value == 2 ? (
                        <div style={{ textTransform: `capitalize` }}>
                          <span style={{ fontSize: `small` }}>{`Voice mails (${missedVoiceMailNot?.length})`}</span>
                        </div>
                      ) : null}
                      {value == 3 ? (
                        <div style={{ textTransform: `capitalize` }} onClick={getListOfPendingProvidersHandler}>
                          <span style={{ fontSize: `small` }}>{`Change in Risk (${alertPatientRiskChange.length})`}</span>
                        </div>
                      ) : null}
                      {value == 4 ? (
                        <div style={{ textTransform: `capitalize` }} onClick={getListOfPendingProvidersHandler}>
                          <span style={{ fontSize: `small` }}>{`Pending Providers (${listOfPendingProviders.length})`}</span>
                        </div>
                      ) : null}
                      {value === 5 ? (
                        <div>
                          <span style={{ fontSize: `small` }}>{`New Patient (${listOfNewPatientNotifications?.length})`}</span>
                        </div>
                      ) : null}
                      {value === 6 ? (
                        <div>
                          <span style={{ fontSize: `small` }}>{`Tasks (${taskNotificationArray?.length})`}</span>
                        </div>
                      ) : null}
                      {value != 6 ? (
                        <ChevronRightIcon
                          style={{ marginRight: 10 }}
                          onClick={() => {
                            if (value === 6) {
                              setValue(6);
                            } else {
                              setValue((p) => p + 1);
                            }
                          }}
                        />
                      ) : (
                        <span style={{ marginLeft: 10 }}></span>
                      )}
                    </div>
                    {/* </Tabs> */}
                    {value === 0 && (
                      <MissedCallNotifications
                        isMissed={true}
                        icon={PhoneMissedIcon}
                        socketName={`missedCallNotification`}
                        notifications={missedCallNot}
                        setNotification={setMissedCallNot}
                        redirection={(patientId) => redirectPatientHandler(patientId, setMissedCallNot, { allMissedVoice: true }, `from`)}
                      />
                    )}
                    {value == 1 ? (
                      <div style={{ overflowY: `auto`, height: smsArrayForNotification?.length ? `230px` : null }}>
                        {smsArrayForNotification?.length > 0 ? (
                          smsArrayForNotification?.map((message, ind) => {
                            let key = `${smsArrayForNotificationKey}${ind + 1}`;
                            return (
                              <Card {...spreadUniqueKeyProp(key)} sx={{ boxShadow: `0 0px 2px #9c909085`, borderRadius: 0 }}>
                                <CardContent>
                                  <div style={{ display: `flex` }}>
                                    <Avatar name={`${message?.firstName} ${message?.lastName}`} size="35" round="40px" />
                                    <div className="ml-2">
                                      <span
                                        className="text-capitalize text-primary"
                                        style={{
                                          cursor: `pointer`,
                                          fontWeight: `600`,
                                          fontSize: `12px`,
                                        }}
                                        onClick={() => redirectPatientHandler(message?.from, false, { allMissedSMS: true })}
                                      >
                                        {capitalizeFirstLetter(message?.firstName)} {capitalizeFirstLetter(message?.lastName)}
                                      </span>
                                      <br />
                                      <span
                                        className="d-inline-block text-truncate"
                                        style={{
                                          fontWeight: `600`,
                                          fontSize: `11px`,
                                          maxWidth: `180px`,
                                        }}
                                      >
                                        Message : <i>{message?.message}</i>
                                      </span>
                                      <br />
                                      <span
                                        style={{
                                          fontSize: `11px`,
                                        }}
                                      >
                                        {momentWrapper(message?.date).format(`MM/DD/YYYY hh:mm:ss A`)}
                                      </span>
                                    </div>
                                  </div>
                                </CardContent>
                              </Card>
                            );
                          })
                        ) : (
                          <Card sx={{ boxShadow: `none` }}>
                            <CardContent style={{ padding: `12px` }}>
                              <p className="text-center">No Message Found</p>
                            </CardContent>
                          </Card>
                        )}
                      </div>
                    ) : null}
                    {value === 2 ? (
                      <VoiceMailNotifications
                        notifications={missedVoiceMailNot}
                        redirection={(patientId, vmId) => redirectPatientHandler(patientId, setMissedCallNot, { allMissedVoice: true }, `from`, vmId)}
                        setNotification={setMissedVoiceMailNot}
                      />
                    ) : null}
                    {value == 3 ? (
                      <div style={{ overflowY: `auto`, height: alertPatientRiskChange?.length ? `230px` : null }}>
                        {alertPatientRiskChange?.length > 0 ? (
                          alertPatientRiskChange?.map((message, ind) => {
                            let key = `${alertPatientRiskChangeKey}${ind + 1}`;
                            return (
                              <Card {...spreadUniqueKeyProp(key)} sx={{ boxShadow: `0 0px 2px #9c909085`, borderRadius: 0 }}>
                                <CardContent>
                                  <div style={{ display: `flex` }}>
                                    <Avatar name={`${message?.patient?.firstName} ${message?.patient?.lastName}`} size="35" round="40px" />
                                    <div className="ml-2">
                                      <span
                                        className="text-capitalize text-primary"
                                        style={{
                                          cursor: `pointer`,
                                          fontWeight: `600`,
                                          fontSize: `12px`,
                                        }}
                                        onClick={() => riskChangeAlertPatientRedirectHandler(message)}
                                      >
                                        {capitalizeFirstLetter(message?.patient?.firstName)} {capitalizeFirstLetter(message?.patient?.lastName)}
                                      </span>
                                      <br />
                                      <span
                                        className="text-truncate"
                                        style={{
                                          fontWeight: `600`,
                                          fontSize: `9px`,
                                          color: `#0d5c77`,
                                        }}
                                      >
                                        Risk : <i className="text-info">{message?.message?.replace(`MODERATE`, `MEDIUM`)}</i>
                                      </span>
                                      <br />
                                      <span
                                        style={{
                                          fontWeight: `600`,
                                          fontSize: `9px`,
                                        }}
                                      >
                                        {momentWrapper(message?.createdAt).format(`MM/DD/YYYY hh:mm:ss A`)}
                                      </span>
                                    </div>
                                  </div>
                                </CardContent>
                              </Card>
                            );
                          })
                        ) : (
                          <Card sx={{ boxShadow: `none` }}>
                            <CardContent style={{ padding: `12px` }}>
                              <p className="text-center">You don't have any notifications</p>
                            </CardContent>
                          </Card>
                        )}
                      </div>
                    ) : null}
                    {value == 4 ? <PendingProviders listOfPendingProviders={listOfPendingProviders} /> : null}
                    {value == 5 ? (
                      <div style={{ overflowY: `auto`, height: listOfNewPatientNotifications?.length ? `230px` : null }}>
                        {listOfNewPatientNotifications?.length > 0 ? (
                          listOfNewPatientNotifications.map((provider, ind) => {
                            let key = `${listOfNewPatientNotificationsKey}${ind + 1}`;
                            return (
                              <Card {...spreadUniqueKeyProp(key)} sx={{ boxShadow: `0 0px 2px #9c909085`, borderRadius: 0 }}>
                                <CardContent style={{ padding: `12px` }}>
                                  <div
                                    onClick={() =>
                                      deleteNewPatientNotifications(provider?._id, provider?._patientId, provider?._providerId, provider?._clinicalId)
                                    }
                                  >
                                    <span
                                      style={{
                                        cursor: `pointer`,
                                        fontWeight: `600`,
                                        fontSize: `13px`,
                                        color: `#1699c5`,
                                      }}
                                    >
                                      {capitalizeFirstLetter(provider?.patientName)}
                                    </span>
                                    <br />
                                    <span
                                      style={{
                                        fontSize: `11px`,
                                        color: `#000`,
                                      }}
                                    >
                                      {` `}
                                      Date Added : <Moment date={provider?.createdAt} format="MM/DD/YY" />
                                    </span>
                                  </div>
                                </CardContent>
                              </Card>
                            );
                          })
                        ) : (
                          <Card sx={{ boxShadow: `none` }}>
                            <CardContent style={{ padding: `12px` }}>
                              <p className="text-center">You don't have any notifications</p>
                            </CardContent>
                          </Card>
                        )}
                      </div>
                    ) : null}
                    {value === 6 ? <TasksNotifications setOpenSpecificTask={setOpenSpecificTask} setSelectedTask={setSelectedTask} /> : null}
                  </Paper>
                </div>
              )}
            </Collapse>
          </ClickAwayListener>
        )}
      </ThemeProvider>
    </>
  );
};

const PendingProviders = ({ listOfPendingProviders, listOfPendingProvidersKey }) => {
  return (
    <div style={{ overflowY: `auto`, height: listOfPendingProviders?.length ? `230px` : null }}>
      {listOfPendingProviders?.length > 0 ? (
        listOfPendingProviders.map((provider, ind) => {
          let key = `${listOfPendingProvidersKey}${ind + 1}`;
          return (
            <Card {...spreadUniqueKeyProp(key)} sx={{ boxShadow: `0 0px 2px #9c909085`, borderRadius: 0 }}>
              <CardContent style={{ padding: `12px` }}>
                <div>
                  <span
                    className="text-capitalize"
                    style={{
                      fontWeight: `600`,
                      fontSize: `13px`,
                      color: `#1699c5`,
                    }}
                  >
                    {capitalizeFirstLetter(provider?.firstName)} {capitalizeFirstLetter(provider?.lastName)}
                  </span>
                  <br />
                  <span
                    className="text-capitalize"
                    style={{
                      fontSize: `11px`,
                      color: `rgb(133 170 183)`,
                    }}
                  >
                    {` `}
                    Created by : {capitalizeFirstLetter(provider?._created_by?.firstName)} {capitalizeFirstLetter(provider?._created_by?.lastName)}
                  </span>
                </div>
              </CardContent>
            </Card>
          );
        })
      ) : (
        <Card sx={{ boxShadow: `none` }}>
          <CardContent style={{ padding: `12px` }}>
            <p className="text-center">You don't have any notifications</p>
          </CardContent>
        </Card>
      )}
    </div>
  );
};