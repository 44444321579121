import React, { useEffect, useRef } from 'react'
import { Box, Grid, InputAdornment, TextField, Typography } from '@mui/material';
import { Controller } from 'react-hook-form';
import { ToolTipProvider } from 'src/pages/common/components/Tooltip/ToolTipProvider';
import css from './addNewPractice.module.css';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { momentWrapper } from 'src/momentWrapper';
import { npiMaskHandler, purifyInputValue, invalidNoGiver, setValuesFromArrRegPrac } from 'src/utils';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import InputMask from 'react-input-mask';
import { providerTypeOptions, stateNameOptions } from './AddNewPracticeFormik';
import { AskForConfirmation } from 'src/pages/common/components/AskForconfirmation/AskForConfirmation';
import { EmailAndLastNameCheck } from 'src/pages/common/components/EmailAndLastNameCheck/EmailAndLastNameCheck';


const Provider = ({ emailRegex, index, adminAdditionalRole, isGroupAdmin, field, removeIndexHandler, control, errors, appendInputs, setisLoading, isAAvailableProvider, isLoading, getValue, setPersistValues, dateFormat }) => {
  let additionalValues = getValue(`additionalValues`, index);
  let isAlreadyProvider = !!(additionalValues && Object.keys(additionalValues)?.length);
  let providerStatus = getValue(`providerStatus`, index);

  return (
    <Grid sx={{ mt: 1, mb: 1 }} rowSpacing={1.5} columnSpacing={1.5} container key={field?._id || index}>
      <Grid
        item
        xs={index === 0 && adminAdditionalRole === `provider` ? 4 : 6}
        sm={index === 0 && adminAdditionalRole === `provider` ? 4 : 6}
        md={index === 0 && adminAdditionalRole === `provider` ? 4 : 6}
        lg={index === 0 && adminAdditionalRole === `provider` ? 4 : 6}
        xl={index === 0 && adminAdditionalRole === `provider` ? 4 : 6}
      >
        <Box sx={{ pb: 1, mb: 1, ...(!isGroupAdmin && { borderBottom: `1px solid rgb(0 0 0 / 13%)` }) }}>
          <Typography variant="h6" component={`div`}>
            Provider {index + 1}
          </Typography>
        </Box>
      </Grid>
      {index === 0 && adminAdditionalRole === `provider` && (
        <Grid item sx={{ textAlign: `center` }} xs={4} sm={4} md={4} lg={4} xl={4}>
          <ToolTipProvider
            toolTipProps={{
              placement: `top`,
              title: `Remove all providers`,
              arrow: true,
            }}
            element={
              <button
                onClick={() => removeIndexHandler(index)}
                id={css.addProviderBtn}
                type="button"
                className="btn btn-outline-info btn-sm"
                size="small"
                color="error"
              >
                Remove all providers
              </button>
            }
          />
        </Grid>
      )}
      <Grid
        sx={{ pb: 1, mb: 1, textAlign: `end`, ...(!isGroupAdmin && { borderBottom: `1px solid rgb(0 0 0 / 13%)` }) }}
        item
        xs={index === 0 && adminAdditionalRole === `provider` ? 4 : 6}
        sm={index === 0 && adminAdditionalRole === `provider` ? 4 : 6}
        md={index === 0 && adminAdditionalRole === `provider` ? 4 : 6}
        lg={index === 0 && adminAdditionalRole === `provider` ? 4 : 6}
        xl={index === 0 && adminAdditionalRole === `provider` ? 4 : 6}
      >
        {index > 0 ? (
          <ToolTipProvider
            toolTipProps={{
              placement: `top`,
              title: `Remove this provider`,
              arrow: true,
            }}
            element={
              <button
                id={css.addProviderBtn}
                type="button"
                className="btn btn-outline-info btn-sm"
                size="small"
                color="error"
                onClick={() => removeIndexHandler(index)}
              >
                Remove
              </button>
            }
          />
        ) : (
          <ToolTipProvider
            toolTipProps={{
              placement: `top`,
              title: `Add more provider`,
              arrow: true,
            }}
            element={
              <button
                type="button"
                id={css.addProviderBtn}
                disabled={errors?.providers?.[index]}
                size="small"
                className="btn btn-outline-info btn-sm"
                color="primary"
                onClick={() => {
                  setPersistValues((p) => ({ ...p, providers: [...p.providers, false] }));
                  setisLoading((p) => [
                    ...p,
                    {
                      num: { loading: false, error: false },
                      userName: { loading: false, error: false },
                      mail: { loading: false, error: false },
                    },
                  ]);
                  appendInputs(`SELECTING`)
                }}
              >
                ADD
              </button>
            }
          />
        )}
      </Grid>

      <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
        <Controller
          name={`providers[${index}].firstName`}
          control={control}
          defaultValue={``}
          rules={{
            required: `*Required`,
          }}
          render={({ field: { onChange, value, onBlur }, fieldState: { invalid, error } }) => (
            <TextField
              disabled={providerStatus === `EXISTING`}
              InputLabelProps={{
                style: { backgroundColor: `white`, left: `-1.5%` },
              }}
              label="*FirstName"
              fullWidth
              size="small"
              error={invalid}
              helperText={error?.message}
              onChange={(e) => purifyInputValue({ event: e, onChange: onChange })}
              onBlur={onBlur}
              value={value}
            />
          )}
        />
      </Grid>

      <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
        <Controller
          name={`providers[${index}].lastName`}
          control={control}
          defaultValue={``}
          rules={{
            required: `*Required`,
          }}
          render={({ field: { onChange, value, onBlur }, fieldState: { invalid, error } }) => (
            <TextField
              disabled={providerStatus === `EXISTING`}
              InputLabelProps={{
                style: { backgroundColor: `white`, left: `-1.5%` },
              }}
              label="*Last Name"
              fullWidth
              size="small"
              error={invalid}
              helperText={error?.message}
              onChange={(e) => purifyInputValue({ event: e, onChange: onChange })}
              onBlur={onBlur}
              value={value}
            />
          )}
        />
      </Grid>
      <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
        <Controller
          name={`providers[${index}].dateOfBirth`}
          control={control}
          defaultValue={``}
          render={({ field: { onChange, value, onBlur }, fieldState: { invalid, error } }) => (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                disabled={providerStatus === `EXISTING`}
                label={`Date Of Birth`}
                openTo="month"
                style={{ width: `100%` }}
                views={[`year`, `month`, `day`]}
                inputFormat={dateFormat || `YYYY/MM/DD`}
                InputLabelProps={{
                  shrink: true,
                }}
                maxDate={momentWrapper().toDate()}
                size="small"
                value={value || null}
                onChange={(date) => {
                  onChange(date?.$d);
                }}
                renderInput={(params) => {
                  return <TextField disabled={providerStatus === `EXISTING`} size="small" fullWidth {...params} />;
                }}
              />
            </LocalizationProvider>
          )}
        />
      </Grid>

      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
        <Controller
          name={`providers[${index}].providerType`}
          control={control}
          defaultValue={``}
          rules={{
            required: `*Required`,
          }}
          render={({ field: { onChange, value, onBlur }, fieldState: { invalid, error } }) => (
            // <div className={css.withoutBorder}>
            <TextField
              disabled={!!(providerStatus === `EXISTING` && isAlreadyProvider)}
              InputLabelProps={{
                style: { backgroundColor: `white`, left: `-1.5%` },
              }}
              label="*Provider Type"
              select
              fullWidth
              size="small"
              error={invalid}
              helperText={error?.message}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
            >
              {providerTypeOptions}
            </TextField>
            // </div>
          )}
        />
      </Grid>
      <Grid item xs={6} sm={4} md={4} lg={4} xl={4}>
        <Controller
          rules={{
            required: `*Required`,
            validate: (value) => {
              return npiMaskHandler(value, false);
            },
          }}
          name={`providers[${index}].NPI`}
          control={control}
          defaultValue=""
          render={({ field: { onChange, value, onBlur }, fieldState: { invalid, error } }) => (
            // <div className={css.withoutBorder}>
            <InputMask
              disabled={!!(providerStatus === `EXISTING` && isAlreadyProvider)}
              key={`providers[${index}].NPI`}
              mask={`9999999999`}
              value={value}
              onChange={(e) => purifyInputValue({ event: e, onChange: onChange })}
            >
              {(inputProps) => {
                return (
                  <TextField
                    disabled={!!(providerStatus === `EXISTING` && isAlreadyProvider)}
                    fullWidth
                    {...inputProps}
                    label={`*Npi`}
                    size="small"
                    error={invalid}
                    helperText={error?.type === `validate` ? `Invalid Npi` : error?.message}
                  />
                );
              }}
            </InputMask>
          )}
        />
      </Grid>
      <Grid item xs={6} sm={8} md={8} lg={8} xl={8}>
        <Controller
          name={`providers[${index}].phoneNumber`}
          control={control}
          defaultValue={``}
          rules={{
            required: `*Required`,
            minLength: 14,
            validate: (value) => {
              return invalidNoGiver(value);
            },
          }}
          render={({ field: { onChange, value, onBlur }, fieldState: { invalid, error } }) => (
            <InputMask
              disabled={providerStatus === `EXISTING`}
              mask={`(999) 999-9999`}
              value={value}
              onChange={(e) => purifyInputValue({ event: e, onChange: onChange })}
              onBlur={(e) => isAAvailableProvider(e, `providers[${index}].phoneNumber`, index)}
            >
              {(inputProps) => {
                return (
                  // <div className={css.withoutBorder}>
                  <TextField
                    disabled={providerStatus === `EXISTING`}
                    InputLabelProps={{
                      style: { backgroundColor: `white`, left: `-1.5%` },
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {isLoading[index]?.num?.error ? (
                            <ErrorOutlineIcon fontSize="small" sx={{ color: `red` }} />
                          ) : isLoading[index]?.num?.loading ? (
                            <div className="spinner-border spinner-border-sm text-info"></div>
                          ) : null}
                        </InputAdornment>
                      ),
                    }}
                    fullWidth
                    {...inputProps}
                    label="*Phone Number"
                    size="small"
                    error={error?.type === `validate` ? true : invalid}
                    helperText={error?.type === `validate` ? `Invalid No.` : error?.message}
                  />
                  // </div>
                );
              }}
            </InputMask>
          )}
        />
      </Grid>

      <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
        <Controller
          name={`providers[${index}].email`}
          control={control}
          defaultValue={``}
          rules={{
            required: `*Required`,
            pattern: {
              value: emailRegex,
              message: `Invalid Email`,
            },
          }}
          render={({ field: { onChange, value, onBlur }, fieldState: { invalid, error } }) => (
            <TextField
              disabled={providerStatus === `EXISTING`}
              InputLabelProps={{
                style: { backgroundColor: `white`, left: `-1.5%` },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {isLoading[index]?.mail.error ? (
                      <ErrorOutlineIcon fontSize="small" sx={{ color: `red` }} />
                    ) : isLoading[index]?.mail?.loading ? (
                      <div className="spinner-border spinner-border-sm text-info"></div>
                    ) : null}
                  </InputAdornment>
                ),
              }}
              label="*Email"
              fullWidth
              size="small"
              error={invalid}
              helperText={error?.message}
              onChange={(e) => purifyInputValue({ event: e, onChange: onChange })}
              onBlur={(e) => isAAvailableProvider(e, `providers[${index}].email`, index)}
              value={value}
            />
          )}
        />
      </Grid>
      <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
        <Controller
          name={`providers[${index}].licenseState`}
          control={control}
          defaultValue={``}
          rules={{
            required: `*Required`,
          }}
          render={({ field: { onChange, value, onBlur }, fieldState: { invalid, error } }) => (
            <TextField
              disabled={!!(providerStatus === `EXISTING` && isAlreadyProvider)}
              InputLabelProps={{
                style: { backgroundColor: `white`, left: `-1.5%` },
              }}
              label="*State"
              select
              fullWidth
              size="small"
              error={invalid}
              helperText={error?.message}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
            >
              {stateNameOptions}
            </TextField>
          )}
        />
      </Grid>

      <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
        <Controller
          name={`providers[${index}].medicalLicense`}
          control={control}
          defaultValue={``}
          rules={{
            required: `*Required`,
          }}
          render={({ field: { onChange, value, onBlur }, fieldState: { invalid, error } }) => (
            <TextField
              disabled={!!(providerStatus === `EXISTING` && isAlreadyProvider)}
              InputLabelProps={{
                style: { backgroundColor: `white`, left: `-1.5%` },
              }}
              label="*License No."
              fullWidth
              size="small"
              error={invalid}
              helperText={error?.message}
              onChange={(e) => purifyInputValue({ event: e, onChange: onChange })}
              onBlur={onBlur}
              value={value}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};

export const RegisterProvider = ({
  onBlurValidForExRoleAdd,
  index,
  adminAdditionalRole,
  isGroupAdmin,
  field,
  removeIndexHandler,
  control,
  errors,
  append,
  setisLoading,
  isAAvailableProvider,
  isLoading,
  setValue,
  getValue,
  getValues,
  persistValues,
  setPersistValues,
  dateFormat,
  resetValues
}) => {
  const providersValues = getValues(`providers`);
  const renderCount = useRef(0)
  const setProviderStatus = (status) => {
    setValue(`providers[${index}].providerStatus`, status)
  }
  useEffect(() => {
    if (renderCount.current === 0 && !providersValues[index]?.providerStatus) {
      setProviderStatus(`SELECTING`)
      renderCount.current = renderCount.current + 1
    }
  }, [renderCount, index, providersValues])
  const emailRegex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;


  const setProviderEmail = (param, status) => {
    param = { ...param, providerStatus: status }
    setValuesFromArrRegPrac(setValue, [`phoneNumber`,
      `email`,
      `firstName`,
      `lastName`,
      `dateOfBirth`,
      `_id`,
      `providerStatus`,], param, index, true)
    let additionalValues = {
      ...(param?.licenseState ? { licenseState: param?.licenseState } : {}),
      ...(param?.medicalLicense ? { medicalLicense: param?.medicalLicense } : {}),
      ...(param?.providerType ? { providerType: param?.providerType } : {}),
      ...(param?.NPI ? { NPI: param?.NPI } : {}),
    };

    if (Object.keys(additionalValues)?.length) {
      setValue(`providers[${index}].additionalValues`, additionalValues);

      for (const key in additionalValues) {
        if (typeof additionalValues[key] === `number`) {
          additionalValues[key] = additionalValues[key].toString();
        }
        if (additionalValues[key]?.length) {
          setValue(`providers[${index}].${key}`, additionalValues[key]);
        }
      }
    } else {
      setValue(`providers[${index}].isNewProvider`, true);
    }
  };




  const appendInputs = (status, isAddition) => {
    if (isAddition) {
      append({
        firstName: ``,
        lastName: ``,
        phoneNumber: ``,
        email: ``,
        providerType: ``,
        licenseState: ``,
        NPI: ``,
        medicalLicense: ``,
        providerStatus: status
      });
    } else {
      setProviderStatus(status)
    }

  }
  let providerProps = {
    index: index,
    adminAdditionalRole: adminAdditionalRole,
    isGroupAdmin: isGroupAdmin,
    field: field,
    removeIndexHandler: removeIndexHandler,
    control: control,
    errors: errors,
    append: append,
    setisLoading: setisLoading,
    isAAvailableProvider: isAAvailableProvider,
    isLoading: isLoading,
    setValue: setValue,
    getValue: getValue,
    persistValues: persistValues,
    setPersistValues: setPersistValues,
    emailRegex: emailRegex,
    dateFormat: dateFormat,
    appendInputs: (status) => appendInputs(status, true)

  };
  const passCrossSign = () => {
    if (adminAdditionalRole === `provider`) {
      return {
        hasSign: true,
        action: () => removeIndexHandler(index)
      }
    }
    return index !== 0 ? {
      hasSign: true,
      action: () => removeIndexHandler(index)
    } : {}
  }
  let providerConfirmation = (
    <AskForConfirmation
      closeSignProps={{
        ...passCrossSign()
      }}
      index={index}
      initialValue={persistValues?.providers[index]}
      acceptElem={
        < EmailAndLastNameCheck
          hasSearchValidation={true}
          onblurEmail={onBlurValidForExRoleAdd}
          isMultiple={true}
          action={(param, status) => setProviderEmail(param, status)}
          succesElem={< Provider {...providerProps} />}
        />
      }
      rejectElem={< Provider {...providerProps} />}
      actionOnAccept={() => appendInputs(`SELECTING`, false)}
      actionOnReject={() => appendInputs(`NEW`, false)}
      confirmationProps={{
        containerStyles: { width: `100%`, mt: 0.5 },
        acceptText: `Existing`,
        rejectText: `New`,
        confirmationText: `Do you want to add a new user or an existing for Provider role?`,
      }}
      navigationProps={{
        action: () => {
          resetValues()
        },
        hasNavigation: true,
        iconBtnBlockStyles: { marginTop: `-13px`, marginLeft: `-14px` },
      }}
    />
  );

  return providerConfirmation;
};
