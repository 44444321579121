import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getRequest } from 'src/crud/crud';
import { Filters } from '../Filters/Filters';
import { IconButton, Fade, Box } from '@mui/material';
import { ToolTipProvider } from 'src/pages/common/components/Tooltip/ToolTipProvider';
import { momentWrapper } from 'src/momentWrapper';
import HomeIcon from '@mui/icons-material/Home';
import {
  capitalizeResmed,
  capitalizeRespironics,
  manufacturerForReact_Health,
  roundNumber,
  extraWordRemover,
  dynamicObjCreator,
  queryParams,
  patientOverViewMonthHandler,
} from 'src/utils';
import { errorToast } from 'src/pages/common/components/snackBar/toast';
import { store } from 'src/redux/store';
import { saveSerSuspenser } from 'src/redux/PatientOverview/Reports.actions';


export const useFilters = (pagination, setPagination) => {
  const { readyToSignModal: { actionTaken } } = useSelector((state) => state.readyToSign);
  const [data, setData] = useState([]);
  const [filterFlag, setFilterFlag] = useState(0);
  const [filtersObj, setFilters] = useState({
    search: ``,
    manufacturer: ``,
    tier: ``,
    reportStatus: ``,
    isAdherent: ``,
    status: ``,
  });
  const dispatch = useDispatch()
  const resetState = () => {
    setFilters({
      search: ``,
      manufacturer: ``,
      tier: ``,
      reportStatus: ``,
      isAdherent: ``,
      status: ``,
    })
    setFilterFlag(0)
    setData([])
    renderCount.current = 0
  }

  const getManufacturerLabel = (manufacturer) => {

    if (manufacturer === `respironics`) return capitalizeRespironics()
    if (manufacturer === `reactHealth`) return manufacturerForReact_Health()

    return capitalizeResmed();
  }

  const getTierLabel = (tier) => {

    if (tier === `VERY HIGH RISK`) return `Very High`
    if (tier === `NO DATA`) return `No Data`

    return extraWordRemover({ word: tier, capitalizeFirstLetter: true, isAllUpperCase: true });
  }

  const checkIsAdherent = (status) => {
    if (status === `Adherent`) return true
    if (status === `Non-Adherent`) return false

    return null
  }


  const getSortOrder = (order) => {
    if (order === 'ASC') return '1'
    if (order === 'DSC') return '-1'

    return ''
  }

  const dataMapHelper = async (response) => {
    let reports = response?.map((e) => {
      let preciseAHI = roundNumber({
        num: e?.AHI,
        roundTo: 1,
      });
      let preciseLeak = roundNumber({
        num: e?.leak,
        roundTo: 1,
      });
      let capitalizedManufacturer = getManufacturerLabel(e?.device?.manufacturer)
      let tier = getTierLabel(e?.tier)
      let mappedReports =
        e?.reports &&
        e?.reports?.map((elem) => {
          if (elem?.month) return { ...elem, month: patientOverViewMonthHandler(elem?.month) };
          return { ...elem };
        });

      return {
        ...e,
        reports: mappedReports,
        AHI: preciseAHI,
        leak: preciseLeak,
        deviceManufacturer: capitalizedManufacturer,
        tier: tier,
      };
    });
    return reports;
  };
  const mappedPromise = ({ hasReachedEnd, ...res }) => {
    setPagination((p) => ({ ...p, totalRows: res?.totalPatients ? res?.totalPatients : 0 }));
    if (Math.ceil(res?.totalPatients / res?.currentPageRecords)) {
      setPagination((p) => ({
        ...p,
        totalPage: res?.totalPages,
      }));
    }
    let mappedProm = new Promise((resolve, reject) => {
      let mappedData = dataMapHelper(res?.data);
      resolve(mappedData);
      reject(`error`);
    });
    mappedProm.then((res) => {
      setData({ data: res, hasReachedEnd });
    });
  };
  const renderCount = useRef(0)

  const { currentPage,
    rowsPerPage,
    sortOrder,
    sortField } = pagination

  const apiCall = useCallback(() => {

    if (actionTaken || renderCount.current === 0 || filterFlag || currentPage ||
      rowsPerPage ||
      sortOrder ||
      sortField) {
      renderCount.current = renderCount.current + 1
      const { readyToSign: { isOpenSwipe } } = store.getState()
      const updatesusPenseSer = (payLoad) => {
        if (isOpenSwipe) dispatch(saveSerSuspenser(payLoad))
      }
      let { isAdherent, tier } = filtersObj;
      let stringFilters = dynamicObjCreator(filtersObj);
      let filtersPayLoad = {
        ...stringFilters,
        ...(filtersObj?.tier?.length > 0 && { tier: tier === `NO_DATA` ? null : tier }),
        ...(isAdherent?.length > 0 && { isAdherent: checkIsAdherent(isAdherent) }),
      };
      let copPagination = {
        endDate: JSON.stringify(momentWrapper(new Date()).endOf(`day`).subtract(1, `days`).toISOString()),
        startDate: JSON.stringify(momentWrapper(new Date()).startOf(`day`).subtract(30, `days`).toISOString()),
        pageNumber: pagination?.currentPage,
        pageSize: pagination?.rowsPerPage,
        filters: { ...filtersPayLoad },
      };
      let sortingObject = {
        field: pagination?.sortField,
        sortOrder: getSortOrder(pagination?.sortOrder),
      };
      copPagination[`sorting`] = JSON.stringify(sortingObject);
      let query = queryParams(copPagination);
      if (!Object.keys(copPagination?.filters).length) {
        setFilters({
          search: ``,
          manufacturer: ``,
          tier: ``,
          reportStatus: ``,
          status: ``,
        });
      }
      if (
        (Object.keys(filtersPayLoad).length && document.getElementById(`navigateHome`).style.display === `none`) ||
        (pagination?.sortField !== `lastName` && document.getElementById(`navigateHome`).style.display === `none`)
      ) {
        document.getElementById(`navigateHome`).style.display = `inline-block`;
      }
      setData(`loading`);
      updatesusPenseSer({ loading: true, error: false })
      getRequest(`patients/overview${query}`)
        .then(({ data: { data = [{ reports: [] }], totalPages = currentPage, ...others }, } = { data: { data: [{ reports: [] }], totalPages: currentPage } }) => {
          if (isOpenSwipe) {
            const hasReports = data.filter((patData) => patData.reports.length)
            const hasReachedEnd = currentPage === totalPages || hasReports.length
            console.log({ hasReachedEnd, hasReports, currentPage }, `asczsadasdasdas`)
            if (hasReachedEnd) updatesusPenseSer({ loading: false, error: false })
          }
          mappedPromise({ ...others, data, totalPages, hasReachedEnd: currentPage === totalPages })
        })
        .catch(() => {
          setData(`error`)
          updatesusPenseSer({ loading: false, error: true })
        });
    }
  }, [renderCount,
    currentPage,
    rowsPerPage,
    sortOrder,
    sortField,
    filterFlag,
    actionTaken]);

  useEffect(() => {
    apiCall();
  }, [apiCall]);

  useEffect(() => {
    return () => resetState()
  }, [])

  const validate = () => {
    let check = Object.values(filtersObj);
    let isValid = check?.find((e) => e?.length > 0);
    if (isValid) {
      return true;
    } else {
      errorToast(`Please Enter at least one filter!`);
      return false;
    }
  };

  let filters = (
    <Filters filtersObj={filtersObj} setFilters={setFilters} validate={validate} setFilterFlag={setFilterFlag} setPagination={setPagination} />
  );
  let defaultButton = (
    <Fade in={filterFlag !== 0 || pagination?.sortField !== `lastName`} mountOnEnter unmountOnExit>
      <Box sx={{ display: `inline-block` }}>
        <ToolTipProvider
          toolTipProps={{
            title: `Restore Default Sort Settings`,
            placement: `top`,
            arrow: true,
          }}
          element={
            <IconButton
              id="navigateHome"
              onClick={() => {
                document.getElementById(`navigateHome`).style.display = `none`;
                if (Object.keys(filtersObj).length) {
                  setFilters({
                    search: ``,
                    manufacturer: ``,
                    tier: ``,
                    reportStatus: ``,
                    isAdherent: ``,
                    status: ``,
                  });
                }
                setFilterFlag((p) => p + 1);
                setPagination((p) => ({ ...p, currentPage: 1, rowsPerPage: 25, sortField: `lastName`, sortOrder: `ASC` }));
              }}
              sx={{ color: `rgb(22, 153, 197)` }}
            >
              <HomeIcon />
            </IconButton>
          }
        />
      </Box>
    </Fade>
  );

  return { poData: data, filters, defaultButton };
};
