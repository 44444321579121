import React, { useState, useEffect } from 'react';
import { Box, Collapse, Fade, IconButton } from '@mui/material';
import { TableFooter } from 'src/pages/common/hooks/TableFooter';
import css from './PatientOver.module.css';
import SearchIcon from '@mui/icons-material/Search';
import { useFilters } from './UseFilters/useFilters';
import { TableRows, reportsDispatcher, resetRedStateonCloseRep } from './TableRows/TableRows';
import useMediaQuery from '@mui/material/useMediaQuery';
import { PuffSpinner } from 'src/pages/common/components/spinner/puff/puff';
import { ReportModal } from './ReportModal/ReportModal';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import { useDispatch, useSelector } from 'react-redux';
import GppMaybeIcon from '@mui/icons-material/GppMaybe';
import { ToolTipProvider } from 'src/pages/common/components/Tooltip/ToolTipProvider';
import { useHistory } from 'react-router-dom';
import { momentWrapper } from 'src/momentWrapper';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import { MuiBtn } from 'src/pages/common/components/Button/CustomBtn';
import { PATIENT_OVERVIEW_CSV, getRequest } from 'src/crud/crud';
import {
  emptyLastClosedRep,
  emptySeriesData,
  saveLastClosedRep,
  triggerSwipe,
  emptyClickedReps,
  saveRepSeriesData,
  updatePatOverDataStatus,
} from 'src/redux/PatientOverview/Reports.actions';
import {
  adherentGiver,
  capitalizeFirstLetter,
  convertStoHm,
  extraWordRemover,
  makeTruncate,
  pressureDecider,
  renderDeviceName,
  returnNull,
  roundNumber,
  timeFormatCallBack,
  patientOverviewDownloadCsv,
} from 'src/utils';
import { MuiSpinner } from 'src/pages/common/components/MuiSpinner/MuiSpinner';
import DownloadIcon from '@mui/icons-material/Download';
import { errorToast } from 'src/pages/common/components/snackBar/toast';

export const sortIconHandler = (pagination, field) => {
  return (
    <>
      {pagination?.sortField === field && pagination?.sortOrder === `ASC` && <i className="fa fa-sort-asc ml-2" />}
      {pagination?.sortField === field && pagination?.sortOrder === `DSC` && <i className="fa fa-sort-desc ml-2" />}
      {pagination?.sortOrder === `` || pagination?.sortField !== field ? <i className="fa fa-sort ml-2" /> : null}
    </>
  );
};

export const sortKeyGiver = (pagination, key) => {
  let { sortOrder: order, sortField: field } = pagination;
  let giver = ``;
  if ((order === `ASC` && key === field) || (order === `ASC` && key !== field)) {
    giver = `DSC`;
  } else if (order === `DSC` && key === field) {
    giver = `ASC`;
  } else if ((order === `DSC` && key === field) || (order === `DSC` && key !== field)) {
    giver = `DSC`;
  }
  return giver;
};

const RenderIcon = ({ loadingState, retIcon: RetIcon }) => {
  const { loading, error } = loadingState
  if (error) return <GppMaybeIcon sx={{ color: `white` }} />;
  else if (loading) return <MuiSpinner size={15} thickness={15} sx={{ color: `white` }} />;
  return <RetIcon />;
};

export const PatientOverView = () => {
  const [pagination, setPagination] = useState({
    currentPage: 1,
    rowsPerPage: 25,
    totalRows: 0,
    totalPage: 0,
    sortOrder: `ASC`,
    sortField: `lastName`,
  });

  const [openFilters, setopenFilters] = useState(false);
  const [patientOverViewData, setpatientOverViewData] = useState([]);
  const [fetchingcsvData, setfetchingcsvData] = useState({ loading: false, error: false });
  const { loading: loadingCsv, error: errorCsv } = fetchingcsvData;
  const {
    readyToSignModal: { open = false },
    isOpenSwipe,
    swipeAbleSeries,
    swipeSuspense: { loading: repLoading, error: repError }
  } = useSelector((state) => state.readyToSign);
  const dispatch = useDispatch();
  const { poData, filters, defaultButton } = useFilters(pagination, setPagination);
  const { data = [] } = poData


  const forwardPage = () => setPagination((p) => ({ ...p, currentPage: p.currentPage + 1 }))

  useEffect(() => {
    const { hasReachedEnd = false } = poData
    if (Array.isArray(data) && data?.length) {
      let reports = data.filter(({ reports = [] }) => reports.length) || []
      if (reports.length) {
        reports = reports.reduce((acc, { phoneNumber: patientNumber, _id: _patientId = ``, reports, firstName, lastName }) => {
          const currentArr = [...reports.map(({ month = ``, _id: _reportId = ``, startDate = ``, endDate = `` }) => {
            return { patientNumber, month, _reportId, _patientId, startDate, endDate, firstName, lastName }
          })]
          return [...acc, ...currentArr]
        }, [])
      }
      if ((isOpenSwipe) && (reports?.length === 0)) {
        if (!hasReachedEnd) forwardPage()
        else if (hasReachedEnd && reports.length === 0) {
          resetRedStateonCloseRep()
          errorToast(`No reports to sign.`)
        }
      }
      dispatch(saveRepSeriesData(reports))
      dispatch(saveLastClosedRep({}));
      setpatientOverViewData(data);
    } else {
      dispatch(saveRepSeriesData([]));
      setpatientOverViewData([]);
    }

    dispatch(updatePatOverDataStatus(hasReachedEnd))
  }, [poData, isOpenSwipe]);

  useEffect(() => {
    return () => {
      dispatch(emptySeriesData());
      dispatch(emptyLastClosedRep());
      dispatch(emptyClickedReps());
    };
  }, []);

  const matches = useMediaQuery(`(max-width:768px)`);
  const matchesMedium = useMediaQuery(`(max-width:1050px)`);
  const matchesLarge = useMediaQuery(`(max-width:1460px)`);
  const history = useHistory();
  const sortHandler = (key, order) => {
    setPagination((p) => ({
      ...p,
      sortField: key,
      sortOrder: order,
    }));
  };
  const moveToPatientModule = (patientId) => {
    history.push({ pathname: `/patient`, patientId });
  };
  let dates = {
    endDate: momentWrapper(new Date()).endOf(`day`).subtract(1, `days`),
    startDate: momentWrapper(new Date()).startOf(`day`).subtract(30, `days`),
  };
  const { endDate, startDate } = dates;
  const fetchingcsvDataHandler = (key, value, isWhole) => {
    setfetchingcsvData(isWhole ? value : (p) => ({ ...p, [key]: value }));
  };


  let tableWidth;
  if (matches || matchesMedium) {
    tableWidth = "200%";
  } else if (matchesLarge) {
    tableWidth = "150%";
  } else {
    tableWidth = "100%";
  }

  return (
    <div className="p-3">
      {open && <ReportModal forwardPage={forwardPage} />}
      <div className={`${css.table} card`}>
        <Box>
          <Collapse in={openFilters} mountOnEnter unmountOnExit>
            {filters}
          </Collapse>
        </Box>
        <Box sx={{ p: 1, width: `100%`, display: `grid`, gridTemplateColumns: `repeat(auto-fit, minmax(100px, max-content))`, justifyContent: `space-between`, alignItems: `center` }}>
          <Box sx={{ width: `100%` }}>
            <ToolTipProvider
              toolTipProps={{
                title: loadingCsv ? `Wait we are preparing the file for you!` : `Download data in Csv File!`,
                arrow: true,
                placement: `top`,
              }}
              element={
                <MuiBtn
                  btnProps={{
                    sx: {
                      backgroundColor: errorCsv ? `red` : `#1699c5`,
                      textTransform: `none`,
                      minWidth: `max-content`,
                    },
                    onClick: () => {
                      if (!loadingCsv) {
                        if (errorCsv) fetchingcsvDataHandler(`error`, false, false);
                        fetchingcsvDataHandler(`loading`, true, false);
                        const endDate = momentWrapper(new Date()).endOf(`day`).subtract(1, `days`).toISOString();
                        const startDate = momentWrapper(new Date()).startOf(`day`).subtract(30, `days`).toISOString();
                        getRequest(`${PATIENT_OVERVIEW_CSV}?startDate=${JSON.stringify(startDate)}&&endDate=${JSON.stringify(endDate)}`)
                          .then((res) => {
                            const { data: rootData } = res;
                            const { data = [] } = rootData;
                            const mapValuesAndDownload = () => {
                              if (data?.length) {
                                let prepareData = [
                                  ...data.map((report) => {
                                    let AHI = roundNumber({
                                      num: report?.AHI,
                                      roundTo: 1,
                                    });
                                    let leak = roundNumber({
                                      num: report?.leak,
                                      roundTo: 1,
                                    });
                                    const tier = () => {
                                      if (report?.tier === `VERY HIGH RISK`) return `Very High`;
                                      else if (report?.tier === `NO DATA`) return `No Data`;
                                      return extraWordRemover({ word: report?.tier, capitalizeFirstLetter: true, isAllUpperCase: true });
                                    };
                                    return {
                                      status: capitalizeFirstLetter(report?.status?.current),
                                      lastInactivatedAt:
                                        report?.status?.lastDeactivatedAt && report?.status?.current === `inactive`
                                          ? momentWrapper(report?.status?.lastDeactivatedAt).format(`MM-DD-YYYY`)
                                          : `-`,
                                      reports: report?.reports ? report?.reports?.length : `-`,
                                      tier: tier(),
                                      AHI,
                                      leak,
                                      firstName: capitalizeFirstLetter(makeTruncate({ maxLength: 22, str: report?.firstName })),
                                      lastName: capitalizeFirstLetter(makeTruncate({ maxLength: 22, str: report?.lastName })),
                                      device: { ...report?.device, manufacturer: renderDeviceName(report?.device?.manufacturer) },
                                      isAdherent: adherentGiver(report?.isAdherent, true),
                                      pressure: pressureDecider(report?.pressure, report?.device?.manufacturer),
                                      daysDeviceUsedGT4: returnNull({
                                        key: report?.daysDeviceUsedGT4,
                                        successRetrun: `${report?.daysDeviceUsedGT4}/${report?.TimedaysDeviceUsedGT4?.days} days`,
                                        faliure: `-`,
                                      }),
                                      daysDeviceUsedInsec: returnNull({
                                        key: report?.daysDeviceUsedInsec,
                                        successRetrun: convertStoHm(report?.daysDeviceUsedInsec, true, 30, timeFormatCallBack),
                                        faliure: `-`,
                                      }),
                                    };
                                  }),
                                ];
                                patientOverviewDownloadCsv(
                                  prepareData,
                                  `${momentWrapper(startDate).format('MM/DD/YYYY') + ' - ' + momentWrapper(endDate).format('MM/DD/YYYY')}.csv`
                                );
                              }
                            };
                            mapValuesAndDownload();
                            fetchingcsvDataHandler(`loading`, false, false);
                          })
                          .catch(() => {
                            fetchingcsvDataHandler(`loading`, { loading: false, error: true }, true);
                          });
                      }
                    },
                  }}
                  exctProps={{
                    variant: `contained`,
                    size: `large`,
                    endIcon: <RenderIcon loadingState={{ loading: loadingCsv, error: errorCsv }} retIcon={DownloadIcon} />,
                  }}
                >
                  Review Period: {momentWrapper(startDate).format(`MM/DD/YYYY`) + ` - ` + momentWrapper(endDate).format(`MM/DD/YYYY`)}
                </MuiBtn>
              }
            />


            <MuiBtn
              exctProps={{
                size: `large`,
                variant: `contained`,
                fullWidth: true,
                disabled: !Array.isArray(data),
                endIcon: <RenderIcon retIcon={ImportContactsIcon} loadingState={{ loading: repLoading, error: repError }} />,
                onClick: () => {
                  dispatch(triggerSwipe());
                  reportsDispatcher({
                    patientId: swipeAbleSeries?.[0]?._patientId,
                    reportId: swipeAbleSeries?.[0]?._reportId, open: true,
                    startDate: swipeAbleSeries?.[0]?.startDate,
                    endDate: swipeAbleSeries?.[0]?.endDate,
                    patientNumber: swipeAbleSeries?.[0]?.patientNumber

                  }, swipeAbleSeries?.[0]?.month)
                },
              }}
              btnProps={{
                styles: {
                  width: `200px`,
                  minWidth: `max-content`,
                  marginLeft: 0,
                  marginTop: `10px`,
                  '@media (min-width: 1229px)': {
                    marginTop: 0,
                    marginLeft: `10px`,
                  },
                },
              }}
            >
              Auto-open reports
            </MuiBtn>

          </Box>
          <Box sx={{ display: `inline-block`, p: 1.2, width: `100%`, textAlign: `end` }}>
            Reporting Period:
            <Box
              sx={{
                padding: `8px 14px`,
                marginLeft: `5px`,
                backgroundColor: `white`,
                display: `inline-block`,
                borderRadius: `7px`,
                border: `1px solid #2299c5`,
              }}
            >
              {momentWrapper(startDate).format(`MM/DD/YYYY`) + ` - ` + momentWrapper(endDate).format(`MM/DD/YYYY`)}
            </Box>
            <Box sx={{ display: `inline-block`, alignSelf: `center` }}>{defaultButton}</Box>
            <Fade in={!openFilters || openFilters} mountOnEnter unmountOnExit>
              <Box sx={{ display: `inline-block`, alignSelf: `center` }}>
                <ToolTipProvider
                  toolTipProps={{
                    title: `${openFilters ? 'Close' : 'Search'}`,
                    placement: `top`,
                    arrow: true,
                  }}
                  element={
                    <IconButton onClick={() => setopenFilters((p) => !p)}>
                      {openFilters ? <CloseSharpIcon sx={{ color: `red` }} /> : <SearchIcon sx={{ color: `rgb(22, 153, 197)` }} />}
                    </IconButton>
                  }
                />
              </Box>
            </Fade>
          </Box>
        </Box>
        <div
          className="table-responsive"
          style={{ width: `${tableWidth} !important` }}>
          <table
            style={{ width: tableWidth }}
            className={`table  table-lg table-borderless table-striped ${css.tableInner}`}
          >
            <thead>
              <tr style={{ textAlign: `center`, verticalAlign: `middle` }}>
                <td className={css.tdLess} onClick={() => sortHandler(`lastName`, sortKeyGiver(pagination, `lastName`))}>
                  Last Name
                  {sortIconHandler(pagination, `lastName`)}
                </td>
                <td className={css.tdLess} onClick={() => sortHandler(`firstName`, sortKeyGiver(pagination, `firstName`))}>
                  First Name
                  {sortIconHandler(pagination, `firstName`)}
                </td>
                <td className={css.tdLess} onClick={() => sortHandler(`manufacturer`, sortKeyGiver(pagination, `manufacturer`))}>
                  Manufacturer
                  {sortIconHandler(pagination, `manufacturer`)}
                </td>
                <td className={css.tdLess} onClick={() => sortHandler(`detail`, sortKeyGiver(pagination, `detail`))}>
                  Device Type
                  {sortIconHandler(pagination, `detail`)}
                </td>
                <td className={css.tdLess} onClick={() => sortHandler(`tier`, sortKeyGiver(pagination, `tier`))}>
                  Risk
                  {sortIconHandler(pagination, `tier`)}
                </td>
                <td className={css.tdLess} onClick={() => sortHandler(`isAdherent`, sortKeyGiver(pagination, `isAdherent`))}>
                  Adherence
                  {sortIconHandler(pagination, `isAdherent`)}
                </td>
                <td className={css.td} onClick={() => sortHandler(`daysDeviceUsedGT4`, sortKeyGiver(pagination, `daysDeviceUsedGT4`))}>
                  Days Used {`>`} 4h
                  {sortIconHandler(pagination, `daysDeviceUsedGT4`)}
                </td>
                <td className={css.tdModerate} onClick={() => sortHandler(`daysDeviceUsedInsec`, sortKeyGiver(pagination, `daysDeviceUsedInsec`))}>
                  Avg Use (total days)
                  {sortIconHandler(pagination, `daysDeviceUsedInsec`)}
                </td>
                <td className={css.tdModerate} onClick={() => sortHandler(`helperPressure`, sortKeyGiver(pagination, `helperPressure`))}>
                  Pressure 90th/95th %{sortIconHandler(pagination, `helperPressure`)}
                </td>
                <td className={css.tdLess} onClick={() => sortHandler(`AHI`, sortKeyGiver(pagination, `AHI`))}>
                  AHI
                  {sortIconHandler(pagination, `AHI`)}
                </td>
                <td className={css.tdLess} onClick={() => sortHandler(`leak`, sortKeyGiver(pagination, `leak`))}>
                  Leak
                  {sortIconHandler(pagination, `leak`)}
                </td>
                <td className={css.td} onClick={() => sortHandler(`timeMonitored`, sortKeyGiver(pagination, `timeMonitored`))}>
                  RPM Time
                  {sortIconHandler(pagination, `timeMonitored`)}
                </td>
                <td className={css.td} onClick={() => sortHandler(`interactiveCommunication`, sortKeyGiver(pagination, `interactiveCommunication`))}>
                  Communication
                  {sortIconHandler(pagination, `interactiveCommunication`)}
                </td>
                <td className={css.td} onClick={() => sortHandler(`reportStatus`, sortKeyGiver(pagination, `reportStatus`))}>
                  Sign Report
                  {sortIconHandler(pagination, `reportStatus`)}
                </td>
              </tr>
            </thead>
            <tbody>
              {poData === `loading` && <PuffSpinner />}

              {poData === `error` && (
                <tr>
                  <td colSpan={14} className={css.fullWidth}>
                    <GppMaybeIcon sx={{ color: `red` }} />
                  </td>
                </tr>
              )}

              {patientOverViewData?.length > 0 &&
                patientOverViewData?.map((patientOverViewMappingData, index) => {
                  return (
                    <TableRows
                      key={patientOverViewMappingData?._id}
                      patientOverViewMappingData={patientOverViewMappingData}
                      index={index}
                      moveToPatientModule={() => moveToPatientModule(patientOverViewMappingData?._id)}
                    />
                  );
                })}

              {Array.isArray(data) && data?.length === 0 && poData !== `loading` &&
                poData !== `error` && (
                  <tr>
                    <td className={css.fullWidth} colSpan={14}>
                      No Records Found
                    </td>
                    {` `}
                  </tr>
                )}
            </tbody>
            <TableFooter totalRows={pagination.totalRows} data={data} pagination={pagination} setPagination={setPagination} isPatientView={true} />
          </table>
        </div>
      </div >
    </div >
  );
};
