import React, { useState, useEffect } from 'react';
import { postRequest, TELEMEDICINE_TOTAL_TIME } from '../../../../../../../crud/crud';
import { Bar } from 'react-chartjs-2';
import 'chartjs-plugin-annotation';
import { useSelector } from 'react-redux';
import { Box, Grid, Zoom } from '@mui/material';
import { DatePickerCustomize } from 'src/pages/common/components/datePicker/DatePickerCustomize';
import { Filterbutton } from 'src/pages/home/modules/settings/Analytics/Components/Filterbutton';
import { fromDateChangeHandler, pickerPropsHandler, toDateChangeHandler } from 'src/pages/home/modules/settings/Analytics/utilities';
import { analyticsUrlHandler, doIfArr, startDateAnalytics } from 'src/utils';
import { momentWrapper } from 'src/momentWrapper';
import moment from 'moment';

export const TotalTelemedicineTime = ({ clicked }) => {
  const [loading, setLoading] = useState({ loading: false, error: false });
  const [fromDate, setFromDate] = useState(startDateAnalytics());
  const [toDate, setToDate] = useState(moment(new Date()).endOf(`month`));
  const [filterBtn, setFilterBtn] = useState(false);
  const [graphLabels, setGraphLabels] = useState([]);
  const [audioCallData, setaudioCallData] = useState([]);
  const [videoCallData, setvideoCallData] = useState([]);
  const [showMsg, setErrorMsg] = useState(false);
  const view = useSelector((state) => state?.analytics?.view);
  useEffect(() => {
    apiCall(view);
  }, [view]);
  const apiCall = (view) => {
    let labelsTempArray = [];
    let audioCallData = [];
    let videoCallData = [];

    setLoading({ loading: true, error: false });
    setFilterBtn(true);
    setGraphLabels([]);
    let filters = analyticsUrlHandler(view);
    postRequest(TELEMEDICINE_TOTAL_TIME + `?filters=${filters}`, {
      startDate: momentWrapper(fromDate).startOf(`month`).toISOString(),
      endDate: momentWrapper(toDate).endOf(`month`).toISOString(),
    })
      .then((res) => {
        setLoading({ loading: false, error: false });
        setFilterBtn(false);

        doIfArr(res?.data?.monitoringAnalytics, () => {
          if (res.data.monitoringAnalytics) {
            for (const data of res.data.monitoringAnalytics) {
              labelsTempArray.push(data.month);
              let { audioCall, videoCall } = data;
              let audioCallMinutes = audioCall.split(` `)[0];
              let videoCallminutes = videoCall.split(` `)[0];
              let audioCallSeconds = audioCall.split(` `)[2];
              let videoCallSeconds = videoCall.split(` `)[2];
              let mergedAudio = audioCallMinutes + `.` + audioCallSeconds;
              let mergedVideo = videoCallminutes + `.` + videoCallSeconds;

              audioCallData.push(mergedAudio);
              videoCallData.push(mergedVideo);
            }
          }
        });

        setGraphLabels([...labelsTempArray]);
        setaudioCallData([...audioCallData]);
        setvideoCallData([...videoCallData]);
      })
      .catch(() => {
        setLoading({ loading: false, error: true });
        setFilterBtn(false);
      });
  };

  const getValueForStepSize = () => {
    let findAudio = audioCallData?.find((e) => {
      let integer = parseInt(e);
      return integer < 1;
    });
    let findVideo = videoCallData?.find((e) => {
      let integer = parseInt(e);
      return integer < 1;
    });

    let highestIndexAudio = audioCallData.reduce(function (p, v) {
      let parsedPre = parseInt(p);
      let parsedValue = parseInt(v);
      return parsedPre > parsedValue ? parsedPre : parsedValue;
    }, 0);
    let highestIndexVideo = videoCallData.reduce(function (p, v) {
      let parsedPre = parseInt(p);
      let parsedValue = parseInt(v);
      return parsedPre > parsedValue ? parsedPre : parsedValue;
    }, 0);
    let stepSize;
    if (findVideo && !findAudio && highestIndexAudio) {
      if (highestIndexAudio < 50) {
        stepSize = 0.5;
      } else {
        stepSize = 5;
      }
    } else if (!findVideo && findAudio && highestIndexVideo) {
      if (highestIndexVideo < 50) {
        stepSize = 0.5;
      } else {
        stepSize = 5;
      }
    } else if (findVideo && findAudio) {
      stepSize = 0.5;
    }
    return stepSize;
  };
  const stepSize = getValueForStepSize();

  return (
    <Zoom in={clicked} timeout={500} unmountOnExit>
      <Box
        sx={{
          backgroundColor: `white`,
          boxShadow: `0 0 10px 0 rgba(0, 0, 0, 0.1), 0 0 16px 0 rgba(0, 0, 0, 0.05) `,
          borderRadius: `8px`,
        }}
      >
        <h4 className="px-3 pt-3 pb-2 mb-0 mb-0" style={{ fontWeight: 400 }}>
          Total telemedicine time:
        </h4>
        {showMsg ? (
          <span className="ml-3" style={{ color: `red`, fontSize: `12px` }}>
            * Invalid date selection
          </span>
        ) : null}
        <Grid sx={{ p: 2 }} container rowSpacing={1} columnSpacing={1}>
          <Grid item xs={5.5} sm={4} md={5.5} lg={4} xl={4}>
            {` `}

            <DatePickerCustomize
              pickerProps={{
                ...pickerPropsHandler(`From`),
              }}
              dateInputValue={fromDate}
              onChange={(e) => fromDateChangeHandler(e, setFilterBtn, setErrorMsg, setFromDate, toDate)}
            />
          </Grid>
          <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
            <Box sx={{ p: 0.7 }}>-</Box>
          </Grid>
          <Grid item xs={5.5} sm={4} md={5.5} lg={4} xl={4}>
            {` `}

            <DatePickerCustomize
              pickerProps={{
                ...pickerPropsHandler(`To`),
              }}
              dateInputValue={toDate}
              maxDate={true}
              onChange={(e) => toDateChangeHandler(e, setFilterBtn, setErrorMsg, fromDate, setToDate)}
            />
          </Grid>
          <Grid item xs={12} sm={3} md={12} lg={3} xl={3}>
            <Filterbutton
              arg={{ payLoad: {}, setData: null, setLoading: null }}
              apiCall={() => apiCall(view)}
              filterBtn={filterBtn}
              loading={loading}
              ownApi={true}
            />
          </Grid>
        </Grid>
        <Box sx={{ m: 3, height: 340 }}>
          <Bar
            data={{
              // labels: graphLabels,
              labels: graphLabels,
              datasets: [
                {
                  label: `Audio call time (Minutes)`,

                  backgroundColor: `#17a2b8`,

                  data: audioCallData.map((e) => {
                    return e;
                  }),
                },
                {
                  label: `Video call time (Minutes)`,

                  backgroundColor: `#95afc0`,

                  data: videoCallData.map((e) => {
                    return e;
                  }),
                },
              ],
            }}
            width={100}
            height={70}
            options={{
              tooltips: {
                callbacks: {
                  label: function (tooltipItem) {
                    let label = ``;
                    let min = tooltipItem.value.split(`.`)[0];
                    let sec = tooltipItem.value.split(`.`)[1];
                    label += min + ` min ` + (sec || 0) + ` sec`;
                    return label;
                  },
                },
              },
              legend: {
                position: `top`,
                labels: {
                  usePointStyle: true,
                  boxWidth: 6,
                },
              },
              responsive: true,
              maintainAspectRatio: false,
              scales: {
                xAxes: [
                  {
                    stacked: true,
                    gridLines: {
                      display: false,
                    },
                    scaleLabel: {
                      display: true,
                    },
                  },
                ],
                yAxes: [
                  {
                    stacked: true,
                    ticks: {
                      beginAtZero: true,
                      stepSize: stepSize,
                    },

                    scaleLabel: {
                      display: true,
                      labelString: `Total Telemedicine Time (Minutes)`,
                    },
                    type: `linear`,
                  },
                ],
              },
            }}
          />
        </Box>
      </Box>
    </Zoom>
  );
};
