import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import css from '../addNewUser.module.css';
import { ADD_NEW_ROLE, CHECK_EMAIL_USERNAME_AVAILABILITY_URL, CREATE_NEW_USER_URL, postRequest } from 'src/crud/crud';
import { PulseSpinner } from '../../../../../../../common/components/spinner/spinner';
import { successToast, errorToast } from 'src/pages/common/components/snackBar/toast';
import { DatePickerCustomize } from 'src/pages/common/components/datePicker/DatePickerCustomize';
import { ToastContainer } from 'react-toastify';
import { addNewUserMountFlag } from 'src/redux/resmed/resmedActions';
import { InputField } from 'src/pages/common/components/InputField/InputField';
import {
  capitalizeFirstLetter,
  disableAdditionalInfoInput,
  dynamicObjCreator,
  extractExactRoles,
  faxNumberMask,
  faxNumberValidation,
  npiMask,
  phoneMask,
  providerType,
  requiredTag,
  runDefalutValidationAssignRole,
  usStateName,
  wrapWithAsyncSimple,
} from 'src/utils';
import { saveUserInforRegistration } from 'src/redux/action/setUserHandler';
import { Box, Fade, Collapse, Grid } from '@mui/material';
import moment from 'moment';
import { MuiCheckBox } from 'src/pages/common/components/MuiCheckBox/MuiCheckBox';
import { MuiSelect } from 'src/pages/common/components/MuiSelect/MuiSelect';
import { emailRegex, npiRegex, phoneRegex } from 'src/regexes';

export const AddNewUser = ({ setAddUserModal, isNew }) => {
  const [firstName, setFirstName] = useState(``);
  const [firstNameError, setFirstNameError] = useState({
    status: false,
    msg: ``,
  });
  const [values, setValues] = useState({
    isSuperAdmin: false,
    isProvider: false,
    isClinicalStaff: false,
    isBiller: false,
    type: ``,
    npi: ``,
    license: ``,
    licenseState: ``,
  });
  const [errors, setErrors] = useState({
    roleError: { status: false, msg: `` },
    typeError: { status: false, msg: `` },
    npiError: { status: false, msg: `` },
    licenseError: { status: false, msg: `` },
    licenseStateError: { status: false, msg: `` },
  });
  const [lastName, setLastName] = useState(``);
  const [lastNameError, setLastNameError] = useState({
    status: false,
    msg: ``,
  });
  const [dateOfBirth, setDateOfBirth] = useState(null);
  const [phone, setPhone] = useState(``);
  const [phoneExtension, setphoneExtension] = useState(``);
  const [phoneError, setPhoneError] = useState({ status: false, msg: `` });
  const [email, setEmail] = useState(``);
  const [emailError, setEmailError] = useState({ status: false, msg: `` });
  const [fax, setFax] = useState(``);
  const [title, setTitle] = useState(``);
  const [runValidation, setrunValidation] = useState(false);
  const [loading, setLoading] = useState(false);
  const asignRoleValues = useRef(null);
  const user = useSelector((state) => state.user);
  const existing = useSelector((state) => state.user?.existingUser);
  const dispatch = useDispatch();
  const hasAlreadyUser = useMemo(() => {
    return !!((existing?.additionalValues && Object.keys(existing?.additionalValues)?.length) || values?.isBiller);
  }, [existing]);
  const emptyExistingUser = () => {
    if (existing) {
      dispatch(saveUserInforRegistration(``));
    }
  };

  const selectedPractice = useSelector((state) => state?.practice?.practiceInfo);
  const groupId = useSelector((state) => state?.user?.user?._practiceGroupId);
  const _practiceGroupId = useSelector((state) => state?.practiceGroup?.groupId);
  let practice = user?.user?._practiceId;
  const specialHandler = useCallback(
    (e) => {
      if (existing) {
        dispatch(saveUserInforRegistration(``));
      }
    },
    [dispatch, existing]
  );
  const errorHandler = (key, value) => {
    setErrors((p) => ({ ...p, [key]: value }));
  };
  const containerRef = useRef(null);
  useEffect(() => {
    window.addEventListener(`beforeunload`, specialHandler);
    if (existing) {
      setFirstName(capitalizeFirstLetter(existing?.firstName));
      setLastName(capitalizeFirstLetter(existing?.lastName));
      setPhone(existing?.phoneNumber);
      setEmail(existing?.email);
      if (existing?.faxNumber) {
        setFax(existing?.faxNumber);
      }

      if (existing?.title) {
        setTitle(existing?.title);
      }
      if (existing?.dateOfBirth) {
        setDateOfBirth(existing?.dateOfBirth);
      }
      if (existing?.ext) {
        setphoneExtension(existing?.ext);
      }
    }
    return () => {
      window.removeEventListener(`beforeunload`, specialHandler);
    };
  }, [existing, specialHandler]);

  const runValidationForRole = async (values, errorHandler) => {
    return runDefalutValidationAssignRole(values, errorHandler, npiRegex, runValidation, setrunValidation, asignRoleValues);
  };

  const defaultValidator = (value, temp, setError, key) => {
    const resetTemp = { status: false, msg: `` }
    if (key === `firstName`) {
      setrunValidation(true);
    }
    if (key === `phone`) {
      if (!value) {
        temp.status = true;
        temp.msg = `*Required`;
        setError(temp);
        return false;
      } else if (!phoneRegex.test(phone)) {
        temp.status = true;
        temp.msg = `Invalid Phone No.`;
        setError(temp);
        return false;
      }
      setError(resetTemp);
      return true;
    } else if (key === `email`) {
      if (!email) {
        temp.status = true;
        temp.msg = `*Required`;
        setError(temp);
        return false;
      } else if (!emailRegex.test(email)) {
        temp.status = true;
        temp.msg = `Invalid Email Address`;
        setError(temp);
        return false;
      }
      return true;
    } else if (!value) {
      temp.status = true;
      temp.msg = `*Required`;
      setError(temp);
      return false;
    }
    return true;

  };

  const setExistingProviderClinicallStaff = () => {
    if (existing?.additionalValues && Object.keys(existing?.additionalValues)?.length) {
      if (existing?.additionalValues?.NPI) {
        onChangeHandler(`npi`, existing?.additionalValues?.NPI);
      }
      if (existing?.additionalValues?.licenseState) {
        onChangeHandler(`licenseState`, existing?.additionalValues?.licenseState);
      }
      if (existing?.additionalValues?.medicalLicense) {
        onChangeHandler(`license`, existing?.additionalValues?.medicalLicense);
      }
      if (existing?.additionalValues?.providerType) {
        onChangeHandler(`type`, existing?.additionalValues?.providerType);
      }
    }
  };

  const validateInput = async (field) => {
    let temp = { status: false, msg: `` };
    switch (field) {
      case `firstName`:
        if (!firstName) {
          temp.status = true;
          temp.msg = `*Required`;
          setFirstNameError(temp);
        } else {
          setFirstNameError(temp);
        }
        break;
      case `lastName`:
        if (!lastName) {
          temp.status = true;
          temp.msg = `*Required`;
          setLastNameError(temp);
        } else {
          setLastNameError(temp);
        }
        break;
      case `phone`:
        if (!phone?.length) {
          temp.status = true;
          temp.msg = `*Required`;
          setPhoneError(temp);
        }
        if (phone?.length > 0 && !phoneRegex.test(phone)) {
          temp.status = true;
          temp.msg = `Invalid Phone No.`;
          setPhoneError(temp);
        } else {
          setPhoneError(temp);
        }
        break;
      case `email`:
        if (!email) {
          temp.status = true;
          temp.msg = `*Required`;
          setEmailError(temp);
        } else if (!emailRegex.test(email)) {
          temp.status = true;
          temp.msg = `Invalid Email Address`;
          setEmailError(temp);
        } else {
          setEmailError(temp);
          isAvailable(email, `email`);
        }
        break;
      case `role`:
        if (!values?.isSuperAdmin && !values?.isProvider && !values?.isClinicalStaff && !values?.isBiller) {
          temp.status = true;
          temp.msg = `*Required`;
          errorHandler(`roleError`, temp);
        } else {
          errorHandler(`roleError`, temp);
        }
        break;
      case `type`:
        if (values?.isProvider && !values?.type) {
          temp.status = true;
          temp.msg = `*Required`;
          errorHandler(`typeError`, temp);
        } else {
          errorHandler(`typeError`, temp);
        }
        break;
      case `npi`:
        if (values?.isProvider && !values?.npi) {
          temp.status = true;
          temp.msg = `*Required`;
          errorHandler(`npiError`, temp);
        } else if (values?.npi.length > 0 && values?.npi.length < 10) {
          temp.status = true;
          temp.msg = `Invalid NPI`;
          errorHandler(`npiError`, temp);
        } else {
          errorHandler(`npiError`, temp);
        }
        break;
      case `license`:
        if (values?.isProvider && !values?.license) {
          temp.status = true;
          temp.msg = `*Required`;

          errorHandler(`licenseError`, temp);
        } else {
          errorHandler(`licenseError`, temp);
        }
        break;
      case `state`:
        if (values?.isProvider && !values?.licenseState) {
          temp.status = true;
          temp.msg = `*Required`;

          errorHandler(`licenseStateError`, temp);
        } else {
          errorHandler(`licenseStateError`, temp);
        }
        break;
      default:
        if (fax) {
          let hasError = !faxNumberValidation(fax);
          if (hasError) {
            temp.status = true;
            temp.msg = `Invalid Fax Number`;
          }
        }

        return await defaultRunner(temp);
    }
  };
  const defaultRunner = async (temp) => {
    console.log(temp, `asdffsdfdsfgdfg`)
    let validateFirstName = defaultValidator(firstName, temp, setFirstNameError, `firstName`);
    let validateLastName = defaultValidator(lastName, temp, setLastNameError, false);
    let validatePhone = defaultValidator(phone, temp, setPhoneError, `phone`);
    let validateEmail = defaultValidator(email, temp, setEmailError, `email`);
    let defaultRunner = await runValidationForRole(values, errorHandler);
    console.log(validatePhone, `validatePhone`)
    return validateFirstName && validateLastName && validatePhone && validateEmail && defaultRunner;
  };
  // VALIDATE FIRST NAME
  const validateFirstName = (val) => {
    if (val.match(`^[a-zA-Z ]*$`) != null) {
      setFirstName(val);
    }
  };

  // VALIDATE LAST NAME
  const validateLastName = (val) => {
    if (val.match(`^[a-zA-Z ]*$`) != null) {
      setLastName(val);
    }
  };

  // RESET FORM
  const resetForm = () => {
    setFirstName(``);
    setFirstNameError({ status: false, msg: `` });
    setLastName(``);
    setLastNameError({ status: false, msg: `` });
    setDateOfBirth(null);
    setPhone(``);
    setPhoneError({ status: false, msg: `` });
    setEmail(``);
    setEmailError({ status: false, msg: `` });
    setFax(``);
  };

  // FORM SUBMIT HANDLER
  const submitHandler = async () => {
    try {
      const temp = await validateInput();
      let userRoles = await extractExactRoles({ ...values }, true);
      let dob = dateOfBirth?.toString()?.length && moment(dateOfBirth).format(`MM/DD/YYYY`);
      let providerKeys = { NPI: values?.npi, providerType: values?.type, medicalLicense: values?.license, licenseState: values?.licenseState };
      const dynamicProviderKeys = dynamicObjCreator({ ...providerKeys });
      let dynamicObject = dynamicObjCreator({
        ...providerKeys,
        firstName: firstName,
        lastName: lastName,
        email: email,
        phoneNumber: phone,
        faxNumber: fax,
        title,
        ...(dob?.length > 0 && { dateOfBirth: dob }),
      });

      let payLoad = {
        ...dynamicObject,
        systemRoles: [
          {
            ...userRoles,
            _practiceId: user.user?.isSystemAdministrator || user?.user?.isGroupAdmin ? selectedPractice?._id : practice,
            _practiceGroupId: user?.user?.isSystemAdministrator ? _practiceGroupId : groupId,
          },
        ],
      };

      const suspenser = (success) => {
        if (success) {
          setLoading(false);
          setAddUserModal(false);
          dispatch(addNewUserMountFlag()); // NEW USER ADDED, RESET REFRESH FLAG
          successToast(`New User Added !`);
          resetForm();
        } else {
          setLoading(false);
        }
      };
      if (temp) {
        setLoading(true);
        if (isNew) {
          postRequest(CREATE_NEW_USER_URL, payLoad)
            .then(() => {
              suspenser(true);
            })
            .catch(() => suspenser(false)
            );
        } else if (userRoles) {
          postRequest(ADD_NEW_ROLE + existing?._id, {
            ...(hasAlreadyUser ? {} : { ...dynamicProviderKeys, isNewProviderOrClinicalStaff: true }),
            systemRoles: [
              {
                ...userRoles,
                _practiceId: user?.user?.isSystemAdministrator || user?.user?.isGroupAdmin ? selectedPractice?._id : user?.user?._practiceId,
                _practiceGroupId: user?.user?.isSystemAdministrator ? _practiceGroupId : groupId,
              },
            ],
          })
            .then(() => {
              emptyExistingUser();
              suspenser(true);
            })
            .catch(() => suspenser(false));
        }
      } else {
        errorToast(`Please fill up the entries!`);
      }
    } catch (error) {
      errorToast(`Failed`);
      console.log(error)
    }
  };

  const isAvailable = (val, key) => {
    setLoading(true);
    postRequest(CHECK_EMAIL_USERNAME_AVAILABILITY_URL, { userName: val })
      .then(() => {
        //
        setLoading(false);
        successToast(`Available`);
      })
      .catch((err) => {
        setLoading(false);
        if (err.response) {
          if (err.response.status === 409 && key === `email`) setEmailError({ status: true, msg: `Email already exists.` });
        }
      });
  };
  const onChangeHandler = (key, value) => {
    setValues((p) => ({ ...p, [key]: value }));
  };

  const checkAndAssignRoleHandler = (e) => {
    const { name } = e.target;

    //admin flag
    if (name === `admin`) {
      if (!values?.isBiller) {
        onChangeHandler(`isSuperAdmin`, !values?.isSuperAdmin);
        return;
      } else {
        errorToast(`Admin role cannot assign with Clinical Staff or Biller`);
      }
    }
    //Provider flag
    if (name === `provider`) {
      if (!values?.isClinicalStaff && !values?.isBiller) {
        onChangeHandler(`isProvider`, !values?.isProvider);
        return;
      } else {
        errorToast(`Provider role cannot assign with Clinical Staff or Biller`);
      }
    }
    //Clinical Staff flag
    if (name === `clinicalStaff`) {
      if (!values?.isProvider && !values?.isBiller) {
        onChangeHandler(`isClinicalStaff`, !values?.isClinicalStaff);
        return;
      } else {
        errorToast(`Clinical Staff role cannot assign with Provider,Biller or Admin`);
      }
    }
    //Clinical Staff flag
    if (name === `biller`) {
      if (!values?.isProvider && !values?.isSuperAdmin && !values?.isClinicalStaff) {
        onChangeHandler(`isBiller`, !values?.isBiller);
        return;
      } else {
        errorToast(`Biller role cannot assign with Provider,Clinical Staff or Admin`);
      }
    }
  };

  let renderSectionHeading = values?.isClinicalStaff ? `Clinical User` : `Provider`;
  let hasMount = true;

  return (
    <>
      <ToastContainer />
      <div>
        <p className={css.sectionHeadingText} style={{ marginTop: `16px` }}>
          Basic Information
        </p>
        <hr style={{ margin: `0px` }} />

        <Box sx={{ mt: 2 }}>
          <Grid container rowSpacing={2} columnSpacing={1}>
            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
              <InputField
                size="small"
                disabled={!isNew && existing}
                error={!!(firstNameError.status && !firstName)}
                onBlur={() => wrapWithAsyncSimple({ successFunc: () => validateInput(`firstName`) })}
                autoComplete="off"
                onChange={(event) => {
                  validateFirstName(event.target.value);
                  wrapWithAsyncSimple({ successFunc: () => validateInput(`firstName`) });
                }}
                value={firstName}
                type="text"
                id="firstName"
                style={{
                  width: `100%`,
                  textTransform: `capitalize`,
                }}
                name="firstName"
                label="First Name*"
              />
              <p className={firstNameError.status && !firstName ? `d-block text-danger` : `d-none`}>{firstNameError.msg}</p>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
              <InputField
                size="small"
                error={!!(lastNameError.status && !lastName)}
                disabled={!isNew && existing}
                onBlur={() => wrapWithAsyncSimple({ successFunc: () => validateInput(`lastName`) })}
                autoComplete="off"
                onChange={(event) => {
                  validateLastName(event.target.value);
                  wrapWithAsyncSimple({ successFunc: () => validateInput(`lastName`) });
                }}
                value={lastName}
                type="text"
                id="lastName"
                style={{
                  width: `100%`,

                  textTransform: `capitalize`,
                }}
                name="lastName"
                label="Last Name*"
              />
              <p className={lastNameError.status && !lastName ? `d-block text-danger` : `d-none`}>{lastNameError.msg}</p>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
              <DatePickerCustomize
                dateInputValue={dateOfBirth || null}
                setDateInputValue={setDateOfBirth}
                disabled={!!(!isNew && existing)}
                pickerProps={{
                  size: `small`,
                  label: `DOB`,
                }}
              />
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
              <InputField
                size="small"
                disabled={!isNew && existing}
                onBlur={() => wrapWithAsyncSimple({ successFunc: () => validateInput(`phone`) })}
                autoComplete="off"
                onChange={(event) => {
                  setphoneExtension(event.target.value);
                }}
                value={phoneExtension}
                type="text"
                id="phoneExtension"
                style={{ width: `100%` }}
                name="phoneExtension"
                label="Extension"
              />
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
              <InputField
                size="small"
                error={!!phoneError.status}
                disabled={!isNew && existing}
                onBlur={() => wrapWithAsyncSimple({ successFunc: () => validateInput(`phone`) })}
                autoComplete="off"
                onChange={(event) => {
                  phoneMask(event.target.value, setPhone);
                  wrapWithAsyncSimple({ successFunc: () => validateInput(`phone`) });
                }}
                value={phone}
                type="text"
                id="phone"
                style={{ width: `100%` }}
                name="phone"
                label="Phone Number*"
              />
              <p className={phoneError.status ? `d-block text-danger` : `d-none`}>{phoneError.msg}</p>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <InputField
                size="small"
                disabled={!isNew && existing}
                error={!!emailError.status}
                onBlur={() => wrapWithAsyncSimple({ successFunc: () => validateInput(`email`) })}
                autoComplete="off"
                onChange={(event) => {
                  setEmail(event.target.value);
                  validateInput(`email`);
                }}
                value={email}
                type="email"
                id="email"
                style={{ width: `100%` }}
                name="email"
                label="Email*"
              />
              <p className={emailError.status ? `d-block text-danger` : `d-none`}>{emailError.msg}</p>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <InputField
                size="small"
                disabled={!isNew && existing}
                autoComplete="off"
                onChange={({ target: { value } }) => faxNumberMask({ value, setValue: setFax })}
                value={fax}
                type="text"
                id="fax"
                style={{ width: `100%` }}
                name="fax"
                label="Fax"
              />
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <InputField
                size="small"
                disabled={!isNew && existing}
                autoComplete="off"
                onChange={(event) => setTitle(event.target.value)}
                value={title}
                type="text"
                id="title"
                style={{ width: `100%` }}
                name="title"
                label="Title"
              />
            </Grid>
          </Grid>
        </Box>

        <Fade in={hasMount} mountOnEnter unmountOnExit>
          <Box ref={containerRef} sx={{ width: `100%`, overflow: `hidden`, mt: 2 }}>
            <div className="d-flex align-items-center w-100 justify-content-center" id={css.additionalRole}>
              <label
                className={css.assingRoleLabel}
                style={
                  errors?.roleError.status && !values?.isSuperAdmin && !values?.isProvider && !values?.isClinicalStaff && !values?.isBiller
                    ? { color: `red` }
                    : null
                }
              >
                Assign Role(s)*:{` `}
              </label>
              <MuiCheckBox
                size="small"
                type="checkbox"
                id="adminaddUser"
                name="admin"
                checked={values?.isSuperAdmin}
                onChange={(e) => checkAndAssignRoleHandler(e)}
                onBlur={() => wrapWithAsyncSimple({ successFunc: () => validateInput(`role`) })}
              />
              <label htmlFor="admin"> Admin</label>
              <MuiCheckBox
                size="small"
                type="checkbox"
                id="providerAddUser"
                name="provider"
                checked={values?.isProvider}
                onChange={(e) => {
                  checkAndAssignRoleHandler(e);
                  setExistingProviderClinicallStaff();
                }}
                onBlur={() => wrapWithAsyncSimple({ successFunc: () => validateInput(`role`) })}
              />
              <label htmlFor="provider"> Provider</label>
              <MuiCheckBox
                size="small"
                type="checkbox"
                id="clinicalStaffAddUser"
                name="clinicalStaff"
                checked={values?.isClinicalStaff}
                onChange={(e) => {
                  let errosObj = {
                    ...errors,
                  };
                  delete errosObj.roleError;
                  let reset = { status: false, msg: `` };
                  for (const iterator in errosObj) {
                    const { msg } = errosObj[iterator];
                    if (msg?.length) {
                      errorHandler(`${iterator}`, { ...reset });
                    }
                  }
                  checkAndAssignRoleHandler(e);
                  setExistingProviderClinicallStaff();
                }}
                onBlur={() => wrapWithAsyncSimple({ successFunc: () => validateInput(`role`) })}
              />
              <label htmlFor="clinicalStaff"> Clinical Staff</label>
              <MuiCheckBox
                size="small"
                type="checkbox"
                id="billerAddUser"
                name="biller"
                checked={values?.isBiller}
                onChange={(e) => checkAndAssignRoleHandler(e)}
                onBlur={() => wrapWithAsyncSimple({ successFunc: () => validateInput(`role`) })}
              />
              <label htmlFor="biller">Biller</label>
            </div>

            <Collapse
              container={containerRef.current}
              direction="left"
              in={values?.isProvider || values?.isClinicalStaff}
              mountOnEnter
              unmountOnExit
              timeout={900}
            >
              <div>
                <p className={css.sectionHeadingText}>{`${renderSectionHeading} Additional Information`}</p>
                <hr style={{ margin: `0px` }} />
                <Box sx={{ mt: 2, mb: 2 }}>
                  <Grid container columnSpacing={1} rowSpacing={2}>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <MuiSelect
                        isSimple={false}
                        controlProps={{
                          size: `small`,
                          fullWidth: true,
                          id: `providerType`,
                        }}
                        label={`Provider Type${requiredTag(!values?.isClinicalStaff)}`}
                        options={[...providerType]}
                        selectProps={{
                          error: !!(errors?.typeError.status && !values?.type),
                          label: `Provider Type${requiredTag(!values?.isClinicalStaff)}`,
                          disabled: disableAdditionalInfoInput({ ...existing?.additionalValues }, `providerType`),
                          onBlur: () => wrapWithAsyncSimple({ successFunc: () => validateInput(`type`) }),
                          name: `type`,
                          onChange: (event) => {
                            onChangeHandler(`type`, event.target.value);
                            wrapWithAsyncSimple({ successFunc: () => validateInput(`type`) });
                          },
                          value: values?.type,
                        }}
                        optionKey={`name`}
                        optionValue={`name`}
                      />
                      <p className={errors?.typeError.status && !values?.type ? `d-block text-danger` : `d-none`}>{errors?.typeError.msg}</p>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <InputField
                        fullWidth={true}
                        size="small"
                        disabled={disableAdditionalInfoInput({ ...existing?.additionalValues }, `medicalLicense`)}
                        error={!!(errors?.licenseError.status && !values?.license)}
                        onBlur={() => wrapWithAsyncSimple({ successFunc: () => validateInput(`license`) })}
                        autoComplete="off"
                        onChange={(event) => {
                          onChangeHandler(`license`, event.target.value);
                          wrapWithAsyncSimple({ successFunc: () => validateInput(`license`) });
                        }}
                        value={values?.license}
                        type="text"
                        id="licenseAddUser"
                        name="license"
                        label={`License No.${requiredTag(!values?.isClinicalStaff)}`}
                      />
                      <p className={errors?.licenseError.status && !values?.license ? `d-block text-danger` : `d-none`}>
                        {errors?.licenseError.msg}
                      </p>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <MuiSelect
                        isSimple={false}
                        controlProps={{
                          size: `small`,
                          fullWidth: true,
                          id: `licenseState`,
                        }}
                        label={`License State${requiredTag(!values?.isClinicalStaff)}`}
                        options={[...usStateName]}
                        selectProps={{
                          error: !!(errors?.licenseStateError.status && !values?.licenseState),
                          id: `licenseState`,
                          label: `License State${requiredTag(!values?.isClinicalStaff)}`,
                          disabled: disableAdditionalInfoInput({ ...existing?.additionalValues }, `licenseState`),
                          onBlur: () => wrapWithAsyncSimple({ successFunc: () => validateInput(`state`) }),
                          name: `type`,
                          onChange: (event) => {
                            onChangeHandler(`licenseState`, event.target.value);
                            wrapWithAsyncSimple({ successFunc: () => validateInput(`state`) });
                          },
                          value: values?.licenseState,
                        }}
                        optionKey={`name`}
                        optionValue={`name`}
                      />
                      <p className={errors?.licenseStateError.status && !values?.licenseState ? `d-block text-danger` : `d-none`}>
                        {errors?.licenseStateError.msg}
                      </p>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <InputField
                        size="small"
                        fullWidth={true}
                        disabled={disableAdditionalInfoInput({ ...existing?.additionalValues }, `NPI`)}
                        error={!!errors?.npiError.status}
                        onBlur={() => wrapWithAsyncSimple({ successFunc: () => validateInput(`npi`) })}
                        autoComplete="off"
                        onChange={(event) => {
                          npiMask(event.target.value, (value) => onChangeHandler(`npi`, value));
                          wrapWithAsyncSimple({ successFunc: () => validateInput(`npi`) });
                        }}
                        value={values?.npi}
                        id="npiAddUser"
                        name="npi"
                        label={`NPI${requiredTag(!values?.isClinicalStaff)}`}
                        placeholder={`NPI${requiredTag(!values?.isClinicalStaff)}`}
                      />
                      <p className={errors?.npiError.status ? `d-block text-danger` : `d-none`}>{errors?.npiError.msg}</p>
                    </Grid>
                  </Grid>
                </Box>
              </div>
            </Collapse>
          </Box>
        </Fade>
      </div>
      <div className="d-flex justify-content-center">
        <button
          type="button"
          className="btn btn-outline-info"
          id={css.addNewUserModalButton}
          onClick={submitHandler}
          disabled={!!(values?.npi.length > 1 && values?.npi.length < 10)}
        >
          ADD USER
        </button>
      </div>
      {loading && <PulseSpinner />}
    </>
  );
};
