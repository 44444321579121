import {
  LIST_OF_CONVERSATION,
  UPDATE_USER_CHATID_COVERSATION,
  SOCKET,
  RECENT_MESSAGE,
  SELECTED_USER,
  INDIVIDUAL_USER,
  RESET_REDUX,
  UPDATE_ONLINE_STATUS,
  UPDATE_USER_CHAT_ID,
  UPDATE_MESSAGE_READ_STATUS,
  GET_PATIENT_LIST,
} from './messengerTypes';

export const listOfConversation = (conversationList) => {
  return {
    type: LIST_OF_CONVERSATION,
    payload: conversationList,
  };
};

export const setUpSocket = (activeSocket) => {
  return {
    type: SOCKET,
    payload: activeSocket,
  };
};

export const mostRecentMessage = (recentMessage) => {
  return {
    type: RECENT_MESSAGE,
    payload: recentMessage,
  };
};

export const updateUserOnlineStatus = (status) => {
  return {
    type: UPDATE_ONLINE_STATUS,
    payload: status,
  };
};

export const _updateUserChatIdCoversation = (status) => {
  return {
    type: UPDATE_USER_CHATID_COVERSATION,
    payload: status,
  };
};
export const updateUserChatId = (status) => {
  return {
    type: UPDATE_USER_CHAT_ID,
    payload: status,
  };
};
export const updateMessageReadStatus = (status) => {
  return {
    type: UPDATE_MESSAGE_READ_STATUS,
    payload: status,
  };
};
export const listOfPatient = (patients) => {
  return {
    type: GET_PATIENT_LIST,
    payload: patients,
  };
};

export const selectedUser = (user) => {
  return {
    type: SELECTED_USER,
    payload: user,
  };
};

export const setIndividualUser = (user) => {
  return {
    type: INDIVIDUAL_USER,
    payload: user,
  };
};

export const resetRedux = () => {
  return {
    type: RESET_REDUX,
  };
};

// -> ASYNC SPECIAL USER CREATOR, RETURN ANOTHER FUNCTION
// -> RECEIVE DISPATCH AS ARGUMENT
export const listOfUsers = (users) => {
  let newUsersArray = users.sort((a, b) => a[`is${b.myId}ChatRead`] - b[`is${a.myId}ChatRead`]);
  return (dispatch) => {
    dispatch(listOfConversation(newUsersArray));
  };
};
// -> ASYNC SPECIAL USER CREATOR, RETURN ANOTHER FUNCTION
// -> RECEIVE DISPATCH AS ARGUMENT
export const _socketSetup = (socket) => {
  return async (dispatch) => {
    dispatch(setUpSocket(socket));
  };
};

// -> ASYNC SPECIAL USER CREATOR, RETURN ANOTHER FUNCTION
// -> RECEIVE DISPATCH AS ARGUMENT
export const _recentMessage = (msgData) => {
  return async (dispatch) => {
    dispatch(mostRecentMessage(msgData));
  };
};
// -> ASYNC SPECIAL USER CREATOR, RETURN ANOTHER FUNCTION
// -> RECEIVE DISPATCH AS ARGUMENT
export const _updateUserOnlineStatus = (status) => {
  return async (dispatch) => {
    dispatch(updateUserOnlineStatus(status));
  };
};
// -> ASYNC SPECIAL USER CREATOR, RETURN ANOTHER FUNCTION
// -> RECEIVE DISPATCH AS ARGUMENT
export const _updateMessageReadStatus = (status) => {
  return async (dispatch) => {
    dispatch(updateMessageReadStatus(status));
  };
};
// -> ASYNC SPECIAL USER CREATOR, RETURN ANOTHER FUNCTION
// -> RECEIVE DISPATCH AS ARGUMENT
export const _updateUserChatId = (status) => {
  return async (dispatch) => {
    dispatch(updateUserChatId(status));
  };
};

// -> ASYNC SPECIAL USER CREATOR, RETURN ANOTHER FUNCTION
// -> RECEIVE DISPATCH AS ARGUMENT
export const _selectedUser = (user) => {
  return async (dispatch) => {
    dispatch(selectedUser(user));
  };
};

// -> ASYNC SPECIAL USER CREATOR, RETURN ANOTHER FUNCTION
// -> RECEIVE DISPATCH AS ARGUMENT
export const _setIndividualUser = (users) => {
  let nestedObj = {};
  users.map((user) => {
    nestedObj[user._id] = {
      lastMessage: user.lastMessage,
      firstName: user.firstName,
      lastName: user.lastName,
      // lastMessage: user.lastMessage,
      isOnline: user.isOnline,
      isMessageRead: user[`is${user.myId}ChatRead`],
      _chatRoomId: user._chatRoomId,
      practiceId: user._practiceId,
      senderId: user.myId,
      receiverId: user._id,
      type: user?.type ? user?.type : ``,
      date: user?.inboundMessageTime,
    };
  });
  return (dispatch) => {
    dispatch(setIndividualUser(nestedObj));
  };
};

// GET ALL LIST OF PATIENTS
// -> ASYNC SPECIAL USER CREATOR, RETURN ANOTHER FUNCTION
// -> RECEIVE DISPATCH AS ARGUMENT
export const _listOfPatient = (patients) => {
  return (dispatch) => {
    dispatch(listOfPatient(patients.patients));
  };
};
