import React, { useEffect, useState } from 'react';
import { MenuItem, Grid, IconButton, Menu, Box } from '@mui/material';
import { capitalizeFirstLetter, convertStoHm, findInd, returnNull, timeFormatCallBack, timeFormat, handleAHI, adherentGiver } from 'src/utils';
import CallToActionIcon from '@mui/icons-material/CallToAction';
import MissedVideoCallIcon from '@mui/icons-material/MissedVideoCall';
import { ToolTipProvider } from 'src/pages/common/components/Tooltip/ToolTipProvider';
import { PressureDecider } from '../components/PressureDecider';
import css from '../PatientOver.module.css';
import VoicemailIcon from '@mui/icons-material/Voicemail';
import { store } from 'src/redux/store';
import { RenderReportState } from '../ReportModal/components/RenderReportState';
import { newSocket } from 'src/pages/common/HOC/SocketWrapper';
import { closetriggerSwipe } from 'src/redux/PatientOverview/Reports.actions';

export const resetRedStateonCloseRep = () => {
  store.dispatch({
    type: `SIGNREPORT`,
    payLoad: {
      open: false,
      id: ``,
      startDate: ``,
      endDate: ``,
      patientId: ``,
      patientNumber: ``,
      reportMonth: ``,
      reportId: ``
    },
  });
  store.dispatch(closetriggerSwipe())
}


export const reportsDispatcher = (payLoad, month) => {
  let concatDate = ``;
  if (month) {
    let array = month.split(` `);
    let monthIndex = findInd(array[0]);
    let currentYear = array[1];
    concatDate = currentYear.concat(monthIndex);
  }

  const prepPayLoad = () => {
    let obj = {}
    for (const key in payLoad) {
      if (payLoad[key]) obj = { ...obj, [key]: payLoad[key] }
    }
    return { ...obj, open: payLoad?.open }
  }
  store.dispatch({
    type: `SIGNREPORT`,
    payLoad: {
      ...prepPayLoad(),
      ...(month && { reportMonth: parseInt(concatDate) })
    },
  });
};

export const TableRows = ({ moveToPatientModule, patientOverViewMappingData, index }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [reportStates, setReportStates] = useState([]);
  const open = Boolean(anchorEl);

  useEffect(() => {
    newSocket.on(`reportStatus`, (data) => setReportStates((p) => [...p, { ...data }]));
    return () => setReportStates([]);
  }, []);

  let timeMonitored = timeFormat(patientOverViewMappingData?.timeMonitored);
  let reports = patientOverViewMappingData?.reports.map((elem) => {
    let lowerCase = elem?.reportStatus?.replace(/_/g, ` `)?.toLowerCase();
    let capitalize = capitalizeFirstLetter(lowerCase);
    return { ...elem, reportStatus: capitalize };
  });

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // The ColorCodedAHI function is store in variable.
  let showColorCodedAHI = handleAHI(patientOverViewMappingData?.AHI);


  const getDeviceInfo = (device) => {
    if (!device) return null; // Handle case where device is undefined/null

    const key = device.manufacturer === 'respironics' || device.manufacturer === 'reactHealth'
      ? device.type
      : device.detail;

    return returnNull({
      key,
      successRetrun: key,
      faliure: `-`,
    });
  };


  const getCommunicationIcon = (patientData) => {
    if (patientData?.interactiveCommunication) {
      return returnNull({
        key: patientData?.interactiveCommunication,
        successRetrun: <MissedVideoCallIcon style={{ color: `#1699c5` }} />,
        faliure: `No`,
      })
    }

    if (patientData?.leftVoiceMail) {
      return returnNull({
        key: patientData?.leftVoiceMail,
        successRetrun: <VoicemailIcon style={{ color: `#1699c5` }} />,
        faliure: `No`,
      })
    }

    return null
  }


  const renderReportsContent = () => {
    if (!reports?.length) {
      return "-";
    }

    if (reports.length > 1) {
      return (
        <Box sx={{ width: `100%`, position: `relative` }}>
          <span className={css.badgeDot}>{reports.length}</span>
          <IconButton sx={{ padding: `0px !important` }} onClick={handleClick}>
            <CallToActionIcon sx={{ color: `#2299c5`, fontSize: `36px` }} />
          </IconButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{ 'aria-labelledby': `basic-button` }}
          >
            {reports.map((item) => (
              <RenderReportState
                key={item._id}
                contProps={{ sx: { pl: 2 } }}
                spinnerProps={{ size: 25, thickness: 8 }}
                report={{ status: "", _id: item._id, patientId: patientOverViewMappingData?._id }}
                reportStates={reportStates}
              >
                <MenuItem
                  sx={{ width: `350px` }}
                  onClick={() => {
                    reportsDispatcher(
                      {
                        open: true,
                        reportId: item?._id || "",
                        patientId: patientOverViewMappingData?._id,
                        patientNumber: patientOverViewMappingData?.phoneNumber || `123 9008 124`,
                        startDate: item?.startDate,
                        endDate: item?.endDate,
                        reportMonth: item.month,
                      },
                      item?.month
                    );
                    handleClose();
                  }}
                  value={item?.value}
                >
                  <Grid container>
                    <Grid sx={{ fontWeight: `600` }} item xs={6}>
                      {item?.reportStatus}
                    </Grid>
                    <Grid sx={{ textAlign: `center` }} item xs={6}>
                      {item.month}
                    </Grid>
                  </Grid>
                </MenuItem>
              </RenderReportState>
            ))}
          </Menu>
        </Box>
      );
    }

    return (
      <RenderReportState
        reportStates={reportStates}
        spinnerProps={{ size: 25, thickness: 8 }}
        report={{ status: "", _id: reports[0]?._id, patientId: patientOverViewMappingData?._id }}
      >
        <ToolTipProvider
          toolTipProps={{ title: "Click on it to open!", placement: "top", arrow: true }}
          element={
            <Box
              onClick={() => {
                reportsDispatcher(
                  {
                    open: true,
                    reportId: reports[0]?._id || "",
                    patientId: patientOverViewMappingData?._id,
                    patientNumber: patientOverViewMappingData?.phoneNumber || `123 9008 124`,
                    startDate: reports[0]?.startDate,
                    endDate: reports[0]?.endDate,
                    reportMonth: reports[0]?.month,
                  },
                  reports[0]?.month
                );
              }}
              sx={{ position: `relative` }}
            >
              <span className={css.badgeDot}>{reports.length}</span>
              <IconButton sx={{ padding: `0px !important` }}>
                <CallToActionIcon sx={{ color: `#2299c5`, fontSize: `36px`, padding: `0px !important` }} />
              </IconButton>
            </Box>
          }
        />
      </RenderReportState>
    );
  };




  return (
    <tr key={index} style={{ textAlign: `center`, verticalAlign: `top` }}>
      <td
        style={{
          cursor: `pointer`,
        }}
        onClick={() => moveToPatientModule()}
      >
        <ToolTipProvider
          toolTipProps={{
            title: `Click to get details`,
            placement: `top`,
            arrow: true,
          }}
          element={<span> {capitalizeFirstLetter(patientOverViewMappingData?.lastName)}</span>}
        />
      </td>
      <td
        style={{
          cursor: `pointer`,
        }}
        onClick={() => moveToPatientModule()}
      >
        <ToolTipProvider
          toolTipProps={{
            title: `Click to get details`,
            placement: `top`,
            arrow: true,
          }}
          element={<span> {capitalizeFirstLetter(patientOverViewMappingData?.firstName)}</span>}
        />
      </td>
      <td>
        {returnNull({
          key: patientOverViewMappingData?.deviceManufacturer,
          successRetrun: patientOverViewMappingData?.deviceManufacturer,
          faliure: `-`,
        })}
      </td>
      <td>
        <td>{getDeviceInfo(patientOverViewMappingData?.device)}</td>
      </td>
      <td>
        {returnNull({
          key: patientOverViewMappingData?.tier,
          successRetrun: patientOverViewMappingData?.tier,
          faliure: `No Data`,
        })}
      </td>
      <td>{adherentGiver(patientOverViewMappingData?.isAdherent)}</td>
      <td>
        {returnNull({
          key: patientOverViewMappingData?.daysDeviceUsedGT4,
          successRetrun: `${patientOverViewMappingData?.daysDeviceUsedGT4}/${patientOverViewMappingData?.TimedaysDeviceUsedGT4?.days} days`,
          faliure: `-`,
        })}
      </td>
      <td>
        {returnNull({
          key: patientOverViewMappingData?.daysDeviceUsedInsec,
          successRetrun: convertStoHm(patientOverViewMappingData?.daysDeviceUsedInsec, true, 30, timeFormatCallBack),
          faliure: `-`,
        })}
      </td>
      <td>
        <PressureDecider deviceMenuFacturer={patientOverViewMappingData?.device?.manufacturer} pressure={patientOverViewMappingData?.pressure} />
      </td>
      <td>
        <ToolTipProvider
          toolTipProps={{
            title: returnNull({
              key: !!patientOverViewMappingData?.AHI,
              successRetrun: `Events/hour`,
              faliure: `-`,
            }),
            placement: `top`,
            arrow: true,
          }}
          element={
            <span style={{ color: showColorCodedAHI }}>
              {returnNull({
                key: !!patientOverViewMappingData?.AHI,
                successRetrun: patientOverViewMappingData?.AHI,
                faliure: `-`,
              })}
            </span>
          }
        />
      </td>
      <td>
        {
          <ToolTipProvider
            toolTipProps={{
              title: returnNull({
                key: !!patientOverViewMappingData?.leak,
                successRetrun: `L/min`,
                faliure: `-`,
              }),
              placement: `top`,
              arrow: true,
            }}
            element={
              <div>
                {returnNull({
                  key: !!patientOverViewMappingData?.AHI,
                  successRetrun: patientOverViewMappingData?.leak,
                  faliure: `-`,
                })}
              </div>
            }
          />
        }
      </td>
      <td>
        {returnNull({
          key: patientOverViewMappingData?.timeMonitored,
          successRetrun: timeMonitored,
          faliure: `-`,
        })}
      </td>
      <td>
        {getCommunicationIcon(patientOverViewMappingData)}
      </td>
      <td className={css.reportsTd}>{renderReportsContent()}</td>
    </tr>
  );
};
