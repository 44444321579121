import React, { useEffect, useState } from 'react';
import { Box, Grid, Zoom, Paper } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { capitalizeFirstLetter, getTwillioVoiceAuth, printInBrowser, returnNull, timeLogAddOnSwitchPatientAndSorting, userAuthHandler, userRoleDecider } from 'src/utils';
import css from './UserRole.module.css';
import staticImage from 'src/assets/images/alt_image.jpg';
import { useDispatch, useSelector } from 'react-redux';
import { switchPractice } from 'src/redux/action/setUserHandler';
import { LOGIN_BY_ROLE, postRequest } from 'src/crud/crud';
import { SERVER_ADDRESS } from 'src/crud/axiosClient';
import { errorToast, successToast } from 'src/pages/common/components/snackBar/toast';
import { Image } from 'src/pages/common/components/Image/Image';
import { BadgePill } from 'src/pages/common/components/BadgePill/BadgePill';
import { ToastContainer } from 'react-toastify';
import { ConfirmationWrapper } from 'src/pages/common/HOC/ConfirmationWrapper';
import { ConditionallyRenderWrapper } from 'src/pages/common/HOC/ConditionallyRenderWrapper';

export const UserRole = ({ role, index, loadingHandler, isSwitch, systemRoles }) => {
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [isGoneForAddingLog, setIsGoneForAddingLog] = useState(false);
  const { timer } = useSelector((state) => state?.patient);
  const history = useHistory();
  const { uniqueString, userId } = role;
  const [pills, setPills] = useState(false);
  const dispatch = useDispatch();
  const { _practiceId: practice, status } = role;
  const { _practiceId: practiceId } = useSelector((state) => state?.user?.user);

  useEffect(() => {
    const extractUserRoles = new Promise((resolve, reject) => {
      let rolesObject = {};
      for (const iterator in role) {
        if (typeof role[iterator] === `boolean`) {
          rolesObject = { ...rolesObject, [iterator]: role[iterator] };
        }
      }
      resolve(rolesObject);
      reject(false);
    });
    const pillDecider = (roleObj) => {
      let pill = ``;
      let superAdminBadge = <BadgePill type="badge-success">Admin</BadgePill>;
      let clinicalStaffBadge = <BadgePill type="badge-secondary">Clinical Staff</BadgePill>;
      let billerBadge = <BadgePill type="badge-dark">Biller</BadgePill>;
      let providerBadge = <BadgePill type="badge-primary">Provider</BadgePill>;
      if (roleObj?.isSuperAdmin) {
        if (roleObj?.isProvider) {
          pill = (
            <>
              {superAdminBadge}
              {providerBadge}
            </>
          );
        } else if (roleObj?.isClinicalStaff) {
          pill = (
            <>
              {superAdminBadge}
              {clinicalStaffBadge}
            </>
          );
        } else {
          pill = superAdminBadge;
        }
      } else if (roleObj?.isProvider) {
        pill = providerBadge;
      } else if (roleObj?.isClinicalStaff) {
        pill = clinicalStaffBadge;
      } else if (roleObj?.isBiller) {
        pill = billerBadge;
      }
      return pill;
    };
    extractUserRoles
      .then((res) => setPills(pillDecider(res)))
      .catch((e) => {
        printInBrowser({ key: ``, value: e });
      });

    return () => {
      setPills(false);
    };
  }, [role]);

  const swtitchPracticeHandler = (condition, payLoad) => {
    if (condition) {
      dispatch(switchPractice(payLoad));
    }
  };

  const succesPhrase = isSwitch ? `You have successfully switched the practice.` : `You Are Logged In!`;

  let isLoggedCurrentRole = isSwitch && practiceId === practice?._id;
  const logInUser = () => {
    postRequest(LOGIN_BY_ROLE + practice?._id, { uniqueString: uniqueString, userId: userId })
      .then((res) => {
        loadingHandler(`loading`, false);
        let userRole = userRoleDecider({ ...res?.data?.user });
        userAuthHandler({
          userProps: {
            ...res?.data?.user,
            refreshToken: res?.data?.refreshToken,
            userRole: userRole,
            enableSwitch: true,
            systemRoles: systemRoles,
            uniqueString: uniqueString,
            practiceLogoPath: `${SERVER_ADDRESS}${practice?.practiceLogoPath}`,
          },
          token: res?.data?.token,
        });
        successToast(succesPhrase);
        history.push(`/`);
        getTwillioVoiceAuth(res?.data?.user)
        swtitchPracticeHandler(isSwitch, true);
      })
      .catch(() => {
        loadingHandler(`error`, true);
        loadingHandler(`loading`, false);
      });
  };
  return (
    <ConfirmationWrapper
      key={practiceId}
      open={openConfirmation}
      successFunc={() => {
        if (isSwitch && isLoggedCurrentRole) {
          errorToast(`You cant login with the role you already logged in`);
        } else if (!isGoneForAddingLog) {
          setIsGoneForAddingLog(true);
          timeLogAddOnSwitchPatientAndSorting(() => {
            setIsGoneForAddingLog(false);
            logInUser();
          });
        }
      }}
      cancelFunc={() => setOpenConfirmation(false)}
      successText={`Stop Timer`}
      cancelText={`Cancel`}
      title={`Attention!`}
      description={`In order to switch you must stop the timer first.`}>
      <Zoom key={role} in={role} mountOnEnter unmountOnExit style={{ ...(!isSwitch ? { transitionDelay: role ? `${index}00ms` : `0ms` } : {}), }}>
        <Grid item xs={6} sm={4} md={3} lg={2} xl={2}>
          <ToastContainer />
          <Box
            component={Paper}
            onClick={() => {
              if (timer?.toggleButton) {
                setOpenConfirmation((p) => !p);
              } else {
                if (status === `active`) {
                  if (isSwitch && isLoggedCurrentRole) {
                    errorToast(`You cant Login with The Role You Already Logged In `);
                  } else {
                    loadingHandler(`loading`, true);
                    logInUser();
                  }
                } else {
                  errorToast(`You Can't Login With Unverified Account!`);
                }
              }
            }}
            sx={{
              borderRadius: `10px`,
              WebkitBackdropFilter: `blur(5px)`,
              backdropFilter: `blur(5px)`,
              boxShadow: `2px black`,
              position: `relative`,
              border: `1px solid ${isLoggedCurrentRole ? '#1699c5' : '#0d6ab45e'}`,
              '&:hover': {
                backgroundColor: `#f4f4f4`,
                transition: `.5s ease`,
                cursor: `pointer`,
              },
              height: `250px`,
            }}
          >
            <Box sx={{ width: `100%`, height: `120px` }}>
              {practice?.practiceLogoPath ? (
                <Image
                  imgProps={{
                    className: css.practiceImage,
                    src: `${SERVER_ADDRESS}${practice?.practiceLogoPath}`,
                  }}
                  alt="practice Image"
                />
              ) : (
                <Image
                  imgProps={{
                    className: css.practiceImage,
                    src: staticImage,
                  }}
                  alt="Alt image"
                />
              )}
            </Box>
            <Box sx={{ p: 1.5 }}>
              <div className={`${css.practiceName}`}>
                <p className="text-truncate">
                  {returnNull({
                    key: practice?.practiceName,
                    successRetrun: capitalizeFirstLetter(practice?.practiceName),
                    failure: `-`,
                  })}
                </p>
              </div>
              <div className={css.locationContainer}></div>
              <Box sx={{ color: `rgba(0, 0, 0, 0.6)`, fontSize: `small`, pt: 0.5, pb: 0.5 }}>
                <ConditionallyRenderWrapper con={pills}>{pills}</ConditionallyRenderWrapper>
              </Box>
              <p className={css.location}>
                {practice?.line1
                  ? practice?.line1
                  : null + ` ` + practice?.line2
                    ? practice?.line2
                    : null + ` ` + practice?.city
                      ? practice?.city
                      : null}
              </p>
            </Box>
            {/*  */}
            <Box
              sx={{
                width: `100%`,
                position: `absolute`,
                bottom: `5px`,
                right: `2px`,
              }}
            >
              <Grid container>
                {isLoggedCurrentRole && (
                  <Grid sx={{ textAlign: `start`, paddingLeft: `5px` }} item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <React.Fragment>
                      <BadgePill type={`badge-success`}>Logged In</BadgePill>
                    </React.Fragment>
                  </Grid>
                )}
                <Grid
                  sx={{ textAlign: `end` }}
                  item
                  xs={isLoggedCurrentRole ? 6 : 12}
                  sm={isLoggedCurrentRole ? 6 : 12}
                  md={isLoggedCurrentRole ? 6 : 12}
                  lg={isLoggedCurrentRole ? 6 : 12}
                  xl={isLoggedCurrentRole ? 6 : 12}
                >
                  <React.Fragment>
                    <BadgePill type={`badge-${status === `active` ? `primary` : `danger`}`}>{capitalizeFirstLetter(status)}</BadgePill>
                  </React.Fragment>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Zoom>
    </ConfirmationWrapper>
  );
};
