import { Box, IconButton } from '@mui/material'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { GET_S3_FILE, postRequest } from 'src/crud/crud'
import { CustomizedDialogs } from 'src/pages/common/components/modal/CustomizeModal'
import { ToolTipProvider } from 'src/pages/common/components/Tooltip/ToolTipProvider'
import { appValidExt, audValidExt, convertToBase64, imgValidExt, vidValidExt } from 'src/utils'
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import DeleteIcon from '@mui/icons-material/Delete';
import css from './filePreview.module.css'
import staticImage from 'src/assets/images/alt_image.jpg';
import { Image } from 'src/pages/common/components/Image/Image'


const DownloadAndDelOpt = ({ file: { src, fileName, delHandler }, children }) => {

    return (
        <Box>
            {children}
            <Box sx={{ display: `flex`, justifyContent: `space-between` }}>
                <Box>
                    <ToolTipProvider toolTipProps={{
                        arrow: true,
                        placement: `top`,
                        title: `Download This File`
                    }} element={<Box>
                        <a download={fileName || `download`} href={`${src}`} rel="noopener noreferrer" target="_blank" > <IconButton><DownloadForOfflineIcon sx={{ color: `black` }} /></IconButton> </a>
                    </Box>} />
                </Box>
                {delHandler && <Box>
                    <ToolTipProvider toolTipProps={{
                        arrow: true,
                        placement: `top`,
                        title: `Delete This File`
                    }} element={<Box>
                        <IconButton><DeleteIcon onClick={delHandler} sx={{ color: `red` }} /></IconButton>
                    </Box>} />
                </Box>}

            </Box>
        </Box>
    )
}

const RenderImg = ({ file }) => {
    const [openImagePreview, setOpenImagePreview] = useState(false)
    const { status, src, delHandler } = file || false
    return (
        <DownloadAndDelOpt delHandler={delHandler} file={file}>
            <div className={css[status]} id={css.taskImageContainer} >
                <div>
                    <Image imgProps={{
                        id: css.taskImage,
                        src: status === `pending` ? staticImage : src,
                        alt: `Task Media`
                    }} />
                </div>
                <CustomizedDialogs noTitle={true} open={openImagePreview} setOpen={() => setOpenImagePreview((p) => !p)} size="md" fullWidth={true}>
                    <div id={css.fullModalImageContainer}>
                        <img src={src} id={css.fullModalImage} alt="chatMessage" />
                    </div>
                </CustomizedDialogs>
            </div>
        </DownloadAndDelOpt>
    )
}

const RenderAud = ({ file }) => {
    const { mimeType, status, src, delHandler } = file || false
    return (
        <DownloadAndDelOpt delHandler={delHandler} file={file}>
            <div className={css[status]} id={css.taskAudContainer} >
                <audio controls>
                    <source src={src} type={`${mimeType || "audio/ogg"}}`} />
                    <source src={src} type="audio/ogg" />
                </audio>
            </div >
        </DownloadAndDelOpt>
    )
}


const RenderVid = ({ file }) => {
    const { mimeType, status, src, delHandler } = file || false
    return (
        <DownloadAndDelOpt delHandler={delHandler} file={file}>
            <div className={css[status]} id={css.taskVidContainer} >
                <video controls>
                    <source src={src} type={`${mimeType || "video/ogg"}`} />
                    <source src={src} type="video/ogg" />
                </video>
            </div>
        </DownloadAndDelOpt>
    )
}

const RenderApp = ({ file }) => {
    const { status, delHandler } = file || false
    return (
        <DownloadAndDelOpt delHandler={delHandler} file={file} >
            <div className={css[status]} id={css.taskAppContainer}>
                <Box sx={{ mt: -0.2 }}>
                    <Box component="div" sx={{ display: `inline-block`, marginLeft: 1, color: `red` }}>
                        <i style={{ fontSize: `40px` }} className={`bi bi-file-earmark-pdf-fill`}></i>
                    </Box>
                </Box>
            </div >
        </DownloadAndDelOpt>
    )
}


export const FilePreview = ({ file, delHandler }) => {
    const { blobId, mimeType } = file || false
    const [fileStat, setFileStat] = useState({ status: ``, src: `` })
    const { status, src } = fileStat
    const fileStatHandler = (value) => {
        setFileStat({ ...value })
    }
    const renderCount = useRef(0)

    useEffect(() => {
        if (renderCount.current === 0 && blobId) {
            renderCount.current = renderCount.current + 1
            fileStatHandler({ status: `pending`, src: `` })
            postRequest(GET_S3_FILE, { blobId: blobId }, { responseType: `arraybuffer` }).then(async (res) => {
                let file = new Blob([res.data], { type: `${mimeType}` });
                let fileUrl = await convertToBase64(file);
                fileStatHandler({ src: fileUrl, status: `received` })
            }).catch(() => fileStatHandler({ src: ``, status: `error` }))
        } else if (renderCount.current === 0 && !blobId) {
            fileStatHandler({ src: file?.src, status: `received` })
        }

    }, [blobId, file, renderCount])
    const filePreviewHandler = useMemo(() => {
        const validateMimes = (hasMime) => {
            const decideMime = hasMime ? mimeType : file?.type
            if (imgValidExt?.find((mime) => decideMime?.includes(mime))) {
                return `img`
            } else if (vidValidExt?.find((mime) => decideMime?.includes(mime))) {
                return `vid`
            } else if (audValidExt?.find((mime) => decideMime?.includes(mime))) {
                return `aud`
            } else if (appValidExt?.find((mime) => decideMime?.includes(mime))) {
                return `app`
            } else {
                return `noMime`
            }
        }
        if (mimeType && file) {
            return validateMimes(true)
        } else if (!mimeType && file) {
            return validateMimes(false)
        } else {
            return `noMime`
        }

    }, [mimeType, file])
    const compProps = { status: status, src: src, delHandler: delHandler }
    const renderFilePreview = {
        img: <RenderImg file={{ ...compProps }} />,
        vid: <RenderVid file={{ ...compProps }} />,
        aud: <RenderAud file={{ ...compProps }} />,
        app: <RenderApp file={{ ...compProps }} />,
        noMime: `noMime`
    }

    return <>{renderFilePreview[filePreviewHandler]}</>
}
