import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import { ListAllPatients } from './components/listAllPatients/filteredAllPatientsList';
import { PatientBasicDetails } from './components/patientDetails/basicDetails/basicDetails';
import { PatientPastData } from './components/patientDetails/moreDetails/pastData/pastData';
import { PatientsViewData } from './components/patientDetails/moreDetails/viewData/viewData';
import { PatientMessages } from './components/patientCommunication/message/message';
import { PatientLogs } from './components/patientCommunication/logs/logs';
import { useDispatch, useSelector } from 'react-redux';
import { VideoWindow } from './components/patientCommunication/videoCall/components/videoWindow/videoWindow';
import { postRequest, ADD_PATIENT_MONITORING_TIME_LOG_URL } from '../../../../crud/crud';
import {
  setAllPatients, handleTimer, setHandleTimer, emptyPatientsList,
  emptyPatientData, setSelectedPatientReport, setAllMessages, communcicationWithPatient,
} from '../../../../redux/patient/patientActions';
import { store } from 'src/redux/store';
import { errorToast, successToast } from 'src/pages/common/components/snackBar/toast';
import css from './patient.module.css';
import { momentWrapper } from 'src/momentWrapper';
import { emitEventOnLessThan5s, isDme, timeLogAddOnSwitchPatientAndSorting } from 'src/utils';
import { PatientTasks } from './components/patientCommunication/tasks/tasks';
import { VoiceCallFeedBackWrapper } from 'src/pages/common/HOC/VoiceCallFeedBackWrapper';

const cancelApiCalls = () => {
  const { patient: { apiTokens: { patDetail, patList } } } = store.getState()
  patDetail.cancel()
  patList.cancel()
}

export const blankPatientModule = (isSimpleUnmount) => {
  cancelApiCalls()
  store.dispatch(emptyPatientData(``));
  store.dispatch(emptyPatientsList(``));
  store.dispatch(setSelectedPatientReport(``));
  store.dispatch(handleTimer({ status: false }));
  store.dispatch(setAllMessages([]));
  store.dispatch({ type: `ISSENT`, payLoad: false });
  if (!isSimpleUnmount) {
    store.dispatch(communcicationWithPatient({ chatting: false, text: `` }));
  } else {
    return null;
  }
};

export const saveTimerReportId = ({
  shouldTriggAct = false,
  source = false,
  shouldNotCloseHideTimer = false,
  id,
  startTime = false,
  selectedPatientIdRedux,
  isFromView,
  customDesc,
}) => {
  const endTimeValue = momentWrapper().toISOString();

  if (!shouldNotCloseHideTimer) store.dispatch(setHandleTimer(false));
  const { timer: { startTime: startTimeRed }, descriptionText: descriptionForTimer, isChatting } = store.getState().patient
  const timeDiff = momentWrapper(endTimeValue).diff(startTime || startTimeRed, `seconds`);
  console.log(timeDiff, `timeDifftimeDiff`)
  if (timeDiff > 5) {
    postRequest(ADD_PATIENT_MONITORING_TIME_LOG_URL + `${id || selectedPatientIdRedux}` + `?reportId=${null}`, {
      startDate: startTime || startTimeRed,
      endDate: endTimeValue,
      startOfMonth: momentWrapper().startOf(`month`).toISOString(),
      now: momentWrapper().toISOString(),
      source: source || `View Report`,
      type: `Auto`,
      description: customDesc && isChatting ? descriptionForTimer : `View graphs and reports`,
      callSuccess: false,
    })
      .then(() => {
        store.dispatch(setHandleTimer(false));
        if (shouldTriggAct) shouldTriggAct();
        if (!shouldNotCloseHideTimer) store.dispatch(setHandleTimer(false));
        if (!id) {
          blankPatientModule(false);
        }
        successToast(`Time Log Added.`);
      })
      .catch((err) => {
        if (err.response) {
          errorToast(err?.response?.data?.message);
        }
      });
  }
};
const getParameterByName = (name, url) => {
  if (!url) url = window.location.href;
  name = name.replace(/[[]]/g, '\\$&');

  let regex = new RegExp(`[?&]` + name + `(=([^&#]*)|&|#|$)`),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return ``;
  return decodeURIComponent(results[2].replace(/\+/g, ` `));
};

export const Patients = () => {
  const auth = useSelector((state) => state.user.auth);
  const user = useSelector((state) => state.user.user);
  const hideTimer = useSelector((state) => state.patient.hideTimer);
  const { startTime } = useSelector((state) => state.patient.timer);
  const [currentTab, setCurrentTab] = useState(`msg`);
  // VERY UNIQUE FLAG, TO AVOID USER TO CLICK ON MULTIPLE PATIENTS simultaneously
  const [refreshFlag, setRefreshFlag] = useState(false);
  // Special flags
  const [messageToggle, setMessageToggle] = useState(true);
  const [chartingToggle, setChartingToggle] = useState(false);
  const [taskToggle, setTaskToggle] = useState(false);
  // INCOMPLETE OR UNSIGNED REPORT
  const reportId = null;
  const location = useLocation();
  const dispatchRedux = useDispatch();
  const selectedPatientIdRedux = useSelector((state) => state.patient?.patientData?.data?._id);
  const selectedTab = useSelector((state) => state?.patient?.selectedTab);
  const timer = useSelector((state) => state?.patient?.timer);


  useEffect(() => {
    let adherent = getParameterByName(`Adherence`);
    let _risk = getParameterByName(`risk`);
    let _monitoring = getParameterByName(`monitoring`);
    let _status = getParameterByName(`status`);
    if (!(adherent || _risk || _monitoring || _status || location.patientId !== ``)) {
      setAllPatients();
    }
  }, []);

  useEffect(() => {
    if (location.pathname === `/patient` && selectedPatientIdRedux) {
      dispatchRedux({ type: `REMOVE_CURRENT_MESSAGE_PATIENT`, payLoad: selectedPatientIdRedux });
    }
  }, [selectedPatientIdRedux]);

  // When we change patient module we have to save time and stop time in case timer is on
  useEffect(() => {
    const onPatientModuleUnMount = () => {
      let {
        patient: { patientData, timer },
      } = store.getState();
      let { data } = patientData || {};
      let { _id: patientId } = data || false;
      if (timer?.toggleButton && patientId) {
        let endTimeValue = momentWrapper().toISOString();
        let timeDiff = momentWrapper(endTimeValue).diff(timer?.startTime, `seconds`);
        if (timeDiff < 5) {
          emitEventOnLessThan5s();
        }
      }
      if (!isDme() && user && patientId) {
        if (hideTimer) {
          saveTimerReportId({ id: false, startTime: startTime, auth: auth, selectedPatientIdRedux: selectedPatientIdRedux, isFromView: false });
        } else if (timer?.toggleButton) {
          timeLogAddOnSwitchPatientAndSorting();
        } else {
          dispatchRedux(
            handleTimer({
              status: false,
            })
          );
        }
        blankPatientModule(false);
      }
    };
    return () => {
      if (window.location.pathname !== `/patient`) {
        onPatientModuleUnMount();
        //Empty the patient module e.g PatientList,selected patient,patient report
        blankPatientModule(true);
      }
    };
    // }
  }, [timer, selectedPatientIdRedux]);

  const handleChartingToggle = () => {
    setChartingToggle(!chartingToggle);
    setMessageToggle(false);
    setTaskToggle(false);
  };
  const handleTaskToggle = () => {
    setChartingToggle(false);
    setMessageToggle(false);
    setTaskToggle((p) => !p);
  };

  const handleMessageToggle = () => {
    setChartingToggle(false);
    setMessageToggle(!messageToggle);
    setTaskToggle(false);
  };

  return (
    <VideoWindow>
      <VoiceCallFeedBackWrapper>
        <Box
          sx={{
            display: `grid`,
            paddingRight: `1px`,
            gridTemplateColumns: user?.isBiller ? `minmax(auto, 330px) 1fr` : `minmax(auto, 26%) 1fr minmax(auto, 24%)`,
          }}
        >
          {/* Implementing the patients list with backend filter and pagination */}
          <ListAllPatients setCurrentTab={setCurrentTab} />
          <div id={css.detailContainer}>
            <PatientBasicDetails />
            {selectedTab === `past` ? <PatientPastData reportId={reportId} /> : !user?.isBiller && <PatientsViewData />}
          </div>
          {user.isSuperAdmin || user.isProvider || user.isClinicalStaff || !user.isBiller ? (
            <div
              id="msgNLogContainer"
              style={{
                boxShadow: `0 1px 2px 0 #0000001a, 0 10px 10px 0 rgba(68, 68, 68, 0.19)`,
              }}
            >
              <PatientMessages
                reportId={reportId}
                refreshFlag={refreshFlag}
                setRefreshFlag={setRefreshFlag}
                handleMessageToggle={handleMessageToggle}
                toggle={messageToggle}
                setCurrentTab={setCurrentTab}
                currentTab={currentTab}
                isTel={false}
              />
              <PatientLogs reportId={reportId} handleChartingToggle={handleChartingToggle} toggle={chartingToggle} />
              <PatientTasks reportId={reportId} handleChartingToggle={handleTaskToggle} toggle={taskToggle} />
            </div>
          ) : null}
        </Box>
      </VoiceCallFeedBackWrapper>
    </VideoWindow>
  );
};
