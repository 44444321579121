import React, { useRef, useState } from 'react';
import { postRequest, patchRequest } from 'src/crud/crud';
import { Stack, TextField, Box, MenuItem, InputAdornment, IconButton, LinearProgress, Grid } from '@mui/material';
import { TravelExplore } from '@mui/icons-material';
import GppMaybeOutlinedIcon from '@mui/icons-material/GppMaybeOutlined';
import { toast, ToastContainer } from 'react-toastify';
import css from './addNewPractice.module.css';
import ErrorOutlineSharpIcon from '@mui/icons-material/ErrorOutlineSharp';
import { validArealCodes } from 'src/utils';

export const Asktwillio = ({ practiceId, onClose }) => {
  const [twillioNumbers, setTwillioNumbers] = React.useState([]);
  const [numberLoadingError, setnumberLoadingError] = React.useState({ loading: false, error: false });
  const [loading, setloading] = React.useState({ loading: false, error: false });
  const [values, setValues] = useState({
    areaCode: ``,
    twillioNum: ``,
  });
  const { areaCode, twillioNum } = values;
  const [errors, setErrors] = useState({
    areaCode: ``,
    twillioNum: ``,
  });
  const { areaCode: areaCodeError, twillioNum: twillioNumError } = errors;
  const onChangeHandler = (key, value) => {
    setValues((p) => ({ ...p, [key]: value }));
  };
  const errorsHandler = (key, value) => {
    setErrors((p) => ({ ...p, [key]: value }));
  };

  const inputRef = useRef(null);

  const alterAnimation = (add) => {
    if (add) document.getElementById(`tarvelExplAskTwill`).classList.add(css.animatedIcon);
    else document.getElementById(`tarvelExplAskTwill`).classList.remove(css.animatedIcon);
  };

  const askTwilioHandler = (name, errorValue) => {
    let integer = parseInt(areaCode, 10);
    alterAnimation(false);
    let hasCode = validArealCodes.some((e) => e === integer);
    let payLoad = {
      twilioAreaCode: areaCode,
    };
    if (hasCode) {
      inputRef.current.focus();
      if (twillioNumError) errorsHandler(`twillioNum`, ``);
      setnumberLoadingError({ error: false, loading: true });
      postRequest(`practices/list/twilioNumbers/${practiceId}`, payLoad)
        .then((res) => {
          setTwillioNumbers(res?.data?.twilioNumbers);
          setnumberLoadingError({ error: false, loading: false });
        })
        .catch(() => {
          setnumberLoadingError({ error: true, loading: false });
        });
    } else {
      errorsHandler(name, errorValue);
    }
  };
  const onSubmitTwillio = (e) => {
    e.preventDefault();
    let validate = {
      code: areaCodeValidator(areaCode),
      num: twillioNumValidator(twillioNum),
    };
    const { code: isValidcodeValidator, num: isValidNumValidator } = validate;
    let payLoad = {
      twilioNumber: twillioNum,
    };
    if (isValidcodeValidator && isValidNumValidator) {
      setloading({ loading: true, error: false });
      patchRequest(`practices/twilio/buyNumber/${practiceId}`, payLoad)
        .then((res) => {
          setloading({ loading: false, error: false });
          toast.success(() => <>Number purchased successfully !</>, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          onClose(res);
        })
        .catch((e) => {
          if (e.response) {
            toast.error(() => <>Some error has occured please try again after sometime !</>, {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
          setloading({ loading: false, error: true });
        });
    }
  };
  let twillioNumbersOptions = twillioNumbers?.map((e, index) => (
    <MenuItem value={e} key={index + e}>
      {e}
    </MenuItem>
  ));

  const invalidAreaCode = `Invalid area code !`;
  const areaCodeValidator = (value) => {
    let parseStr = value?.toString();
    if (!parseStr?.length || parseStr?.length < 3) {
      errorsHandler(`areaCode`, invalidAreaCode);
      return false;
    } else {
      errorsHandler(`areaCode`, ``);
      return true;
    }
  };
  const twillioNumValidator = (value) => {
    if (!value) {
      errorsHandler(`twillioNum`, `*Required`);
      return false;
    } else {
      errorsHandler(`twillioNum`, ``);
      return true;
    }
  };
  return (
    <>
      <ToastContainer />
      <form onSubmit={onSubmitTwillio}>
          <Grid sx={{ mt: 3 }} container rowSpacing={1.5} columnSpacing={1.5}>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <TextField
                type={`number`}
                size="small"
                label="*Area Code"
                fullWidth
                onChange={({ target: { value } }) => {
                  onChangeHandler(`areaCode`, value);
                  if (value) alterAnimation(true);
                  if (twillioNum) {
                    onChangeHandler(`twillioNum`, ``);
                    setTwillioNumbers([]);
                  }
                }}
                value={areaCode}
                onBlur={({ target: { value } }) => areaCodeValidator(value)}
                error={!!areaCodeError}
                helperText={areaCodeError}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => askTwilioHandler(`areaCode`, invalidAreaCode)}>
                        <TravelExplore id="tarvelExplAskTwill" />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <TextField
                inputProps={{
                  ref: inputRef,
                }}
                select
                size="small"
                label="*Select Number"
                onChange={({ target: { value } }) => onChangeHandler(`twillioNum`, value)}
                value={twillioNum}
                onBlur={({ target: { value } }) => twillioNumValidator(value)}
                error={!!twillioNumError}
                helperText={twillioNumError}
                fullWidth
              >
                {numberLoadingError.error ? (
                  <Box sx={{ color: `red`, textAlign: `center`, mt: 2, pb: 2 }}>
                    <Box sx={{ display: `inline-block`, mt: 0.2 }}>Sorry try again later an error has occured</Box>
                    {` `}
                    <Box sx={{ display: `inline-block` }}>
                      <GppMaybeOutlinedIcon sx={{ color: `red` }} fontSize="large" />
                    </Box>
                  </Box>
                ) : numberLoadingError.loading ? (
                  <LinearProgress sx={{ mt: 2 }} />
                ) : (
                  twillioNumbersOptions
                )}
              </TextField>
            </Grid>
          </Grid>
          <Stack sx={{ mt: 1.5 }} direction="row" justifyContent="center">
            <button id={css.addProviderBtn} className="btn btn-outline-info btn-sm" disabled={loading?.loading} type="submit">
              {loading?.error ? (
                <ErrorOutlineSharpIcon fontSize="small" />
              ) : loading?.loading ? (
                <span className="spinner-border spinner-border-sm text-primary"></span>
              ) : (
                <span>Submit</span>
              )}
            </button>
          </Stack>
      </form>
    </>
  );
};
