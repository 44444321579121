import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Device } from 'twilio-client';
import { getRequest, PATIENT_TWILIO_VOICE_CALL_TOKEN_VERIFY_URL } from '../../../../../../../crud/crud';
import { TelemedicineDialer } from './components/dialer/dialer';
import { startAudioCall, endAudioCall } from '../../../../../../../redux/timer/timerActions';
import { handleTimer, saveActiveConn, saveTimerUser, updateAudioTimeLogs } from 'src/redux/patient/patientActions';
import { momentWrapper } from 'src/momentWrapper';
import { Zoom } from '@mui/material';

const twilioDevice = new Device();

export const PatientVoiceCall = ({ reportId, patientId }) => {
  const patient = useSelector((state) => state?.patient);
  const { isSwitchingPatient } = patient;
  const {
    auth,
    user: { _id: userID },
  } = useSelector((state) => state?.user);
  const {
    timer: { callType, audioCall: audioCallStatus },
  } = useSelector((state) => state);
  const patientNumber = useSelector((state) => state?.patient?.patientData?.data?.phoneNumber);
  const isChatting = useSelector((state) => state?.patient?.isChatting);
  const descriptionForTimer = useSelector((state) => state?.patient?.descriptionText);
  const timer = useSelector((state) => state.patient.timer);
  const { startTime } = useSelector((state) => state.patient.timer);
  const [muted, setMuted] = useState(false);
  const [onPhone, setOnPhone] = useState(false);
  const [ready, setReady] = useState(false);
  const [latestPatientPhoneNumber, setLatestPatientPhoneNumber] = useState(``);
  const [userIdBe, setuserIdBe] = useState(false);

  let enter = true;
  const dispatchRedux = useDispatch();

  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = ``;
  };

  useEffect(() => {
    if (audioCallStatus) {
      window.addEventListener(`beforeunload`, alertUser);
    }
    console.log(isSwitchingPatient, patientId, 'patientIdpatientIdpatientId');
    if (!isSwitchingPatient && patientId) {
      const isTelemedicine = false;
      getRequest(`${PATIENT_TWILIO_VOICE_CALL_TOKEN_VERIFY_URL}${patientId}?isTelemedicine=` + isTelemedicine)
        .then((res) => {
          let encryptedUserId = res.data.token.slice(-24);
          const getLastFourWords = () => {
            let arr = [];
            for (let i = 1; i < 5; i++) {
              arr.push(encryptedUserId.charAt(encryptedUserId.length - i));
            }
            return arr.reverse().join(``);
          };
          const convertAsciiChar = (words) => {
            let arr = [];
            for (let i = 0; i < 4; i++) {
              let char = String.fromCharCode(words.charCodeAt(i) - 1);
              arr.push(char);
            }
            return arr.join('');
          };
          let lastfourWords = getLastFourWords();
          let convertedWords = convertAsciiChar(lastfourWords);
          let finalencryptedUserId = `${encryptedUserId.slice(0, encryptedUserId.length - 4)}${convertedWords}`;
          if (finalencryptedUserId === userID) {
            let idRemovedToken = res?.data?.token.slice(0, res.data?.token.length - 24);
            setuserIdBe(true);
            twilioDevice.setup(idRemovedToken);
          }

          setLatestPatientPhoneNumber(res.data?.patientPhoneNumber);
          const deviceStatus = twilioDevice.status();
          //  according to new logics
          if (deviceStatus === `ready`) {
            setReady(true);
          }
        })
        .catch((e) => {
          console.log(e, `whatisERROR`);
        });

      twilioDevice.on('connection', (connection) => console.log('hiiamCOnnection', connection));
      twilioDevice.disconnect(function () {
        dispatchRedux({ type: `HANDLE_LOGOUT_MODAL`, payLoad: false });
        setOnPhone(false);
        dispatchRedux(endAudioCall());
        twilioDevice.disconnectAll();
        dispatchRedux(updateAudioTimeLogs({ data: null, callSuccess: null, runTimer: true }));
      });

      twilioDevice.ready(function () {
        setReady(true);
      });
    }
    return () => {
      window.removeEventListener(`beforeunload`, alertUser);
      setuserIdBe(false);
    };
  }, [patientId, isSwitchingPatient]);

  const handleToggleMute = () => {
    let muteTemp = !muted;

    setMuted(muteTemp);
    twilioDevice.activeConnection().mute(muteTemp);
  };

  // Make an outbound call with the current number,
  // or hang up the current call

  const handleToggleCall = async () => {
    let isReady = await ready;
    if (isReady && userIdBe) {
      if (!onPhone && !audioCallStatus && ready) {
        // STOP PATIENT TIME, BEFORE CALLING

        if (timer?.toggleButton) {
          // if timer toggle is already running first remove the timer
          const endTimeValue = momentWrapper().toISOString();
          dispatchRedux(handleTimer({ status: false, timeEnd: endTimeValue, videoOrAudio: null, autoTimer: `true` }));
          const timeDiff = momentWrapper(endTimeValue).diff(startTime, `seconds`);
          // if time toggle is running more than 5 seconds then save the time first
          if (timeDiff > 5) {
            dispatchRedux(
              saveTimerUser({
                videoOrAudioOn: `audio`,
                updateVideoorAudioLogStatus: null,
                callSuccess: null,
                statuss: isChatting ? descriptionForTimer : `View graphs and reports`,
                sources: `View Report`,
              })
            );
          }
        }

        dispatchRedux(startAudioCall('outGoing'));
        setMuted(false);
        setOnPhone(true);

        twilioDevice.connect({
          number: patientNumber,
          // number: "+923187313810",
          token: auth,
          patientId: patientId,
          reportId: reportId,
          date: momentWrapper().toDate(),
          isTelemedicine: false,
          userId: userID,
        });
        dispatchRedux(saveActiveConn(twilioDevice));
      } else {
        // hang up call in progress
        dispatchRedux(endAudioCall());
        twilioDevice.disconnectAll();
        setOnPhone(false);
      }
    } else {
      dispatchRedux(endAudioCall());
      twilioDevice.disconnectAll();
      setOnPhone(false);
      const deviceStatus = twilioDevice.status();
      //  according to new logics
      if (deviceStatus === `ready`) {
        setReady(true);
      }
      dispatchRedux(saveActiveConn(false));
      return false;
    }
  };

  let isOutgoingCall = audioCallStatus && callType === 'outGoing';

  return (
    <Zoom in={enter} mountOnEnter unmountOnExit>
      <div
        // className="p-3"
        style={{
          backgroundColor: `white`,
          flex: 1,
          verticalAlign: `middle`,
          height: `100%`,
        }}
      >
        {onPhone || isOutgoingCall ? (
          <>
            {/* Still facing calling null issue */}
            <h5 className="text-center pt-3 mb-3">{`Calling ...`}</h5>
            <div className="d-flex justify-content-center" style={{ height: `15%` }}>
              <button style={{ outline: `none` }} onClick={handleToggleMute}>
                <i
                  className="fa fa-microphone-slash pt-2 pb-2 pl-4 pr-4"
                  style={
                    muted
                      ? {
                          fontSize: 28,
                          color: `black`,
                          borderRadius: `32px`,
                        }
                      : {
                          fontSize: 28,
                          color: `rgb(230, 230, 230)`,
                          borderRadius: `32px`,
                        }
                  }
                  aria-hidden="true"
                ></i>
              </button>
              <button style={{ outline: `none` }} onClick={handleToggleCall}>
                <i
                  className="fa fa-phone pt-2 pb-2 pl-4 pr-4 ml-3"
                  style={{
                    fontSize: 28,
                    backgroundColor: `red`,
                    color: `white`,
                    borderRadius: `32px`,
                  }}
                  aria-hidden="true"
                ></i>
              </button>
            </div>
          </>
        ) : (
          <TelemedicineDialer
            patientNumber={latestPatientPhoneNumber || patientNumber}
            readyFlag={ready}
            callHandler={handleToggleCall}
          />
        )}
      </div>
    </Zoom>
  );
};
