import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { momentWrapper } from 'src/momentWrapper';
import { REPORT_ADD_NEW_NOTE, postRequest } from 'src/crud/crud';
import { setAllNotes, logsApiCall } from 'src/redux/patient/patientActions';
import { SmartPhraseModal } from 'src/pages/common/components/smartphrasemodal/SmartPhraseModal';
import { Box, Fade } from '@mui/material';
import { chatSkelton } from 'src/pages/home/modules/patient2/components/patientCommunication/message/message';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { ToastContainer } from 'react-toastify';
import { SignButton } from '../components/SignButton';
import { capitalizeFirstLetter, returnNull, onKeyUpCredentialsHandler } from 'src/utils';
import { AutoWriteCredentials } from 'src/pages/common/components/AutoWriteCredentials/AutoWriteCredentials';

export const Charting = ({ loadingReport, onClose, forwardPage }) => {
  const style = { width: `24%`, right: `9%`, bottom: `136px` };
  const dispatchRedux = useDispatch();
  const chartingRef = useRef(null);
  const { user } = useSelector((state) => state.user);
  const notes = useSelector((state) => state.patient.notes);
  const [newNote, setNewNote] = useState(``);
  const [isRendered, setIsRendered] = useState(false);
  const [shallowValue, setShallowValue] = useState(``);
  const [openTemplatesGate, setOpenTemplatesGate] = useState(false);
  const [phraseId, setPhraseId] = useState(null);
  const [phraseScope, setPhraseScope] = useState(null);
  const { patientId = ``, reportId = `` } = useSelector((state) => state?.readyToSign?.readyToSignModal);
  const { loadingCall, error } = useSelector((state) => state?.patient.notesFlag);
  const patientReport = useSelector((state) => state?.patient?.patientReport);

  useEffect(() => {
    if ((notes?.length > 1 && isRendered && newNote) || chartingRef.current) chartingRef.current.scrollTop = chartingRef.current.scrollHeight;
  }, [notes, isRendered, newNote]);
  useEffect(() => {
    if (patientId) dispatchRedux(logsApiCall(reportId, patientId));
    return () => dispatchRedux(setAllNotes([]))
  }, [patientId]);



  const onlySpaces = (str) => {
    return /^\s*$/.test(str);
  };
  const addNewNoteHandler = (e) => {
    if (e.keyCode === 8 && newNote.length < 1) {
      e.target.style.height = 0;
    }
    if (e.keyCode === 46 && newNote.length < 1) {
      e.target.style.height = 0;
    }
    if (e.target.style.height.split(`p`)[0] < 140) {
      e.target.style.height = 0;
      e.target.style.height = e.target.scrollHeight + `px`;
    }
    if (e.keyCode === 13) {
      if (!e.shiftKey && patientId) {
        if (!onlySpaces(newNote) && newNote !== `#`) {
          let note = {
            note: newNote,
            added_by_provider: user?.isProvider,
            _created_by: {
              firstName: `${user?.firstName}`,
              lastName: `${user?.lastName}`,
              isClinicalStaff: user?.isClinicalStaff,
              isProvider: user?.isProvider,
              providerType: user?.providerType,
            },
            date: momentWrapper().format(`ddd MMM DD YYYY HH:mm:ss z`),
          };

          const copyNotesArray = notes;
          copyNotesArray.push(note);

          dispatchRedux(setAllNotes(copyNotesArray));

          e.target.style.height = 0;
          setNewNote(``);
          postRequest(REPORT_ADD_NEW_NOTE + patientId + `?reportId=${reportId}`, { notes: newNote, phraseId: phraseId, phraseScope: phraseScope })
            .then(() => {
              setNewNote(``);
              setPhraseId(null);
              setPhraseScope(null);
            })
            .catch(() => {
              setPhraseId(null);
              setPhraseScope(null);
            });
        } else {
          e.target.style.height = 0;
          return;
        }
      }
    }
  };



  const hasDataCome = !loadingCall && !error

  return (
    <Box sx={{ border: `1px solid #2299c5`, borderRadius: `5px`, width: `100% !important` }}>
      <ToastContainer />
      <AutoWriteCredentials setShallowValue={setShallowValue} value={shallowValue} open={openTemplatesGate} setValue={setNewNote} />
      <Box
        sx={{
          backgroundColor: `rgb(22, 153, 197)`,
          color: `white`,
          padding: `10px`,
          fontFamily: `sans-serif`,
          textAlign: `start`,
          borderTopLeftRadius: `4px`,
          borderTopRightRadius: `4px`,
        }}
      >
        Charting
      </Box>
      <div>
        {error && (
          <Box sx={{ width: `100%`, textAlign: `center` }}>
            <ReportProblemIcon sx={{ color: `red` }} />
          </Box>
        )}
        {loadingCall ? <Box sx={{ height: `40.7vh`, overflow: `auto` }}>{chatSkelton}</Box>
          :
          hasDataCome && (
            <Box
              ref={(el) => {
                setIsRendered(true);
                chartingRef.current = el;
              }}
              sx={{
                borderBottom: `1px solid #c1c1c1`,
                backgroundColor: `white`,
                overflow: `auto`,
                p: 2,
                height: `40.7vh`,
              }}
            >
              {notes?.map((note) => {
                return (
                  <Fade in={note} key={note._id}>
                    <p
                      style={{
                        backgroundColor: note?.added_by_provider ? `rgba(223, 212, 119, 0.5)` : `rgba(119, 223, 218, 0.5)`,
                        padding: `10px`,
                        borderRadius: `10px`,
                      }}
                    >
                      {note.note}
                      <p key={notes.length + 1}>
                        {momentWrapper(note.dateCreated).format(`MM/DD hh:mm A`)}
                        <br />
                        {capitalizeFirstLetter(note?._created_by?.firstName) +
                          ` ` +
                          capitalizeFirstLetter(note?._created_by?.lastName) +
                          returnNull({
                            key: note?._created_by?.providerType,
                            successRetrun: `, ${note?._created_by?.providerType}`,
                            faliure: `, -`,
                          })}
                      </p>
                    </p>
                  </Fade>
                );
              })}

            </Box>
          )}
        {patientReport?.data && (
          <SignButton
            loadingReport={loadingReport}
            forwardPage={forwardPage}
            patientReport={patientReport}
            onClose={(dispatchTime, isOnlyClose) => onClose(dispatchTime, isOnlyClose)}
            isFromView={false}
            reportFromView={null}
            patientIdFromView={null}
            timeFromView={null}
            interactiveCommunicationFromView={null}
          />
        )}
        <SmartPhraseModal
          slectedPhraseId="patientOverViewChartingPhrases"
          setNewNote={setNewNote}
          newNote={newNote}
          setPhraseId={setPhraseId}
          style={style}
          setPhraseScope={setPhraseScope}
        >
          <Box sx={{ paddingTop: `2%` }}>
            <textarea
              disabled={!patientId}
              value={newNote}
              style={{
                bottom: 0,
                width: `100%`,
                minHeight: 64,
                resize: `none`,
                boxShadow: `0 0 3px 0px #5e575794`,
                borderRadius: `3px`,
                marginBottom: `-7.5px`,
              }}
              placeholder="Add note ..."
              onChange={(e) => {
                if (openTemplatesGate) {
                  setShallowValue(e.target.value);
                }
                setNewNote(e.target.value);
              }}
              onKeyDown={addNewNoteHandler}
              onKeyUp={(e) =>
                onKeyUpCredentialsHandler({
                  event: e,
                  openTemplatesHandler: (value) => setOpenTemplatesGate(value),
                })
              }
            />
          </Box>
        </SmartPhraseModal >
      </div>
    </Box>
  );
};
