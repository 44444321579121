import React from 'react';
import { AddPhrase } from './components/AddPhrase';
import { EditPhrase } from './components/EditSmartPhrase';
import DeleteIcon from '@material-ui/icons/Delete';
import { useSelector, useDispatch } from 'react-redux';
import { deleteRequest, getRequest, SMART_PHRASE_LIST } from '../../../../../crud/crud';
import { IconButton, Chip, InputAdornment, TableRow, TableCell } from '@mui/material';
import { successToast } from 'src/pages/common/components/snackBar/toast';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Paginator } from 'src/pages/common/components/pagination/Paginator';
import { capitalizationFirstLetterWithIAndCapitalizeTheMonitair, dynamicWriteCredential } from 'src/utils';
import { ToolTipProvider } from 'src/pages/common/components/Tooltip/ToolTipProvider';
import { setSmartPhrases } from 'src/redux/practices/practiceActions';
import { PulseSpinner } from 'src/pages/common/components/spinner/spinner';
import { InputField } from 'src/pages/common/components/InputField/InputField';
import { Search } from '@material-ui/icons';
import { ConditionallyRenderWrapper } from 'src/pages/common/HOC/ConditionallyRenderWrapper';

export const SmartPhrase = () => {
  const dispatch = useDispatch();
  const [toggle, setToggle] = React.useState(false);
  const smartPhraseFromRedux = useSelector((state) => state?.practice?.smartPhrases)
  const user = useSelector((state) => state.user.user);
  const [searchKey, setSearchKey] = React.useState(``);
  const [editObject, setEditObject] = React.useState({});
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [recordPerPage, setRecordPerPage] = React.useState(10);
  const [viewPhrase, setViewPhrase] = React.useState();
  const [phrases, setPhrase] = React.useState([]);
  const [phraseLength, setPhrasesLength] = React.useState(0);
  const [sortKey, setSortKey] = React.useState(`count`);
  const [sortOrder, setSortOrder] = React.useState(-1);
  const [forceRefresh, setForceRefresh] = React.useState(false);

  const sortHandler = (key) => {
    setSortKey(key);
    if (sortOrder === -1) setSortOrder(1);
    else setSortOrder(-1);
  };

  const capitalizeFirstLetter = (str) => {
    if (str) {
      // converting first letter to uppercase
      const capitalized = str.replace(/^./, str[0].toUpperCase());

      return capitalized;
    }
    return `-`
  }

  React.useEffect(() => {
    getAllPhrases();
  }, [toggle, page, recordPerPage, sortOrder, forceRefresh]);


  const editPhrase = (phrase, view = null) => {
    setViewPhrase(view);
    setEditObject(phrase);
    setOpen(true);
  };


  const getAllPhrases = () => {
    setLoading(true);
    let payLoad = {
      page: page,
      recordPerPage: recordPerPage,
      sortKey: sortKey,
      sortOrder: sortOrder,
      searchFilter: searchKey,
    };
    let query = `${payLoad?.id}?pageNumber=${payLoad?.page}&pageSize=${payLoad?.recordPerPage}&fieldName=${payLoad?.sortKey}&Sortorder=${payLoad?.sortOrder}&searchFilter=${payLoad?.searchFilter}`

    getRequest(SMART_PHRASE_LIST + query)
      .then((response) => {
        setLoading(false);
        if (response.status === 200) {
          setPhrase(response.data.phrases.phrases);
          setPhrasesLength(response.data.phrases.totalDocuments);
        }
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  const deletePhrase = (phraseItem) => {
    setLoading(true);
    let phrase = {
      phraseId: phraseItem._id,
      scope: phraseItem.scope,
    };
    let smartPhraseArray = smartPhraseFromRedux;
    deleteRequest(SMART_PHRASE_LIST + phrase.phraseId)
      .then((response) => {
        setLoading(false);
        if (response.status === 200) {
          setPage(1);
          const getFilterPhrase = smartPhraseArray.filter((item) => item._id !== phraseItem._id);
          dispatch(setSmartPhrases(getFilterPhrase));
          setToggle(!toggle);
          successToast(`Smart Phrase Deleted Successfully`);
        }
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  //   check for user role to assign action against role
  const checkRoleOfUser = (item) => {
    if (user.isSuperAdmin) {
      return (
        <>
          <IconButton title="Click to view Phrase" sx={{ color: `#1699c5` }} onClick={() => editPhrase(item, 1)} aria-label="edit">
            <VisibilityIcon />
          </IconButton>
          <IconButton title="Click to Edit Phrase" sx={{ color: `#1699c5` }} onClick={() => editPhrase(item)} aria-label="edit">
            <EditIcon />
          </IconButton>

          <IconButton
            disabled={!!(item.scope.toLowerCase() === `standard` || item.scope.toLowerCase() === `practice`)}
            onClick={() => deletePhrase(item)}
            title="Click to Delete Phrase"
            color="secondary"
            aria-label="delete"
          >
            <DeleteIcon />
          </IconButton>
        </>
      );
    } else {
      return (
        <>
          <IconButton title="Click to view Phrase" sx={{ color: `#1699c5` }} onClick={() => editPhrase(item, 1)} aria-label="edit">
            <VisibilityIcon />
          </IconButton>
          <IconButton disabled={true} title="Click to Edit Phrase" onClick={() => editPhrase(item)} aria-label="edit">
            <EditIcon />
          </IconButton>

          <IconButton disabled={true} onClick={() => deletePhrase(item)} title="Click to Delete Phrase" color="secondary" aria-label="delete">
            <DeleteIcon />
          </IconButton>
        </>
      );
    }
  }

  const tableRow = () => {
    return phrases?.length > 0 ? (
      phrases?.map((item, index) => {
        item.text = dynamicWriteCredential(item.text)
        return (
          <tr key={item._id}>
            <th scope="row">{(page - 1) * recordPerPage + index + 1}</th>
            <th title={item?.title}>
              <b>{item?.title?.length > 20 ? item.title.substring(0, 20) + `...` : capitalizeFirstLetter(item?.title)}</b>
            </th>
            <td >
              <ToolTipProvider
                toolTipProps={{
                  arrow: true,
                  placement: `top`,
                  title: capitalizationFirstLetterWithIAndCapitalizeTheMonitair(item.text)
                }}
                element={
                  <div>
                    {item.text.length > 80 ? capitalizationFirstLetterWithIAndCapitalizeTheMonitair(item.text).substring(0, 80) + `...` : capitalizationFirstLetterWithIAndCapitalizeTheMonitair(item.text)}
                  </div>
                }
              />
            </td >
            <td className="text-center">
              <Chip className="mt-2" size="small" label={item?.count} color="primary" variant="outlined" />
            </td>
            <td className="text-center">{checkRoleOfUser(item)}</td>
          </tr >
        );
      })
    ) : (
      <TableRow>
        <TableCell colSpan={8} align="center">
          No data available
        </TableCell>
      </TableRow>
    );
  }

  const renderSortIcon = (key, order) => {
    if (sortKey === key) {
      if (order === 1) {
        return <i className="fa fa-sort-asc ml-2" />;
      } else if (order === -1) {
        return <i className="fa fa-sort-desc ml-2" />;
      }
    }
    return <i className="fa fa-sort ml-2" />;
  }

  return (
    <div className="container mt-5">
      <div className="row no-gutters">
        <div className="col-auto">
          <AddPhrase toggle={toggle} setToggle={setToggle} setLoading={setLoading} loading={loading} setPage={setPage} />
          <ConditionallyRenderWrapper con={open}>
            <EditPhrase
              setOpen={setOpen}
              open={open}
              phrase={editObject}
              toggle={toggle}
              setToggle={setToggle}
              setLoading={setLoading}
              loading={loading}
              viewPhrase={viewPhrase}
            />
          </ConditionallyRenderWrapper>
        </div>
        <div className="col-6 ml-2">
          <div className="form-group">
            <InputField
              fullWidth
              size="small"
              label="Search Phrase..."
              onChange={({ target: { value } }) => {
                setSearchKey(value);
              }}
              value={searchKey}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => {
                      setPage(1);
                      setForceRefresh(prev => !prev);
                    }}>
                      <ToolTipProvider
                        toolTipProps={{
                          title: `Search`,
                          placement: `top`,
                          arrow: true,
                        }}
                        element={<Search sx={{ color: `#1699C5` }} />}
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </div>
        <div className="col-sm-12 mt-1">
          <table className={`table table-striped`}>
            <thead className="thead-info">
              <tr>
                <th scope="col">#</th>
                <th scope="col" onClick={() => sortHandler(`title`)}>
                  Title
                  {renderSortIcon(`title`, sortOrder)}
                </th>
                <th scope="col" onClick={() => sortHandler(`text`)}>
                  Phrase
                  {renderSortIcon(`text`, sortOrder)}
                </th>
                <th scope="col" onClick={() => sortHandler(`count`)}>
                  Phrase Usage
                  {renderSortIcon(`count`, sortOrder)}
                </th>
                <th scope="col" className="text-center">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>{tableRow()}</tbody>
          </table>
        </div>
      </div>

      <ConditionallyRenderWrapper con={phrases?.length}>
        <Paginator setPage={setPage} page={page} count={phraseLength} setRecordPerPage={setRecordPerPage} recordPerPage={recordPerPage} />
      </ConditionallyRenderWrapper>
      {loading && <PulseSpinner />}
    </div>
  )
};