import React, { useState } from 'react';
import { PulseSpinner } from '../../../../../../../common/components/spinner/spinner';
import { CustomizedDialogs } from 'src/pages/common/components/modal/CustomizeModal';
import { useSelector } from 'react-redux';
import { successToast, errorToast } from 'src/pages/common/components/snackBar/toast';
import { CREATE_TICKET, postRequest } from '../../../../../../../../crud/crud';
import { WordsFormatter } from 'src/pages/common/components/WordsFormatter/WordsFormatter';
import { Box, Grid } from '@mui/material';
import { MuiSelect } from 'src/pages/common/components/MuiSelect/MuiSelect';
import { cleanWordsFormatterVal } from 'src/utils';

export const CreateIssue = ({ createIssueModelHandler, setCreateIssueModalHandler, setIssueListFlag }) => {
  const [loading, setLoading] = useState(false);
  const [issuePriority, setIssuePriority] = useState(`Normal`);
  const [issueType, setIssueType] = useState(`Other`);
  const [issueText, setIssueText] = useState(``);

  const groupId = useSelector((state) => state?.user?.user?._practiceGroupId);

  const handleSubmit = (e) => {
    e.preventDefault();
    let filterValue = cleanWordsFormatterVal(issueText);
    if (!filterValue) {
      errorToast(`Comment text is empty.`);
      return;
    }
    setLoading(true);
    postRequest(CREATE_TICKET, {
      _practiceGroupId: groupId,
      issuePriority,
      issueType,
      issueText,
    })
      .then((response) => {
        setLoading(false);
        successToast(response?.data.message);
        setCreateIssueModalHandler((createIssueModelHandler) => !createIssueModelHandler);
        setIssueListFlag((p) => !p);
      })
      .catch((err) => {
        setLoading(false);
        errorToast(err?.response?.data?.message ? err?.response?.data?.message : err.response?.data?.error);
      });
    /// CALLING API TO CREATE NEW ISSUE
  };

  return (
    <CustomizedDialogs
      title="Create New Issue"
      open={createIssueModelHandler}
      setOpen={() => {
        setCreateIssueModalHandler((createIssueModelHandler) => !createIssueModelHandler);
      }}
      size="sm"
      fullWidth={true}
      notShowDividers={true}
      showCloseButton={false}
    >
      <form onSubmit={handleSubmit}>
        <Box sx={{ mt: 2 }}>
          <Grid container rowSpacing={2} columnSpacing={2}>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <MuiSelect
                controlProps={{ fullWidth: true, id: `priority`, size: `small` }}
                label="Priority"
                isSimple={true}
                options={[`Normal`, `Urgent`]}
                selectProps={{
                  label: `Priority`,
                  id: `priority`,
                  name: `issuePriority`,
                  onChange: (event) => {
                    setIssuePriority(event.target.value);
                  },
                  value: issuePriority,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <MuiSelect
                controlProps={{ size: `small`, fullWidth: true, id: `IssueType` }}
                label="Issue Type"
                isSimple={true}
                options={[
                  `Import/Add patient`,
                  `Dashboard`,
                  `Patients`,
                  `Messenger`,
                  `Telemedicine`,
                  `Analytics`,
                  `Administration`,
                  `Billing`,
                  `Request new feature`,
                  `Other`,
                ]}
                selectProps={{
                  label: `Issue Type`,
                  id: `IssueType`,
                  name: `IssueType`,
                  onChange: (event) => {
                    setIssueType(event.target.value);
                  },
                  value: issueType,
                }}
              />
            </Grid>
          </Grid>
        </Box>
        <div className="form-group">
          <WordsFormatter placeHolder={`Add Description`} setKeyValue={setIssueText} readyToSet={true} />
        </div>
        <div style={{ display: `flex`, justifyContent: `flex-end` }}></div>

        <Box sx={{ display: `flex`, justifyContent: `flex-end`, mt: { xs: `30%`, sm: `15%`, md: `15%`, lg: `15%`, xl: `15%` } }}>
          <div style={{ display: `flex`, justifyContent: `flex-end` }}>
            <button className="btn btn-outline-info" type="submit" size="sm" disabled={loading}>
              Save
            </button>
          </div>
        </Box>
      </form>
      {loading && <PulseSpinner />}
    </CustomizedDialogs>
  );
};
