import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import css from './reassignPatient.module.css';
import { getRequest, GET_ALL_MD_BY_PRACTICE_ID, GET_ALL_QHCP_BY_PRACTICE_ID, GET_LIST_OF_ALL_THE_PROVIDER_OR_CLINICAL_USER_PATIENTS, postRequest, REASSIGN_PATIENTS } from '../../../../../../crud/crud';
import { PulseSpinner } from '../../../../../common/components/spinner/spinner';
import { addNewUserMountFlag } from '../../../../../../redux/resmed/resmedActions';
import { CustomizedDialogs } from 'src/pages/common/components/modal/CustomizeModal';
import { successToast } from 'src/pages/common/components/snackBar/toast';
import { momentWrapper } from 'src/momentWrapper';
import { Container, Grid, MenuItem, TextField, Zoom } from '@mui/material';
import { capitalizeFirstLetter } from 'src/utils';
import { CapitalizeFirstLetterComp } from 'src/pages/common/HOC/CapitalizeFirstLetterComp';

let assignedClinicalStaff = null;

export const ReassignPatient = ({ reassignModal, setReassignModal, setValue }) => {
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state?.user)
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [providerList, setProviderList] = useState([]);
  const [clinicalStaffList, setClinicalStaffList] = useState([]);
  const [providerOrStaffFlag, setProviderOrStaffFlag] = useState(``);
  const [listOfPatients, setListOfPatients] = useState([]);

  //SELECTED VALUES
  const [selectedProvider, setSelectedProvider] = useState(``);
  const [selectedClinicalUser, setSelectedClinicalUser] = useState(``);

  // LIST OF ALL THE TRANSFER PATIENTS
  const [listOfTransferPatients, setListOfTransferPatients] = useState([]);

  // RE-ASSIGNED PATIENTS
  const [assignProviderList, setAssignProviderList] = useState([]);
  const [assignClinicalStaffList, setAssignClinicalStaffList] = useState([]);

  // SORT VALUES
  const [sortKey, setSortKey] = useState(`createdAt`);
  const [sortOrder, setSortOrder] = useState(1);

  //RE-ASSIGNED SELECTED VALUES
  const [assignSelectedProvider, setAssignSelectedProvider] = useState(``);
  const [assignSelectedClinicalUser, setAssignSelectedClinicalUser] = useState(``);

  // Re-Rerender to fetch values from select after returning from step-2 form
  const [selectedIdForFetchDataFromSelector, setSelectedIdForFetchDataFromSelector] = useState(``);

  // GET LIST OF ALL THE PROVIDERS

  useEffect(() => {
    if (!user?.isGroupAdmin) {
      getRequest(GET_ALL_MD_BY_PRACTICE_ID + user._practiceId)
        .then((response) => {
          setProviderList(response.data.MD);
          setAssignProviderList(response.data.MD);
        })
        .catch((err) => {

        });
    }
  }, [user, step]);

  // GET LIST OF ALL THE CLINICAL STAFF
  useEffect(() => {
    if (!user?.isGroupAdmin) {
      getRequest(GET_ALL_QHCP_BY_PRACTICE_ID + user._practiceId)
        .then((response) => {
          setClinicalStaffList(response.data.QHCPUsers);
          setAssignClinicalStaffList(response.data.QHCPUsers);
        })
        .catch((err) => {

        });
    }
  }, [user]);

  //RE-ASSIGNED CLINICAL STAFF LIST OPTIONS
  useEffect(() => {
    if (assignClinicalStaffList) {
      assignedClinicalStaff = assignClinicalStaffList.map((staff) => {
        return <option key={staff._id} value={staff._id}>{capitalizeFirstLetter(`${staff.firstName} ${staff.lastName}`)}</option>;
      });
    }
  }, [assignClinicalStaffList]);

  //To wraps in a dependency
  useEffect(() => {
    if (selectedIdForFetchDataFromSelector) {
      getListOfPatients(selectedIdForFetchDataFromSelector);
    }
  }, [sortKey, sortOrder]);


  const getSortDirection = (sortOrder) => {
    if (sortOrder === -1) return `ASC`;
    if (sortOrder === 1) return `DESC`;
    return null;
  };
  // GETTING LIST OF ALL THE patient's
  const getListOfPatients = (id) => {
    let pageNumber = 1;
    setLoading(true);
    if (!user?.isGroupAdmin) {
      postRequest(GET_LIST_OF_ALL_THE_PROVIDER_OR_CLINICAL_USER_PATIENTS + id, {
        pageNumber,
        pageSize: 100000,
        sortField: sortKey,
        sortOrder: getSortDirection(sortOrder),
      })
        .then((response) => {
          setStep(3);
          setLoading(false);

          setListOfPatients(response?.data?.patients);
          let providerExist = providerList.find((p) => {
            let bool;
            if (p._id === id) {
              bool = true;
              return bool;
            } else {
              bool = false;
              return bool;
            }
          });

          let clinicalStaffExist = clinicalStaffList.find((c) => {
            let bool;
            if (c._id === id) {
              bool = true;
              return bool;
            } else {
              bool = false;
              return bool;
            }
          });

          if (providerExist) {
            let pList = providerList.filter((item) => item._id !== id);

            assignProviderList(pList);
          }

          if (clinicalStaffExist) {
            let cList = assignClinicalStaffList.filter((item) => item._id !== id);
            assignClinicalStaffList(cList);
          }
        })
        .catch(() => {
          setStep(3);
          setLoading(false);
        });
    }
  };

  // UPDATE SELECTED LIST OF PATIENTS
  const updateSelectedListOfPatients = (patientId, flag) => {
    if (flag) {
      setListOfTransferPatients([...listOfTransferPatients, patientId]);
    } else {
      let result = listOfTransferPatients.filter((patient) => {
        return patient !== patientId;
      });
      setListOfTransferPatients([...result]);
    }
  };
  // UPDATE ALL SELECTED LIST OF PATIENTS
  const updateSelectedAllListOfPatients = (listOfPatients, flag) => {
    const patientId = listOfPatients?.map((patient) => patient?._id);

    if (flag) {
      setListOfTransferPatients(patientId);
    } else {
      setListOfTransferPatients([]);
    }
  };

  // REASSIGN PATIENTS
  const _reassignPatients = () => {
    setLoading(true);
    let payload = {
      patientIds: listOfTransferPatients,
      userId: providerOrStaffFlag === `provider` ? assignSelectedProvider : assignSelectedClinicalUser,
      userRole: providerOrStaffFlag,
    };
    postRequest(REASSIGN_PATIENTS, payload)
      .then(() => {
        setLoading(false);
        setStep(1);
        setListOfTransferPatients([]);
        getListOfPatients(selectedIdForFetchDataFromSelector);
        dispatch(addNewUserMountFlag()); // NEW USER ADDED, RESET REFRESH FLAG
        successToast(`Patients re-assigned successfully`);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  // SORT HANDLER
  const sortHandler = (key) => {
    setSortKey(key);
    if (sortOrder === -1) setSortOrder(1);
    else setSortOrder(-1);
  };

  return (
    <div>
      <CustomizedDialogs
        title="Primary Reassign patient"
        open={reassignModal}
        setOpen={() => {
          setValue(0)
          setReassignModal(false)
        }}
        size="md"
        fullWidth={true}
        notShowDividers={true}
        showCloseButton={false}
      >
        <div>
          {step === 2 ? (
            providerOrStaffFlag === `provider` ? (
              <div className="col-md-5">
                <div style={{ marginBottom: `15px` }}>
                  <button type="button" className="btn btn-info btn-sm" onClick={() => setStep(3)}>
                    back
                  </button>
                </div>

                <div>
                  <TextField
                    fullWidth
                    select
                    size="medium"
                    label="Provider"
                    name="listOfProviders"
                    value={assignSelectedProvider}
                    disabled={loading}
                    onChange={(event) => {
                      setAssignSelectedProvider(event.target.value);
                      setProviderOrStaffFlag(`provider`);
                    }}
                  >
                    <MenuItem defaultValue value="">
                      Select
                    </MenuItem>
                    {assignProviderList?.length > 0 &&
                      assignProviderList?.map((provider) => (
                        <MenuItem
                          key={provider._id}
                          value={provider._id}
                        >{capitalizeFirstLetter(`${provider.firstName} ${provider.lastName}`)}</MenuItem>
                      ))}
                  </TextField>
                </div>
              </div>
            ) : (
              <div className="col-md-5">
                <button type="button" className="btn btn-info btn-sm" onClick={() => setStep(3)}>
                  back
                </button>
                <div className="form-group">
                  <label id={css.customLabel} htmlFor="listOfClinicalUsers">
                    Clinical users
                  </label>
                  <select
                    id={css.selector}
                    name="listOfClinicalUsers"
                    disabled={loading}
                    value={assignSelectedClinicalUser}
                    onChange={(event) => {
                      setAssignSelectedClinicalUser(event.target.value);
                      setProviderOrStaffFlag(`clinicalStaff`);
                    }}
                  >
                    <option defaultValue value="">
                      Select
                    </option>
                    {assignedClinicalStaff}
                  </select>
                </div>
              </div>
            )
          ) : (
            <>
              <h6 style={{ marginBottom: `10px` }}>Filter by:</h6>
              <Container sx={{ mt: 2, mb: 2 }}>
                <Grid container>
                  <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
                    <TextField
                      size="medium"
                      fullWidth
                      label="Providers"
                      select
                      name="listOfProviders"
                      value={selectedProvider}
                      disabled={loading}
                      onChange={(event) => {
                        setSelectedProvider(event.target.value, `provider`);
                        setSelectedClinicalUser(``);
                        getListOfPatients(event.target.value);
                        setProviderOrStaffFlag(`provider`);
                        setSelectedIdForFetchDataFromSelector(event.target.value);
                      }}
                      style={{ textTransform: `capitalize` }}
                    >
                      <MenuItem defaultValue value="">
                        Select
                      </MenuItem>
                      {providerList?.length > 0 &&
                        providerList.map((provider) => (
                          <MenuItem key={provider._id} value={provider._id} style={{ textTransform: `capitalize` }}>
                            {capitalizeFirstLetter(`${provider.firstName} ${provider.lastName}`)}
                            {``}
                          </MenuItem>
                        ))}
                    </TextField>
                  </Grid>
                  <Grid sx={{ textAlign: `center` }} item xs={2} sm={2} md={2} lg={2} xl={2}>
                    <div style={{ paddingTop: `10px` }}>- OR -</div>
                  </Grid>
                  <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
                    {` `}
                    <TextField
                      size="medium"
                      fullWidth
                      label="Clinical users"
                      select
                      name="listOfClinicalUsers"
                      disabled={loading}
                      value={selectedClinicalUser}
                      onChange={(event) => {
                        setSelectedClinicalUser(event.target.value, `clinicalStaff`);
                        setSelectedProvider(``);
                        getListOfPatients(event.target.value);
                        setProviderOrStaffFlag(`clinicalStaff`);
                        setSelectedIdForFetchDataFromSelector(event.target.value);
                      }}
                      style={{ textTransform: `capitalize` }}
                    >
                      <MenuItem defaultValue value="">
                        Select
                      </MenuItem>
                      {clinicalStaffList.map((staff) => (
                        <MenuItem
                          key={staff._id}
                          value={staff._id}
                          style={{ textTransform: `capitalize` }}
                        >{capitalizeFirstLetter(`${staff.firstName} ${staff.lastName}`)}</MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
              </Container>

              {step === 3 ? (
                listOfPatients?.length > 0 ? (
                  <table className="table table-striped">
                    <thead style={{ display: `block` }}>
                      <tr>
                        <th scope="col" style={{ width: `10%` }}>
                          {` `}
                          <input
                            type="checkbox"
                            onChange={(event) => {
                              updateSelectedAllListOfPatients(listOfPatients, event.target.checked);
                            }}
                          />
                        </th>
                        <th scope="col" onClick={() => sortHandler(`lastName`)} style={{ width: `10%` }}>
                          PATIENT LAST NAME
                          {sortKey === `lastName` && sortOrder === 1 ? (
                            <i className="fa fa-sort-asc ml-2" />
                          ) : sortKey === `lastName` && sortOrder === -1 ? (
                            <i className="fa fa-sort-desc ml-2" />
                          ) : (
                            <i className="fa fa-sort ml-2" />
                          )}
                        </th>
                        <th scope="col" style={{ width: `10%` }}>
                          PATIENT FIRST NAME
                        </th>
                        <th scope="col" style={{ width: `15%` }}>
                          DATE OF BIRTH
                        </th>
                        <th scope="col" onClick={() => sortHandler(`provider`)} style={{ width: `15%` }}>
                          PROVIDER
                          {sortKey === `provider` && sortOrder === 1 ? (
                            <i className="fa fa-sort-asc ml-2" />
                          ) : sortKey === `provider` && sortOrder === -1 ? (
                            <i className="fa fa-sort-desc ml-2" />
                          ) : (
                            <i className="fa fa-sort ml-2" />
                          )}
                        </th>
                        <th scope="col" onClick={() => sortHandler(`clinicalUser`)} style={{ width: `15%` }}>
                          CLINICAL STAFF
                          {sortKey === `clinicalUser` && sortOrder === 1 ? (
                            <i className="fa fa-sort-asc ml-2" />
                          ) : sortKey === `clinicalUser` && sortOrder === -1 ? (
                            <i className="fa fa-sort-desc ml-2" />
                          ) : (
                            <i className="fa fa-sort ml-2" />
                          )}
                        </th>
                        <th scope="col" style={{ width: `10%` }}>
                          STATUS
                        </th>
                      </tr>
                    </thead>
                    <tbody style={{ display: `block`, height: `38vh`, overflowY: `auto` }}>
                      {listOfPatients?.map((patient, index) => {
                        return (
                          <Zoom in={patient} mountOnEnter unmountOnExit style={{ transitionDelay: patient ? `${index}00ms` : `0ms` }}>
                            <tr key={patient?._id}>
                              <td style={{ width: `10%` }}>
                                <input
                                  type="checkbox"
                                  checked={!!listOfTransferPatients.includes(patient?._id)}
                                  onChange={(event) => {
                                    updateSelectedListOfPatients(patient?._id, event.target.checked);
                                  }}
                                />
                              </td>
                              <td style={{ width: `10%` }}>
                                {capitalizeFirstLetter(patient?.lastName)}
                              </td>
                              <td style={{ width: `10%` }}>
                                {capitalizeFirstLetter(patient?.firstName)}
                              </td>
                              <td style={{ width: `15%` }}>{momentWrapper(patient?.dateOfBirth, `YYYY-MM-DD`).format(`MM/DD/YYYY`)}</td>
                              <td style={{ width: `15%` }}>
                                <CapitalizeFirstLetterComp>
                                  {`${patient?.provider.firstName} ${patient?.provider?.lastName}`}
                                </CapitalizeFirstLetterComp>
                              </td>
                              <td style={{ width: `15%` }}>
                                <CapitalizeFirstLetterComp>
                                  {`${patient?.clinicalUser.firstName} ${patient?.clinicalUser?.lastName}`}
                                </CapitalizeFirstLetterComp>

                              </td>
                              <td style={{ textTransform: `uppercase`, width: `10%` }}>{patient?.status?.current}</td>
                            </tr>
                          </Zoom>
                        );
                      })}
                    </tbody>
                  </table>
                ) : (
                  <div className="d-flex justify-content-center align-items-center" style={{ color: `rgb(193, 193, 193)` }}>
                    Patients record not found.
                  </div>
                )
              ) : null}
            </>
          )}
        </div>
        <div className="modal-footer d-flex justify-content-center" id={css.reassignPatientModalFooter}>
          {step === 2 ? (
            <button
              type="button"
              disabled={loading}
              className="btn btn-outline-info"
              id={css.reassignPatientModalButton}
              onClick={() => _reassignPatients()}
            >
              SAVE
            </button>
          ) : (
            <button
              type="button"
              className="btn btn-outline-info"
              id={css.reassignPatientModalButton}
              onClick={() => setStep(2)}
              disabled={listOfPatients?.length <= 0}
            >
              NEXT
            </button>
          )}
        </div>
        {loading && <PulseSpinner />}
      </CustomizedDialogs>
    </div>
  );
}