import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Device } from 'twilio-client';
import { PatientLogs } from '../patientCommunication/logs/logs';
import css from './telemedicineDialer.module.css';
import { momentWrapper } from 'src/momentWrapper';
import {
  PATIENT_TWILIO_VOICE_CALL_TOKEN_VERIFY_URL,
  TELEMEDICINE_USER_LOGS,
  postRequest,
  GET_PATIENT_BY_PHONE_NUMBER,
  getRequest,
} from '../../../../../../crud/crud';

import { startAudioCall, endAudioCall } from '../../../../../../redux/timer/timerActions';
import MicOffIcon from '@mui/icons-material/MicOff';
import PhoneIcon from '@mui/icons-material/Phone';
import BackspaceIcon from '../../../../../../assets/icons/backspace.svg';
import { PatientBasicInfoTelemedicine } from '../PatientBasicInfo/PatientBasicInfoTelemedicine';
import { TelemedicineVideoWindow } from '../telemedicineVideoWindow/telemedicineVideoWindow';
import CallEndIcon from '@mui/icons-material/CallEnd';
import MissedVideoCallIcon from '@mui/icons-material/MissedVideoCall';
import { CallLogs } from '../CallLogs/Logs';
import { newSocket as socket } from 'src/pages/common/HOC/SocketWrapper';
import { PatientMessages } from '../../../../modules/patient2/components/patientCommunication/message/message';
import { messagesApiCall, saveActiveConn, setAllMessages } from 'src/redux/patient/patientActions';
import { InputField } from 'src/pages/common/components/InputField/InputField';
import { Box, Stack } from '@mui/material';
import { phoneRegex } from 'src/regexes';
import axios from 'axios'

const twilioDevice = new Device();
const messagesToken = axios.CancelToken.source()

export const TelemedicineDialer = () => {
  const {
    user: { auth, user },
  } = useSelector((state) => state);
  // const { auth, user: { _id: userID } } = useSelector((state) => state?.user)
  const startAudioCallToDispatch = useDispatch();
  const endAudioCallToDispatch = useDispatch();
  const [dialer, setDialer] = useState(``);
  const [muted, setMuted] = useState(false);
  const [onPhone, setOnPhone] = useState(false);
  const [ready, setReady] = useState(false);
  const [patientData, setPatientData] = useState(null);
  const [summaryData, setSummaryData] = useState();
  const [videoCallFlag, setVideoCallFlag] = useState(false);
  const [chartingToggle, setChartingToggle] = useState(false);
  const [messageToggle, setMessageToggle] = useState(true);
  const [telemedicineLogs, setTelemedicineLogs] = useState({});
  const [callEndReady, setCallEndReady] = useState(false);
  const [sets, setSets] = useState();
  const dispatchRedux = useDispatch();

  const [refreshFlag, setRefreshFlag] = useState(false);
  ///patientMessages
  const handleMessageToggle = () => {
    if (chartingToggle) {
      setChartingToggle(!chartingToggle);
      setMessageToggle(!messageToggle);
    } else {
      setMessageToggle(!messageToggle);
    }
  };
  const videoCallStatus = useSelector((state) => state.timer.videoCall);

  useEffect(() => {
    if (telemedicineLogs) {
      socket.on(`audioCallTelemedicineLogs`, (data) => {
        let copyTelemedicineLogs = [...telemedicineLogs];
        let lastIndex = copyTelemedicineLogs?.length - 1;
        copyTelemedicineLogs.pop(lastIndex);
        copyTelemedicineLogs.unshift(data);
        setTelemedicineLogs(copyTelemedicineLogs);
      });

      return () => {
        //
        socket.off(`audioCallTelemedicineLogs`);
      };
    }
  }, [telemedicineLogs]);
  useEffect(() => {

    getRequest(`${PATIENT_TWILIO_VOICE_CALL_TOKEN_VERIFY_URL}${undefined}?isTelemedicine=` + true)
      .then((res) => {
        let encryptedUserId = res.data.token.slice(-24);
        const getLastFourWords = () => {
          let arr = [];
          for (let i = 1; i < 5; i++) {
            arr.push(encryptedUserId.charAt(encryptedUserId.length - i));
          }
          return arr.reverse().join(``);
        };
        const convertAsciiChar = (words) => {
          let arr = [];
          for (let i = 0; i < 4; i++) {
            let char = String.fromCharCode(words.charCodeAt(i) - 1);
            arr.push(char);
          }
          return arr.join(``);
        };
        let lastFourWords = getLastFourWords();
        let convertedWords = convertAsciiChar(lastFourWords);
        let finalEncryptedUserId = `${encryptedUserId.slice(0, encryptedUserId.length - 4)}${convertedWords}`;
        if (finalEncryptedUserId === user?._id) {
          let idRemovedToken = res?.data?.token.slice(0, res.data?.token.length - 24);
          twilioDevice.setup(idRemovedToken);
        }
        // twilioDevice.setup(res.data.token);
        setReady(true);
      })
      .catch(() => { });

    fetchTelemedicineLogs();

    twilioDevice.disconnect(function () {
      setOnPhone(false);
      endAudioCallToDispatch(endAudioCall());
      // Blank object when call end
      setPatientData();
      setSummaryData();
    });

    Device.ready(function () { });

  }, []);

  const fetchTelemedicineLogs = () => {
    let data = {
      userId: user?._id,
      practiceId: user?._practiceId,
    };

    postRequest(TELEMEDICINE_USER_LOGS, data)
      .then((res) => {
        setTelemedicineLogs(res?.data?.log);
        // setTelemedicineLogs
      })
      .catch(() => { });
  };

  const backspaceHandler = () => {
    let temp = dialer;
    if (temp.length) temp = temp.substr(0, temp.length - 1);
    phoneMask(temp);
  };

  const phoneMask = (val) => {
    let temp = ``;
    let x = val.replace(/\D/g, ``).match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    let tempVal = x[3] ? `-` + x[3] : ``;
    temp = !x[2] ? x[1] : `(` + x[1] + `) ` + x[2] + tempVal;
    setDialer(temp);
  };

  const callHandler = (num) => {
    if (!onPhone) {
      setMuted(false);
      setOnPhone(true);
      twilioDevice.connect({
        number: num,
        token: auth,
        isTelemedicine: true,
        userId: user?._id,
      });
      getPatientByPhoneNumberApiCall(num);
      startAudioCallToDispatch(startAudioCall(`outGoing`));
      dispatchRedux(saveActiveConn(twilioDevice))
    } else {
      twilioDevice.disconnectAll();
      setPatientData(null);
      dispatchRedux(saveActiveConn(false))
    }
  };

  const videoCallHandler = (num) => {
    getPatientByPhoneNumberApiCall(num);

    setVideoCallFlag(true);
  };
  const propsFromVideoWindowReceiver = () => {
    setPatientData();
    setSummaryData();
  };
  const getPatientByPhoneNumberApiCall = (num) => {
    const makeCall = (res) => {
      dispatchRedux(messagesApiCall(res?.data?._id, messagesToken.token));
      let sets = [];
      for (let key in res.data?.device?.set) {
        sets.push({ key, data: res.data?.device?.set[key] });
      }

      localStorage.setItem(`deviceName`, res.data?.device?.manufacturer);
      setSets(sets);
      setPatientData(res?.data);
      setCallEndReady(false);
    }
    setCallEndReady(true);
    const paramPayload = `?provider=true&clinicalUser=true&startDate="${momentWrapper()
      .startOf(`month`)
      .toISOString()}"&endDate="${momentWrapper().toISOString()}"&timeLogs=true`;
    getRequest(GET_PATIENT_BY_PHONE_NUMBER + num + paramPayload)
      .then(makeCall)
      .catch(() => {
        setCallEndReady(false);
        // errorToast(err.response?.data?.message ? err.response?.data?.message : err.response?.data?.error);
      });
  };

  // Handle muting
  const handleToggleMute = () => {
    let muteTemp = !muted;

    setMuted(muteTemp);
    twilioDevice.activeConnection().mute(muteTemp);
  };
  const handleChartingToggle = () => {
    if (messageToggle) {
      setChartingToggle(!chartingToggle);
      setMessageToggle(!messageToggle);
    } else {
      setChartingToggle(!chartingToggle);
    }
  };

  const callingLogs = (num) => {
    setDialer(num);
    if (!onPhone) {
      setMuted(false);
      setOnPhone(true);
      twilioDevice.connect({
        number: num,
        token: auth,
        isTelemedicine: true,
        userId: user?._id,
      });
      getPatientByPhoneNumberApiCall(num);

      startAudioCallToDispatch(startAudioCall(`outGoing`));
    } else {
      twilioDevice.disconnectAll();
    }
  };

  const videoCalling = (num) => {
    setDialer(num);
    getPatientByPhoneNumberApiCall(num);

    setVideoCallFlag(true);
  };

  const callEndingButton = () => {
    if (!callEndReady) {
      callHandler(dialer);
    }
  };
  return (
    <div style={{ width: `100%` }}>
      <div id={css.telemedicineDialer} style={{ pointerEvents: videoCallStatus ? `none` : null }}>
        <div
          style={{
            padding: `20px 5px 15px 4px`,
            marginLeft: `2px`,
          }}
        >
          <Stack direction="row" spacing={1}>
            <Box sx={{ width: `60%` }}>
              <InputField
                size="small"
                fullWidth={true}
                inputProps={{
                  style: {
                    fontSize: `x-large`,
                  },
                }}
                id={css.searchPatientDialer}
                type="text"
                value={dialer}
                onChange={(e) => phoneMask(e.target.value)}
              />
            </Box>
            <Box sx={{ alignItems: `center`, display: `flex`, width: `20%` }}>
              <img
                src={BackspaceIcon}
                alt="img"
                onClick={() => backspaceHandler()}
                style={{ marginLeft: `0.5em`, width: `2em`, height: `auto`, paddingRight: 5 }}
              />
            </Box>
            {!onPhone ? (
              <>
                <Box sx={{ width: `20%`, display: `flex` }}>
                  <button id={css.dialPadButton} className="btn" disabled={!ready || !phoneRegex.test(dialer)} onClick={() => callHandler(dialer)}>
                    <PhoneIcon /> Voice Call
                  </button>
                </Box>
                <Box sx={{ width: `20%`, display: `flex` }}>
                  <button
                    id={css.dialPadButton}
                    className="btn"
                    disabled={!ready || !phoneRegex.test(dialer)}
                    onClick={() => videoCallHandler(dialer)}
                  >
                    <MissedVideoCallIcon /> Video Call
                  </button>
                </Box>
              </>
            ) : (
              <>
                <Box sx={{ width: `20%`, display: `flex` }}>
                  <span>{`Calling ...`}</span>
                  <span style={{ marginLeft: 15, cursor: `pointer` }} onClick={handleToggleMute}>
                    <MicOffIcon fontSize="large" style={{ color: !muted ? `rgb(230, 230, 230)` : null }} />
                  </span>
                </Box>
                <Box sx={{ width: `20%`, display: `flex` }}>
                  <span style={{ marginLeft: 15, cursor: `pointer` }}>
                    <CallEndIcon onClick={callEndingButton} fontSize="large" color="secondary" />
                  </span>
                </Box>
              </>
            )}
          </Stack>

          <div className={css.rightAddon}></div>
        </div>
      </div>

      <TelemedicineVideoWindow
        videoCallFlag={videoCallFlag}
        setVideoCallFlag={setVideoCallFlag}
        dialer={dialer}
        propsFromVideoWindowReceiver={propsFromVideoWindowReceiver}
        fetchTimeLogs={fetchTelemedicineLogs}
      />

      {patientData &&
        <>

          <PatientProf patientData={patientData}
            sets={sets}
            summaryData={summaryData}
            setSummaryData={setSummaryData}
            refreshFlag={refreshFlag}
            setRefreshFlag={setRefreshFlag}
            handleMessageToggle={handleMessageToggle}
            messageToggle={messageToggle}
            handleChartingToggle={handleChartingToggle}
            chartingToggle /></>
      }
      {!patientData && <CallLogs videoCalling={videoCalling} telemedicineLogs={telemedicineLogs} calling={callingLogs} />}
    </div>
  );
};



const PatientProf = ({ patientData,
  sets,
  summaryData,
  setSummaryData,
  refreshFlag,
  setRefreshFlag,
  handleMessageToggle,
  messageToggle,
  handleChartingToggle,
  chartingToggle }) => {
  const dispatchRedux = useDispatch()
  useEffect(() => {
    return () => {
      dispatchRedux(setAllMessages([]))
      messagesToken.cancel()
    }
  }, [patientData])
  return <div id={css.patientComponent}>
    <PatientBasicInfoTelemedicine data={patientData} sets={sets} summaryData={summaryData} setSummaryData={setSummaryData} />
    <div style={{ width: `30%`, paddingTop: 10 }}>
      <PatientMessages
        reportId={null}
        refreshFlag={refreshFlag}
        setRefreshFlag={setRefreshFlag}
        handleMessageToggle={handleMessageToggle}
        toggle={messageToggle}
        isTel={true}
      />
      <PatientLogs
        patientId={patientData?._id}
        reportId={patientData?.latestReport?._id}
        handleChartingToggle={handleChartingToggle}
        toggle={chartingToggle}
      />
    </div>
  </div>
}