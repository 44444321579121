import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { momentWrapper } from 'src/momentWrapper';
import { communcicationWithPatient, handleTimer, saveTimerUser } from 'src/redux/patient/patientActions';
import css from './basicDetails.module.css';
import { Stack } from '@mui/material';
import { formatDurationHms } from 'src/utils';
import moment from 'moment';

let t = null;

export const Timer = ({ setTimeLogModal, setVisibleFlag, visibleFlag }) => {
  const { videoCall = false, audioCall = false } = useSelector((state) => state?.timer);
  const timer = useSelector((state) => state.patient.timer);
  const { toggleButton, startTime } = timer
  const patientData = useSelector((state) => state.patient.patientData);
  const userId = useSelector((state) => state.patient?.patientData?.data?._id);
  const timerView = useRef(null)
  const initializeTimer = useRef(0)
  const dispatchRedux = useDispatch();
  const isChatting = useSelector((state) => state?.patient?.isChatting);
  const descriptionForTimer = useSelector((state) => state?.patient?.descriptionText);
  const location = useLocation();

  const setTimer = (seconds, isResetOrStart) => {
    if (timerView.current) {
      let time = formatDurationHms(seconds)
      timerView.current.innerHTML = `Time : ${time}`
      if (isResetOrStart) initializeTimer.current = seconds
    }
  }



  useEffect(() => {
    console.log(`inherererercalled`, patientData?.data?.timeMonitored)
    setTimer(patientData?.data?.timeMonitored, true)
  }, [patientData?.data?._id, patientData?.data?.timeMonitored, timerView, initializeTimer]);



  useEffect(() => {

    if (toggleButton) {
      t = setInterval(() => {
        initializeTimer.current = initializeTimer.current + 1
        setTimer(initializeTimer.current)
      }, 1000);
    } else if (t) {
      clearInterval(t);

    }
  }, [toggleButton, initializeTimer]);


  const trigger = () => {
    if (userId) {
      let timeStampTemp = momentWrapper().toISOString();
      if (toggleButton) {
        const timeDiff = moment(new Date()).diff(moment(startTime), `seconds`);
        dispatchRedux(handleTimer({ status: false, timeEnd: timeStampTemp }));
        if (timeDiff > 5) {
          dispatchRedux(communcicationWithPatient({ chatting: false, text: `` }));

          dispatchRedux(
            saveTimerUser({
              videoOrAudioOn: null,
              updateVideoorAudioLogStatus: null,
              callSuccess: null,
              statuss: isChatting ? descriptionForTimer : `View graphs and reports`,
              sources: `View Report`,
            })
          );
        } else setTimer(patientData?.data?.timeMonitored, true)
      } else {
        dispatchRedux(handleTimer({ status: true }));
      }
    }
  };

  return (<>
    <Stack justifyContent={`right`} alignItems={`center`}>
      <Stack justifyContent={`space-between`} direction={`row`}>
        <i
          className="fa fa-clock-o"
          style={{
            fontSize: `x-large`,
            color: `#2196F3`,
            marginRight: `5px`,
            cursor: `pointer`,
          }}
          onClick={() => userId && setTimeLogModal(true)}
        />
        <label className={css.switch}>
          {/* Timer Toggle Button */}
          <input
            type="checkbox"
            checked={toggleButton}
            onChange={() => trigger()}
            id="my-timer-button"
            disabled={!!(momentWrapper().format(`YYYYMM`) > momentWrapper(location?.endDateOfReport).format(`YYYYMM`) || videoCall || audioCall)}
          />
          <span className={css.slider}></span>
        </label>
        <div>
          <button
            className="btn"
            id={css.collapseButton}
            onClick={() => setVisibleFlag(!visibleFlag)}
            type="button"
            data-toggle="collapse"
            data-target="#patientBasicInfo"
            aria-expanded="true"
            aria-controls="patientBasicInfo"
          >
            {visibleFlag ? <i className="fa fa-caret-down" aria-hidden="true"></i> : <i className="fa fa-caret-right" aria-hidden="true"></i>}
          </button>
        </div>
      </Stack>
      <div style={{
        position: `absolute`,
        top: `66px`,
        right: `20px`
      }} >{userId ? <h4 ref={timerView} id={css.timeLogsLink}></h4> : null}</div>
    </Stack>
  </>

  );
}
