import React from 'react';
import css from './messages.module.css';
import { momentWrapper } from 'src/momentWrapper';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Box, Grid, Link } from '@mui/material';
import { keyframes } from '@emotion/react';
import ErrorIcon from '@mui/icons-material/Error';
import { capitalizeFirstLetter } from 'src/utils';
import PhoneMissedIcon from '@mui/icons-material/PhoneMissed';

const animate = keyframes` 0% {
  opacity: .3;
}

50% {
  opacity: .5;
}

100% {
  opacity: .8;
}`;

export const PracticeMessageCard = ({ i, msg }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down(`sm`));
  let error = msg?.error;
  let status = msg?.status;
  let animateCss = `${animate} 2s linear infinite alternate`
  const pdfFileStyles = {
    backgroundColor: msg.messageDirection == `outbound` ? `rgb(239 239 239 / 74%)` : `rgb(255, 181, 181)`,
    marginRight: msg.messageDirection == `outbound` ? `auto` : ``,
    marginLeft: !msg.messageDirection == `outbound` ? `auto` : ``,
    margin: `7px 0px`,
    width: matches ? `90%` : `60%`,
    borderRadius: `10px`,
    padding: `5px`,
  };
  const pendingPdfStyles = {
    width: matches ? `90%` : `60%`,
    backgroundColor: `rgb(239 239 239 / 74%)`,
    borderRadius: 5,
    fontSize: `x-large`,
    animation: `${error || status === 'failed' ? '' : animateCss}`,
    border: error || status === `failed` ? `1px solid red` : `1px solid rgb(22, 153, 197)`,
    mt: 1,
  };

  const MessagesInterface = ({ children }) => {
    return <div id={msg.messageDirection == `outbound` ? css.cardMainMD : css.cardMainPatient}>{children}</div>;
  };
  const PendingMessageIInterface = ({ children }) => {
    return (
      <Box
        sx={{
          border: error ? `1px solid red` : ``,
          backgroundColor: `rgb(239 239 239 / 74%)`,
          animation: error ? `` : `${animate} 2s linear infinite alternate`,
          width: `80%`,
          pt: 0.4,
          pr: 0.4,
          pl: 0.4,
          ml: 0.3,
          borderRadius: 1,
          mt: 0.5,
        }}
      >
        {children}
      </Box>
    );
  };

  const toShowData = () => {
    let type = msg?.mediaType;

    let date = msg?.date || msg?.createdAt ? momentWrapper(msg?.date || msg?.createdAt).format(`MM/DD hh:mm A`) : ``;
    let { createdBy } = msg;
    let firstName = capitalizeFirstLetter(createdBy?.firstName);
    let lastName = capitalizeFirstLetter(createdBy?.lastName);
    if (msg?.type === `Audio Call`) {
      return (
        <MessagesInterface>
          <PhoneMissedIcon color="error" />
          <p id={css.cardTimeStamp}>{date}</p>
        </MessagesInterface>
      );
    } else if ((!msg?.status && !error) || (msg?.status === `delivered` && !error) || msg?.messageDirection === `inbound`) {
      if (msg.type === `MMS`) {
        let img = `image`;
        let vid = `video`;
        let aud = `audio`;
        let app = `application`;
        let csv = `text/csv`;

        if (type?.includes(vid)) {
          return (
            <div className={msg.messageDirection == `outbound` ? css.mdVideo : css.cardPatientVideo}>
              <video controls id={msg.messageDirection == `outbound` ? css.videoMsg : css.cardPatientVideomsg}>
                <source src={msg.media} type={`video/${type}`} />
                <source src={msg.media} type="video/ogg" />
              </video>
              {msg.messageDirection === `outbound` &&
                (msg?.createdBy ? <p className={css.nameStamp}>{firstName + ` ` + lastName + `, ` + msg?.createdBy?.providerType}</p> : `-`)}
              <p id={css.cardTimeStamp}>{date}</p>
            </div>
          );
        } else if (type?.includes(img)) {
          return (
            <a href={`${msg.media}`} rel="noopener noreferrer" target="_blank" className={css.msgLink}>
              <div id={msg.messageDirection === `outbound` ? css.cardMainMDimageblock : css.cardPatientImage}>
                <img id={msg.messageDirection === `outbound` ? css.cardMainMDimage : css.patientImage} src={msg.media} alt="chat" />
                {msg.messageDirection === `outbound` &&
                  (msg?.createdBy ? <p className={css.nameStamp}>{firstName + ` ` + lastName + `, ` + msg?.createdBy?.providerType}</p> : `-`)}
                <p id={css.cardTimeStamp}>{date}</p>
              </div>
            </a>
          );
        } else if (type?.includes(aud)) {
          return (
            <div className={msg.messageDirection == `outbound` ? css.mdAudiomsg : css.patientAudio}>
              <audio className={msg.messageDirection == `outbound` ? css.mdAudio : css.patientAudiomsg} controls>
                <source src={msg.media} type={`audio/${type}`} />
                <source src={msg.media} type="audio/ogg" />
              </audio>
              {msg.messageDirection === `outbound` &&
                (msg?.createdBy ? <p className={css.nameStamp}>{firstName + ` ` + lastName + `, ` + msg?.createdBy?.providerType}</p> : `-`)}
              <p id={css.cardTimeStamp}>{date}</p>
            </div>
          );
        } else if (type?.includes(csv)) {
          return (
            <Box sx={{ ...pdfFileStyles }}>
              <Box sx={{ mt: -0.2 }}>
                <Link sx={{ fontSize: `x-large` }} href={msg.media} underline="none" target="_blank">
                  <Box component="div" sx={{ display: `inline-block`, marginLeft: 1 }}>
                    <i className="text-info bi bi-file-earmark-binary-fill"></i>
                  </Box>
                  {matches ? (
                    <></>
                  ) : (
                    <Box component="div" sx={{ display: `inline-block`, fontSize: `x-small`, verticalAlign: `middle`, p: 0 }}>
                      Open file
                    </Box>
                  )}
                </Link>
              </Box>
              {msg.messageDirection === `outbound` &&
                (msg?.createdBy ? <p className={css.nameStamp}>{firstName + ` ` + lastName + `, ` + msg?.createdBy?.providerType}</p> : `-`)}
              <p id={css.cardTimeStamp}>{date}</p>
            </Box>
          );
        } else if (type?.includes(app)) {
          return (
            <Box sx={{ ...pdfFileStyles }}>
              <Box sx={{ mt: -0.2 }}>
                <Link sx={{ fontSize: `x-large` }} href={msg.media} underline="none" target="_blank">
                  <Box component="div" sx={{ display: `inline-block`, marginLeft: 1 }}>
                    <i className="text-danger bi bi-file-earmark-pdf-fill"></i>
                  </Box>
                  {matches ? (
                    <></>
                  ) : (
                    <Box component="div" sx={{ display: `inline-block`, fontSize: `x-small`, verticalAlign: `middle`, p: 0 }}>
                      Open file
                    </Box>
                  )}
                </Link>
              </Box>
              {msg.messageDirection === `outbound` &&
                (msg?.createdBy ? <p className={css.nameStamp}>{firstName + ` ` + lastName + `, ` + msg?.createdBy?.providerType}</p> : `-`)}
              <p id={css.cardTimeStamp}>{date}</p>
            </Box>
          );
        }
      } else {
        return (
          <MessagesInterface>
            <p>{msg.message}</p>
            {msg.messageDirection === `outbound` &&
              (msg?.createdBy ? <p className={css.nameStamp}>{firstName + ` ` + lastName + (msg?.createdBy?.providerType ? ', ' : '') + (msg?.createdBy?.providerType || '')}</p> : `-`)}
            <p id={css.cardTimeStamp}>{date}</p>
          </MessagesInterface>
        );
      }
    }
  };

  const showSendingSms = () => {
    let type = msg?.type;
    let fileType = msg?.fileTtype;
    let source = msg?.file;
    let vid = `video`;
    let audio = `audio`;
    let img = `image`;
    let app = `application`;
    let error = msg?.error;
    let message = msg?.message;
    let status = msg?.status?.toLowerCase();
    let media = msg?.media;
    let mediaType = msg?.mediaType;
    if (status === `pending` || msg?.error || status === `failed`) {
      if (type === `MMS`) {
        if (media ? mediaType?.includes(vid) : fileType?.includes(vid)) {
          return (
            <PendingMessageIInterface>
              <div id={css.videopPending}>
                <video className={css.pendingVideoTag} controls>
                  <source src={media || source} type="video/mp4" />
                  <source src={media || source} type="video/ogg" />
                </video>
                {error || status === `failed` ? <p className="text-danger">Failed</p> : <p>Sending....</p>}
              </div>
            </PendingMessageIInterface>
          );
        } else if (media ? mediaType?.includes(audio) : fileType?.includes(audio)) {
          return (
            <PendingMessageIInterface>
              <div id={css.audioPending}>
                <audio controls>
                  <source src={media || source} type="audio/ogg" />
                  <source src={media || source} type="audio/mpeg" />
                </audio>
                {error || status === `failed` ? <p className="text-danger">Failed</p> : <p>Sending....</p>}
              </div>
            </PendingMessageIInterface>
          );
        } else if (media ? mediaType?.includes(img) : fileType?.includes(img)) {
          return (
            <Box
              component="div"
              sx={{
                animation: error || status === `failed` ? `` : `${animate} 2s linear infinite alternate`,
                width: `80%`,
                mt: 1,
              }}
            >
              <img
                style={{ border: `${msg?.error || status === 'failed' ? '2px solid red' : '2px solid rgb(22, 153, 197)'}` }}
                className={css.imagePending}
                src={media || source}
                alt="ok"
              />
              {error || status === `failed` ? <p className="text-danger">Failed</p> : <p>Sending....</p>}
            </Box>
          );
        } else if (media ? mediaType?.includes(app) : fileType?.includes(app)) {
          return (
            <Box
              sx={{
                ...pendingPdfStyles,
              }}
            >
              <div style={{ marginLeft: `5px` }}>
                {` `}
                <i className="text-danger bi bi-file-earmark-pdf p-0"></i>
                <div style={{ display: `inline-block` }}>
                  {` `}
                  {error || status === `failed` ? <p className="text-danger ml-2">Failed</p> : <p>Sending....</p>}
                </div>
              </div>
            </Box>
          );
        } else if (media ? mediaType?.includes(`text/csv`) : fileType?.includes(`text/csv`)) {
          return (
            <Box
              sx={{
                ...pendingPdfStyles,
              }}
            >
              <div style={{ marginLeft: `5px` }}>
                {` `}
                <i className={`${error || status === 'failed' ? 'text-danger' : 'text-info'} bi bi-file-earmark-binary-fill`}></i>
                <div style={{ display: `inline-block` }}>
                  {` `}
                  {error || status === `failed` ? <p className="text-danger ml-2">Failed</p> : <p>Sending....</p>}
                </div>
              </div>
            </Box>
          );
        }
      } else if (type === `SMS`) {
        return (
          <PendingMessageIInterface>
            <div id={css.textPnding}>
              <p>{message}</p>
              {error || status === `failed` ? (
                <Grid container>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <p className="text-danger">Failed</p>
                  </Grid>

                  <Grid item sx={{ textAlign: `end` }} xs={6} sm={6} md={6} lg={6} xl={6}>
                    <ErrorIcon sx={{ color: `red`, fontSize: `small`, mt: -0.3 }} />
                  </Grid>
                </Grid>
              ) : (
                <p>Sending....</p>
              )}
            </div>
          </PendingMessageIInterface>
        );
      }
    }
  };
  return (
    <>
      {toShowData()}
      {showSendingSms()}
    </>
  );
};
