import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  postRequest,
  CREATE_ADDENDUM_REPORT,
  CREATE_ADDENDUM_REPORT_RESPIRONICS,
  PATIENT_CREATE_REPORT_URL,
  PATIENT_CREATE_REPORT_URL_RESPIRONICS,
  SEND_REPORT_TO_MD,
  SEND_ADDENDUM_REPORT,
  PATIENT_CREATE_REPORT_URL_REACT_HEALTH,
  CREATE_ADDENDUM_REPORT_REACT_HEALTH,
} from 'src/crud/crud';
import { errorToast, successToast } from 'src/pages/common/components/snackBar/toast';
import { handleReportLoadingFlag, handleTimer, saveTimerUser } from 'src/redux/patient/patientActions';
import { momentWrapper } from 'src/momentWrapper';
import TaskAltRoundedIcon from '@mui/icons-material/TaskAltRounded';
import CircularProgress from '@mui/material/CircularProgress';
import { REACT_HEALTH, btnLoader, capitalizeFirstLetter } from 'src/utils';
import { removeFromSeriesData, saveInClickedRep } from 'src/redux/PatientOverview/Reports.actions';
import { saveTimerReportId } from '../../../PatientComponent';
import { store } from 'src/redux/store';


const defPatRep = { data: [{ device: { manufacturer: `` }, reports: [{ reportStatus: ``, billingStatus: `` }] }] }

export const SignButton = ({ loadingReport = false, forwardPage, patientReport = defPatRep, onClose, isFromView, reportFromView, patientIdFromView, timeFromView, interactiveCommunicationFromView }) => {
  const { user } = useSelector((state) => state.user);
  const {
    readyToSignModal: { patientId, reportId },
    swipeAbleSeries,
    isOpenSwipe,
    hasPoMoreData,
  } = useSelector((state) => state?.readyToSign);
  const { reportLoadingFlag, timer }
    = useSelector((state) => state.patient);
  const { startTime } = timer;
  const { data = [{ device: { manufacturer: `` }, reports: [{ reportStatus: ``, billingStatus: `` }] }] } = patientReport;
  const patientManufacturer = data?.[0]?.device?.manufacturer;
  const [loading, setLoading] = useState(false);
  const dispatchRedux = useDispatch();
  const isLastRep = isOpenSwipe && swipeAbleSeries[swipeAbleSeries.length - 1]?._reportId === reportId


  const setLoadingFalse = () => {
    setLoading(false);
    dispatchRedux(handleReportLoadingFlag(false))
  }


  useEffect(() => {
    if (reportId) setLoadingFalse()
  }, [reportId])




  const onCloseHandler = (shouldAddTime, shouldClearVal, shouldClenClickedRep) => {
    const {
      hasPoMoreData
    } = store.getState().readyToSign
    onClose(shouldAddTime, !hasPoMoreData && shouldClearVal);
    if (shouldClenClickedRep) dispatchRedux(saveInClickedRep(reportId));
  };

  const successAlertHandler = (text, isLastRep) => {
    const {
      hasPoMoreData
    } = store.getState().readyToSign
    successToast(`Report has been ${text} successfully.`);
    if ((!isOpenSwipe) || (!hasPoMoreData && isLastRep)) dispatchRedux({ type: `REPORTACTION`, payLoad: {} });
  };

  const clearValuesIfLastRep = (isLastRep) => {
    let shouldAddTime = () => {
      if (!isOpenSwipe) return false;
      else if (isOpenSwipe && isLastRep) return true;
      return false;
    };
    if (isLastRep) onCloseHandler(shouldAddTime(), false, false);
  };

  const makeAddendumReport = async () => {
    onCloseHandler(!isOpenSwipe, !!(!isOpenSwipe || (isOpenSwipe && isLastRep)), true);
    let patientName = givePatientName(data)
    if (user?.defaultSignature?.base64String) {
      setLoading(true);
      dispatchRedux(handleReportLoadingFlag(true));
      if (timer?.toggleButton) {
        dispatchRedux(handleTimer({ status: false }));
        const endTimeValue = momentWrapper().toISOString();
        const timeDiff = momentWrapper(endTimeValue).diff(startTime, `seconds`);
        if (timeDiff > 5) {
          await dispatchRedux(
            saveTimerUser({
              videoOrAudioOn: null,
              updateVideoorAudioLogStatus: null,
              callSuccess: null,
              statuss: `View graphs and reports`,
              sources: `View Report`,
            })
          );
        }
      }

      if (patientManufacturer === `resmed`) {
        //here i have to send report id
        let url = CREATE_ADDENDUM_REPORT + patientId + `/?reportId=${reportId}`;
        postRequest(url, {
          signature: user.defaultSignature.base64String,
        })
          .then(() => {
            clearValuesIfLastRep(isLastRep);
            successAlertHandler(`addendum ${patientName}`, isLastRep);
            setLoadingFalse()
          })
          .catch(setLoadingFalse);
      } else if (patientManufacturer === REACT_HEALTH) {
        let url = CREATE_ADDENDUM_REPORT_REACT_HEALTH + patientId + `/?reportId=${reportId}`;
        //here i have to send report id
        postRequest(url, {
          signature: user.defaultSignature.base64String,
        })
          .then(() => {
            clearValuesIfLastRep(isLastRep);
            setLoadingFalse()
            successAlertHandler(`addendum ${patientName}`, isLastRep);
          })
          .catch(setLoadingFalse);
      } else if (patientManufacturer === `respironics`) {
        let url = CREATE_ADDENDUM_REPORT_RESPIRONICS + patientId + `/?reportId=${reportId}`;
        //here i have to send report id
        postRequest(url, {
          signature: user.defaultSignature.base64String,
        })
          .then(() => {
            clearValuesIfLastRep(isLastRep);
            successAlertHandler(`addendum ${patientName}`, isLastRep);
            setLoadingFalse()


          })
          .catch(setLoadingFalse);
      }
    } else {
      errorToast(`Please create your Signature before signing the report`);
    }
  };

  const givePatientName = (data) => {
    if (data?.[0]?.firstName && data?.[0]?.lastName) {
      const fullName = `${data[0].firstName} ${data[0].lastName}`;
      return `for ${capitalizeFirstLetter(fullName)}`;
    }
    return "";
  };

  // Sign as MD`
  const signReport = async () => {
    onCloseHandler(!isOpenSwipe, !!(!isOpenSwipe || (isOpenSwipe && isLastRep)), true);
    dispatchRedux(handleReportLoadingFlag(true));
    setLoading(true);
    let patientName = givePatientName(data)
    if (user?.defaultSignature?.base64String) {
      if (timer?.toggleButton) {
        dispatchRedux(handleTimer({ status: false }));
        const endTimeValue = momentWrapper().toISOString();
        const timeDiff = momentWrapper(endTimeValue).diff(startTime, `seconds`);
        if (timeDiff > 5) {
          await dispatchRedux(
            saveTimerUser({
              videoOrAudioOn: null,
              updateVideoorAudioLogStatus: null,
              callSuccess: null,
              statuss: `View graphs and reports`,
              sources: `View Report`,
            })
          );
        }
      }

      if (patientManufacturer === `resmed`) {
        let url = PATIENT_CREATE_REPORT_URL + patientId + `/?reportId=${reportId}`;
        postRequest(url, {
          signature: user.defaultSignature.base64String,
          startDate: momentWrapper().startOf(`month`).toISOString(),
          endDate: momentWrapper().toISOString(),
        })
          .then(() => {
            setLoadingFalse()
            clearValuesIfLastRep(isLastRep);
            successAlertHandler(`signed ${patientName}`, isLastRep);
          }
          )
          .catch(setLoadingFalse);

      } else if (patientManufacturer === REACT_HEALTH) {
        let url = PATIENT_CREATE_REPORT_URL_REACT_HEALTH + patientId + `/?reportId=${reportId}`;
        postRequest(url, {
          signature: user.defaultSignature.base64String,
          startDate: momentWrapper().startOf(`month`).toISOString(),
          endDate: momentWrapper().toISOString(),
        })
          .then(() => {
            setLoadingFalse()
            clearValuesIfLastRep(isLastRep);
            successAlertHandler(`signed ${patientName}`, isLastRep)
          })
          .catch(setLoadingFalse);
      } else if (patientManufacturer === `respironics`) {
        let url = PATIENT_CREATE_REPORT_URL_RESPIRONICS + patientId + `/?reportId=${reportId}`;
        postRequest(url, {
          signature: user.defaultSignature.base64String,
          startDate: momentWrapper().startOf(`month`).toISOString(),
          endDate: momentWrapper().toISOString(),
        })
          .then(() => {
            setLoadingFalse()
            clearValuesIfLastRep(isLastRep);
            successAlertHandler(`signed ${patientName}`, isLastRep)
          })
          .catch(setLoadingFalse);
      }
    } else {
      errorToast(`Please create your Signature before signing the report`);
    }
  };

  const sendReportToMedicalDoctor = () => {
    setLoading(true);
    postRequest(SEND_REPORT_TO_MD + patientIdFromView, {
      reportMonth: Number(momentWrapper().format(`YYYYMM`)),
    })
      .then((response) => {
        setLoading(false);
        successToast(response?.data?.message);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const sendToMd = async () => {
    setLoading(true);
    if (timer?.toggleButton) {
      dispatchRedux(handleTimer({ status: false }));
      await dispatchRedux(
        saveTimerUser({
          videoOrAudioOn: null,
          updateVideoorAudioLogStatus: null,
          callSuccess: null,
          statuss: `View graphs and reports`,
          sources: `View Report`,
        })
      );
    }
    sendReportToMedicalDoctor();
  };

  // CLINICAL STAFF - SEND ADDENDUM HANDLER
  const sendAddendumHandler = () => {
    setLoading(true);
    postRequest(SEND_ADDENDUM_REPORT + reportFromView?._id ? reportFromView?._id : null)
      .then((res) => {
        setLoading(false);
        successToast(res?.data?.message);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const sendAddendum = async () => {
    setLoading(true);
    sendAddendumHandler();
    if (timer?.toggleButton) {
      dispatchRedux(handleTimer({ status: false }));
      await dispatchRedux(
        saveTimerUser({
          videoOrAudioOn: null,
          updateVideoorAudioLogStatus: null,
          callSuccess: null,
          statuss: `View graphs and reports`,
          sources: `View Report`,
        })
      );
    }
  };

  const dispatchTimerForRep = () => {
    saveTimerReportId({
      id: patientId,
      auth: user?.auth,
      selectedPatientIdRedux: false,
      isFromView: false,
      customDesc: true,
    });
  }


  const fetchNextRepHandler = (cb) => {
    if (isOpenSwipe) {
      const removalAndProceeding = () => {
        dispatchRedux(removeFromSeriesData(reportId));
        dispatchRedux({
          type: `SIGNREPORT`, payLoad: {
            reportId: ``,
            patientId: ``,
            open: true, startDate: ``,
            endDate: ``
          }
        })
      }
      if (isLastRep && hasPoMoreData) {
        removalAndProceeding()
        forwardPage()
      }
      else if (isLastRep && !hasPoMoreData) {
        successToast(`End of reports.`)
      }
      else if (!isLastRep) {
        removalAndProceeding()
        dispatchTimerForRep()
      }

    }
    cb()
  };

  const actionButton = () => {
    if (isFromView) {
      if (
        user?.isClinicalStaff &&
        reportFromView &&
        (reportFromView.reportStatus === `SIGNED` || reportFromView?.reportStatus === `ADDENDUMED`) &&
        (reportFromView?.billingStatus === `READY_TO_BILL` || reportFromView?.billingStatus === `BILLED`)
      ) {
        return (
          <button
            type="button"
            disabled={!!(loadingReport || loading)}
            onClick={sendAddendum}
            className="btn btn-outline-info btn-sm"
            style={{ fontSize: `9px`, height: `24px`, marginTop: `10px` }}
          >
            {btnLoader({ loading: loading, loader: <CircularProgress size={10} />, text: `Send Addendum` })}
          </button>
        );
      } else if (user?.isClinicalStaff && timeFromView / 60 > 20 && interactiveCommunicationFromView && !reportFromView) {
        return (
          <button
            type="button"
            disabled={!!(loadingReport || loading)}
            onClick={sendToMd}
            className="btn btn-outline-info btn-sm"
            style={{ fontSize: `9px`, height: `24px`, marginTop: `10px` }}
          >
            {btnLoader({ loading: loading, loader: <CircularProgress size={10} />, text: `Send to MD` })}
          </button>
        );
      } else {
        return <TaskAltRoundedIcon color="success" />;
      }
    } else if (data?.length) {
      if (
        data?.[0]?.timeMonitored / 60 > 20 && user?.isProvider && data?.[0]?.interactiveCommunication && data
        && data?.[0]?.reports?.[0]?.reportStatus === `READY_TO_SIGN` && data?.[0]?.reports?.[0]?.billingStatus === `NOT_READY_TO_BILL`
      ) {
        return (
          <button
            type="button"
            disabled={!!(loadingReport || loading || reportLoadingFlag)}
            onClick={() => fetchNextRepHandler(signReport)}
            className="btn btn-outline-info btn-sm"
            style={{ fontSize: `9px`, height: `24px`, marginTop: `10px`, marginLeft: `5px` }}
          >
            {btnLoader({ loading: loading, loader: <CircularProgress size={10} />, text: `Sign as MD` })}
          </button>
        );
      } else if (
        user?.isProvider &&
        (data?.[0]?.reports[0]?.reportStatus === `SIGNED` ||
          data?.[0]?.reports[0]?.reportStatus === `READY_TO_ADDENDUM` ||
          data?.[0]?.reports[0]?.reportStatus == `ADDENDUMED`)
      ) {
        return (
          <button
            type="button"
            disabled={!!(loadingReport || loading || reportLoadingFlag)}
            onClick={() => fetchNextRepHandler(makeAddendumReport)}
            className="btn btn-outline-info btn-sm"
            style={{ fontSize: `9px`, height: `24px`, marginTop: `10px`, marginLeft: `5px` }}
          >
            {btnLoader({ loading: loading, loader: <CircularProgress size={10} />, text: `Sign Addendum` })}
          </button>
        );
      }
      return <TaskAltRoundedIcon color="success" />;


    }
  };
  return <>{actionButton()}</>;
};
