import React, { useEffect, useRef, useState } from 'react';
import css from './messages.module.css';
import { PATIENT_ALL_TEXT_MESSAGES_URL, getRequest, PATIENT_INVITE_MESSAGE_URL, postRequest } from '../../../../../../../crud/crud';
import { useSelector, useDispatch } from 'react-redux';
import { _twilioOutGoingSMS } from '../../../../../../../redux/twilio/twilioActions';
import { PracticeMessageCard } from './card';
import { PatientVoiceCall } from '../voiceCall/voiceCall';
import { PatientVideoCall } from '../videoCall/videoCall';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { Box, Skeleton, Zoom } from '@mui/material';
import { successToast } from 'src/pages/common/components/snackBar/toast';
import { SendMsg } from './SendMsg';
import { useLocation } from 'react-router-dom';
import Spinner from 'react-spinners/PuffLoader';
import { setAllMessages, setMessagesCurrentPage, setMessagesHasMore } from '../../../../../../../redux/patient/patientActions';
import { PatientVoiceMails } from '../voiceMails/PatientVoiceMails';
import { returnIfArr } from 'src/utils';
import axios from 'axios'

export const chatSkelton = (
  <>
    <Skeleton className={css.chatSkeltonVideo} height={180} sx={{ mt: -3 }} type="rectangular" />
    <Skeleton className={css.chatSkeltonText} height={40} sx={{ mt: -2 }} type="rectangular" />
    <Skeleton className={css.chatSkeltonImage} height={180} sx={{ mt: -3 }} type="rectangular" />
    <Skeleton className={css.chatSkeltonTextInBound} height={40} sx={{ mt: -2 }} type="rectangular" />
    <Skeleton className={css.chatSkeltonVideo} height={180} sx={{ mt: -3 }} type="rectangular" />
    <Skeleton className={css.chatSkeltonText} height={40} sx={{ mt: -2 }} type="rectangular" />
    <Skeleton className={css.chatSkeltonImage} height={180} sx={{ mt: -3 }} type="rectangular" />
    <Skeleton className={css.chatSkeltonTextInBound} height={40} sx={{ mt: -2 }} type="rectangular" />
  </>
);

let chat = null;

export const PatientMessages = ({ reportId, setRefreshFlag, handleMessageToggle, toggle, setCurrentTab, currentTab, isTel, patientIdFromProp }) => {
  const [msgLoading, setMsgLoading] = useState(false);
  const [singleMsg, setSingleMsg] = useState(false);
  const dispatchRedux = useDispatch();
  const auth = useSelector((state) => state.user.auth);
  const patientId = useSelector((state) => state?.patient?.patientData?.data?._id);
  const patientUserName = useSelector((state) => state?.patient?.patientData?.data?.userName);
  const patientStatus = useSelector((state) => state?.patient?.patientData?.data?.status);
  const patientNum = useSelector((state) => state?.patient?.patientData?.data?.phoneNumber);
  const messagesStore = useSelector((state) => state.patient.messages);
  const page = useSelector((state) => state.patient.messagesCurrentPage);
  const hasMore = useSelector((state) => state.patient.messagesHasMore);
  const location = useLocation();
  const chatBox = useRef(null);

  useEffect(() => {
    const source = axios.CancelToken.source()
    if ((patientId || patientIdFromProp) && hasMore) {
      let pageParams = `?pageSize=10&pageNumber=${page}`
      let url = `${PATIENT_ALL_TEXT_MESSAGES_URL}${patientId || patientIdFromProp}${pageParams}`;
      setMsgLoading(true);
      getRequest(url, { cancelToken: source.token })
        .then(({ data }) => {
          setMsgLoading(false);
          dispatchRedux(setMessagesHasMore(data?.hasMore));
          if (page === 1) {
            dispatchRedux(setAllMessages([...returnIfArr(data?.messages).reverse()]));
            chatBox.current.scrollTop = chatBox?.current?.scrollHeight;
          } else {
            dispatchRedux(setAllMessages([...returnIfArr(data?.messages).reverse(), ...messagesStore]));
            chatBox.current.scrollTop = 785;
          }
        })
        .catch(() => {
          setMsgLoading(false);
        });
    }
    return () => source.cancel()
  }, [page, patientId, patientIdFromProp]);

  const handleScroll = (e) => {
    if (e.currentTarget.scrollTop === 0 && messagesStore?.length > 1 && (patientId || patientIdFromProp) && hasMore) {
      dispatchRedux(setMessagesCurrentPage(page + 1));
    }
  };
  const twilioOutGoingSMS = (payLoad) => {
    dispatchRedux(_twilioOutGoingSMS(payLoad));
  };
  // REDUX - SHIFT EVENTS TO MAIN.JS,

  chat =
    messagesStore.length > 0 &&
    messagesStore?.map((m, i) => {
      return <PracticeMessageCard i={i} msg={m} key={`key` + i} />;
    });
  function sendInvitation() {
    postRequest(PATIENT_INVITE_MESSAGE_URL + patientId, {})
      .then(() => {
        successToast(`Invitation text has been sent!`);
      })
      .catch();
  }

  useEffect(() => {
    if (singleMsg) {
      chatBox.current.scrollTop = chatBox.current.scrollHeight;
      setSingleMsg(false);
    }
  }, [singleMsg]);

  return (
    <div id={css.msgMain}>
      <div
        style={{
          display: `flex`,
          borderTop: `1px solid #c1c1c1`,
          borderBottom: `1px solid #c1c1c1`,
          backgroundColor: `#1699c5`,
          height: `30px`,
          color: `white`,
        }}
      >
        {toggle === true ? (
          <ExpandLessIcon onClick={() => handleMessageToggle(toggle)} />
        ) : (
          <ExpandMoreIcon onClick={() => handleMessageToggle(toggle)} />
        )}
        Communication Center
      </div>
      {toggle && (
        <Box
          sx={{
            height: `${location.pathname === '/patient' ? '80vh' : '100vh'}`,
            position: `relative`,
            backgroundColor: `white`,
          }}
        >
          <div id={css.customMsgHead}>
            <div
              id={currentTab === `msg` ? css.menuItemSelected : css.menuItem}
              onClick={() => (patientId ? setCurrentTab(`msg`) : null)}
              className="p-3"
            >
              <i style={{ fontSize: `x-large` }} className="fa fa-comment-o" aria-hidden="true"></i>
            </div>
            <div
              id={currentTab === `voice` ? css.menuItemSelected : css.menuItem}
              className="p-3"
              onClick={() => (patientId ? setCurrentTab(`voice`) : null)}
            >
              <i style={{ fontSize: `x-large` }} className="fa fa-phone" aria-hidden="true"></i>
            </div>
            <div
              id={currentTab === `video` ? css.menuItemSelected : css.menuItem}
              onClick={() => (patientId ? setCurrentTab(`video`) : null)}
              className="p-3"
            >
              <i style={{ fontSize: `x-large` }} className="fa fa-video-camera" aria-hidden="true"></i>
            </div>
            <div
              id={currentTab === `voiceMail` ? css.menuItemSelected : css.menuItem}
              onClick={() => (patientId ? setCurrentTab(`voiceMail`) : null)}
              className="p-2"
            >
              <i style={{ fontSize: `x-large` }} className="bi bi-voicemail" aria-hidden="true"></i>
            </div>
          </div>
          {currentTab === `voice` && (
            <PatientVoiceCall patientId={patientId} reportId={reportId} setRefreshFlag={setRefreshFlag} />
          )}

          {currentTab === `video` && (
            <PatientVideoCall reportId={reportId} setRefreshFlag={setRefreshFlag} />
          )}
          {currentTab === `msg` && (
            <>
              <Zoom in={currentTab !== `voice` || currentTab !== `video`} mountOnEnter unmountOnExit>
                <div
                  ref={chatBox}
                  id={css.customBody}
                  className="p-2"
                  style={{
                    backgroundColor: `white`,
                    overflow: `auto`,
                  }}
                  onScroll={handleScroll}
                >
                  {(patientId || patientIdFromProp) && msgLoading && messagesStore.length < 1 ? (
                    chatSkelton
                  ) : (
                    <>
                      <div
                        style={{
                          zIndex: 20,
                          display: `flex`,
                          justifyContent: `center`,
                          alignItems: `center`,
                        }}
                      >
                        <Spinner
                          //   css={override}
                          size={25}
                          color={`rgb(22, 153, 197)`}
                          loading={msgLoading}
                        />
                      </div>
                      {chat}
                    </>
                  )}
                </div>
              </Zoom>

              <div style={{ position: `absolute`, bottom: 0, width: `100%` }}>
                {patientStatus !== "inactive" && !isTel && !patientUserName && patientId && (
                  <button
                    type="button"
                    disabled={!patientId}
                    onClick={() => sendInvitation()}
                    className="btn-info btn-sm"
                    style={{ fontSize: "9px", height: "24px", marginBottom: "4px" }}
                  >
                    Invite Patient
                  </button>
                )}

                <SendMsg
                  slectedPhraseId={`patientMessengerPatientDetailPhrases`}
                  patientNumber={patientNum}
                  setSingleMsg={() => setSingleMsg(true)}
                  chatBox={chatBox}
                  auth={auth}
                  patientId={patientId}
                  twilioOutGoingSMS={(payLoad) => twilioOutGoingSMS(payLoad)}
                  overView={false}
                  conditionalStyles={{ bottom: `60px`, width: `90%`, left: `1.6%`, position: `absolute` }}
                />
              </div>
            </>
          )}

          {currentTab && currentTab !== `voice` && currentTab !== `video` && currentTab !== `msg` && (
            <PatientVoiceMails />
          )}
        </Box>
      )}
    </div>
  );
};
