import React, { useEffect, useState } from 'react';
import {
  Table, TableBody, TableCell, TableHead,
  TableRow
} from '@mui/material';
import { getRequest, postRequest } from 'src/crud/crud';
import { makeStyles } from '@mui/styles';
import { PulseSpinner } from '../spinner/spinner';
import { ConditionallyRenderWrapper } from '../../HOC/ConditionallyRenderWrapper';

const useStyles = makeStyles({
  tableHeading: {
    color: `#DCF0F7`,
  },
  tableHeader: {
    backgroundColor: `#1699C5`,
  },
  tableRowEven: {
    backgroundColor: `#F2F2F2`,
  },
  tableRowOdd: {
    backgroundColor: `white`,
  },
});

export const CustomTable = ({ route, method, body = {}, setDataKey, headers = [], keys = [], externalDep = 0 }) => {
  const ComponentStyles = useStyles();
  const [data, setData] = useState([]);
  const [loadingState, setLoading] = useState({ error: false, loading: false });
  const [depKey, setDepKey] = useState(0);
  const { loading, error } = loadingState;
  const loadingSetter = (key, value) => {
    setLoading((p) => ({ ...p, [key]: value }));
  };

  useEffect(() => {
    if (depKey || !depKey || externalDep) {
      const successHandler = (res) => {
        loadingSetter(`loading`, false);
        let data = setDataKey(res);
        setData(data);
      };
      const catcher = (error) => {
        loadingSetter(`loading`, false);
        loadingSetter(`error`, true);
      };

      const selectApiCall = (method) => {
        switch (method) {
          case `get`:
            return getRequest(route).then(successHandler).catch(catcher);
          case `post`:
            return postRequest(route, { ...body })
              .then(successHandler)
              .catch(catcher);
          default:
            return false;
        }
      };
      loadingSetter(`loading`, true);
      selectApiCall(method);
    }
  }, [depKey, externalDep]);

  return (
    <>
      {loading && <PulseSpinner />}
      <ConditionallyRenderWrapper con={!loading && !error}>
        <Table>
          <TableHead className={ComponentStyles.tableHeader}>
            <TableRow>
              {headers?.map((header) => {
                return (
                  <TableCell className={ComponentStyles.tableHeading} key={header}>
                    {header}
                  </TableCell>
                )
              })}
            </TableRow>
          </TableHead>
          <ConditionallyRenderWrapper con={data?.length}>
            <TableBody>
              {data?.map((row, ind) => {
                return (
                  <TableRows key={ind?._id} row={row} headers={[...keys]} setDepKey={setDepKey} />
                )
              })}
            </TableBody>
          </ConditionallyRenderWrapper>
          <ConditionallyRenderWrapper con={!data?.length}>
            <TableRow>
              <TableCell colSpan={8} align="center">
                No data available
              </TableCell>
            </TableRow>
          </ConditionallyRenderWrapper>
        </Table>
      </ConditionallyRenderWrapper>
    </>
  );
};

const TableRows = ({ row, headers, ind, setDepKey }) => {
  const ComponentStyles = useStyles();

  return (
    <TableRow className={ind % 2 === 0 ? ComponentStyles.tableRowEven : ComponentStyles.tableRowOdd}>
      {headers.map(({ key = () => null }) => {
        return (
          <TableCell key={row?._id}> {key(row, () => setDepKey((p) => p + 1))}</TableCell>
        );
      })}
    </TableRow>
  );
};