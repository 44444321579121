import { Skeleton } from '@mui/material';
import React from 'react';

export const LoadingState = ({ type }) => {

  let width = 80;
  let arr = [
    {
      id: 1,
      width: width,
      type: type,
    },
    {
      id: 2,
      width: width,
      type: type,
    },
    {
      id: 3,
      width: width,
      type: type,
    },
    {
      id: 4,
      width: width,
      type: type,
    },
    {
      id: 5,
      width: width,
      type: type,
    },
    {
      id: 6,
      width: width,
      type: type,
    },
    {
      id: 7,
      width: width,
      type: type,
    },
    {
      id: 8,
      width: width,
      type: type,
    },
    {
      id: 9,
      width: width,
      type: type,
    },
    {
      id: 10,
      width: width,
      type: type,
    },
    {
      id: 11,
      width: width,
      type: type,
    },
    {
      id: 12,
      width: width,
      type: type,
    },
    {
      id: 13,
      width: width,
      type: type,
    },
  ];

  return (
    <>
      {arr.map((e) => {
        return (
          <td key={e.id}>
            <Skeleton variant={e.type} />
          </td>
        );
      })}
    </>
  );
}
